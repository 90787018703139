import { makeVar } from "@apollo/client";

const defaultValue = {
  sceneOrigin: null,
};

export const sceneOriginVar = makeVar(defaultValue);

export function setSceneOrigin(sceneOrigin) {
  sceneOriginVar({
    sceneOrigin,
  });
}

export function clearSceneOrigin() {
  sceneOriginVar(defaultValue);
}
