import React from "react";
import { useReactiveVar } from "@apollo/client";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";

//icons
import EditIcon from "@mui/icons-material/Edit";
import LaunchIcon from "@mui/icons-material/Launch";
import CloseIcon from "@mui/icons-material/Close";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";

import { userVar } from "../../../gql";
import { userIsAdminOrEditor } from "../../../accessControl";

function handleEditClick(id) {
  window.open(
    `${process.env.REACT_APP_ADMIN_URI}/digital-scene-digital-assets/${id}`
  );
}

function CustomMenuItem({ handleClick, icon, label }) {
  return (
    <MenuItem
      dense
      onClick={handleClick}
      disableGutters
      sx={{
        px: 1,
        justifyContent: "flex-start",
        "& .MuiListItemIcon-root ": {
          minWidth: 18,
        },
        "& .MuiListItemIcon-root .MuiSvgIcon-root": {
          fontSize: 13,
        },

        "&:hover": {
          "& .MuiListItemIcon-root .MuiSvgIcon-root": {
            fill: (theme) => theme.palette.primary.main,
          },

          "& .MuiTypography-root": {
            color: (theme) => theme.palette.primary.main,
          },
        },
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <Typography
        sx={{
          fontSize: 12,
        }}
      >
        {label}
      </Typography>
    </MenuItem>
  );
}

export function AssetContextMenu({
  asset,
  contextMenu,
  handleClose,
  flyToAssetById,
}) {
  const user = useReactiveVar(userVar);

  return (
    <Menu
      sx={{
        zIndex: (theme) => theme.zIndex.contextMenu,

        "& .MuiPaper-root": {
          borderRadius: 0,
        },

        "& .MuiList-root": {
          py: 0,
        },
      }}
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
      }
    >
      {userIsAdminOrEditor(user) && (
        <CustomMenuItem
          handleClick={() => {
            handleEditClick(asset.id);
            handleClose();
          }}
          icon={<EditIcon />}
          label="编辑"
        ></CustomMenuItem>
      )}

      <CustomMenuItem
        handleClick={() => {
          window.open(asset.digitalAsset.viewerUrl);
          handleClose();
        }}
        icon={<LaunchIcon />}
        label="查看资产"
      ></CustomMenuItem>

      <CustomMenuItem
        handleClick={() => {
          flyToAssetById(asset.id, { fly: true });
          handleClose();
        }}
        icon={<CenterFocusStrongIcon />}
        label="飞行定位"
      ></CustomMenuItem>

      <CustomMenuItem
        handleClick={handleClose}
        icon={<CloseIcon />}
        label="关闭菜单"
      ></CustomMenuItem>
    </Menu>
  );
}
