import React from "react";
import { useReactiveVar } from "@apollo/client";
import Portal from "@mui/base/Portal";

import { useGlobalFlyToAssetById } from "../../../hooks";
import {
  useDrawingContext,
  useSourceObjectContext,
  useSceneContext,
  useSourceObjectRenderMapContext,
  useSupportedMetasMapContext,
} from "../../../hooks";

import {
  dispatchdrawingStatusVar,
  dispatchDrawingToolVar,
} from "../../../components";
import { GeometryRender } from "./GeometryRender";
import { SourceObjectContent } from "../SourceObjectContent";

import {
  SourceObjectEditPanel,
  SourceObjectUpdateForm,
} from "../createSourceObject";

function usePanel(initOpen = false) {
  const [open, setOpen] = React.useState(initOpen);
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  return [open, setOpen, handleClose];
}

export function SourceObjectRenderer({
  sourceObject,
  flyTo,
  // renderGeometry = true, //控制是否渲染几何,或只渲染面板,用于在table里复用
  ...props
}) {
  const { metasMap } = useSupportedMetasMapContext();
  const meta = metasMap[sourceObject.meta.id];

  const { showAllSourceObjects, metasRenderMap, sourceObjectsRenderMap } =
    useSourceObjectRenderMapContext();

  const show = React.useMemo(() => {
    return (
      showAllSourceObjects &&
      metasRenderMap[sourceObject.meta.id] &&
      sourceObjectsRenderMap[sourceObject.id]
    );
  }, [
    showAllSourceObjects,
    metasRenderMap,
    sourceObjectsRenderMap,
    sourceObject,
  ]);

  //控制菜单开关
  const [showContent, setShowContent] = React.useState(false);
  const handleClose = React.useCallback(() => {
    setShowContent(false);
  }, [setShowContent]);
  const toggleClose = React.useCallback(() => {
    setShowContent((state) => !state);
  }, [setShowContent]);

  //控制源对象创建面板开关
  const [
    openEditSourceObjectPanel,
    setOpenEditSourceObjectPanel,
    handleSourceObjectPanelClose,
  ] = usePanel(false);

  const handleEditSourceObjectPanelClose = React.useCallback(() => {
    // 重置绘制工具状态,清除载入的数据，避免uuid冲突，并关闭面板
    dispatchDrawingToolVar({
      type: "SET_TO_DEFAULT",
    });
    dispatchdrawingStatusVar({
      type: "SET_TO_DEFAULT",
    });
    handleSourceObjectPanelClose();
  }, [handleSourceObjectPanelClose]);
  return openEditSourceObjectPanel ? (
    // 渲染对象编辑面板
    <Portal>
      <SourceObjectEditPanel
        handleClose={handleEditSourceObjectPanelClose}
        handleId="draggable-update-sourceobject-panel"
      >
        <SourceObjectUpdateForm
          sourceObject={sourceObject}
          handleEditSourceObjectPanelClose={handleEditSourceObjectPanelClose}
        ></SourceObjectUpdateForm>
      </SourceObjectEditPanel>
    </Portal>
  ) : (
    <>
      {/* 源对象几何渲染 */}
      {sourceObject.geometry && (
        <GeometryRender
          sourceObject={sourceObject}
          geometryProps={meta.geometryProps}
          show={show}
          onClick={toggleClose}
        ></GeometryRender>
      )}

      {/* 源对象内容渲染 */}
      {showContent && (
        <SourceObjectContent
          sourceObject={sourceObject}
          meta={meta}
          handleClose={handleClose}
          setOpenEditSourceObjectPanel={setOpenEditSourceObjectPanel}
        ></SourceObjectContent>
      )}
    </>
  );
}
