import pdfImg from "./pdf.png";
import documentImg from "./document.png";
import presentationImg from "./presentation.png";
import sheetImg from "./sheet.png";
import txtImg from "./txt.png";
import photoImg from "./photo.png";
import zipImg from "./zip.png";
import fileImg from "./file.png";
import videoImg from "./video.png";
import audioImg from "./audio.png";

export const fileExtensionToIcon = {
  mp4: videoImg,
  mp3: audioImg,
  wav: audioImg,
  txt: txtImg,
  pdf: pdfImg,
  zip: zipImg,
  "7z": zipImg,
  rar: zipImg,
  png: photoImg,
  jpg: photoImg,
  jpeg: photoImg,
  svg: photoImg,
  gif: photoImg,
  doc: documentImg,
  docx: documentImg,
  xls: sheetImg,
  xlsx: sheetImg,
  ppt: presentationImg,
  pptx: presentationImg,
  default: fileImg,
};

export function getFileExtIcon(filename) {
  let ext;
  const fileSplit = filename.split(".");
  if (fileSplit.length > 1) {
    ext = fileSplit.pop();
  } else {
    ext = "default";
  }
  return fileExtensionToIcon[ext] || fileExtensionToIcon.default;
}
