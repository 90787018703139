import React from "react";
import { useReactiveVar } from "@apollo/client";

import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

//icons
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import {
  setFpvMoveRate,
  cameraModeVar,
  minMoveRate,
  maxMoveRate,
} from "../../../../gql";

function calculateValue(value) {
  return 2 ** (value - 1);
}

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="bottom" title={value} arrow>
      {children}
    </Tooltip>
  );
}

export function FpvSlider(props) {
  const { moveRate } = useReactiveVar(cameraModeVar);

  const handleSliderChange = (event, newValue) => {
    setFpvMoveRate(newValue);
  };

  return (
    <Stack
      spacing={1.5}
      direction="row"
      sx={{
        alignItems: "center",
        mb: 1,
      }}
    >
      <Tooltip title="慢速移动" placement="bottom">
        <DirectionsWalkIcon></DirectionsWalkIcon>
      </Tooltip>

      <Slider
        value={moveRate}
        onChange={handleSliderChange}
        aria-labelledby="moverate-slider"
        max={maxMoveRate}
        min={minMoveRate}
        scale={calculateValue}
        valueLabelDisplay="auto"
        components={{
          ValueLabel: ValueLabelComponent,
        }}
      />

      <Tooltip title="快速移动" placement="bottom">
        <DirectionsRunIcon></DirectionsRunIcon>
      </Tooltip>
    </Stack>
  );
}
