import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export function Page404(props) {
  return (
    <Box
      sx={{
        p: 4,
        textAlign: "center",
        background: "transparent",
      }}
    >
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        404
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        此页面不存在
      </Typography>

      {/* <Button
        component={Link}
        to="/"
        variant="contained"
        color="secondary"
        sx={{
          mt: 2,
        }}
      >
        返回首页
      </Button> */}
    </Box>
  );
}
