import React from "react";
import { useReactiveVar } from "@apollo/client";
import { Entity, PointGraphics } from "resium";
import { Color, VerticalOrigin } from "cesium";

import { depthTestVar } from "../../../../gql";

import { iconVarientMap } from "../../icons";

import {
  radianArrayToCartesian3,
  createCesiumColor,
  useRequestRender,
} from "../../utils";

function PointEntity({ entityProps, graphicProps }) {
  //在性能优化的情况下，请求主动渲染
  useRequestRender();
  return (
    <Entity {...entityProps}>
      <PointGraphics
        verticalOrigin={VerticalOrigin.BOTTOM}
        // 始终显示不被遮挡
        // disableDepthTestDistance={Number.POSITIVE_INFINITY}
        {...graphicProps}
      />
    </Entity>
  );
}

export function PointRender({ data, ...props }) {
  //深度检测设置,耦合了全局设置，破坏独立性
  const { enableDepthTest } = useReactiveVar(depthTestVar);
  const disableDepthTestDistance = enableDepthTest
    ? undefined
    : Number.POSITIVE_INFINITY;

  const { type, id, position, color, alpha, outlineColor, ...extraProps } =
    data;

  const pointEntityProps = {
    entityProps: { id, position: radianArrayToCartesian3(position), ...props },
    graphicProps: {
      color: createCesiumColor({ color, alpha }),
      disableDepthTestDistance,
      outlineColor: createCesiumColor({ color: outlineColor, alpha: 1 }),
      ...extraProps,
    },
  };

  return <PointEntity {...pointEntityProps}></PointEntity>;
}
