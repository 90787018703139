import React from "react";
import { useReactiveVar } from "@apollo/client";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuList from "@mui/material/MenuList";
import Divider from "@mui/material/Divider";

//icons
import PlaceIcon from "@mui/icons-material/Place";
import StraightenIcon from "@mui/icons-material/Straighten";
import HomeIcon from "@mui/icons-material/Home";
import SpeedIcon from "@mui/icons-material/Speed";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
//local
import { useGoHome } from "../../hooks";

import {
  screenEventsVar,
  removeEvent,
  addEvent,
  viewerSettingVar,
  setViewerSetting,
} from "../../gql";
import { ToolSettingPanel } from "../panel";
import {
  Cesium3DTileMaxMemoryUsageSlider,
  PrecisionSlider,
  FxaaSwitch,
  BackFaceCullingSwitch,
  ShowFpsSwitch,
  ViewLinkMenuItem,
  CompassMenuItem,
  SetAsHomeViewMenuItem,
  BackgroundColorMenuItem,
  ScreenshotMenuItem,
} from "./menuItem";

export function ToolMenu() {
  const screenEvents = useReactiveVar(screenEventsVar);

  const goHome = useGoHome();

  const handleToggleEvent = (eventName) => () => {
    screenEventsVar()[eventName] ? removeEvent(eventName) : addEvent(eventName);
  };

  return (
    <MenuList
      sx={{
        "& .MuiMenuItem-root:hover": {
          color: "primary.main",
          "& .MuiSvgIcon-root": {
            color: "primary.main",
          },
        },
      }}
    >
      <MenuItem onClick={handleToggleEvent("positionTool")}>
        <ListItemIcon>
          <PlaceIcon />
        </ListItemIcon>
        拾取坐标
      </MenuItem>
      <MenuItem onClick={handleToggleEvent("distanceTool")}>
        <ListItemIcon>
          <StraightenIcon />
        </ListItemIcon>
        测量距离
      </MenuItem>
      <CompassMenuItem></CompassMenuItem>

      <Divider variant="fullWidth" />

      {/* 视角链接 */}
      <ViewLinkMenuItem></ViewLinkMenuItem>

      {/* 设为初始 */}
      <SetAsHomeViewMenuItem></SetAsHomeViewMenuItem>

      <MenuItem onClick={goHome}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        初始视角
      </MenuItem>

      <Divider />

      {/* 背景切换 */}
      <BackgroundColorMenuItem></BackgroundColorMenuItem>

      {/* 截屏录屏工具 */}
      <ScreenshotMenuItem></ScreenshotMenuItem>

      <MenuItem onClick={handleToggleEvent("precisionTool")}>
        <ListItemIcon>
          <DisplaySettingsIcon />
        </ListItemIcon>
        渲染设置
      </MenuItem>
      {/* 渲染设置调整面板 */}
      {screenEvents.precisionTool && (
        <ToolSettingPanel panelTitle="渲染设置调整" toolName="precisionTool">
          <PrecisionSlider></PrecisionSlider>
          <Cesium3DTileMaxMemoryUsageSlider></Cesium3DTileMaxMemoryUsageSlider>
          <FxaaSwitch></FxaaSwitch>
          <BackFaceCullingSwitch></BackFaceCullingSwitch>
          <ShowFpsSwitch></ShowFpsSwitch>
        </ToolSettingPanel>
      )}

      {/* <MenuItem
        onClick={() => {
          setViewerSetting({
            showFps: !viewerSettingVar().showFps,
          });
        }}
      >
        <ListItemIcon>
          <SpeedIcon />
        </ListItemIcon>
        渲染帧率
      </MenuItem> */}
    </MenuList>
  );
}
