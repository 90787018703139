import React from "react";
import Box from "@mui/material/Box";
import Portal from "@mui/base/Portal";

import logo from "../assets/logos/logo.png";

export function Logo(props) {
  return (
    <Portal>
      <Box
        component="img"
        src="/logo.png"
        alt="源场景"
        sx={{
          position: "fixed",
          top: (theme) => theme.spacing(2),
          left: (theme) => theme.spacing(2),
          zIndex: (theme) => theme.zIndex.logo,
          width: {
            xs: 80,
            sm: 90,
            md: 100,
            lg: 110,
            xl: 120,
          },
        }}
      ></Box>
    </Portal>
  );
}
