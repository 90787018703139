import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useGoHome } from "../../../hooks";

export function HomeIconButton() {
  const goHome = useGoHome();

  return (
    <Tooltip arrow title="初始视角" placement="top">
      <IconButton size="small" onClick={goHome} color="white">
        <HomeOutlinedIcon></HomeOutlinedIcon>
      </IconButton>
    </Tooltip>
  );
}
