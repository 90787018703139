import React from "react";
import { useReactiveVar } from "@apollo/client";
import { Entity, ModelGraphics } from "resium";
import {
  ColorBlendMode,
  HeightReference,
  DistanceDisplayCondition,
  ShadowMode,
  Color,
} from "cesium";

import { depthTestVar } from "../../../../gql";

import { iconVarientMap } from "../../icons";

import {
  radianArrayToCartesian3,
  createCesiumColor,
  useRequestRender,
  createOrientation,
} from "../../utils";

function GlbModelEntity({ entityProps, graphicProps }) {
  //在性能优化的情况下，请求主动渲染
  useRequestRender();
  return (
    <Entity {...entityProps}>
      <ModelGraphics
        // 始终显示不被遮挡
        // disableDepthTestDistance={Number.POSITIVE_INFINITY}
        distanceDisplayCondition={
          new DistanceDisplayCondition(0, Number.POSITIVE_INFINITY)
        }
        // shadows={ShadowMode.ENABLED}
        {...graphicProps}
      />
    </Entity>
  );
}

export function GlbModelRender({ data, ...props }) {
  // console.log({ data, props });
  //深度检测设置,耦合了全局设置，破坏独立性
  //   const { enableDepthTest } = useReactiveVar(depthTestVar);
  //   const disableDepthTestDistance = enableDepthTest
  //     ? undefined
  //     : Number.POSITIVE_INFINITY;

  const {
    type,
    id,
    position,
    color,
    alpha,
    colorBlendMode,
    heightReference,
    orientation,
    //设置轮廓后，部分模型会导致渲染崩溃，目前无解决方案
    // silhouetteSize,
    // silhouetteColor,
    ...extraProps
  } = data;

  const glbModelEntityProps = {
    entityProps: {
      id,
      position: radianArrayToCartesian3(position),

      orientation: orientation
        ? createOrientation({
            position: radianArrayToCartesian3(position),
            ...orientation,
          })
        : undefined,
      ...props,
    },
    graphicProps: {
      // uri: uri[0] ? uri[0].url : undefined,
      color: createCesiumColor({ color, alpha }),
      colorBlendMode: ColorBlendMode[colorBlendMode],
      heightReference: HeightReference[heightReference],
      // silhouetteSize,
      // silhouetteColor:
      //   silhouetteSize > 0
      //     ? createCesiumColor({ color: silhouetteColor })
      //     : undefined,
      ...extraProps,
    },
  };

  return <GlbModelEntity {...glbModelEntityProps}></GlbModelEntity>;
}
