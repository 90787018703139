import React from "react";

import Box from "@mui/material/Box";

import { ToolButton } from "./ToolButton";

export function ToolMenuBar(props) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItem: "center",
        justifyContent: "center",
        // bgcolor: "none",
        // bgcolor: (theme) => theme.palette.background.alphaToolPanel,
      }}
      elevation={4}
      {...props}
    >
      <ToolButton></ToolButton>
    </Box>
  );
}
