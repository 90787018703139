import { gql, useQuery } from "@apollo/client";

//fragments
const BMAP_FIELDS = gql`
  fragment BMapFields on BMap {
    id
    name
    description
    center
    zoom
    minZoom
    maxZoom
    heading
    tilt
    styleId
    scaleControl
    navigationControl
    zoomControl
    mapTypeControl
    drawingManager
    enableDoubleClickZoom
    enableDragging
    enableRotate
    enableScrollWheelZoom
    enableTilt
    jsonSchema {
      id
      name
      fields
      schema
      uiSchema
      formProps
    }
    mapItems {
      id
      name
      bd09
      targetUrl
      data
    }
  }
`;

//queries
const GET_BMAP_BY_ID = gql`
  ${BMAP_FIELDS}
  query getBMap($where: BMapWhereUniqueInput!) {
    bMap(where: $where) {
      ...BMapFields
    }
  }
`;

//queries
const GET_BMAPS = gql`
  ${BMAP_FIELDS}
  query getBMaps($where: BMapWhereInput! = {}) {
    bMaps(where: $where) {
      ...BMapFields
    }
  }
`;

//mutations

//hooks query
export function useBMap(options) {
  return useQuery(GET_BMAP_BY_ID, options);
}

export function useBMaps(options) {
  return useQuery(GET_BMAPS, options);
}

//hooks mutation
