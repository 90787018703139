import React from "react";

import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { processTagTree, getPathList } from "../../utils";

function TreeNode({ data, handleClick, handleDelete, tags, descMap }) {
  return (
    <TreeItem nodeId={data.path} label={data.name}>
      {data.children.map((nodeData) =>
        nodeData.children ? (
          <TreeNode
            tags={tags}
            data={nodeData}
            key={nodeData.path}
            handleClick={handleClick}
            handleDelete={handleDelete}
            descMap={descMap}
          />
        ) : (
          <TreeTag
            tags={tags}
            data={nodeData}
            key={nodeData.path}
            handleClick={handleClick}
            handleDelete={handleDelete}
            descMap={descMap}
          ></TreeTag>
        )
      )}
    </TreeItem>
  );
}

function TreeTag({ data, handleClick, handleDelete, tags, descMap }) {
  return (
    <Tooltip title={descMap[data.tagId]} placement="top" key={data.tagId} arrow>
      <Chip
        label={data.name}
        size="small"
        sx={{
          m: "2px",
        }}
        //   选择与删除标签使用不同样式
        variant={tags.indexOf(data.tagId) !== -1 ? "filled" : "outlined"}
        color="primary"
        onClick={handleClick(data.tagId)}
        onDelete={
          tags.indexOf(data.tagId) !== -1 ? handleDelete(data.tagId) : undefined
        }
      />
    </Tooltip>
  );
}

export function TagSelectFieldWithTree({
  tags,
  commentTags,
  commentTagTree,
  handleClick,
  handleDelete,
}) {
  const tagsMap = React.useMemo(() => {
    const map = {};
    commentTags.forEach(({ id, name }) => {
      map[id] = name;
    });
    return map;
  }, [commentTags]);

  const descMap = React.useMemo(() => {
    const map = {};
    commentTags.forEach(({ id, description }) => {
      map[id] = description;
    });
    return map;
  }, [commentTags]);

  const tagTree = React.useMemo(() => {
    return processTagTree({ tree: commentTagTree.treeDef, tagsMap });
  }, [tagsMap, commentTagTree]);
  const pathList = React.useMemo(() => {
    return getPathList(tagTree);
  }, [tagTree]);

  return (
    <TreeView
      aria-label="comment-tag-tree-view"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      defaultExpanded={pathList}
      sx={{
        maxHeight: 300,
        width: 1,
        overflowY: "auto",
      }}
    >
      {tagTree.map((data) => (
        <TreeNode
          tags={tags}
          data={data}
          key={data.name}
          handleClick={handleClick}
          handleDelete={handleDelete}
          descMap={descMap}
        ></TreeNode>
      ))}
    </TreeView>
  );
}
