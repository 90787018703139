import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import TreeItem from "@mui/lab/TreeItem";

//behavior icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useGlobalFlyToAssetById } from "../../../hooks";

import { ContextMenuState } from "./ContextMenuState";
import { AssetContextMenu } from "./AssetContextMenu";

function ToggleVisibleIcon({ visible, handleClick, ...iconProps }) {
  return visible ? (
    <VisibilityIcon
      onClick={handleClick}
      color="primary"
      {...iconProps}
    ></VisibilityIcon>
  ) : (
    <VisibilityOffIcon
      onClick={handleClick}
      {...iconProps}
      color="disabled"
    ></VisibilityOffIcon>
  );
}

export function TreeItems({
  search,
  assetType,
  assets,
  renderMap,
  toggleAssetRenderStatus,
  renderTypeMap,
  toggleTypeRenderStatus,
  config: { assetIconMap, assetLabelMap },
}) {
  //处理检索请求
  if (search) {
    assets = assets.filter((asset) => {
      const label = asset.name || asset.digitalAsset.name;
      return label.indexOf(search) !== -1;
    });
  }

  const flyToAssetById = useGlobalFlyToAssetById();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <ToggleVisibleIcon
        visible={renderTypeMap[assetType]}
        handleClick={() => {
          toggleTypeRenderStatus(assetType);
        }}
        fontSize="small"
        sx={{
          marginRight: 1,
          cursor: "pointer",
        }}
      ></ToggleVisibleIcon>
      <TreeItem
        nodeId={assetType}
        label={assetLabelMap[assetType]}
        icon={assetIconMap[assetType]}
        sx={{
          marginRight: "auto",

          "& > .MuiTreeItem-content .MuiTreeItem-label": {
            fontSize: "14px",
            fontWeight: "bold",
          },

          "& .MuiSvgIcon-root, &  .MuiTreeItem-label": {
            color: renderTypeMap[assetType] ? "inherit" : "text.disabled",
          },
        }}
      >
        {assets.map((asset) => {
          const label = asset.name || asset.digitalAsset.name;
          return (
            <ContextMenuState key={asset.id}>
              {({ contextMenu, handleContextMenu, handleClose }) => (
                <>
                  <TreeItem
                    onContextMenu={handleContextMenu}
                    id={asset.id}
                    nodeId={asset.id}
                    label={label}
                    onDoubleClick={() => {
                      flyToAssetById(asset.id);
                    }}
                    icon={
                      <ToggleVisibleIcon
                        visible={renderMap[asset.id]}
                        handleClick={() => {
                          toggleAssetRenderStatus(asset.id);
                        }}
                      ></ToggleVisibleIcon>
                    }
                    sx={{
                      // "& > .MuiTreeItem-content .MuiTreeItem-label": {
                      //   fontSize: "14px",
                      // },
                      "& .MuiSvgIcon-root, &  .MuiTreeItem-label": {
                        color:
                          renderTypeMap[assetType] && renderMap[asset.id]
                            ? "text.primary"
                            : "text.disabled",
                      },
                    }}
                  />

                  <AssetContextMenu
                    asset={asset}
                    flyToAssetById={flyToAssetById}
                    contextMenu={contextMenu}
                    handleClose={handleClose}
                  ></AssetContextMenu>
                </>
              )}
            </ContextMenuState>
          );
        })}
      </TreeItem>

      <Typography
        variant="caption"
        sx={{
          ml: 2,
          mr: 1,
          userSelect: "none",
        }}
      >
        {assets.length}
      </Typography>
    </Box>
  );
}
