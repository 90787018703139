import React from "react";
import { useCesium } from "resium";
import { GridActionsCellItem } from "@mui/x-data-grid";
import ArticleIcon from "@mui/icons-material/Article";

import { useSourceObjectContext } from "../../../../hooks";

export function OpenContentDialogAction({ params }) {
  const { viewer } = useCesium();

  const handleClick = () => {
    //直接点击几何的第一个entity
    try {
      const entity = viewer.entities.getById(params.row.geometry[0].id);
      entity.onClick();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <GridActionsCellItem
      icon={<ArticleIcon color="primary" />}
      label="查看内容"
      onClick={handleClick}
      showInMenu
      sx={{
        color: "primary.main",
      }}
    />
  );
}
