import React from "react";
import Box from "@mui/material/Box";

import { GridActionsCellItem } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
//behavior icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { useCommentRenderMapContext } from "../../../../hooks";

export function ToggleCommentAction({ params }) {
  const { id } = params;
  const { commentsRenderMap, toggleCommentRenderStatus } =
    useCommentRenderMapContext();

  return (
    <GridActionsCellItem
      icon={
        <Tooltip
          title={commentsRenderMap[id] ? "关闭" : "开启"}
          placement="top"
        >
          <VisibilityIcon
            color={commentsRenderMap[id] ? "primary" : "disabled"}
          />
        </Tooltip>
      }
      label="开关评论"
      onClick={() => {
        toggleCommentRenderStatus(id);
      }}
    />
  );
}
