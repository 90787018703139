import React from "react";
import { useReactiveVar } from "@apollo/client";

import Form from "@rjsf/mui";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { drawingToolEditVar, checkEditDataReady } from "../drawingToolVars";
import { useSaveEditData } from "../utils";

import { CustomForm } from "../../../schemaForms";

//强耦合了存储功能的表单
export function SettingForm({ children, ...props }) {
  const saveEditData = useSaveEditData();
  useReactiveVar(drawingToolEditVar);

  return (
    <Box>
      <CustomForm
        showErrorList={false}
        noHtml5Validate
        onSubmit={(e) => {
          // console.log(JSON.stringify(e.formData, null, 2));
          saveEditData();
        }}
        {...props}
      >
        <Button type="submit" disabled={!checkEditDataReady().success}>
          保存
        </Button>
        {children}
      </CustomForm>
    </Box>
  );
}
