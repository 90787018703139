//各类常用的全局渲染参数设置,包括3Dtile渲染精度设置，Home位置设置，Fps显示设置
import { makeVar } from "@apollo/client";

//支持的key设置
const legalKeys = ["showFps", "maximumScreenSpaceError", "homeCameraProps"];

const defaultValue = {
  showFps: false, //Fps显示设置
  maximumScreenSpaceError: Number(process.env.REACT_APP_SCREEN_SPACE_ERROR), //3Dtile渲染精度设置
  homeCameraProps: null, //Home位置设置
};

export const viewerSettingVar = makeVar(defaultValue);

function isEqual() {
  let equal = true;
  legalKeys.forEach((key) => {
    if (defaultValue[key] !== viewerSettingVar()[key]) {
      equal = false;
    }
  });
  return equal;
}

export function setViewerSetting(setting) {
  //   Object.keys(setting);
  viewerSettingVar({
    ...viewerSettingVar(),
    ...setting,
  });
}

export function resetviewerSetting() {
  if (!isEqual()) {
    viewerSettingVar(defaultValue);
  }
}
