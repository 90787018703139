import React from "react";
import { useReactiveVar } from "@apollo/client";
import { Entity, PolylineGraphics } from "resium";
import {
  Color,
  ArcType,
  ClassificationType,
  PolylineDashMaterialProperty,
} from "cesium";

import { depthTestVar } from "../../../../gql";

import { iconVarientMap } from "../../icons";

import {
  radianArrayToCartesian3,
  createCesiumColor,
  radianArraysToCartesian3Array,
  useRequestRender,
} from "../../utils";

function PolylineEntity({ entityProps, graphicProps }) {
  //在性能优化的情况下，请求主动渲染
  useRequestRender();
  return (
    <Entity {...entityProps}>
      <PolylineGraphics
        // classificationType={ClassificationType.CESIUM_3D_TILE}
        // 始终显示不被遮挡
        disableDepthTestDistance={Number.POSITIVE_INFINITY}
        {...graphicProps}
      />
    </Entity>
  );
}

export function PolylineRender({ data, ...props }) {
  //深度检测设置,耦合了全局设置，破坏独立性
  const { enableDepthTest } = useReactiveVar(depthTestVar);
  const disableDepthTestDistance = enableDepthTest
    ? undefined
    : Number.POSITIVE_INFINITY;

  const {
    type,
    id,
    positions,
    color,
    alpha,
    clampToGround,
    dashed,
    closed,
    ...extraProps
  } = data;
  const cesiumColor = createCesiumColor({ color, alpha });

  // if (closed) {
  //   positionsClosed = [...positions, positions[0]];
  // }

  const material = dashed
    ? new PolylineDashMaterialProperty({
        color: cesiumColor,
      })
    : cesiumColor;
  const polylineEntityProps = {
    entityProps: { id, ...props },
    graphicProps: {
      positions: radianArraysToCartesian3Array(
        closed ? [...positions, positions[0]] : positions
      ), //闭合属性为true自动添加最后坐标
      material,
      arcType: clampToGround ? ArcType.GEODESIC : ArcType.NONE, //贴地时仅支持ArcType.GEODESIC & ArcType.RHUMB
      //https://community.cesium.com/t/zoom-to-entity-is-not-zooming-properly-when-clamp-to-ground-is-used/9072 got a bug when flyTo entity with clampToGround=true
      clampToGround,
      ...extraProps,
    },
  };

  return <PolylineEntity {...polylineEntityProps}></PolylineEntity>;
}
