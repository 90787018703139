import React from "react";

import { Entity, ModelGraphics, useCesium } from "resium";
import {
  Color,
  DistanceDisplayCondition,
  ColorBlendMode,
  defined,
  HeightReference,
} from "cesium";

export function ModelEntity({
  uri,
  color = "RED",
  alpha = 0.3,
  modelGraphicsProps,
  ...props
}) {
  // const { scene } = useCesium();
  // const model = React.useRef(null);
  // React.useEffect(() => {
  //   if (defined(model)) {
  //     // console.log(model.current.cesiumElement);
  //     // console.log(scene.primitives._primitives);

  //     const currentModel = scene.primitives._primitives.filter((model) => {
  //       const id = model.id && model.id._id;

  //       return id && props.id === id;
  //     });
  //     // console.log({ currentModel });
  //     // console.log();
  //   }
  // });
  return (
    <Entity {...props}>
      <ModelGraphics
        uri={uri}
        color={Color[color].withAlpha(alpha)}
        colorBlendMode={ColorBlendMode.HIGHLIGHT}
        heightReference={HeightReference.CLAMP_TO_GROUND}
        // silhouetteColor={Color.BLUE}
        // silhouetteSize={2}
        distanceDisplayCondition={
          new DistanceDisplayCondition(0, Number.POSITIVE_INFINITY)
        }
        {...modelGraphicsProps}
      />
    </Entity>
  );
}
