import React from "react";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { ErrorBoundary, JsonView } from "../../../components";

import { processTagTree } from "../../../utils";

function TreeNode({ data, tagsMap }) {
  return (
    <TreeItem nodeId={data.name} label={data.name}>
      {data.children.map((nodeData) =>
        nodeData.isLastNode ? (
          <TreeTag
            data={nodeData}
            key={nodeData.tagId}
            tagsMap={tagsMap}
          ></TreeTag>
        ) : (
          <TreeNode data={nodeData} key={nodeData.name} tagsMap={tagsMap} />
        )
      )}
    </TreeItem>
  );
}
function TreeTag({ data, tagsMap }) {
  const { tagId } = data;
  return (
    <Chip
      label={tagsMap[tagId]}
      size="small"
      variant="outlined"
      color="primary"
      sx={{
        m: "2px",
      }}
    />
  );
}

export function TagTreeView({ tree, tags }) {
  const [showJson, setShowJson] = React.useState(false);
  const [showTree, setShowTree] = React.useState(false);
  const tagsMap = React.useMemo(() => {
    const map = {};
    tags.forEach(({ id, name }) => {
      map[id] = name;
    });

    return map;
  }, [tags]);

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        mb: 2,
      }}
    >
      <Box
        sx={{
          width: "50%",
          p: 1,
          maxHeight: 500,
          overflow: "auto",
        }}
      >
        <Button
          variant="text"
          onClick={() => {
            setShowTree((state) => !state);
          }}
        >
          {showTree ? "隐藏树渲染" : "显示树渲染"}
        </Button>
        {showTree && (
          <ErrorBoundary message="数据不完整,请填写完整数据后在查看树渲染效果">
            <TreeView
              aria-label="comment-tag-tree-view"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              sx={{
                maxHeight: 500,
                flexGrow: 1,
                maxWidth: 1,
                overflowY: "auto",
              }}
            >
              {tree.map((data) => (
                <TreeNode
                  data={data}
                  key={data.name}
                  tagsMap={tagsMap}
                ></TreeNode>
              ))}
            </TreeView>
          </ErrorBoundary>
        )}
      </Box>

      <Box
        sx={{
          width: "50%",
          p: 1,
          maxHeight: 500,
          overflow: "auto",
        }}
      >
        <Button
          variant="text"
          onClick={() => {
            setShowJson((state) => !state);
          }}
        >
          {showJson ? "隐藏JSON渲染" : "显示JSON渲染"}
        </Button>
        {showJson && (
          <JsonView json={processTagTree({ tree, tagsMap })}></JsonView>
        )}
      </Box>
    </Box>
  );
}
