import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
const ImageBlock = (props) => {
  const { url, altText, showName } = props;

  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <img
        src={url}
        alt={altText}
        style={{
          maxWidth: "80%",
        }}
      />
      {showName && (
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          {altText}
        </p>
      )}
    </div>
  );
};

const IframeBlock = (props) => {
  const { url, title, showName } = props;

  return (
    <div
      style={{
        textAlign: "center",
        width: "100%",
        height: "100%",
      }}
    >
      {url && (
        <iframe
          title={title && "未设置"}
          src={url}
          allowFullScreen
          style={{
            width: "960px",
            height: "540px",
            maxWidth: "100%",
            maxHeight: "100vh",
          }}
        />
      )}

      {showName && (
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          {title}
        </p>
      )}
    </div>
  );
};

const PanoramaBlock = (props) => {
  const { panorama, searchParams } = props;

  if (panorama === null || panorama.data === undefined) {
    return null;
  }
  return (
    <>
      {/* <pre>{JSON.stringify(panorama, null, 2)}</pre> */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          component="iframe"
          sx={{
            border: "none",
            mx: 4,
            width: 960,
            height: 600,
            maxHeight: "100vh",
            maxWidth: "100%",
          }}
          allowFullScreen
          src={
            searchParams
              ? `${panorama.data.viewerUrl}?${searchParams}`
              : panorama.data.viewerUrl
          }
        ></Box>
      </Box>
      {/* <Typography variant="subtitle1" color="initial" align="center">
        {panorama.label}
      </Typography> */}
    </>
  );
};

export const componentBlockRenderers = {
  imageBlock: ImageBlock,
  iframeBlock: IframeBlock,
  panoramaBlock: PanoramaBlock,
};
