import React from "react";
import { useReactiveVar } from "@apollo/client";

import Stack from "@mui/material/Stack";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

//icons

import { labelVar, setLabelShow, setLabelKey } from "../../../gql";

export function AssetLabelSetting({ ...props }) {
  const label = useReactiveVar(labelVar);

  const handleShowChange = (event) => {
    setLabelShow(event.target.checked);
  };

  const handleKeyChange = (event) => {
    setLabelKey(event.target.value);
  };
  return (
    <Stack spacing={1} direction="column" alignItems="flex-start" {...props}>
      <FormControlLabel
        control={<Switch checked={label.show} onChange={handleShowChange} />}
        label="显示标签"
      />
      {label.show && (
        <RadioGroup
          value={label.key}
          onChange={handleKeyChange}
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormControlLabel value="name" control={<Radio />} label="名称" />
          <FormControlLabel value="type" control={<Radio />} label="类型" />
          <FormControlLabel
            value="producedAt"
            control={<Radio />}
            label="日期"
          />
        </RadioGroup>
      )}
    </Stack>
  );
}
