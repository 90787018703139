import { pageList, pageKeys, roles } from "../config";
import {
  pageUiConfigSchemas,
  pageUiConfigUiSchemas,
} from "./pageUiConfigSchemas";
const pageSchemas = {};
const pageUiSchemas = {};

//页面权限设置
const authSchema = {
  title: "页面访问权限",
  type: "array",
  minItems: 0,
  items: {
    type: "string",
    enum: roles,
  },
  uniqueItems: true,
  default: roles,
};

const authUiSchema = {
  "ui:widget": "checkboxes",
  "ui:options": {
    inline: true,
  },
};

//生成uischema
pageKeys.forEach((pageKey) => {
  pageSchemas[pageKey] = {
    type: "object",
    required: ["auth"],
    title: pageList[pageKey].title,
    description: pageList[pageKey].description,
    properties: {
      auth: authSchema,
      uiConfig: pageUiConfigSchemas[pageKey] || false,
    },
  };

  pageUiSchemas[pageKey] = {
    auth: authUiSchema,
    uiConfig: pageUiConfigUiSchemas[pageKey],
  };
});

// // pageSchemaProperties;
// //label schema
// const labelSettingSchema = {
//   type: "object",
//   required: ["active", "auth"],
//   properties: {
//     active: {
//       title: "页面启用",
//       type: "boolean",
//       default: true,
//     },
//     auth: {
//       title: "页面访问权限",
//       type: "array",
//       minItems: 0,
//       items: {
//         type: "string",
//         enum: roles,
//       },
//       uniqueItems: true,
//       default: ["admin"],
//     },
//     // text: {
//     //   type: "string",
//     //   title: "文字内容",
//     // },
//     // fontSize: {
//     //   type: "integer",
//     //   title: "文字大小(像素)",
//     //   minimum: 12,
//     //   multipleOf: 1,
//     //   maximum: 100,
//     // },
//     // color: {
//     //   type: "string",
//     //   title: "文字颜色",
//     //   // description: "点颜色设置",
//     // },
//     // alpha: {
//     //   type: "number",
//     //   title: "文字透明度",
//     //   // description: "点透明度设置",
//     //   minimum: 0,
//     //   maximum: 1,
//     //   multipleOf: 0.01,
//     // },
//     // showBackground: {
//     //   type: "boolean",
//     //   title: "是否有背景",
//     // },
//   },
//   // dependencies: {
//   //   showBackground: {
//   //     oneOf: [
//   //       {
//   //         required: ["showBackground"],
//   //         properties: {
//   //           showBackground: {
//   //             enum: [false],
//   //           },
//   //         },
//   //       },
//   //       {
//   //         required: [
//   //           "showBackground",
//   //           "backgroundColor",
//   //           "backgroundColorAlpha",
//   //           "backgroundPaddingX",
//   //           "backgroundPaddingY",
//   //         ],
//   //         properties: {
//   //           showBackground: {
//   //             enum: [true],
//   //           },

//   //           backgroundColor: {
//   //             type: "string",
//   //             title: "背景颜色",
//   //             default: "#ffffff", //动态字段必须添加默认值
//   //           },
//   //           backgroundColorAlpha: {
//   //             type: "number",
//   //             title: "背景透明度",
//   //             minimum: 0,
//   //             maximum: 1,
//   //             multipleOf: 0.01,
//   //             default: 1, //动态字段必须添加默认值
//   //           },
//   //           backgroundPaddingX: {
//   //             type: "integer",
//   //             title: "背景水平边距(像素)",
//   //             minimum: 1,
//   //             multipleOf: 1,
//   //             maximum: 100,
//   //             default: 10, //动态字段必须添加默认值
//   //           },
//   //           backgroundPaddingY: {
//   //             type: "integer",
//   //             title: "背景垂直边距(像素)",
//   //             minimum: 1,
//   //             multipleOf: 1,
//   //             maximum: 100,
//   //             default: 10, //动态字段必须添加默认值
//   //           },
//   //         },
//   //       },
//   //     ],
//   //   },
//   // },
// };

// const labelSettingUiSchema = {
//   auth: {
//     "ui:widget": "checkboxes",
//     "ui:options": {
//       inline: true,
//     },
//   },
//   // text: {
//   //   "ui:widget": "textarea",
//   // },
//   // fontSize: {
//   //   "ui:widget": "range",
//   // },
//   // color: {
//   //   "ui:widget": "color",
//   // },
//   // alpha: {
//   //   "ui:widget": "range",
//   // },
//   // backgroundColor: {
//   //   "ui:widget": "color",
//   // },
//   // backgroundColorAlpha: {
//   //   "ui:widget": "range",
//   // },
//   // backgroundPaddingX: {
//   //   "ui:widget": "range",
//   // },
//   // backgroundPaddingY: {
//   //   "ui:widget": "range",
//   // },
// };

// //动态字段，开启omitExtraData与liveOmit
// const labelSettingFormProps = {
//   // omitExtraData: true,
//   // liveOmit: true,
//   liveValidate: true,
// };

// export const pageSchema = {
//   label: {
//     schema: labelSettingSchema,
//     uiSchema: labelSettingUiSchema,
//     formProps: labelSettingFormProps,
//   },
// };

export { pageSchemas, pageUiSchemas };
