import React from "react";

import { useSceneContext } from "../../../hooks";

import { MemoizedTiledModelRender } from "./TiledModelRender";
import { MemoizedTiledImageRender } from "./TiledImageRender";
import { MemoizedPanoramaRender } from "./PanoramaRender";

export function SceneRenderer() {
  const { assets, renderMap, renderTypeMap } = useSceneContext();

  return (
    <>
      {/* render tiledModel */}
      {assets.tiledModel.map((asset, index) => {
        return (
          <MemoizedTiledModelRender
            type="tiledModel"
            key={asset.id}
            asset={asset}
            show={renderTypeMap.tiledModel && renderMap[asset.id]}
          />
        );
      })}

      {/* render pointCloud todo */}
      {assets.pointCloud.map((asset, index) => {
        return (
          <MemoizedTiledModelRender
            type="pointCloud"
            key={asset.id}
            asset={asset}
            show={renderTypeMap.pointCloud && renderMap[asset.id]}
          />
        );
      })}
      {/* render panorama */}
      {assets.panorama.map((asset) => {
        return (
          <MemoizedPanoramaRender
            key={asset.id}
            asset={asset}
            show={renderTypeMap.panorama && renderMap[asset.id]}
          />
        );
      })}

      {/* render tiledImage */}
      {assets.tiledImage.map((asset) => {
        return (
          <MemoizedTiledImageRender
            key={asset.id}
            asset={asset}
            show={renderTypeMap.tiledImage && renderMap[asset.id]}
          />
        );
      })}
    </>
  );
}
