import { PanoramaRender, UploadImageRender } from "./relationships";
export const renderers = {
  inline: {
    relationship({ relationship, data }) {
      if (data === null || data.data === undefined) {
        return null;
      } else {
        if (relationship === "panorama") {
          return <PanoramaRender data={data}></PanoramaRender>;
        }
        if (relationship === "uploadImage") {
          return <UploadImageRender data={data}></UploadImageRender>;
        }
      }
    },
  },
};
