import React from "react";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";

export function FullscreenIconButton() {
  let [isFullScreen, setIsFullScreen] = React.useState(false);

  function toggleFullScreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().then(() => {
        setIsFullScreen(true);
      });
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen().then(() => {
          setIsFullScreen(false);
        });
      }
    }
  }

  return (
    <Tooltip
      arrow
      title={isFullScreen ? "退出全屏" : "进入全屏"}
      placement="top"
    >
      <IconButton size="small" onClick={toggleFullScreen} color="white">
        {isFullScreen ? (
          <FullscreenExitOutlinedIcon></FullscreenExitOutlinedIcon>
        ) : (
          <FullscreenOutlinedIcon></FullscreenOutlinedIcon>
        )}
      </IconButton>
    </Tooltip>
  );
}
