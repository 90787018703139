import React from "react";

import { useComments } from "../../gql";

import { CommentRenderMapContext, CommentContext } from "../../hooks";
import { DrawingComments } from "../../components";

export function DrawingCommentsModule({ drawing, children }) {
  return drawing.showComments ? (
    <DrawingCommentsContext drawing={drawing}>
      <DrawingComments></DrawingComments>
      {children}
    </DrawingCommentsContext>
  ) : (
    children
  );
}

function DrawingCommentsContext({ drawing, children }) {
  //全局评论渲染开关
  const [showAllComments, setShowAllComments] = React.useState(true);

  //单独评论渲染开关
  const [commentsRenderMap, setCommentsRenderMap] = React.useState({});

  //开关一个评论是否render的函数
  const toggleCommentRenderStatus = (id) => {
    setCommentsRenderMap((state) => {
      return {
        ...state,
        [id]: !state[id],
      };
    });
  };

  //请求该场景下全部Comments
  const { data } = useComments({
    variables: {
      where: { drawing: { id: { equals: drawing.id } } },
    },
    onCompleted: (data) => {
      const initCommentsRenderMap = {};

      if (data && data.comments) {
        for (const comment of data.comments) {
          initCommentsRenderMap[comment.id] = true;
        }
        setCommentsRenderMap((state) => ({
          ...initCommentsRenderMap,
          ...commentsRenderMap, //获取新数据后执行，保留原有评论的开关状态
        }));
      }
    },
  });

  return (
    data &&
    data.comments && (
      //  comment相关Context放在Viewer内部,避免导致场景渲染
      <CommentContext.Provider value={{ comments: data.comments }}>
        <CommentRenderMapContext.Provider
          value={{
            showAllComments,
            setShowAllComments,
            commentsRenderMap,
            toggleCommentRenderStatus,
          }}
        >
          {children}
        </CommentRenderMapContext.Provider>
      </CommentContext.Provider>
    )
  );
}
