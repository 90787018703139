import React from "react";
import { useReactiveVar } from "@apollo/client";

import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { setFpvMode, cameraModeVar } from "../../../../gql";

export function FpvModeRadio(props) {
  const { fpvMode } = useReactiveVar(cameraModeVar);

  const handleChange = (event) => {
    setFpvMode(event.target.value);
  };

  return (
    <RadioGroup
      value={fpvMode}
      onChange={handleChange}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <FormControlLabel value="free" control={<Radio />} label="自由模式" />
      <FormControlLabel value="ground" control={<Radio />} label="地面约束" />
    </RadioGroup>
  );
}
