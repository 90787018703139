import React from "react";

import { useSourceObjects } from "../../gql";

import {
  SourceObjectRenderMapContext,
  SourceObjectContext,
  SupportedMetasMapContext,
} from "../../hooks";
import { SourceObjects, JsonView } from "../../components";

export function SourceObjectModule({ drawing, children }) {
  return drawing.showSourceObjects ? (
    <DrawingSourceObjectContext drawing={drawing}>
      <SourceObjects></SourceObjects>
      {children}
    </DrawingSourceObjectContext>
  ) : (
    children
  );
}

function DrawingSourceObjectContext({ drawing, children }) {
  //图纸支持的SourceObjectMeta列表
  const metaIds = React.useMemo(
    () => drawing.supportedMetas.map(({ id }) => id),
    [drawing.supportedMetas]
  );
  //SourceObjectMeta的id映射
  const metasMap = React.useMemo(() => {
    const map = {};
    drawing.supportedMetas.forEach((meta) => {
      map[meta.id] = meta;
    });
    return map;
  }, [drawing.supportedMetas]);

  //全局渲染开关
  const [showAllSourceObjects, setShowAllSourceObjects] = React.useState(true);

  //元信息分类渲染开关初始值,默认全部开启
  const initMetaRenderMap = React.useMemo(() => {
    const map = {};
    drawing.supportedMetas.forEach((meta) => {
      map[meta.id] = true;
    });
    return map;
  }, [drawing.supportedMetas]);
  //元信息分类渲染开关
  const [metasRenderMap, setMetasRenderMap] = React.useState(initMetaRenderMap);
  //单独开关一类元信息隐藏的函数
  const toggleMetaRender = (metaId) => {
    setMetasRenderMap((state) => ({ ...state, [metaId]: !state[metaId] }));
  };

  //单独渲染开关
  const [sourceObjectsRenderMap, setSourceObjectsRenderMap] = React.useState(
    {}
  );

  //开关一个对象是否render的函数
  const toggleSourceObjectRenderStatus = (id) => {
    setSourceObjectsRenderMap((state) => {
      return {
        ...state,
        [id]: !state[id],
      };
    });
  };

  //请求该场景下全部SourceObjects
  const { data } = useSourceObjects({
    variables: {
      where: {
        drawing: { id: { equals: drawing.id } },
        meta: {
          id: {
            in: metaIds, //图纸支持的SourceObjectMeta列表
          },
        },
      },
    },

    //请求后更新sourceObjectsRenderMap
    onCompleted: (data) => {
      const initSourceObjectsRenderMap = {};
      if (data && data.sourceObjects) {
        for (const sourceObject of data.sourceObjects) {
          initSourceObjectsRenderMap[sourceObject.id] = true;
        }

        setSourceObjectsRenderMap((state) => ({
          ...initSourceObjectsRenderMap,
          ...sourceObjectsRenderMap, //获取新数据后执行，保留原有对象的开关状态
        }));
      }
    },
  });

  return (
    data &&
    data.sourceObjects && (
      <SourceObjectContext.Provider
        value={{ sourceObjects: data.sourceObjects }}
      >
        <SupportedMetasMapContext.Provider
          value={{
            metasMap,
          }}
        >
          <SourceObjectRenderMapContext.Provider
            value={{
              showAllSourceObjects, //全局显示开关
              setShowAllSourceObjects, //全局显示开关函数
              // metasHideArray, //类渲染隐藏数组
              // setMetasHideArray, //设置类渲染隐藏
              // toggleMetasHideArray, //设置类渲染开关,由setMetasHideArray计算出来，方便使用
              metasRenderMap,
              setMetasRenderMap,
              toggleMetaRender,
              sourceObjectsRenderMap, //单对象渲染开关
              toggleSourceObjectRenderStatus, //设置单对象渲染
            }}
          >
            {children}
          </SourceObjectRenderMapContext.Provider>
        </SupportedMetasMapContext.Provider>
      </SourceObjectContext.Provider>
    )
  );
}
