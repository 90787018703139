import { useCesium } from "resium";

//capture screen shot
function downloadImage(uri, name) {
  const link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function useScreenshotDownload() {
  const { viewer } = useCesium();
  return ({
    targetResolutionScale = 1,
    timeout = 100,
    fileName = "屏幕截图",
    jpegQuality = 0.7,
  }) => {
    const scene = viewer.scene;
    if (!scene) {
      console.error("No scene");
      return;
    }
    const canvas = scene.canvas;

    const takeScreenshot = function () {
      scene.postRender.removeEventListener(takeScreenshot);

      canvas.toBlob(
        function (blob) {
          let url = URL.createObjectURL(blob);

          downloadImage(url, fileName + ".jpg");

          // reset resolutionScale
          viewer.resolutionScale = 1.0;
        },
        "image/jpeg",
        jpegQuality
      );
    };
    // define callback functions

    const prepareScreenshot = function () {
      viewer.resolutionScale = targetResolutionScale;
      console.log("resolutionScale", viewer.resolutionScale);

      scene.preRender.removeEventListener(prepareScreenshot);

      // take snapshot after defined timeout to allow scene update (ie. loading data)

      setTimeout(function () {
        scene.postRender.addEventListener(takeScreenshot);
      }, timeout);
    };

    scene.preRender.addEventListener(prepareScreenshot);
  };
}

//capture video shot
function downloadVideo(videoUrl) {
  const link = document.createElement("a");

  link.href = videoUrl;
  link.download = "屏幕录制.webm";
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function useVideoRecorderDownload() {
  const { viewer } = useCesium();

  return () => {
    const stream = viewer.canvas.captureStream();
    let recorder;

    if (MediaRecorder.isTypeSupported("video/webm;codecs=vp8")) {
      recorder = new MediaRecorder(stream, {
        mimeType: "video/webm;codecs=vp8",
      });

      const data = [];

      recorder.onstart = () => {
        viewer.scene.requestRenderMode = false;
      };
      recorder.ondataavailable = function (event) {
        if (event.data && event.data.size) {
          data.push(event.data);
        }
      };

      recorder.onstop = () => {
        const url = URL.createObjectURL(
          new Blob(data, { type: "video/webm;codecs=vp8" })
        );
        downloadVideo(url);
        viewer.scene.requestRenderMode = true;
      };
    }

    return recorder;
  };
}
