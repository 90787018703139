import React from "react";
import { useParams, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useSourceDrawing, resetEvent } from "../../gql";

import { Debug, JsonView, AssetsTree } from "../../components";
// import { DigitalSceneViewer } from "../DigitalSceneViewer";

import { Logo } from "../../components";
import { createDigitalSceneData } from "../../utils";
import { DrawingViewer } from "./DrawingViewer";

export function DrawingPage(props) {
  const { id } = useParams();
  const { data } = useSourceDrawing({
    variables: {
      where: { id },
    },
  });
  //unmount时重置screenEventsVar
  React.useEffect(() => {
    return resetEvent;
  }, []);

  let digitalSceneData = {};
  if (data && data.sourceDrawing && data.sourceDrawing.digitalScene) {
    digitalSceneData = createDigitalSceneData(data.sourceDrawing.digitalScene);
  }

  return (
    data &&
    (data.sourceDrawing && data.sourceDrawing.digitalScene ? (
      <>
        <Helmet>
          <title>{data.sourceDrawing.name}</title>
        </Helmet>

        <DrawingViewer
          drawing={data.sourceDrawing}
          digitalSceneData={digitalSceneData}
        ></DrawingViewer>
        {/* <JsonView json={drawingData}></JsonView> */}
        <Logo></Logo>
      </>
    ) : (
      <Navigate to="/404" replace></Navigate>
    ))
  );
}
