import React from "react";
import { useSnackbar } from "notistack";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Button from "@mui/material/Button";

import { useDeleteComment, useDeleteUserUploadImages } from "../../../gql";

export function CommentDeleteButton({ afterDelete, comment }) {
  const { enqueueSnackbar } = useSnackbar();

  //Alert 相关状态
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // 删除评论Mutation
  const [deleteCommentMutation] = useDeleteComment({
    refetchQueries: ["getComments"],
  });
  // 删除照片Mutation
  const [deleteUserUploadImages] = useDeleteUserUploadImages({});

  //删除函数
  const deleteComment = React.useCallback(async () => {
    //计算删除图片的id变量
    const imageIds = comment.images.map(({ id }) => ({ id }));
    try {
      //删除图片
      if (imageIds.length > 0) {
        const deleteImagesRes = await deleteUserUploadImages({
          variables: { where: imageIds },
        });
      }

      //删除评论
      const res = await deleteCommentMutation({
        variables: {
          where: {
            id: comment.id,
          },
        },
      });

      if (res.errors) {
        enqueueSnackbar("删除评论失败", {
          variant: "error",
          key: "delete-comment-error",
        });
      } else {
        enqueueSnackbar("删除评论成功", {
          variant: "success",
          key: "delete-comment-sucess",
        });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("删除评论失败", {
        variant: "error",
        key: "delete-comment-error",
      });
    }
  }, [comment, deleteCommentMutation, deleteUserUploadImages, enqueueSnackbar]);
  return (
    <>
      <Button onClick={handleClickOpen}>删除</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-comment-delete-dialog-title"
        aria-describedby="alert-comment-delete-dialog-description"
      >
        <DialogTitle id="alert-comment-delete-dialog-title">
          删除评论？
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-comment-delete-dialog-description">
            是否确定删除此评论,删除后不可恢复,请谨慎操作。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>取消</Button>
          <Button onClick={deleteComment} autoFocus>
            删除
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
