import { gql, useQuery } from "@apollo/client";

import { DIGITAL_SCENE_FIELDS } from "./digitalScene";

const DS_COLLECTION_FIELDS = gql`
  ${DIGITAL_SCENE_FIELDS}
  fragment DsCollectionFields on DsCollection {
    id
    name
    description
    type
    order
    viewerUrl
    DigitalSceneLinks(orderBy: $orderBy) {
      id
      name
      timePoint
      timePointDescription
      digitalScene {
        ...DigitalSceneFields
      }
    }
  }
`;

//queries
const GET_DS_COLLECTION_BY_ID = gql`
  ${DS_COLLECTION_FIELDS}
  query getDsCollection(
    $where: DsCollectionWhereUniqueInput!
    $orderBy: [DsCollection_DigitalSceneOrderByInput!]
  ) {
    dsCollection(where: $where) {
      ...DsCollectionFields
    }
  }
`;

//queries
const GET_DS_COLLECTIONS = gql`
  ${DS_COLLECTION_FIELDS}
  query getDsCollections(
    $where: DsCollectionWhereInput! = {}
    $orderBy: [DsCollection_DigitalSceneOrderByInput!]
  ) {
    dsCollections(where: $where) {
      ...DsCollectionFields
    }
  }
`;

//mutations

//hooks query
export function useDsCollection(options) {
  return useQuery(GET_DS_COLLECTION_BY_ID, options);
}

export function useDsCollections(options) {
  return useQuery(GET_DS_COLLECTIONS, options);
}

//hooks mutation
