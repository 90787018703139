import React from "react";
import Draggable from "react-draggable";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
// import StepButton from "@mui/material/StepButton";
import StepLabel from "@mui/material/StepLabel";
import MobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },

  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark"
        ? theme.palette.grey[800]
        : theme.palette.common.white,
    borderTopWidth: 1,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: theme.palette.secondary.light,
  }),
  "& .QontoStepIcon-completedIcon": {
    color: theme.palette.secondary.light,
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

export function DsTimeLine({
  dsCollectionData,
  currentScene,
  setCurrentScene,
}) {
  const theme = useTheme();
  const upLg = useMediaQuery(theme.breakpoints.up("lg"));
  const maxSteps = dsCollectionData.length;

  const handleStep = (step) => () => {
    setCurrentScene(step);
  };
  const handleNext = () => {
    setCurrentScene((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setCurrentScene((prevActiveStep) => prevActiveStep - 1);
  };

  const getTooltipTitle = (index) => {
    if (index > maxSteps - 1 || index < 0) {
      return "";
    }
    return `${dsCollectionData[index].sceneLink.timePoint.slice(0, 10)} ${
      dsCollectionData[index].sceneLink.name ||
      dsCollectionData[index].scene.name
    }${dsCollectionData[index].sceneLink.timePointDescription && " : "}${
      dsCollectionData[index].sceneLink.timePointDescription
    }`;
  };

  return (
    <Draggable
      handle="#draggable-ds-timeline"
      // defaultPosition={{ x: window.width / 2 }}
      positionOffset={{
        x: "-50%",
        y: 0,
      }}
    >
      <Box
        aria-labelledby="draggable-ds-timeline"
        sx={{
          position: "fixed",
          //此处肯需要根据BottomMenu的内容多少来调整
          bottom: {
            xs: 100,
            phone: 60,
          },
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: (theme) => theme.zIndex.bottomMenuBar - 1,
          maxWidth: "100vw",
          bgcolor: {
            lg: "background.alphaBlack",
          },
          // bgcolor: "background.alphaBlack",

          pb: {
            lg: 1,
          },
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",

          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {upLg ? (
          <>
            <Box
              sx={{
                width: 1,
                height: 8,
                // border: 1,
                // borderColor: "red",
                cursor: "move",
              }}
              id="draggable-ds-timeline" //可拖动部位
            ></Box>
            <Stepper
              nonLinear
              activeStep={currentScene}
              connector={<QontoConnector />}
              sx={{
                flexGrow: 1,
                maxWidth: "100vw",
                width: 1200,
              }}
            >
              {dsCollectionData.map(({ sceneLink, scene }, index) => (
                <Step key={index}>
                  <Tooltip title={getTooltipTitle(index)} placement="top">
                    <StepLabel
                      StepIconComponent={QontoStepIcon}
                      onClick={handleStep(index)}
                      sx={{
                        userSelect: "none",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        variant="h6"
                        color="common.white"
                        sx={{ mt: 1, fontWeight: "bold" }}
                        gutterBottom
                      >
                        {(sceneLink.name || scene.name).slice(0, 16)}
                      </Typography>
                    </StepLabel>
                  </Tooltip>
                </Step>
              ))}
            </Stepper>
          </>
        ) : (
          <>
            <Tooltip title={getTooltipTitle(currentScene)} placement="top">
              <Typography
                variant="h6"
                color="initial"
                sx={{ mt: 1, fontWeight: "bold", userSelect: "none" }}
                gutterBottom
                id="draggable-ds-timeline" //可拖动部位
              >
                {dsCollectionData[currentScene].sceneLink.name ||
                  dsCollectionData[currentScene].scene.name}
              </Typography>
            </Tooltip>

            <MobileStepper
              sx={{
                borderRadius: {
                  phone: 4,
                },
                maxWidth: "100vw",
                width: 400,
                flexGrow: 1,
                bgcolor: "background.alphaBlack",
                "& .MuiMobileStepper-dotActive": {
                  bgcolor: (theme) => theme.palette.secondary.light,
                },
              }}
              variant="dots"
              steps={dsCollectionData.length}
              position="static"
              activeStep={currentScene}
              nextButton={
                <Tooltip
                  title={getTooltipTitle(currentScene + 1)}
                  placement="top"
                >
                  <Button
                    size="small"
                    onClick={handleNext}
                    disabled={currentScene === maxSteps - 1}
                    color="white"
                  >
                    下一场景
                    <KeyboardArrowRight />
                  </Button>
                </Tooltip>
              }
              backButton={
                <Tooltip
                  title={getTooltipTitle(currentScene - 1)}
                  placement="top"
                >
                  <Button
                    size="small"
                    onClick={handleBack}
                    disabled={currentScene === 0}
                    color="white"
                  >
                    <KeyboardArrowLeft />
                    上一场景
                  </Button>
                </Tooltip>
              }
            ></MobileStepper>
          </>
        )}
      </Box>
    </Draggable>
  );
}
