import React from "react";
import { useReactiveVar } from "@apollo/client";

import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { viewerSettingVar, setViewerSetting } from "../../../../gql";

export function ShowFpsSwitch(props) {
  const { showFps } = useReactiveVar(viewerSettingVar);

  const handleShowFps = (event) => {
    setViewerSetting({
      showFps: event.target.checked,
    });
  };

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <FormControlLabel
        control={<Switch checked={showFps} onChange={handleShowFps} />}
        label="显示渲染帧率"
      />
    </Box>
  );
}
