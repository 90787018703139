//源对象内容渲染
import React from "react";
import Draggable from "react-draggable";

import Portal from "@mui/base/Portal";

import Paper from "@mui/material/Paper";

import { SourceObjectContentHeader } from "./SourceObjectContentHeader";
import { SourceObjectContentTabs } from "./SourceObjectContentTabs";

export function SourceObjectContent({
  sourceObject,
  meta,
  handleClose,
  setOpenEditSourceObjectPanel,
  ...props
}) {
  return (
    <Portal>
      <Draggable
        handle="#draggable-source-object-content"
        cancel=".source-object-title"
        // 默认使Paper居中
        positionOffset={{
          x: "-50%",
          y: 0,
        }}
      >
        <Paper
          sx={{
            bgcolor: "background.alphaPaper",
            zIndex: "sourceObjectContent",
            position: "fixed",
            top: 80, //默认向下移动80px，不遮挡logo和指南针
            left: "50%",
            // maxHeight: "70vh",
            // overflow: "auto",
            width: {
              xs: 1,
              lg: "80%",
              xl: "70%",
            },
            borderRadius: {
              xs: "unset",
              lg: 4,
            },
            pb: 1,
          }}
        >
          {/* 内容Header */}
          <SourceObjectContentHeader
            sourceObject={sourceObject}
            meta={meta}
            handleClose={handleClose}
            setOpenEditSourceObjectPanel={setOpenEditSourceObjectPanel}
          ></SourceObjectContentHeader>

          {/* 源对象内容渲染 Accordion UI*/}
          {/* <SourceObjectContentAccordions
            sourceObject={sourceObject}
            meta={meta}
          ></SourceObjectContentAccordions> */}

          {/* 源对象内容渲染 Tabs UI*/}
          <SourceObjectContentTabs
            sourceObject={sourceObject}
            meta={meta}
          ></SourceObjectContentTabs>
        </Paper>
      </Draggable>
    </Portal>
  );
}
