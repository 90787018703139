import React from "react";
import { useParams, Navigate } from "react-router-dom";
import { usePanorama } from "../../../gql";
import { Helmet } from "react-helmet";

import { FullScreenIframe } from "../../../components";

export function PanoramaViewerIframe(props) {
  const { id } = useParams();
  const { data } = usePanorama({
    variables: {
      where: { id },
    },
  });

  return (
    data &&
    (data.panorama ? (
      <>
        <Helmet title="全景照片">{/* <title>全景照片</title> */}</Helmet>
        <FullScreenIframe
          src={data.panorama.viewerUrl}
          title={data.panorama.name}
          allowFullScreen
        ></FullScreenIframe>
      </>
    ) : (
      <Navigate to="/404" replace></Navigate>
    ))
  );
}
