import React from "react";
import { useReactiveVar } from "@apollo/client";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

//icons

import { dsHotspotVar, setShowDsHotspot } from "../../../gql";

export function DsHotspotSetting() {
  const { showHotspot } = useReactiveVar(dsHotspotVar);

  const handleShowChange = (event) => {
    setShowDsHotspot(event.target.checked);
  };

  return (
    <FormControlLabel
      control={<Switch checked={showHotspot} onChange={handleShowChange} />}
      label="显示场景热点"
    />
  );
}
