import React from "react";

// import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

export function WaitingProgress(props) {
  return (
    <Box {...props}>
      <LinearProgress />
    </Box>
  );
}
