import React from "react";
import { useReactiveVar } from "@apollo/client";

import Stack from "@mui/material/Stack";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { overlayIframeVar, setOverlayIframeVar } from "../../../gql";

export function AssetOverlayIframeSetting({ ...props }) {
  const { useOverlayIframe } = useReactiveVar(overlayIframeVar);

  const handleUseOverlayIframeChange = React.useCallback((event) => {
    setOverlayIframeVar(event.target.checked);
  }, []);

  return (
    <Stack spacing={1} direction="column" alignItems="flex-start" {...props}>
      <FormControlLabel
        control={
          <Switch
            checked={useOverlayIframe}
            onChange={handleUseOverlayIframeChange}
          />
        }
        label="资产动态跟随展示"
      />
    </Stack>
  );
}
