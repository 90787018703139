import { gql, useQuery, useMutation } from "@apollo/client";

//fragments
const USER_UPLOAD_FILE_FIELDS = gql`
  fragment UserUploadFileFields on UserUploadFile {
    id
    file {
      filename
      filesize
      url
    }
    altText
    createdAt
    updatedAt
  }
`;

//queries
const GET_USER_UPLOAD_FILE_BY_ID = gql`
  ${USER_UPLOAD_FILE_FIELDS}
  query UserUploadFile($where: UserUploadFileWhereUniqueInput!) {
    userUploadFile(where: $where) {
      ...UserUploadFileFields
    }
  }
`;

const GET_USER_UPLOAD_FILES = gql`
  ${USER_UPLOAD_FILE_FIELDS}
  query UserUploadFiles($where: UserUploadFileWhereInput! = {}) {
    userUploadFiles(where: $where) {
      ...UserUploadFileFields
    }
  }
`;

//mutations

const CREATE_USER_UPLOAD_FILE = gql`
  ${USER_UPLOAD_FILE_FIELDS}
  mutation CreateUserUploadFile($data: UserUploadFileCreateInput!) {
    createUserUploadFile(data: $data) {
      ...UserUploadFileFields
    }
  }
`;

const CREATE_USER_UPLOAD_FILES = gql`
  ${USER_UPLOAD_FILE_FIELDS}
  mutation CreateUserUploadFiles($data: [UserUploadFileCreateInput!]!) {
    createUserUploadFiles(data: $data) {
      ...UserUploadFileFields
    }
  }
`;

export function useCreateUserUploadFile(options) {
  return useMutation(CREATE_USER_UPLOAD_FILE, options);
}

export function useCreateUserUploadFiles(options) {
  return useMutation(CREATE_USER_UPLOAD_FILES, options);
}
