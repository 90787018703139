import React from "react";
import Draggable from "react-draggable";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Portal from "@mui/base/Portal";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

//icon
import CloseIcon from "@mui/icons-material/Close";

import { dispatchdrawingStatusVar } from "../drawingToolVars";

export function DrawingToolContainer({ children }) {
  const handleClose = React.useCallback(() => {
    dispatchdrawingStatusVar({
      type: "SET_TO_DEFAULT",
    });
  }, []);
  return (
    <Portal>
      <Draggable
        handle="#draggable-drawing-bar"
        cancel={'[class*="MuiIconButton-root"]'}
      >
        <Paper
          elevation={8}
          sx={{
            width: 350,
            maxHeight: "80vh",
            position: "fixed",
            zIndex: "drawingBar",
            overflow: "auto",
            top: 80,
            right: 16,
            bgcolor: "background.alphaPaper",
          }}
        >
          <Box
            id="draggable-drawing-bar"
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "move",
              px: 2,
              py: 1,
              borderBottom: 1,
              borderColor: "grey.500",
            }}
          >
            <Typography
              variant="h6"
              display="block"
              // color="primary.main"
              align="left"
              sx={{
                fontWeight: "bold",
              }}
            >
              绘图工具
            </Typography>

            {/* 关闭工具面板按钮 */}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              size="small"
              sx={{
                ml: "auto",
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          {children}
        </Paper>
      </Draggable>
    </Portal>
  );
}
