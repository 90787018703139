import React from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

//icon
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";

export function AssetSearchField({ search, setSearch, ...props }) {
  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          mb: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <TextField
          id="standard-search"
          label="检索数字资产"
          type="text"
          variant="standard"
          fullWidth
          value={value}
          onChange={handleChange}
        />

        <IconButton
          aria-label="search-assets"
          onClick={() => {
            setSearch(value);
          }}
        >
          <SearchIcon
            sx={{
              "&:hover": {
                fill: (theme) => theme.palette.primary.main,
              },
            }}
          ></SearchIcon>
        </IconButton>
      </Box>
      {search && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Typography variant="caption" color="primary" display="block">
            "{search}"搜索结果
          </Typography>
          <Tooltip title="取消检索" placement="bottom" arrow>
            <IconButton
              aria-label="cancel-search"
              color="primary"
              size="small"
              onClick={() => {
                setSearch("");
                setValue("");
              }}
              sx={{
                "& .MuiSvgIcon-root ": {
                  fontSize: 20,
                  transform: "translateY(-3px)",
                },
              }}
            >
              <CancelIcon></CancelIcon>
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}
