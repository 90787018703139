import { makeVar } from "@apollo/client";

const defaultValue = {
  flyTo: true, //默认飞行聚焦
  zoomDistance: 16, //默认16米距离
  zoomPitch: -45, //默认俯视45度
  zoomHeading: 0, //默认朝北
};

export const zoomVar = makeVar(defaultValue);

export function setZoomFly(flyTo) {
  zoomVar({
    ...zoomVar(),
    flyTo,
  });
}

export function setZoomDistance(zoomDistance) {
  zoomVar({
    ...zoomVar(),
    zoomDistance,
  });
}

export function setzoomPitch(zoomPitch) {
  zoomVar({
    ...zoomVar(),
    zoomPitch,
  });
}

export function setZoomHeading(zoomHeading) {
  zoomVar({
    ...zoomVar(),
    zoomHeading,
  });
}
