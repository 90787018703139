//源对象 非结构化-自定义编辑内容 内容渲染
import React from "react";
import { useReactiveVar } from "@apollo/client";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { userVar } from "../../../../gql";
import { userIsAdminOrEditor } from "../../../../accessControl";

import { useWysiwyg } from "../../../../gql";
import { Document } from "../../../document";

//此处根据id拉去自定义文档内容，并用Accordion来显示，避免额外的数据请求消耗
export function SourceObjectWysiwyg({ sourceObject, ...props }) {
  const user = useReactiveVar(userVar);

  const { data } = useWysiwyg({
    variables: {
      where: { id: sourceObject.editContent.id },
    },
  });

  //跳转sourceObject对象后台
  const handleOpen = React.useCallback(() => {
    window.open(
      `${process.env.REACT_APP_ADMIN_URI}/source-objects/${sourceObject.id}`
    );
  }, [sourceObject.editContent.id]);

  return (
    data &&
    data.wysiwyg && (
      <Box {...props}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          {data.wysiwyg.title && (
            <Typography
              variant="h5"
              color="primary"
              align="center"
              sx={{
                flex: "none",
                fontWeight: "bold",
              }}
            >
              {data.wysiwyg.title}
            </Typography>
          )}

          {userIsAdminOrEditor(user) && (
            <Tooltip title="跳转后台编辑" placement="top">
              <IconButton
                aria-label="open"
                onClick={handleOpen}
                size="small"
                sx={{
                  flex: "none",
                  ml: "auto",
                  "&:hover": {
                    color: "secondary.main",
                  },
                }}
              >
                <OpenInNewIcon sx={{ fontSize: "18px" }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Document document={data.wysiwyg.content.document}></Document>
      </Box>
    )
  );
}
