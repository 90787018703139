import React from "react";
import { useCesium } from "resium";
import { defined } from "cesium";

import Portal from "@mui/base/Portal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

//HtmlOverlayContainer,在3d空间position处叠加实时html内容，position为Cartesian3对象
export function HtmlOverlayContainer({ position, children, ...props }) {
  const { scene } = useCesium();
  const ref = React.useRef();
  React.useEffect(() => {
    function updateCanvasPosition() {
      const canvasPosition = scene.cartesianToCanvasCoordinates(position);
      if (defined(canvasPosition) && ref.current) {
        ref.current.style.top = `${canvasPosition.y}px`;
        ref.current.style.left = `${canvasPosition.x}px`;
      }
    }
    scene.preRender.addEventListener(updateCanvasPosition);

    return () => {
      scene.preRender.removeEventListener(updateCanvasPosition);
    };
  }, []);

  return (
    <Portal>
      <Box ref={ref} position="fixed" {...props}>
        {/* <Button variant="contained">HtmlOverLay</Button> */}
        {children}
      </Box>
    </Portal>
  );
}
