import React from "react";
import { useReactiveVar } from "@apollo/client";

import { ScreenSpaceEvent, useCesium } from "resium";
import { ScreenSpaceEventType, defined } from "cesium";

import { ECEF2LatLongDegrees } from "../../../utils";
import { PositionLabel, PositionBillBoard } from "../../geometry";
import { setPickedPosition, depthTestVar } from "../../../gql";

export function PickPositionEvent(props) {
  //深度检测设置
  const { enableDepthTest } = useReactiveVar(depthTestVar);
  const disableDepthTestDistance = enableDepthTest
    ? undefined
    : Number.POSITIVE_INFINITY;

  const { viewer } = useCesium();
  const [position, setPosition] = React.useState(null);
  const coord = position ? ECEF2LatLongDegrees(position) : null;
  React.useEffect(() => {
    if (coord) {
      setPickedPosition(
        coord.longitude + "," + coord.latitude + "," + coord.height
      );
    }
  }, [coord]);
  return (
    <>
      {/* 事件定义 左键点击后修改state*/}
      <ScreenSpaceEvent
        action={(event) => {
          try {
            const cartesian = viewer.scene.pickPosition(event.position);

            if (defined(cartesian)) {
              setPosition(cartesian);
            }
          } catch (e) {
            console.log(e);
          }
        }}
        type={ScreenSpaceEventType.LEFT_CLICK}
      ></ScreenSpaceEvent>
      {coord && (
        <>
          {/* 渲染测量点 */}
          <PositionBillBoard
            position={position}
            iconVarient="comment"
            color="RED"
            BillboardGraphicsProps={{
              disableDepthTestDistance,
              scale: 0.8,
            }}
          ></PositionBillBoard>
        </>
      )}
    </>
  );
}
