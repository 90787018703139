import { UsernameCellRender } from "./UsernameCellRender";
import { TagsCellRender } from "./TagsCellRender";
import { FlyToAction } from "./FlyToAction";
import { OpenContentDialogAction } from "./OpenContentDialogAction";
import { ToggleCommentAction } from "./ToggleCommentAction";

//util functions
function getCreatedAt({ value }) {
  return new Date(value);
}

function getFormattedDate(date) {
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return year + "-" + month + "-" + day;
}

function getUserName(params) {
  return params.row.user.name;
}

function getTagsName({ value }) {
  return value.map(({ name }) => name).join(" ");
}

const commonColumnConfig = {
  hideable: true,
};

// export const initialState = {
//     pinnedColumns: { left: ["flyAction", "username"] },
// };

// column def
export const columns = [
  {
    ...commonColumnConfig,
    field: "flyAction",
    type: "actions",
    headerName: "常用操作",
    getActions: (params) => [
      <ToggleCommentAction params={params}></ToggleCommentAction>,
      <FlyToAction params={params}></FlyToAction>,
    ],
  },
  {
    ...commonColumnConfig,
    field: "username",
    headerName: "评论人",
    minWidth: 150,
    valueGetter: getUserName,
    flex: 1,
    renderCell: (params) => {
      return <UsernameCellRender params={params}></UsernameCellRender>;
    },
  },

  {
    ...commonColumnConfig,
    field: "text",
    headerName: "内容",
    minWidth: 150,
    flex: 2,
  },
  {
    ...commonColumnConfig,
    field: "tags",
    headerName: "标签",
    align: "",
    minWidth: 150,
    valueGetter: getTagsName,
    flex: 1,
    cellClassName: "comment-table-tag-cell", //标签cell增加gap属性，在
    renderCell: (params) => {
      return <TagsCellRender params={params}></TagsCellRender>;
    },
  },
  {
    ...commonColumnConfig,
    field: "createdAt",
    headerName: "评论时间",
    type: "dateTime",
    width: 150,
    valueGetter: getCreatedAt,
    valueFormatter: (params) => {
      return getFormattedDate(params.value);
    },
  },
  {
    ...commonColumnConfig,
    field: "imagesCount",
    headerName: "图片数量",
    width: 100,
    type: "number",
  },
  {
    ...commonColumnConfig,
    field: "repliesCount",
    headerName: "回复数量",
    width: 100,
    type: "number",
  },
  {
    ...commonColumnConfig,
    field: "documentAction",
    flex: 1,
    type: "actions",
    minWidth: 140,
    headerName: "查看内容",
    getActions: (params) => [
      <OpenContentDialogAction params={params}></OpenContentDialogAction>,
    ],
  },
];
