import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
//fragments
const CH_FIELDS = gql`
  fragment CHFields on CulturalRelic {
    name
    age
    addr
    rank
    batch
    relicType
    coordinates
  }
`;

//queries
const GET_CH_BY_ID = gql`
  ${CH_FIELDS}
  query getCulturalRelic($id: ID!) {
    culturalRelic(where: { id: $id }) {
      ...CHFields
    }
  }
`;

const GET_CHS = gql`
  ${CH_FIELDS}
  query getCulturalRelics($where: CulturalRelicWhereInput! = {}) {
    culturalRelics(where: $where) {
      ...CHFields
    }
  }
`;

//mutations

//hooks
export function useCHbyId(options) {
  return useQuery(GET_CH_BY_ID, options);
}

export function useLazyCHs(options) {
  const { enqueueSnackbar } = useSnackbar();

  return useLazyQuery(GET_CHS, {
    onCompleted: (data) => {
      enqueueSnackbar("Lazy请求成功", {
        variant: "success",
        key: "useLazyCHs-success",
      });
    },
    ...options,
  });
}

export function useCHs(options) {
  return useQuery(GET_CHS, options);
}
