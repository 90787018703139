import { gql, useQuery, useMutation } from "@apollo/client";

import { SOURCE_OBJECT_META_FIELDS } from "./sourceObjectMeta";

// content {
//   document
// }
// name
// title

//fragments
const SOURCE_OBJECT_FIELDS = gql`
  fragment SourceObjectFields on SourceObject {
    id
    name
    geometry
    zoomView
    data
    editContent {
      id
    }
    meta {
      id
    }
    createdAt
    updatedAt
  }
`;

//queries
const GET_SOURCE_OBJECT_BY_ID = gql`
  ${SOURCE_OBJECT_FIELDS}
  query SourceObject($where: SourceObjectWhereUniqueInput!) {
    sourceObject(where: $where) {
      ...SourceObjectFields
    }
  }
`;

//queries
const GET_SOURCE_OBJECTS = gql`
  ${SOURCE_OBJECT_FIELDS}
  query SourceObjects(
    $where: SourceObjectWhereInput! = {}
    $orderBy: [SourceObjectOrderByInput!]! = { createdAt: desc }
  ) {
    sourceObjects(where: $where, orderBy: $orderBy) {
      ...SourceObjectFields
    }
  }
`;

//mutations
const CREATE_SOURCE_OBJECT = gql`
  ${SOURCE_OBJECT_FIELDS}
  mutation CreateSourceObject($data: SourceObjectCreateInput!) {
    createSourceObject(data: $data) {
      ...SourceObjectFields
    }
  }
`;

const UPDATE_SOURCE_OBJECT = gql`
  ${SOURCE_OBJECT_FIELDS}
  mutation UpdateSourceObject(
    $where: SourceObjectWhereUniqueInput!
    $data: SourceObjectUpdateInput!
  ) {
    updateSourceObject(where: $where, data: $data) {
      ...SourceObjectFields
    }
  }
`;

const DELETE_SOURCE_OBJECT = gql`
  ${SOURCE_OBJECT_FIELDS}
  mutation DeleteSourceObject($where: SourceObjectWhereUniqueInput!) {
    deleteSourceObject(where: $where) {
      ...SourceObjectFields
    }
  }
`;

//hooks query
export function useSourceObject(options) {
  return useQuery(GET_SOURCE_OBJECT_BY_ID, options);
}

export function useSourceObjects(options) {
  return useQuery(GET_SOURCE_OBJECTS, options);
}

//hooks mutation
export function useCreateSourceObject(options) {
  return useMutation(CREATE_SOURCE_OBJECT, options);
}

export function useUpdateSourceObject(options) {
  return useMutation(UPDATE_SOURCE_OBJECT, options);
}

export function useDeleteSourceObject(options) {
  return useMutation(DELETE_SOURCE_OBJECT, options);
}
