import React from "react";

import { PointRender } from "../geometryRenders";
import { RenderMap } from "../RenderMap";

export function PositionsHelper({ positions, geometrySetting }) {
  return (
    <>
      {positions.map((position, index) => (
        <RenderMap
          data={{
            position,
            ...geometrySetting,
            id: `edit-render-helper-point-${index}`,
          }}
          key={`edit-render-helper-point-${index}`}
        ></RenderMap>
      ))}
    </>
  );
}
