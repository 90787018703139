import React from "react";
import { useReactiveVar } from "@apollo/client";

import {
  drawingToolEditVar,
  geometrySettingVar,
  checkEditDataReady,
} from "../drawingToolVars";

import { RenderMap } from "./RenderMap";
import { PositionsHelper } from "./helperGeometryRender";
import { useRequestRender } from "../utils";
// function RenderMap(props) {
//   // console.log({ props });
//   // useReactiveVar(drawingToolEditVar);
//   // useReactiveVar(geometrySettingVar);
//   switch (props.data.type) {
//     case "billboard":
//       return <BillboardRender {...props}></BillboardRender>;
//     case "polyline":
//       return <PolylineRender {...props}></PolylineRender>;
//     case "polygon":
//       return <PolygonRender {...props}></PolygonRender>;
//     default:
//       return null;
//   }
// }

//当编辑对象数据不足无法渲染时，渲染辅助点的几何设置
const positionsHelpergeometrySetting = {
  type: "point",
  pixelSize: 5,
  color: "#ff0000",
  alpha: 0.8,
  outlineWidth: 2,
  outlineColor: "#000000",
};

export function EditingDataRender(props) {
  const { editData } = useReactiveVar(drawingToolEditVar);
  const geometrySetting = useReactiveVar(geometrySettingVar);
  useRequestRender();
  return checkEditDataReady().success ? (
    <RenderMap
      data={{
        id: `editing-geometry-${editData.type}`,
        ...editData,
        ...geometrySetting[editData.type],
      }}
    ></RenderMap>
  ) : (
    editData.positions && (
      // 绘制多点对象当无法渲染时，绘制辅助点帮助
      <PositionsHelper
        positions={editData.positions}
        geometrySetting={positionsHelpergeometrySetting}
      ></PositionsHelper>
    )
  );
}
