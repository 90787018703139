import React from "react";
import { useParams, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
// import { Cesium3DTileStyle } from "cesium";
//local
import { usePointCloud, resetEvent } from "../../../gql";
import {
  TiledModel,
  ScreenEvents,
  CustomViewer,
  CustomScene,
  CustomCamera,
  Logo,
  BottomMenu,
} from "../../../components";

// const style = new Cesium3DTileStyle();
// // Override pointSize expression with a number
// style.pointSize = 2.0;

export function PointCloudViewer(props) {
  const { id } = useParams();
  const { data } = usePointCloud({
    variables: {
      where: { id },
    },
  });

  //unmount时重置screenEventsVar
  React.useEffect(() => {
    return resetEvent;
  }, []);

  return (
    data &&
    (data.pointCloud ? (
      <>
        <Helmet>
          <title>三维点云查看器</title>
        </Helmet>

        {/* requestRenderMode取消性能节省设置,单模型显示性能要求不高,提高响应速度 */}
        <CustomViewer requestRenderMode={false}>
          <CustomScene></CustomScene>

          <CustomCamera></CustomCamera>

          {/* 根据设置了setAsHome属性的模型来设置初始视角  */}
          <TiledModel
            url={data.pointCloud.url}
            zoom
            setAsHome
            // debugShowBoundingVolume
            // debugShowContentBoundingVolume
            pointSize={4}
            // style={style}
          />

          <ScreenEvents></ScreenEvents>

          <BottomMenu
            toolMenu
            homeButton
            cameraModeButton
            vrButton={false}
            fullScreenButton
          ></BottomMenu>
          <Logo></Logo>
        </CustomViewer>
      </>
    ) : (
      <Navigate to="/404" replace></Navigate>
    ))
  );
}
