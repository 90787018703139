import { useCesium } from "resium";
import { useReactiveVar } from "@apollo/client";

import { HeadingPitchRange, Math } from "cesium";

import { zoomVar, setCompassHeading } from "../gql";

export function useZoomTo() {
  let { viewer } = useCesium();

  const zoomTo = (target, offset) => {
    if (viewer) {
      return viewer.zoomTo(target, offset);
    }
  };
  return zoomTo;
}

export function useFlyTo() {
  let { viewer } = useCesium();

  const flyTo = (target, offset) => {
    if (viewer) {
      return viewer.flyTo(target);
    }
  };
  return flyTo;
}

//使用全局设置，来飞行至目标
export function useGlobalFlyTo() {
  let { viewer, camera } = useCesium();
  const { flyTo } = useReactiveVar(zoomVar);
  const offset = useGlobalZoomOffest();
  const duration = flyTo ? 3 : 0.1;
  const globalFlyTo = (target) => {
    if (viewer) {
      return viewer.flyTo(target, { duration, offset }).then(() => {
        setCompassHeading(Math.toDegrees(camera.heading).toFixed(0)); //飞行完成后更新指南针Heading参数
      });
    }
  };
  return globalFlyTo;
}

//使用全局设置，来飞行至特定id的entity
export function useGlobalFlyToAssetById() {
  const { viewer } = useCesium();
  const flyTo = useGlobalFlyTo();
  return (id) => {
    const entity = viewer.entities.getById(id);
    if (entity && entity.isShowing) {
      return flyTo(entity);
    }
  };
}

export function useGlobalZoomOffest() {
  const { zoomDistance, zoomPitch, zoomHeading } = useReactiveVar(zoomVar);
  return new HeadingPitchRange(
    Math.toRadians(zoomHeading),
    Math.toRadians(zoomPitch),
    zoomDistance
  );
}
