import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import DeleteIcon from "@mui/icons-material/Delete";
export function SelectFileToDeleteField({
  images,
  imageIdsToDelete,
  setImageIdsToDelete,
}) {
  return (
    images.length > 0 && (
      <Box
        sx={{
          width: "100%",
          p: 1,
          mt: 2,
        }}
      >
        <Typography
          variant="h6"
          align="left"
          sx={{
            fontWeight: 700,
          }}
          gutterBottom
        >
          选择拟删除配图
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flexStart",
            gap: 1,
          }}
        >
          {images.map(({ id, image }) => {
            const selected = imageIdsToDelete.indexOf(id) !== -1;
            return (
              <Box
                key={id}
                sx={{
                  cursor: "pointer",

                  position: "relative",
                  width: {
                    xs: 60,
                    md: 70,
                    lg: 80,
                    xl: 90,
                  },
                  height: {
                    xs: 60,
                    md: 70,
                    lg: 80,
                    xl: 90,
                  },
                  borderStyle: "solid",
                  borderColor: "secondary.main",
                  borderWidth: selected ? 3 : 0,
                }}
                onClick={() => {
                  if (selected) {
                    setImageIdsToDelete((images) =>
                      images.filter((imageId) => imageId !== id)
                    );
                  } else {
                    setImageIdsToDelete((images) => [...images, id]);
                  }
                }}
              >
                <Box
                  sx={{
                    borderRadius: 1,
                    width: 1,
                    height: 1,

                    // 使图片img标签具有background-size的效果
                    objectFit: "cover",
                  }}
                  component="img"
                  src={image.url}
                ></Box>

                {selected && (
                  <Typography
                    variant="subtitle"
                    display="block"
                    align="center"
                    color="secondary"
                    sx={{
                      bgcolor: "background.alphaPaper",
                      width: 1,
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%,-50%)",
                    }}
                  >
                    拟删除
                  </Typography>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    )
  );
}
