import React from "react";
import { useSnackbar } from "notistack";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { CustomForm } from "../../../../schemaForms";
import { useUpdateSourceObject } from "../../../../gql";

export function SourceObjectDataUpdateForm({ sourceObject, meta, ...props }) {
  const formRef = React.createRef();
  const { enqueueSnackbar } = useSnackbar();

  const [updateSourceObject, { loading }] = useUpdateSourceObject();

  const handleSubmit = React.useCallback(
    async (e) => {
      try {
        const res = await updateSourceObject({
          variables: {
            where: { id: sourceObject.id },
            data: {
              data: e.formData,
            },
          },
        });
        if (res.errors) {
          enqueueSnackbar("编辑属性失败", {
            variant: "error",
            key: "update-source-object-error",
          });
        } else {
          enqueueSnackbar("成功编辑属性", {
            variant: "success",
            key: "update-source-object-sucess",
          });
        }
      } catch (error) {
        enqueueSnackbar("编辑属性失败", {
          variant: "error",
          key: "update-source-object-error",
        });
      }
    },
    [sourceObject.id, updateSourceObject, enqueueSnackbar]
  );

  return (
    <Box pb="50px">
      <CustomForm
        ref={formRef}
        formData={sourceObject.data || {}}
        showErrorList={false}
        schema={meta.jsonSchema.schema}
        uiSchema={meta.jsonSchema.uiSchema}
        onSubmit={handleSubmit}
      >
        <Box
          sx={{
            width: "100%",
            height: 60,
            position: "fixed",
            bottom: 0,
            zIndex: 10000,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            pr: 6,
          }}
        >
          <Button
            variant="contained"
            disabled={loading}
            sx={{ mr: 1, height: 40, flex: "none" }}
            size="small"
            onClick={() => {
              if (formRef.current) {
                if (formRef.current.validateForm()) {
                  enqueueSnackbar("数据验证通过", {
                    variant: "success",
                    key: "data-validate-succes",
                  });
                } else {
                  enqueueSnackbar("数据验证失败,请检查表单", {
                    variant: "error",
                    key: "data-validate-error",
                  });
                }
              }
            }}
          >
            验证数据
          </Button>
          <Button
            size="small"
            type="submit"
            variant="contained"
            disabled={loading}
            sx={{ height: 40, flex: "none" }}
          >
            提交
          </Button>
        </Box>
      </CustomForm>
    </Box>
  );
}
