import React from "react";

import { Entity, BillboardGraphics } from "resium";
import { Color, VerticalOrigin } from "cesium";

import { iconVarientMap } from "./config";

// 标记点位使用的BillBoard,不会被3dtile遮挡
export function PositionBillBoard({
  //billboard Settings
  iconVarient = "place",
  color = "RED",
  alpha = 1,
  BillboardGraphicsProps,
  ...props
}) {
  //默认使用place Icon
  const iconImage = iconVarientMap[iconVarient] || iconVarientMap["place"];
  return (
    <Entity {...props}>
      <BillboardGraphics
        verticalOrigin={VerticalOrigin.BOTTOM}
        image={iconImage}
        color={color === "NONE" ? undefined : Color[color].withAlpha(alpha)}
        // 始终显示不被遮挡
        disableDepthTestDistance={Number.POSITIVE_INFINITY}
        {...BillboardGraphicsProps}
      />
    </Entity>
  );
}
