import React from "react";

import { Entity, PolygonGraphics } from "resium";
import { Color, ArcType, ClassificationType } from "cesium";

export function PolygonEntity({
  //polygon Settings
  hierarchyArray,
  id,
  fill = true,
  color = "RED",
  alpha = 0.2,
  // outline = false,
  // outlineColor = "BLUE",
  polygonClamp,
  PolygonGraphicsProps,
  ...props
}) {
  // const parent = React.useRef(null);
  // const child = React.useRef(null);

  // React.useEffect(() => {
  //   console.log(parent.current.merge);
  //   child.parent = parent;
  // });
  return hierarchyArray.map((hierarchy, index) => (
    <Entity id={index === 0 ? id : `${id}-${index}`} {...props} key={index}>
      <PolygonGraphics
        arcType={ArcType.GEODESIC}
        hierarchy={hierarchy}
        //是否clamp至模型表面
        perPositionHeight={!polygonClamp}
        classificationType={ClassificationType.CESIUM_3D_TILE}
        //显示参数
        fill={fill}
        material={Color[color].withAlpha(alpha)}
        // outline={!polygonClamp && outline} //clamp至地面时无边界线
        // outlineColor={Color[outlineColor]}
        // outlineWidth={10}
        // 始终显示不被遮挡
        {...PolygonGraphicsProps}
      />
    </Entity>
  ));
}

// export function PolygonEntity({
//   //polygon Settings
//   hierarchyArray,
//   id,
//   fill = true,
//   color = "RED",
//   alpha = 0.2,
//   // outline = false,
//   // outlineColor = "BLUE",
//   polygonClamp,
//   PolygonGraphicsProps,
//   ...props
// }) {
//   const { viewer } = useCesium();
//   // const parent = React.useRef(null);
//   // const child = React.useRef(null);
//   const polygons = viewer.entities.add(
//     new CesiumEntity({ id: id, show: props.show })
//   );
//   // React.useEffect(() => {
//   //   console.log(parent.current.merge);
//   // });
//   return hierarchyArray.map((hierarchy, index) => (
//     <Entity id={`${id}-${index}`} {...props} key={index} parent={polygons}>
//       <PolygonGraphics
//         arcType={ArcType.GEODESIC}
//         hierarchy={hierarchy}
//         //是否clamp至模型表面
//         perPositionHeight={!polygonClamp}
//         classificationType={ClassificationType.CESIUM_3D_TILE}
//         //显示参数
//         fill={fill}
//         material={Color[color].withAlpha(alpha)}
//         // outline={!polygonClamp && outline} //clamp至地面时无边界线
//         // outlineColor={Color[outlineColor]}
//         // outlineWidth={10}
//         // 始终显示不被遮挡
//         {...PolygonGraphicsProps}
//       />
//     </Entity>
//   ));
// }
