export const components = {
  MuiCssBaseline: {
    //hide cesium credits
    styleOverrides: `
    .cesium-performanceDisplay-defaultContainer {
      position: absolute;
      top: unset;
      right: unset;
      bottom: 8px;
      left: 8px;
      text-align: left;
      z-index: 1600;
  }
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #888888;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555555;
    }
    `,
  },
  MuiDataGrid: {
    styleOverrides: {
      menu: {
        zIndex: 1490, //确保列表弹出菜单zIndex较高，避免被列表遮挡Tooltip相等
      },
      panel: {
        // inset: "auto auto 0px 0px !important", //避免panel溢出
        // transform: "unset !important", //避免panel溢出
        zIndex: 1490, //确保列表弹出面板zIndex较高，避免被列表遮挡Tooltip相等
      },
    },
  },
};
