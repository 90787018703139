import { gql, useQuery } from "@apollo/client";

//fragments
const TILED_MODEL_FIELDS = gql`
  fragment TiledModelFields on TiledModel {
    id
    name
    url
  }
`;

//queries
const GET_TILED_MODEL_BY_ID = gql`
  ${TILED_MODEL_FIELDS}
  query getTiledModel($where: TiledModelWhereUniqueInput!) {
    tiledModel(where: $where) {
      ...TiledModelFields
    }
  }
`;

//queries
const GET_TILED_MODELS = gql`
  ${TILED_MODEL_FIELDS}
  query getTiledModels($where: TiledModelWhereInput! = {}) {
    tiledModels(where: $where) {
      ...TiledModelFields
    }
  }
`;

//mutations

//hooks query
export function useTiledModel(options) {
  return useQuery(GET_TILED_MODEL_BY_ID, options);
}

export function useTiledModels(options) {
  return useQuery(GET_TILED_MODELS, options);
}

//hooks mutation
