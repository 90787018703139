import React from "react";
import { useReactiveVar } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Draggable from "react-draggable";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Avatar from "@mui/material/Avatar";
import Portal from "@mui/base/Portal";

import { userVar, useComment } from "../../../gql";
import { CommentImageGallery } from "./CommentImageGallery";
import { CommentTags } from "./CommentTags";
import { CommentDeleteButton } from "./CommentDeleteButton";
import { CommentEditPanel, UpdateCommentForm } from "../../../components";
import { userIdEquals } from "../../../accessControl";

//用于控制Dialog拖动
function DraggablePaper(props) {
  return (
    <Draggable handle="#draggable-comment-dialog">
      <Paper {...props} />
    </Draggable>
  );
}

export function CommentDialog({ id, open, handleClose, edit = false }) {
  const { data } = useComment({
    variables: {
      where: { id },
    },
  });

  const [editMode, setEditMode] = React.useState(edit);

  const exitEdit = React.useCallback(() => {
    setEditMode(false);
  }, [setEditMode]);
  const user = useReactiveVar(userVar);
  const theme = useTheme();
  //小于md时全屏展示
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    data &&
    data.comment &&
    (editMode ? (
      <Portal>
        <CommentEditPanel
          handleClose={exitEdit}
          handleId="draggable-update-comment-panel"
        >
          <UpdateCommentForm
            comment={data.comment}
            handleClose={exitEdit}
          ></UpdateCommentForm>
        </CommentEditPanel>
      </Portal>
    ) : (
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={smDown}
        hideBackdrop
        maxWidth="md"
        scroll="paper"
        sx={{
          zIndex: "commentDialog",
        }}
        PaperProps={{
          sx: { bgcolor: "background.alphaPaper" },
        }}
        PaperComponent={DraggablePaper}
        aria-labelledby="draggable-comment-dialog"
      >
        {/* 显示评论人头像 姓名 评论时间  */}
        <DialogTitle
          id="draggable-comment-dialog"
          sx={{
            minWidth: { xs: 1, sm: 480 },
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
          }}
        >
          <Avatar
            sx={{
              mr: 1.5,
              bgcolor: "primary.main",
            }}
          >
            {data.comment.user.name.slice(0, 1)}
          </Avatar>
          <Typography variant="h5" color="initial" component="p">
            {data.comment.user.name}
          </Typography>

          <Typography
            variant="h6"
            color="initial"
            sx={{ ml: "auto" }}
            component="p"
          >
            {data.comment.createdAt.slice(0, 10)}
          </Typography>
        </DialogTitle>

        {/* 显示评论内容 包括图片画廊 和 文本内容  */}
        <DialogContent
          dividers
          sx={{
            width: {
              xs: 1,
              sm: 480,
              md: data.comment.imagesCount > 1 ? 660 : 480,
            },

            //小caption标题上均margin2 除了最初的标题
            "& .MuiTypography-caption:not(:first-of-type)": {
              mt: 2,
            },
          }}
        >
          {data.comment.images.length > 0 && (
            <Typography display="block" variant="caption" color="primary">
              评论配图
            </Typography>
          )}
          <CommentImageGallery
            images={data.comment.images}
            title={data.comment.text}
          ></CommentImageGallery>

          {/* 评论标签 */}
          <CommentTags tags={data.comment.tags}></CommentTags>

          <Typography display="block" variant="caption" color="primary">
            评论内容
          </Typography>
          <Typography
            variant="h6"
            color="initial"
            sx={{
              lineHeight: 1.6,
              lineBreak: "anywhere", //避免不换行导致撑开父元素
            }}
          >
            {data.comment.text}
          </Typography>

          {/* 显示评论的回复信息，按回复时间排序 */}
          {/* todo */}
        </DialogContent>

        <DialogActions>
          {/* 评论人特定动作按钮 删除 编辑 */}
          {userIdEquals(user, data.comment.user.id) && (
            <>
              <CommentDeleteButton
                afterDelete={handleClose}
                comment={data.comment}
              ></CommentDeleteButton>

              <Button onClick={() => setEditMode(true)}>编辑</Button>
            </>
          )}

          <Button onClick={handleClose}>关闭</Button>
        </DialogActions>
      </Dialog>
    ))
  );
}
