import React from "react";
import { useCesium } from "resium";
import { setViewerSetting } from "../../gql";

export function SetCameraView({ view, setAsHome = true, goToView = true }) {
  const { camera } = useCesium();

  React.useEffect(() => {
    // console.log("run SetCameraView Effect");
    try {
      if (goToView) {
        camera.setView({
          destination: view.position,
          orientation: {
            direction: view.direction,
            up: view.up,
          },
        });
      }
      if (setAsHome) {
        setViewerSetting({
          homeCameraProps: view,
        });
      }
    } catch (error) {
      console.log("error when setting default camera view", error);
    }
  }, [camera, view, setAsHome, goToView]);
  return null;
}
