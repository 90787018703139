import React from "react";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import { useCommentTags, useCommentTagTreeConfig } from "../../gql";
import { TagSelectFieldWithTree } from "./TagSelectFieldWithTree";
import { TagSelectWithoutTree } from "./TagSelectWithoutTree";
import { ErrorBoundary } from "../../components";

export function TagSelectField({ tags, setTags, ...props }) {
  const { data: tagConfigData, loading } = useCommentTagTreeConfig({});

  const { data } = useCommentTags({
    variables: {
      orderBy: {
        order: "asc",
      },
    },
  });

  //选择标签
  const handleClick = React.useCallback(
    (id) => () => {
      if (tags.indexOf(id) === -1) {
        setTags((tags) => [...tags, id]);
      }
    },
    [setTags]
  );

  //删除标签
  const handleDelete = React.useCallback(
    (id) => () => {
      setTags((tags) => tags.filter((tagId) => tagId !== id));
    },
    [setTags]
  );

  return (
    data &&
    data.commentTags &&
    data.commentTags.length > 0 && (
      <ErrorBoundary
        message="
      评论标签树配置配置错误,请停止启用,重新配置正确后再启用"
      >
        <Box
          sx={{
            width: 1,
            p: 1,
          }}
        >
          <Typography
            variant="h6"
            align="left"
            sx={{
              fontWeight: 700,
            }}
            gutterBottom
          >
            选取评论标签
          </Typography>

          {!loading &&
            (tagConfigData &&
            tagConfigData.commentTagTree &&
            tagConfigData.commentTagTree.inUse ? (
              <TagSelectFieldWithTree
                tags={tags}
                commentTags={data.commentTags}
                commentTagTree={tagConfigData.commentTagTree}
                handleClick={handleClick}
                handleDelete={handleDelete}
              ></TagSelectFieldWithTree>
            ) : (
              <TagSelectWithoutTree
                tags={tags}
                commentTags={data.commentTags}
                handleClick={handleClick}
                handleDelete={handleDelete}
              ></TagSelectWithoutTree>
            ))}
        </Box>
      </ErrorBoundary>
    )
  );
}
