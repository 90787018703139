import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
// import GlobalStyles from "@mui/material/GlobalStyles";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import Button from "@mui/material/Button";

function JsonSchemaPageHeaderTitle({ title }) {
  return (
    <>
      <Typography
        variant="h4"
        align="center"
        sx={{
          fontWeight: "bold",
          mb: 3,
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <Button variant="outlined" component={Link} to="/config/schema">
          数据模式列表
        </Button>
        <Button variant="outlined" component={Link} to="/config/schema/create">
          创建数据模式
        </Button>
      </Box>
    </>
  );
}

const paperProps = {
  width: 1,
  zIndex: 10000,
  p: 2,
};

export function JsonSchemaPageHeader({ title, children }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {/* <GlobalStyles
        styles={{
          ".pace": {
            display: "none",
          },
        }}
      ></GlobalStyles> */}
      <Paper
        sx={{
          position: "fixed",
          top: 0,
          ...paperProps,
        }}
      >
        <JsonSchemaPageHeaderTitle title={title}></JsonSchemaPageHeaderTitle>
        {children}
      </Paper>

      {/* 普通布局盒子,内容与fixed Paper相同,占空间 */}
      <Box
        sx={{
          visibility: "hidden",
          mb: 3,
          ...paperProps,
        }}
      >
        <JsonSchemaPageHeaderTitle title={title}></JsonSchemaPageHeaderTitle>
        {children}
      </Box>
    </>
  );
}
