import React from "react";
import { useParams, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

//local
import { useTiledModel, resetEvent } from "../../../gql";
import {
  TiledModel,
  ScreenEvents,
  CustomViewer,
  CustomScene,
  CustomCamera,
  Logo,
  BottomMenu,
} from "../../../components";

export function TiledModelViewer(props) {
  const { id } = useParams();
  const { data } = useTiledModel({
    variables: {
      where: { id },
    },
  });

  //unmount时重置screenEventsVar
  React.useEffect(() => {
    return resetEvent;
  }, []);

  return (
    data &&
    (data.tiledModel ? (
      <>
        <Helmet>
          <title>实景模型查看器</title>
        </Helmet>

        {/* requestRenderMode取消性能节省设置,单模型显示性能要求不高,提高响应速度 */}
        <CustomViewer requestRenderMode={false}>
          <CustomScene></CustomScene>

          <CustomCamera></CustomCamera>

          {/* 根据设置了setAsHome属性的模型来设置初始视角  */}
          <TiledModel url={data.tiledModel.url} zoom setAsHome />

          <ScreenEvents></ScreenEvents>

          <BottomMenu
            toolMenu
            homeButton
            cameraModeButton
            vrButton={false}
            fullScreenButton
          ></BottomMenu>
          <Logo></Logo>
        </CustomViewer>
      </>
    ) : (
      <Navigate to="/404" replace></Navigate>
    ))
  );
}
