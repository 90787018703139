import React from "react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";

export function PreviewIframe({ src, open, closeIframe }) {
  return (
    <Dialog
      sx={{
        zIndex: (theme) => theme.zIndex.previewIframe,
      }}
      fullWidth={true}
      maxWidth="90vw"
      open={open}
      BackdropComponent={() => (
        <Backdrop open={open} onClick={closeIframe}>
          <IconButton
            size="small"
            sx={{
              bgcolor: "grey.100",
              position: "absolute",
              top: 2,
              right: 2,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Backdrop>
      )}
    >
      <Box
        sx={{
          width: 1,
          height: "90vh",
          border: "none",
        }}
        component="iframe"
        src={src}
        allowFullScreen
      ></Box>
    </Dialog>
  );
}
