import React from "react";
import { useCesium } from "resium";

import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export function FxaaSwitch(props) {
  const { scene } = useCesium();
  const [enableFxaa, setEnableFxaa] = React.useState(
    scene.postProcessStages.fxaa.enabled
  );
  React.useEffect(() => {
    scene.postProcessStages.fxaa.enabled = enableFxaa;
  }, [enableFxaa]);
  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <FormControlLabel
        control={
          <Switch
            checked={enableFxaa}
            onChange={(event) => {
              setEnableFxaa(event.target.checked);
            }}
          />
        }
        label="开启抗锯齿"
      />
    </Box>
  );
}
