import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { CommentTag } from "../components";
export function CommentTags({ tags }) {
  return (
    tags.length > 0 && (
      <>
        <Typography display="block" variant="caption" color="primary">
          评论标签
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          {tags.map((tag) => {
            return <CommentTag key={tag.name} tag={tag}></CommentTag>;
          })}
        </Box>
      </>
    )
  );
}
