import React, { useState } from "react";
import { useFormik } from "formik";
import { useLogin } from "../../gql";

import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import * as Yup from "yup";

import PersonIcon from "@mui/icons-material/Person";
import KeyIcon from "@mui/icons-material/Key";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const loginSchema = Yup.object({
  name: Yup.string().required("用户名不能为空"),
  password: Yup.string().required("密码不能为空"),
});

export function LoginForm({ redirectUrl, ...props }) {
  const [loginMutation] = useLogin({
    redirectUrl,
  });

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      name: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      loginMutation({ variables: values }).then(() => {
        setSubmitting(false);
      });
    },
  });

  //密码是否明文显示的按钮设置
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box
      sx={{
        maxWidth: 400,
      }}
    >
      <form onSubmit={handleSubmit} noValidate>
        <TextField
          id="name"
          name="name"
          type="text"
          label="用户名"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && Boolean(errors.name)}
          helperText={(touched.name && errors.name) || "请填写用户名"}
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
          required
          sx={{
            mb: 2,
          }}
        />

        <TextField
          id="password"
          name="password"
          label="密码"
          variant="outlined"
          type={showPassword ? "text" : "password"}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.password && Boolean(errors.password)}
          helperText={(touched.password && errors.password) || "请填写密码"}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <KeyIcon />
              </InputAdornment>
            ),
            endAdornment: showPassword ? (
              <Tooltip title="关闭密码" placeholder="bottom">
                <IconButton
                  aria-label="toggle-password-on"
                  onClick={() => {
                    setShowPassword(false);
                  }}
                >
                  <VisibilityOffIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="显示密码" placeholder="bottom">
                <IconButton
                  aria-label="toggle-password-off"
                  onClick={() => {
                    setShowPassword(true);
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            ),
          }}
          required
          sx={{
            mb: 2,
          }}
        />

        <Button
          color="primary"
          variant="contained"
          type="submit"
          fullWidth
          disabled={isSubmitting}
        >
          登陆
        </Button>
      </form>
    </Box>
  );
}
