import { drawingType } from "../config";

export function calculateGeometryProps(geometryData) {
  const geometryProps = {};
  for (const item of geometryData) {
    const {
      type,
      id,
      positions,
      position,
      text,
      uri,
      orientation,
      scale,
      clampToGround,
      closed,
      ...props
    } = item;
    geometryProps[type] = props;
  }

  return geometryProps;
}
