import { gql, useQuery } from "@apollo/client";

//fragments
const DSHOTSPOT_FIELDS = gql`
  fragment DsHotspotFields on DsHotspot {
    id
    name
    position
    iconType
    targetUrl
    editContent {
      id
      title
    }
  }
`;

//queries
const GET_DSHOTSPOT_BY_ID = gql`
  ${DSHOTSPOT_FIELDS}
  query getDsHotspot($where: DsHotspotWhereUniqueInput!) {
    dsHotspot(where: $where) {
      ...DsHotspotFields
    }
  }
`;

//queries
const GET_DSHOTSPOTS = gql`
  ${DSHOTSPOT_FIELDS}
  query getDsHotspots($where: DsHotspotWhereInput! = {}) {
    dsHotspots(where: $where) {
      ...DsHotspotFields
    }
  }
`;

//mutations

//hooks query
export function useDsHotspot(options) {
  return useQuery(GET_DSHOTSPOT_BY_ID, options);
}

export function useDsHotspots(options) {
  return useQuery(GET_DSHOTSPOTS, options);
}

//hooks mutation
