import React from "react";
import { useReactiveVar } from "@apollo/client";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";

import { HtmlOverlayContainer } from "../../components";
import { overlayIframeVar } from "../../gql";

export function PreviewHtmlOverlayIframe({ src, closeIframe, position }) {
  const { overlayContainerProps } = useReactiveVar(overlayIframeVar);
  return (
    <HtmlOverlayContainer
      position={position}
      sx={{
        zIndex: (theme) => theme.zIndex.previewIframe,
      }}
      {...overlayContainerProps}
    >
      <Box
        sx={{
          width: 1,
          height: 1,
          border: "none",
        }}
        component="iframe"
        src={src}
        allowFullScreen
      ></Box>
      <IconButton
        onClick={closeIframe}
        size="small"
        sx={{
          bgcolor: "grey.100",
          position: "absolute",
          top: 2,
          right: 2,
        }}
      >
        <CloseIcon />
      </IconButton>
    </HtmlOverlayContainer>
  );
}
