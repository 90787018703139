import React from "react";
import Clipboard from "clipboard";
import { useSnackbar } from "notistack";

import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";

//icons
import LinkIcon from "@mui/icons-material/Link";
//local
import { useCameraViewUrl } from "../../../hooks";

import { LocalPanel } from "../../panel";
import { QrPanelContent } from "./panelElement";

export function ViewLinkMenuItem(props) {
  //控制二维码面板开关
  let [qrViewUrl, setQrViewUrl] = React.useState(null);

  const closePanel = () => {
    setQrViewUrl(null);
  };

  // const { enqueueSnackbar } = useSnackbar();
  const getViewUrl = useCameraViewUrl();

  // React.useEffect(() => {
  //   const btn = new Clipboard("#copy-viewurl-link", {
  //     text: function (trigger) {
  //       return getViewUrl();
  //     },
  //   });

  //   return () => {
  //     btn.destroy();
  //   };
  // }, [getViewUrl]);

  return (
    <>
      <MenuItem
        // id="copy-viewurl-link"
        onClick={() => {
          // window.navigator.clipboard.writeText(viewerUrl);
          setQrViewUrl(getViewUrl());
          // enqueueSnackbar("当前视角链接已复制至剪贴板", {
          //   variant: "success",
          //   key: "copy-viewurl-success",
          // });
        }}
      >
        <ListItemIcon>
          <LinkIcon />
        </ListItemIcon>
        视角链接
      </MenuItem>

      {/* 二维码展示面板 */}
      {qrViewUrl && (
        <LocalPanel
          panelTitle="当前视角二维码"
          closeHandler={closePanel}
          draggableProps={{
            //初始transform参数
            positionOffset: {
              x: "-50%",
              y: "-50%",
            },
          }}
          sx={{
            left: "50%",
            top: "50%",
            // transform: "translate(-50%, 50%)",
          }}
        >
          <QrPanelContent url={qrViewUrl}></QrPanelContent>
        </LocalPanel>
      )}
    </>
  );
}
