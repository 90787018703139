import React from "react";
import { useReactiveVar } from "@apollo/client";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { SettingForm } from "./SettingForm";
import { iconTypes } from "../icons";

import {
  geometrySettingVar,
  dispatchGeometrySettingVar,
} from "../drawingToolVars";

//基于schema 自动生成的各类几何配置的表单
export function GeometrySettingForm({ geometryType, ...props }) {
  const geometryProps = useReactiveVar(geometrySettingVar)[geometryType];
  const setgeometryProps = React.useCallback(
    (e) => {
      dispatchGeometrySettingVar({
        type: "SET_GEOMETRY_SETTING",
        payload: {
          geometryType: geometryType,
          data: e.formData,
        },
      });
      // console.log(e.formData);
    },
    [geometryType]
  );
  return (
    <SettingForm
      formData={geometryProps}
      onChange={setgeometryProps}
      {...props}
    ></SettingForm>
  );
}
