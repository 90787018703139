import React from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

//forwardRef 用于Transition动画组件
export const BottomPanel = React.forwardRef(({ sxProps, ...props }, ref) => (
  <Paper
    ref={ref}
    sx={{
      position: "fixed",
      bottom: 0,
      // zIndex: (theme) => theme.zIndex.infoPanel,
      width: "100%",
      bgcolor: "background.alphaToolPanel",
      ...sxProps,
    }}
    {...props}
  >
    {props.children}
  </Paper>
));
