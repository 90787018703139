import React from "react";
import { useReactiveVar } from "@apollo/client";
import { Entity, PolygonGraphics } from "resium";
import { ArcType, ClassificationType, ShadowMode } from "cesium";

import { depthTestVar } from "../../../../gql";

import {
  radianArrayToCartesian3,
  createCesiumColor,
  radianArraysToCartesian3Array,
  useRequestRender,
} from "../../utils";

function PolygonEntity({ entityProps, graphicProps }) {
  //在性能优化的情况下，请求主动渲染
  useRequestRender();
  return (
    <Entity {...entityProps}>
      <PolygonGraphics
        classificationType={ClassificationType.CESIUM_3D_TILE}
        // 始终显示不被遮挡
        // disableDepthTestDistance={Number.POSITIVE_INFINITY}
        // shadows={ShadowMode.ENABLED}
        {...graphicProps}
      />
    </Entity>
  );
}

export function PolygonRender({ data, ...props }) {
  //深度检测全局状态追踪，当深度检测发生变化时polygon需要重新渲染
  useReactiveVar(depthTestVar);
  const {
    type,
    id,
    positions,
    color,
    alpha,
    clampToGround,
    outline,
    outlineColor,
    ...extraProps
  } = data;

  const outlineProps = { outline };
  if (outline) {
    outlineProps.outlineColor = createCesiumColor({
      color: outlineColor,
    });
  }

  const polygonEntityProps = {
    entityProps: { id, ...props },
    graphicProps: {
      hierarchy: radianArraysToCartesian3Array(positions),
      material: createCesiumColor({ color, alpha }),
      arcType: clampToGround ? ArcType.GEODESIC : ArcType.NONE, //贴地时仅支持ArcType.GEODESIC & ArcType.RHUMB
      //https://community.cesium.com/t/zoom-to-entity-is-not-zooming-properly-when-clamp-to-ground-is-used/9072 got a bug when flyTo entity with clampToGround=true
      perPositionHeight: !clampToGround, //贴地属性
      ...outlineProps,
      ...extraProps,
    },
  };

  return <PolygonEntity {...polygonEntityProps}></PolygonEntity>;
}
