import React from "react";
import { ariaDescribedByIds } from "@rjsf/utils";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputBase from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

export function SingleFileWidget(props) {
  console.log(props);
  const {
    value,
    readonly,
    disabled,
    onBlur,
    onFocus,
    options,
    schema,
    onChange,
    required,
    label,
    id,
  } = props;

  const _onBlur = ({ target: { value } }) => onBlur(id, value);
  const _onFocus = ({ target: { value } }) => onFocus(id, value);

  const _onChange = (event, value) => {
    // console.log(event.target.value);
    // console.log(value);
    // onChange(value ? value : options.emptyValue);
    console.log(value);
    onChange(value);
  };

  const inputProps = {
    value,
    label,
    id,
    name: id,
  };
  console.log({
    value,
    readonly,
    disabled,
    onBlur,
    onFocus,
    options,
    schema,
    onChange,
    required,
    label,
    id,
  });

  const [file, setFile] = React.useState(null);

  const inputRef = React.createRef(null);
  return (
    <FormControl>
      {/* <InputLabel required={required} htmlFor={id}>
        {label || schema.title}
      </InputLabel> */}

      {/* <InputBase
        type="file"
        {...inputProps}
        aria-describedby={ariaDescribedByIds(id)}
      ></InputBase> */}
      {/* 
      <FormHelperText id={ariaDescribedByIds(id)}>
        {schema.description}
      </FormHelperText> */}
      {/* {file && (
        <Box
          sx={{
            borderRadius: 1,
            width: 150,
            height: 150,

            // 使图片img标签具有background-size的效果
            objectFit: "cover",
          }}
          component="img"
          src={file.preview}
          onLoad={() => {
            // 元素的onload 事件触发后将销毁URL对象, 释放内存。
            URL.revokeObjectURL(file.preview);
          }}
        ></Box>
      )} */}

      {/* <label htmlFor="ttt"></label>
      <Box
        sx={{
          width: 400,
          height: 200,
          border: 2,
          borderStyle: "dashed",
          borderColor: "red",
          "& input": {
            display: "none",
          },
        }}
        onClick={() => {
          document.getElementById("ttt").click();
        }}
      >
        {file && (
          <Box
            sx={{
              borderRadius: 1,
              width: 150,
              height: 150,

              // 使图片img标签具有background-size的效果
              objectFit: "cover",
            }}
            component="img"
            src={file.preview}
            onLoad={() => {
              // 元素的onload 事件触发后将销毁URL对象, 释放内存。
              URL.revokeObjectURL(file.preview);
            }}
          ></Box>
        )}
        <input
          type="file"
          id="ttt"
          style={{
            display:"none"
          }}
          onChange={(e) => {
            console.log(e.target.files);
            const filesWithPreview = Object.assign(e.target.files[0], {
              preview: URL.createObjectURL(e.target.files[0]),
            });
            setFile(filesWithPreview);
            console.log(filesWithPreview);
          }}
        />
      </Box> */}

      {/* <input
        ref={inputRef}
        type="file"
        id="ttt"
        style={{
          display: "none",
        }}
        onChange={(e) => {
          console.log(e.target.files);
          const filesWithPreview = Object.assign(e.target.files[0], {
            preview: URL.createObjectURL(e.target.files[0]),
          });
          setFile(filesWithPreview);
          console.log(filesWithPreview);
        }}
      />
      {file && (
        <Box
          sx={{
            borderRadius: 1,
            width: 150,
            height: 150,

            // 使图片img标签具有background-size的效果
            objectFit: "cover",
          }}
          component="img"
          src={file.preview}
          onLoad={() => {
            // 元素的onload 事件触发后将销毁URL对象, 释放内存。
            URL.revokeObjectURL(file.preview);
          }}
        ></Box>
      )}
      <Button
        variant="contained"
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.click();
          }
        }}
      >
        上传文件
      </Button> */}
      {/* <Button
        onClick={() => {
          const inputEl = document.getElementById("ttt");
          console.log(inputEl.__proto__);
          console.log(inputEl.files);
        }}
      >
        Test
      </Button> */}

      <Button
        onClick={async () => {
          const [fileHandle] = await window.showOpenFilePicker();
          const fileData = await fileHandle.getFile();
          const reader = new FileReader();

          reader.onload = async (evt) => {
            console.log(evt);
            console.log(evt.target.result);

            const writable = await fileHandle.createWritable();
            await writable.write(evt.target.result + "写入新内容");
            await writable.close();
          };
          reader.readAsText(fileData);
        }}
      >
        文件写入新内容
      </Button>

      <input
        ref={inputRef}
        type="file"
        aria-describedby={ariaDescribedByIds(id)}
        id={id}
        style={{
          display: "none",
        }}
        accept={options.accept ? options.accept.join(", ") : undefined}
        onChange={(e) => {
          console.log(e.target.files);
          const filesWithUrl = Object.assign(e.target.files[0], {
            url: URL.createObjectURL(e.target.files[0]),
          });
          console.log(filesWithUrl);
          onChange(filesWithUrl.url);
          if (file) {
            URL.revokeObjectURL(file.url);
          }
          setFile(filesWithUrl);
        }}
      />
    </FormControl>
  );
}
