import React from "react";
import { useReactiveVar } from "@apollo/client";

import { userVar } from "../../../gql";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

export function MutateCommentHeader({ actionText }) {
  const user = useReactiveVar(userVar);

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        textTransform: "capitalize",
        mb: 1,
      }}
    >
      <Avatar
        sx={{
          mr: 0.5,
          bgcolor: "primary.main",
        }}
      >
        {user.name.slice(0, 1)}
      </Avatar>
      <Typography
        variant="h5"
        color="initial"
        component="p"
        sx={{
          fontWeight: 700,
        }}
      >
        {user.name}
      </Typography>
      <Typography
        variant="h5"
        color="primary"
        sx={{
          ml: 1,
          fontWeight: 700,
        }}
      >
        {actionText}
      </Typography>
    </Box>
  );
}
