import placeIcon from "../../assets/icons/place.png";
import tileModelIcon from "../../assets/icons/tiledModel.png";
import pointCloudIcon from "../../assets/icons/pointCloud.png";
import tiledImageIcon from "../../assets/icons/tiledImage.png";
import vtourIcon from "../../assets/icons/vtour.png";
import panoramaIcon from "../../assets/icons/panorama.png";
import commentIcon from "../../assets/icons/comment.png";
import magnifierIcon from "../../assets/icons/magnifier.png";
import handIcon from "../../assets/icons/hand.png";
import personPinIcon from "../../assets/icons/personPin.png";

//https://fonts.google.com/icons?icon.style=Filled&icon.set=Material+Icons
//download icons here

export const iconVarientMap = {
  place: placeIcon,
  tiledModel: tileModelIcon,
  pointCloud: pointCloudIcon,
  tiledImage: tiledImageIcon,
  panorama: vtourIcon,
  vtour: vtourIcon,
  //for ds hotspot use
  point: placeIcon,
  hand: handIcon,
  comment: commentIcon,
  magnifier: magnifierIcon,
  //comment
  personPin: personPinIcon,
};
