import { makeVar } from "@apollo/client";

const eventsType = ["positionTool", "distanceTool", "precisionTool"];

const defaultValue = {
  positionTool: false, //点位测量事件
  distanceTool: false, //距离测量事件
  precisionTool: false, //渲染精度调整事件
};
export const screenEventsVar = makeVar(defaultValue);

function isEqual() {
  let equal = true;
  eventsType.forEach((key) => {
    if (defaultValue[key] !== screenEventsVar()[key]) {
      equal = false;
    }
  });
  return equal;
}

export function addEvent(eventName) {
  if (eventsType.indexOf(eventName) !== -1 && !screenEventsVar()[eventName]) {
    screenEventsVar({
      // ...screenEventsVar(),
      ...defaultValue,
      [eventName]: true,
    });
  } else {
    console.log(`no event change: ${eventName}`);
  }
}

export function removeEvent(eventName) {
  if (eventsType.indexOf(eventName) !== -1 && screenEventsVar()[eventName]) {
    screenEventsVar({
      // ...screenEventsVar(),
      ...defaultValue,
      [eventName]: false,
    });
  } else {
    console.log(`no event change: ${eventName}`);
  }
}

export function resetEvent() {
  if (!isEqual()) {
    screenEventsVar(defaultValue);
  }
}
