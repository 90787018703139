import React from "react";

import { FilesPreview } from "./FilesPreview";
import { ImagesPreview } from "./ImagesPreview";

export function Preview(props) {
  switch (props.fileType) {
    case "image":
      return <ImagesPreview {...props}></ImagesPreview>;
    case "file":
      return <FilesPreview {...props}></FilesPreview>;
    default:
      return null;
  }
}
