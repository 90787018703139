import React from "react";

import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

export function TagSelectWithoutTree({
  tags,
  commentTags,
  handleClick,
  handleDelete,
}) {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      {commentTags.map(({ id, name, description }) => (
        <Tooltip title={description} placement="top" key={id} arrow>
          <Chip
            label={name}
            size="small"
            //选择与删除标签使用不同样式
            variant={tags.indexOf(id) !== -1 ? "filled" : "outlined"}
            color="primary"
            onClick={handleClick(id)}
            onDelete={tags.indexOf(id) !== -1 ? handleDelete(id) : undefined}
          />
        </Tooltip>
      ))}
    </Box>
  );
}
