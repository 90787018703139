import React from "react";
import { useReactiveVar } from "@apollo/client";

import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import {
  cesium3DTilesetRenderConfigVar,
  setBackFaceCulling,
} from "../../../../gql";

export function BackFaceCullingSwitch(props) {
  const { backFaceCulling } = useReactiveVar(cesium3DTilesetRenderConfigVar);
  const handleBackFaceCulling = (event) => {
    setBackFaceCulling(event.target.checked);
  };

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <FormControlLabel
        control={
          <Switch checked={backFaceCulling} onChange={handleBackFaceCulling} />
        }
        label="单面渲染"
      />
    </Box>
  );
}
