import React from "react";

import { useSearchParams } from "react-router-dom";

import { useSourceObjectContext } from "../../../hooks";
import { SourceObjectRenderer } from "./SourceObjectRenderer";

let initSourceObjects = true;

export function SourceObjects(props) {
  const { sourceObjects } = useSourceObjectContext();

  //object search param 用于自动飞行至此对象
  const [search] = useSearchParams();
  const searchObjectId = search.get("object");

  //首次加载后修改initDrawingComments参数,确保flyTo始终为false
  React.useEffect(() => {
    initSourceObjects = false;
  }, []);

  return (
    <>
      {sourceObjects.map((sourceObject) => (
        <SourceObjectRenderer
          sourceObject={sourceObject}
          key={sourceObject.id}
          flyTo={initSourceObjects && searchObjectId === sourceObject.id}
        ></SourceObjectRenderer>
      ))}
    </>
  );
}
