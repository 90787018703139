import React from "react";
import { useReactiveVar } from "@apollo/client";
import { useSnackbar } from "notistack";

import Button from "@mui/material/Button";

import { useDrawingContext } from "../../../hooks";

import { useUpdateSourceObject } from "../../../gql";
import {
  drawingToolDataVar,
  dispatchDrawingToolVar,
  dispatchdrawingStatusVar,
} from "../../drawingTool";
import { SourceObjectEditHeader } from "./SourceObjectEditHeader";

import { CustomForm } from "../../../schemaForms";

export function SourceObjectUpdateForm({
  sourceObject,
  handleEditSourceObjectPanelClose,
}) {
  const [formData, setFormData] = React.useState({
    name: sourceObject.name,
    meta: sourceObject.meta.id,
  });

  const { data: geometry } = useReactiveVar(drawingToolDataVar);

  const { enqueueSnackbar } = useSnackbar();
  const drawing = useDrawingContext();

  React.useEffect(() => {
    const nameInput = document.querySelector(".source-object-name-input input");
    nameInput.addEventListener("keypress", (e) => {
      e.stopPropagation(); //避免和绘图快捷键冲突
    });
  }, []);

  const metaChoices = drawing.supportedMetas.map(
    (meta) => {
      return {
        type: "string",
        title: meta.name,
        enum: [meta.id],
      };
    },
    [drawing.supportedMetas]
  );

  const { schema, uiSchema } = React.useMemo(() => {
    return {
      schema: {
        type: "object",
        required: ["meta", "name"],
        properties: {
          meta: {
            title: "源对象类型",
            // description: "如果对象已绑定数据不匹配新的类型的要求,请勿随意修改",
            type: "string",
            anyOf: metaChoices,
          },
          name: {
            type: "string",
            title: "对象名称",
          },
        },
      },
      uiSchema: {
        name: {
          "ui:classNames": "source-object-name-input",
        },
        meta: {
          "ui:readonly": true,
        },
      },
    };
  }, [metaChoices]);

  const [updateSourceObjectMutation, { loading }] = useUpdateSourceObject({
    refetchQueries: ["SourceObjects"],
  });

  const handleSubmit = React.useCallback(
    async (e) => {
      if (geometry === null || geometry.length === 0) {
        enqueueSnackbar("未绘制源对象的几何", {
          variant: "error",
          key: "update-source-object-error",
        });
        return;
      }

      try {
        const res = await updateSourceObjectMutation({
          variables: {
            where: {
              id: sourceObject.id,
            },
            data: {
              name: e.formData.name,
              meta: {
                connect: {
                  id: e.formData.meta,
                },
              },
              drawing: {
                connect: {
                  id: drawing.id,
                },
              },
              geometry,
            },
          },
        });
        if (res.errors) {
          enqueueSnackbar("编辑源对象失败", {
            variant: "error",
            key: "update-source-object-error",
          });
        } else {
          enqueueSnackbar("成功编辑源对象", {
            variant: "success",
            key: "create-source-object-sucess",
          });

          //提交成功后，直接关闭面板
          // handleEditSourceObjectPanelClose();
        }
      } catch (error) {
        enqueueSnackbar("编辑源对象失败", {
          variant: "error",
          key: "update-source-object-error",
        });
      }
    },
    [
      updateSourceObjectMutation,
      enqueueSnackbar,
      drawing.id,
      geometry,
      sourceObject.id,
      // handleEditSourceObjectPanelClose,
    ]
  );

  return (
    <>
      <SourceObjectEditHeader editMode></SourceObjectEditHeader>
      <CustomForm
        formData={formData}
        onChange={(e) => setFormData(e.formData)}
        showErrorList={false}
        schema={schema}
        uiSchema={uiSchema}
        onSubmit={handleSubmit}
      >
        <Button
          size="small"
          type="submit"
          variant="contained"
          disabled={loading}
          sx={{
            my: 2,
          }}
        >
          编辑源对象
        </Button>
      </CustomForm>
    </>
  );
}
