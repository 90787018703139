import React from "react";

import { ProxyGraphicRender } from "../proxyGraphicRender";

export function PanoramaRender(props) {
  const { asset } = props;
  // console.log(
  //   `render panorama ${asset.name || asset.digitalAsset.name} ${asset.id}`
  // );

  return <ProxyGraphicRender {...props}></ProxyGraphicRender>;
}

export const MemoizedPanoramaRender = React.memo(PanoramaRender);
