export function processTagTree({ tagsMap, tree }) {
  return tree.map((root) => {
    const path = root.name;
    return {
      ...root,
      path,
      children: root.children.map((node) => {
        return node.isLastNode
          ? processLastNode({ tagsMap, node, fatherPath: path })
          : processNode({ tagsMap, node, fatherPath: path });
      }),
    };
  });
}

function processNode({ tagsMap, node, fatherPath }) {
  const path = fatherPath + "/" + node.name;
  return {
    ...node,
    path,
    children: node.children.map((childNode) => {
      return childNode.isLastNode
        ? processLastNode({ tagsMap, node: childNode, fatherPath: path })
        : processNode({ tagsMap, node: childNode, fatherPath: path });
    }),
  };
}

function processLastNode({ tagsMap, node, fatherPath }) {
  const name = tagsMap[node.tagId];
  const path = fatherPath + "/" + name;
  return { ...node, name, path };
}

function getChildrenPaths(node) {
  let paths = [];
  node.children.forEach((childNode) => {
    if (!childNode.isLastNode) {
      paths.push(childNode.path);
      paths = paths.concat(getChildrenPaths(childNode));
    }
  });

  return paths;
}

export function getPathList(tree) {
  let paths = [];
  tree.forEach((node) => {
    paths.push(node.path);
    paths = paths.concat(getChildrenPaths(node));
  });

  return paths;
}
