import React from "react";
import Clipboard from "clipboard";
import { useSnackbar } from "notistack";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import EditIcon from "@mui/icons-material/Edit";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PreviewIcon from "@mui/icons-material/Preview";

const iconButtonProps = {
  size: "small",
  sx: {
    "&:hover": {
      color: "secondary.main",
    },
  },
};

const iconProps = {
  sx: {
    fontSize: "18px",
  },
};

export function MapItemRenderToolBar({
  item,
  schema,
  edit,
  setEdit,
  ...props
}) {
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    const btn = new Clipboard("#map-item-data-copy");
    return () => {
      btn.destroy();
    };
  }, []);

  //复制粘贴item.data
  const handleCopy = React.useCallback(() => {
    enqueueSnackbar("属性已复制至剪贴板", {
      variant: "success",
      key: "map-item-data-copy-success",
    });
  }, [enqueueSnackbar]);

  //编辑sourceObject.data
  const handleEdit = React.useCallback(() => {
    setEdit((edit) => !edit);
  }, [setEdit]);

  //跳转sourceObject对象后台
  const handleOpen = React.useCallback(() => {
    window.open(`${process.env.REACT_APP_ADMIN_URI}/map-items/${item.id}`);
  }, [item.id]);

  return (
    <Box
      width="100%"
      display="flex"
      flexWrap="nowrap"
      justifyContent="flex-end"
      {...props}
    >
      {/* 复制 */}
      {item.data && (
        <Tooltip title="复制" placement="top">
          <IconButton
            aria-label="edit"
            id="map-item-data-copy"
            data-clipboard-text={JSON.stringify(item.data, null, 2)}
            onClick={handleCopy}
            {...iconButtonProps}
          >
            <ContentCopyIcon {...iconProps} />
          </IconButton>
        </Tooltip>
      )}

      {/* 编辑 */}
      <Tooltip title={edit ? "查看属性" : "编辑属性"} placement="top">
        <IconButton aria-label="copy" onClick={handleEdit} {...iconButtonProps}>
          {edit ? <PreviewIcon {...iconProps} /> : <EditIcon {...iconProps} />}
        </IconButton>
      </Tooltip>

      {/* 跳转后台查看 */}
      <Tooltip title="跳转后台查看" placement="top">
        <IconButton aria-label="open" onClick={handleOpen} {...iconButtonProps}>
          <OpenInNewIcon {...iconProps} />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
