import React from "react";

import { rangeSpec, ariaDescribedByIds } from "@rjsf/utils";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import FormLabel from "@mui/material/FormLabel";

//带标签的滑动数字选择,在slider右侧显示数值
export function RangeWithLabelWidget(props) {
  //   console.log(props);
  const {
    value,
    readonly,
    disabled,
    onBlur,
    onFocus,
    options,
    schema,
    onChange,
    required,
    label,
    id,
  } = props;

  const sliderProps = {
    value,
    label,
    id,
    name: id,
    ...rangeSpec(schema),
  };

  const _onChange = (event, value) => {
    // console.log(event.target.value);
    // console.log(value);
    // onChange(value ? value : options.emptyValue);
    onChange(value);
  };
  const _onBlur = ({ target: { value } }) => onBlur(id, value);
  const _onFocus = ({ target: { value } }) => onFocus(id, value);

  return (
    <>
      <FormLabel required={required} htmlFor={id}>
        {label || schema.title}
      </FormLabel>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Slider
          sx={{
            mr: 3,
          }}
          disabled={disabled || readonly}
          onChange={_onChange}
          onBlur={_onBlur}
          onFocus={_onFocus}
          valueLabelDisplay="off"
          {...sliderProps}
          aria-describedby={ariaDescribedByIds(id)}
        />
        <Typography>{value}</Typography>
      </Box>
    </>
  );
}
