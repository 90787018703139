import React from "react";

import { GridActionsCellItem } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import {
  useGlobalFlyToAssetById,
  useCommentRenderMapContext,
} from "../../../../hooks";

export function FlyToAction({ params }) {
  const { id } = params;
  const flyToAssetById = useGlobalFlyToAssetById();
  const { commentsRenderMap, showAllComments } = useCommentRenderMapContext();

  return (
    <GridActionsCellItem
      icon={
        <Tooltip title="导航" placement="top">
          <PlayArrowIcon
            color={
              showAllComments && commentsRenderMap[id] ? "action" : "disabled"
            }
          />
        </Tooltip>
      }
      disabled={!(showAllComments && commentsRenderMap[id])}
      label="Fly"
      onClick={() => {
        flyToAssetById(id);
      }}
    />
  );
}
