import React from "react";

import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";

//icon
import PlaceIcon from "@mui/icons-material/Place";
import PolylineIcon from "@mui/icons-material/Polyline";
import CropDinIcon from "@mui/icons-material/CropDin";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  DrawingTypeButton,
  ClearEditDataButton,
  DrawingButtonHelper,
} from "./drawingButtons";
import { drawingButtonTypeMap } from "../config";
import { ShortCutEvent } from "./ShortCutEvent";
export function DrawingButtonAccordion(props) {
  const [expanded, setExpanded] = React.useState(true);

  return (
    <Accordion
      disableGutters
      square
      expanded={expanded}
      onChange={() => {
        setExpanded((state) => !state);
      }}
      elevation={0}
      sx={{
        bgcolor: "transparent",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="caption" display="block" color="primary.main">
          绘制几何
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            display: "flex",
          }}
        >
          {/* 各类绘图button */}
          {drawingButtonTypeMap.map((drawingButtonProps) => (
            <DrawingTypeButton
              key={drawingButtonProps.buttonType}
              {...drawingButtonProps}
            ></DrawingTypeButton>
          ))}

          {/* 清楚绘制内容按钮 */}
          <ClearEditDataButton
            sx={{
              ml: "auto",
            }}
          ></ClearEditDataButton>
        </Box>
        <DrawingButtonHelper></DrawingButtonHelper>
        <ShortCutEvent></ShortCutEvent>
      </AccordionDetails>
    </Accordion>
  );
}
