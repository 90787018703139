const fileTypes = [
  ".pdf",
  ".zip",
  ".rar",
  ".txt",
  ".glb",
  ".gltf",
  ".obj",
  ".doc",
  ".docx",
  ".ppt",
  ".pptx",
  ".xls",
  ".xlsx",
  ".csv",
  ".jpg",
  ".png",
  ".jpeg",
  ".mp4",
  ".avi",
];

export const fieldTypesDef = {
  textInput: {
    title: "单行文本框",
    attr: {
      schema: {
        type: "string",
      },
      uiOptions: {},
    },
    dependency: {
      required: ["fieldType", "schema"],
      properties: {
        fieldType: {
          enum: ["textInput"],
        },
        schema: {
          type: "object",
          title: "文本框属性配置",
          properties: {
            minLength: {
              title: "最小字符长度",
              type: "integer",
              minimum: 0,
            },
            maxLength: {
              title: "最大字符长度",
              type: "integer",
              minimum: 0,
            },
          },
        },
      },
    },
  },
  textarea: {
    title: "多行文本框",
    attr: {
      schema: {
        type: "string",
      },
      uiOptions: {
        widget: "textarea",
      },
    },
    dependency: {
      required: ["fieldType", "schema", "uiOptions"],
      properties: {
        fieldType: {
          enum: ["textarea"],
        },

        schema: {
          type: "object",
          title: "文本框属性配置",
          properties: {
            minLength: {
              title: "最小字符长度",
              type: "integer",
              minimum: 0,
            },
            maxLength: {
              title: "最大字符长度",
              type: "integer",
              minimum: 0,
            },
          },
        },
        uiOptions: {
          type: "object",
          title: "文本框UI属性配置",
          required: ["rows"],
          properties: {
            rows: {
              type: "integer",
              title: "文本框行数",
              minimum: 2,
              default: 6,
            },
          },
        },
      },
    },
  },

  password: {
    title: "密码",
    attr: {
      schema: {
        type: "string",
      },
      uiOptions: {
        widget: "password",
      },
    },
  },
  color: {
    title: "颜色",
    attr: {
      schema: {
        type: "string",
      },
      uiOptions: {
        widget: "color",
      },
    },
  },

  email: {
    title: "邮箱地址",
    attr: {
      schema: {
        type: "string",
        format: "email",
      },
      uiOptions: {},
    },
  },

  uri: {
    title: "链接",
    attr: {
      schema: {
        type: "string",
        format: "uri",
      },
      uiOptions: {},
    },
  },

  date: {
    title: "日期",
    attr: {
      schema: {
        type: "string",
        format: "date",
      },
      uiOptions: {},
    },
  },

  dateTime: {
    title: "日期(含时间)",
    attr: {
      schema: {
        type: "string",
        format: "date-time",
      },
      uiOptions: {},
    },
  },
  // dataUrl: {
  //   title: "单文件上传",
  //   attr: {
  //     schema: {},
  //     uiOptions: {},
  //   },
  // },
  // dataUrls: {
  //   title: "多文件上传",
  //   attr: {
  //     schema: {},
  //     uiOptions: {},
  //   },
  // },
  updown: {
    title: "数字填写框",
    attr: {
      schema: {},
      uiOptions: {},
    },
    dependency: {
      required: ["fieldType", "schema", "uiOptions"],
      properties: {
        fieldType: {
          enum: ["updown"],
        },
        schema: {
          type: "object",
          title: "字段属性配置",
          required: ["type", "minimum", "maximum"],
          properties: {
            type: {
              type: "string",
              title: "数字类型",
              default: "integer",
              anyOf: [
                {
                  type: "string",
                  title: "浮点数",
                  enum: ["number"],
                },
                {
                  type: "string",
                  title: "整数",
                  enum: ["integer"],
                },
              ],
            },
            minimum: {
              type: "number",
              title: "最小值",
              default: 0,
            },
            maximum: {
              type: "number",
              title: "最大值",
              default: 100,
            },
            multipleOf: {
              type: "number",
              title: "倍数",
            },
          },
        },
        uiOptions: {
          type: "object",
          title: "文本框UI属性配置",
          properties: {
            unit: {
              type: "string",
              title: "单位",
            },
          },
        },
      },
    },
  },

  range: {
    title: "滑动条",
    attr: {
      schema: {},
      uiOptions: {
        widget: "range",
      },
    },
    dependency: {
      required: ["fieldType", "schema", "uiOptions"],
      properties: {
        fieldType: {
          enum: ["range"],
        },
        schema: {
          type: "object",
          title: "字段属性配置",
          required: ["type", "default", "minimum", "maximum", "multipleOf"],
          properties: {
            type: {
              type: "string",
              title: "数字类型",
              default: "integer",
              anyOf: [
                {
                  type: "string",
                  title: "浮点数",
                  enum: ["number"],
                },
                {
                  type: "string",
                  title: "整数",
                  enum: ["integer"],
                },
              ],
            },
            default: {
              type: "number",
              title: "默认值",
            },
            minimum: {
              type: "number",
              title: "最小值",
              default: 0,
            },
            maximum: {
              type: "number",
              title: "最大值",

              default: 100,
            },
            multipleOf: {
              type: "number",
              title: "倍数",
              default: 1,
            },
          },
        },
        uiOptions: {
          type: "object",
          title: "文本框UI属性配置",
          properties: {
            unit: {
              type: "string",
              title: "单位",
            },
          },
        },
      },
    },
  },

  checkbox: {
    title: "勾选框(是否选择)",
    attr: {
      schema: {
        type: "boolean",
      },
      uiOptions: {},
    },
  },

  select: {
    title: "单选",
    attr: {
      schema: {
        type: "string",
      },
      uiOptions: {},
    },
    dependency: {
      required: ["fieldType", "schema"],
      properties: {
        fieldType: {
          enum: ["select"],
        },
        schema: {
          type: "object",
          title: "字段属性配置",
          required: ["enum"],
          properties: {
            enum: {
              type: "array",
              title: "选项设置",
              uniqueItems: true,
              default: ["选项1", "选项2"],
              items: {
                type: "string",
                title: "选项",
              },
            },
          },
        },
      },
    },
  },

  multiSelect: {
    title: "多选框",
    attr: {
      schema: {
        type: "array",
        uniqueItems: true,
      },
      uiOptions: {
        widget: "checkboxes",
        inline: true,
      },
    },
    dependency: {
      required: ["fieldType", "schema", "uiOptions"],
      properties: {
        fieldType: {
          enum: ["multiSelect"],
        },
        schema: {
          type: "object",
          title: "字段属性配置",
          required: ["items", "minItems"],
          properties: {
            minItems: {
              type: "integer",
              title: "最少需要选择的选项数量",
              minimum: 0,
              multipleOf: 1,
              default: 1,
            },
            items: {
              type: "object",
              title: "多选属性设置",
              required: ["type", "enum"],
              properties: {
                type: {
                  title: "选项数据类型",
                  type: "string",
                  default: "string",
                  enum: ["string"],
                },

                enum: {
                  type: "array",
                  title: "选项设置",
                  uniqueItems: true,
                  default: ["选项1", "选项2"],
                  items: {
                    type: "string",
                    title: "选项",
                  },
                },
              },
            },
          },
        },
        uiOptions: {
          type: "object",
          title: "字段UI属性配置",
          required: ["inline"],
          properties: {
            inline: {
              type: "boolean",
              title: "单行选项",
              description: "勾选后,选项连续排列不换行,适用于短选项",
              default: true,
            },
          },
        },
      },
    },
  },

  fileUpload: {
    title: "文件上传",
    attr: {
      schema: {
        type: "array",
        default: [],
        items: {
          type: "object",
          required: ["id", "url", "name"],
          properties: {
            id: { type: "string" },
            name: { type: "string" },
            url: { type: "string" },
          },
        },
      },
      uiOptions: {
        field: "dropZoneUpload",
        fileType: "file",
        identifier: "rjsf-schema", //由schema配置出来的上传框标识符
      },
    },
    dependency: {
      required: ["fieldType", "uiOptions"],
      properties: {
        fieldType: {
          enum: ["fileUpload"],
        },
        uiOptions: {
          type: "object",
          title: "文件上传UI属性配置",
          required: ["multiple", "accept"],
          properties: {
            multiple: {
              type: "boolean",
              title: "是否支持多文件上传",
              description: "不勾选则此文件上传框仅支持单文件上传",
              default: true,
            },
            maxFileZize: {
              type: "number",
              title: "单文件最大上传大小(MB)",
              description: "不设置则不限制文件大小",
            },
            accept: {
              type: "array",
              uniqueItems: true,
              title: "支持的文件格式",
              description: "可多选，如果全部不选则默认支持所有类型文件",
              default: [],
              minItems: 0,
              items: {
                type: "string",
                enum: fileTypes,
              },
            },
          },
        },
      },
    },
  },

  imageUpload: {
    title: "图片上传",
    attr: {
      schema: {
        type: "array",
        default: [],
        items: {
          type: "object",
          required: ["id", "url", "name"],
          properties: {
            id: { type: "string" },
            name: { type: "string" },
            url: { type: "string" },
          },
        },
      },
      uiOptions: {
        field: "dropZoneUpload",
        fileType: "image",
        identifier: "rjsf-schema", //由schema配置出来的上传框标识符
      },
    },
    dependency: {
      required: ["fieldType", "uiOptions"],
      properties: {
        fieldType: {
          enum: ["imageUpload"],
        },
        uiOptions: {
          type: "object",
          title: "图片上传UI属性配置",
          required: ["multiple"],
          properties: {
            multiple: {
              type: "boolean",
              title: "是否支持多图片上传",
              description: "不勾选则此图片上传框仅支持单图片上传",
              default: true,
            },
            maxFileZize: {
              type: "number",
              title: "单图片最大上传大小(MB)",
              description: "不设置则不限制图片大小",
            },
          },
        },
      },
    },
  },
};

export const fieldTypes = Object.keys(fieldTypesDef);

// console.log(fieldTypes);
// [
//   "textInput",
//   "textarea",
//   "password",
//   "color",
//   "email",
//   "uri",
//   "date",
//   "dateTime",
//   "updown",
//   "range",
//   "checkbox",
//   "select",
//   "multiSelect",
//   "fileUpload",
//   "imageUpload"
// ]
