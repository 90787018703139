import React from "react";
import { useReactiveVar } from "@apollo/client";
import { useCesium } from "resium";
import { HeadingPitchRange, Math } from "cesium";

import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import EditIcon from "@mui/icons-material/Edit";
import ArticleIcon from "@mui/icons-material/Article";

import { useDsHotspots, dsHotspotVar, userVar } from "../../../gql";
import { useGlobalFlyToAssetById, useSceneContext } from "../../../hooks";
import { userIsAdminOrEditor } from "../../../accessControl";
import { WysiwygDialog } from "../../../components";

function handleEditClick(id) {
  window.open(`${process.env.REACT_APP_ADMIN_URI}/ds-hotspots/${id}`);
}

function DsHotspotItem({ hotspot, showHotspot }) {
  const { id, name, editContent } = hotspot;
  const user = useReactiveVar(userVar);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);
  const flyToAssetById = useGlobalFlyToAssetById();

  return (
    <>
      <ButtonBase
        disabled={!showHotspot}
        sx={{
          bgcolor: (theme) =>
            showHotspot
              ? theme.palette.background.alphaRed
              : theme.palette.background.alphaBlack,
          borderRadius: 1,
          m: 1,
          p: 0.5,
          boxShadow: 1,
          transition: "all 0.2s",
          "&:hover": {
            boxShadow: 4,
          },
        }}
        onDoubleClick={() => {
          flyToAssetById(id);
        }}
      >
        <Typography
          variant="body1"
          sx={{
            userSelect: "none",
          }}
        >
          {name}
        </Typography>
        {editContent && (
          <Tooltip title="查看热点信息">
            <ArticleIcon
              onClick={async () => {
                await flyToAssetById(id);
                handleOpen();
              }}
              sx={{
                fontSize: "inherit",
                ml: 1,
                color: "primary.main",
              }}
            />
          </Tooltip>
        )}

        {userIsAdminOrEditor(user) && (
          <Tooltip title="编辑热点信息">
            <EditIcon
              onClick={() => {
                handleEditClick(hotspot.id);
              }}
              sx={{
                fontSize: "inherit",
                ml: 1,
              }}
            />
          </Tooltip>
        )}
      </ButtonBase>
      {editContent && (
        <WysiwygDialog
          title={editContent.title || name}
          open={open}
          handleClose={handleClose}
          id={editContent.id}
          editUri={`${process.env.REACT_APP_ADMIN_URI}/ds-hotspots/${id}`}
        ></WysiwygDialog>
      )}
    </>
  );
}

export function DsHotspotsTree() {
  const {
    scene: { id },
  } = useSceneContext();
  const { showHotspot } = useReactiveVar(dsHotspotVar);

  const { data } = useDsHotspots({
    variables: {
      where: {
        digitalScene: {
          id: {
            equals: id,
          },
        },
      },
    },
  });

  return (
    data &&
    data.dsHotspots && (
      <>
        {data.dsHotspots.length > 0 && (
          <Typography
            variant="body1"
            sx={{
              userSelect: "none",
              color: "primary.main",
              ml: 1,
            }}
          >
            双击热点按钮导航，单击文档按钮查看
          </Typography>
        )}

        <Box
          sx={{
            maxWidth: "100%",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {data.dsHotspots.map((hotspot) => (
            <DsHotspotItem
              key={hotspot.id}
              hotspot={hotspot}
              showHotspot={showHotspot}
            ></DsHotspotItem>
          ))}
        </Box>
      </>
    )
  );
}
