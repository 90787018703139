import React from "react";
import { useReactiveVar } from "@apollo/client";
import { Entity, LabelGraphics } from "resium";
import { Color, VerticalOrigin, Cartesian2, LabelStyle } from "cesium";

import { depthTestVar } from "../../../../gql";

import {
  radianArrayToCartesian3,
  createCesiumColor,
  useRequestRender,
} from "../../utils";

function LabelEntity({ entityProps, graphicProps }) {
  //在性能优化的情况下，请求主动渲染
  useRequestRender();
  return (
    <Entity {...entityProps}>
      <LabelGraphics
        verticalOrigin={VerticalOrigin.CENTER} //默认使用中心对齐，这样无论背景边距如何变化，不影响文字位置
        style={LabelStyle.FILL}
        // 始终显示不被遮挡
        // disableDepthTestDistance={Number.POSITIVE_INFINITY}
        {...graphicProps}
      />
    </Entity>
  );
}

export function LabelRender({ data, ...props }) {
  // console.log("render label");
  // console.log({ data });
  //深度检测设置,耦合了全局设置，破坏独立性
  const { enableDepthTest } = useReactiveVar(depthTestVar);
  const disableDepthTestDistance = enableDepthTest
    ? undefined
    : Number.POSITIVE_INFINITY;

  const {
    type,
    id,
    position,
    showBackground,
    backgroundColor,
    backgroundColorAlpha,
    color,
    alpha,
    backgroundPaddingX,
    backgroundPaddingY,
    fontSize,
    ...extraProps
  } = data;

  const showBackgroundProps = { showBackground };

  //背景设置
  if (showBackground) {
    showBackgroundProps.backgroundColor = createCesiumColor({
      color: backgroundColor,
      alpha: backgroundColorAlpha,
    });
    showBackgroundProps.backgroundPadding = new Cartesian2(
      backgroundPaddingX,
      backgroundPaddingY
    );
  }

  const labelEntityProps = {
    entityProps: { id, position: radianArrayToCartesian3(position), ...props },
    graphicProps: {
      fillColor: createCesiumColor({
        color,
        alpha,
      }),
      font: `${fontSize}px "Microsoft Yahei"`,
      ...showBackgroundProps,
      disableDepthTestDistance,
      ...extraProps,
    },
  };

  return <LabelEntity {...labelEntityProps}></LabelEntity>;
}
