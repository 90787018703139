import React from "react";
import { useRoutes } from "react-router-dom";

import { useReactiveVar } from "@apollo/client";

//local
import { userVar, useInitUserVar } from "./gql";
import { routes } from "./routes";

function App() {
  useReactiveVar(userVar);
  const { loading } = useInitUserVar();

  let app = useRoutes(routes);
  return loading ? null : app;
}

export default App;
