import React from "react";

import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import { dispatchGeometrySettingVar } from "../drawingToolVars";

export function ResetGeometrySettingButton({ geometryType, ...props }) {
  const handleClick = React.useCallback(() => {
    dispatchGeometrySettingVar({
      type: "SET_SINGLE_GEOMETRY_SETTING_DEFAULT",
      payload: geometryType,
    });
  }, [geometryType]);

  return (
    <Tooltip arrow title="重置默认属性设置" placement="bottom">
      <IconButton size="small" onClick={handleClick} color="default" {...props}>
        <RestartAltIcon fontSize="small"></RestartAltIcon>
      </IconButton>
    </Tooltip>
  );
}
