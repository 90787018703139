import React from "react";
import { useReactiveVar } from "@apollo/client";
import { Navigate, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { userIsAdmin } from "../../../accessControl";
import {
  userVar,
  useCreateJsonSchema,
  useUpdateJsonSchema,
} from "../../../gql";
import { CustomForm } from "../../../schemaForms";
import { JsonSchemaPageHeader, CreateFieldsForm, JsonView } from "./components";
import { createSchemaFromFields } from "../../../meta";

const switchsConfig = [
  { switchKey: "createFieldsForm", label: "配置字段" },
  { switchKey: "formExample", label: "表单样例" },
  {
    switchKey: "jsonView",
    label: "JSON数据展示",
  },
];

function GridItem({ children, ...props }) {
  return (
    <Grid
      item
      xs={true}
      minWidth={{
        xs: "100%",
        md: 450,
        lg: 500,
      }}
    >
      <Box p={2} {...props}>
        {children}
      </Box>
    </Grid>
  );
}

export function JsonSchemaCreatePage({ initFields = [], initName = "", id }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const user = useReactiveVar(userVar);
  let createFieldsForm;
  let nameInput;

  //mutation
  const [createJsonSchemaMutation] = useCreateJsonSchema({
    // refetchQueries: ["getJsonSchemas"],
  });
  const [updateJsonSchemaMutation] = useUpdateJsonSchema({
    // refetchQueries: ["getJsonSchemas"],
  });

  const [formExampleSchema, setFormExampleSchema] = React.useState({
    schema: {},
    uiSchema: {},
  });

  const [updated, setUpdated] = React.useState(false);
  const [fields, setFields] = React.useState(initFields);

  const handleFieldsChange = React.useCallback(
    (e) => {
      if (updated) {
        setUpdated(false);
      }
      setFields(e.formData);
    },
    [updated]
  );

  const handleFieldsFormSubmit = React.useCallback((e) => {
    // console.log("Submit", JSON.stringify(e.formData, null, 2));
    setFormExampleSchema(createSchemaFromFields(e.formData));
    setUpdated(true);
  }, []);

  // 开关switch控制
  const [checked, setChecked] = React.useState({
    createFieldsForm: true,
    formExample: true,
    jsonView: true,
  });
  const handleSwitchChange = React.useCallback((switchKey) => {
    return (event) => {
      setChecked((state) => ({
        ...state,
        [switchKey]: event.target.checked,
      }));
    };
  }, []);

  //同步初始schema和初始fields值
  React.useEffect(() => {
    if (initFields.length > 0) {
      setFormExampleSchema(createSchemaFromFields(initFields));
    }
  }, [initFields]);

  return userIsAdmin(user) ? (
    <>
      <JsonSchemaPageHeader title={id ? "编辑数据模式" : "创建数据模式"}>
        {/* 控制按钮 */}
        <FormGroup
          row
          sx={{
            my: 2,
          }}
        >
          {checked.createFieldsForm && (
            <Button
              size="small"
              variant="contained"
              disabled={updated}
              sx={{
                mr: 2,
              }}
              onClick={() => {
                if (createFieldsForm) {
                  createFieldsForm.submit();
                }
              }}
            >
              更新字段配置
            </Button>
          )}
          {/* <Button
            size="small"
            variant="contained"
            sx={{
              mr: 2,
            }}
            onClick={() => {
              if (createFieldsForm) {
                const res = createFieldsForm.validateForm();
                console.log(res);
              }
            }}
          >
            验证配置-开发测试用
          </Button> */}
          <Button
            size="small"
            variant="contained"
            disabled={!updated}
            sx={{
              mr: 2,
            }}
            onClick={async () => {
              if (!nameInput.value) {
                enqueueSnackbar("必须设置模式名称", {
                  variant: "error",
                  key: "create-jsonschema-error",
                });
              }

              const data = {
                ...formExampleSchema,
                fields,
                name: nameInput.value,
              };

              //update
              if (id) {
                try {
                  const res = await updateJsonSchemaMutation({
                    variables: {
                      where: { id: id },
                      data,
                    },
                  });
                  if (res.errors) {
                    enqueueSnackbar("更新失败errors", {
                      variant: "error",
                      key: "create-jsonschema-error",
                    });
                  } else {
                    enqueueSnackbar("成功更新", {
                      variant: "success",
                      key: "create-jsonschema-sucess",
                    });
                  }
                } catch (error) {
                  enqueueSnackbar("更新失败", {
                    variant: "error",
                    key: "create-jsonschema-error",
                  });
                }
              } else {
                //create
                try {
                  const res = await createJsonSchemaMutation({
                    variables: {
                      data,
                    },
                  });
                  if (res.errors) {
                    enqueueSnackbar("创建失败errors", {
                      variant: "error",
                      key: "create-jsonschema-error",
                    });
                  } else {
                    enqueueSnackbar("成功创建", {
                      variant: "success",
                      key: "create-jsonschema-sucess",
                    });
                    navigate("../");
                  }
                } catch (error) {
                  enqueueSnackbar("创建失败", {
                    variant: "error",
                    key: "create-jsonschema-error",
                  });
                }
              }
            }}
          >
            提交后台
          </Button>
          {/* ui渲染控制 */}
          {switchsConfig.map(({ switchKey, label }) => (
            <FormControlLabel
              key={switchKey}
              control={
                <Switch
                  checked={checked[switchKey]}
                  onChange={handleSwitchChange(switchKey)}
                />
              }
              label={label}
            ></FormControlLabel>
          ))}
        </FormGroup>
      </JsonSchemaPageHeader>

      {/* 配置页面内容 */}
      <Grid
        container
        spacing={2}
        sx={{
          px: 2,
        }}
      >
        {/* 配置字段表单 */}
        {checked.createFieldsForm && (
          <GridItem>
            <Typography
              variant="h5"
              color="primary"
              sx={{
                fontWeight: "bold",
                mb: 2,
              }}
              gutterBottom
            >
              配置模式
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontWeight: "bold",
              }}
              gutterBottom
              display="block"
            >
              模式名称
            </Typography>
            <TextField
              required
              defaultValue={initName}
              inputRef={(el) => {
                nameInput = el;
              }}
              label="模式名称"
              variant="standard"
              sx={{
                mb: 2,
              }}
            />
            <Typography
              variant="caption"
              sx={{
                fontWeight: "bold",
              }}
              gutterBottom
              display="block"
            >
              模式字段配置
            </Typography>
            <CreateFieldsForm
              ref={(form) => {
                createFieldsForm = form;
              }}
              onSubmit={handleFieldsFormSubmit}
              formData={fields}
              onChange={handleFieldsChange}
            >
              <Button type="submit">更新字段配置</Button>
            </CreateFieldsForm>
          </GridItem>
        )}

        {/* 配置此模式生成的表单渲染效果 */}
        {checked.formExample && (
          <GridItem>
            <Typography
              variant="h5"
              color="primary"
              sx={{
                fontWeight: "bold",
              }}
              gutterBottom
            >
              表单样例
            </Typography>
            <Typography variant="caption" gutterBottom>
              样例表单,仅供查看该数据模式定义的表单效果
            </Typography>
            <CustomForm
              showErrorList={true}
              {...formExampleSchema}
              onSubmit={(e) => {
                alert(
                  `提交数据如下：\n \n${JSON.stringify(e.formData, null, 2)}`
                );
                console.log("Submit", JSON.stringify(e.formData, null, 2));
              }}
            >
              <Button type="submit">提交</Button>
            </CustomForm>
          </GridItem>
        )}

        {/* 各类状态数据JSON展示 */}
        {checked.jsonView && (
          <GridItem>
            <JsonView fields={fields} {...formExampleSchema}></JsonView>
          </GridItem>
        )}
      </Grid>
    </>
  ) : (
    <Navigate to="/404" replace />
  );
}
