import React from "react";

import { Entity, PolylineGraphics } from "resium";
import { Color, ArcType, PolylineDashMaterialProperty } from "cesium";

const dashedRed = new PolylineDashMaterialProperty({
  color: Color.BLACK,
});

// 标记点位使用的Polyline,遮挡部分用虚线显示
export function DistanceLine({ positions }) {
  return (
    <Entity name="distanceLine">
      <PolylineGraphics
        arcType={ArcType.NONE}
        width={3}
        material={Color.BLACK}
        //遮挡处使用虚线效果
        depthFailMaterial={dashedRed}
        positions={positions}
      ></PolylineGraphics>
    </Entity>
  );
}
