import { gql, useQuery } from "@apollo/client";

//fragments
const WYSIWYG_FIELDS = gql`
  fragment WysiwygFields on Wysiwyg {
    id
    name
    title
    content {
      document(hydrateRelationships: true)
    }
  }
`;

//queries
const GET_WYSIWYG_BY_ID = gql`
  ${WYSIWYG_FIELDS}
  query getWysiwyg($where: WysiwygWhereUniqueInput!) {
    wysiwyg(where: $where) {
      ...WysiwygFields
    }
  }
`;

//queries
const GET_WYSIWYGS = gql`
  ${WYSIWYG_FIELDS}
  query getWysiwygs($where: WysiwygWhereInput! = {}) {
    wysiwygs(where: $where) {
      ...WysiwygFields
    }
  }
`;

//mutations

//hooks query
export function useWysiwyg(options) {
  return useQuery(GET_WYSIWYG_BY_ID, options);
}

export function useWysiwygs(options) {
  return useQuery(GET_WYSIWYGS, options);
}

//hooks mutation
