import {
  Cartesian3,
  Transforms,
  HeadingPitchRoll,
  Math,
  Quaternion,
  DebugModelMatrixPrimitive,
  PolylineDashMaterialProperty,
  Color,
  Matrix4,
} from "cesium";

export const assetLabelMap = {
  tiledModel: "实景模型",
  pointCloud: "三维点云",
  tiledImage: "正射影像",
  panorama: "全景图像",
  vtour: "全景漫游",
};

//根据position string创建坐标点
export function createPosition(position) {
  let cartesian3;
  try {
    const positionArray = position.split(",").map((num) => Number(num));
    if (
      positionArray.length !== 3 ||
      isNaN(positionArray[0]) ||
      isNaN(positionArray[1]) ||
      isNaN(positionArray[2])
    ) {
      throw Error("invalid position string");
    }
    if (
      positionArray[0] <= 180 &&
      positionArray[0] >= 0 &&
      positionArray[1] <= 180 &&
      positionArray[1] >= 0
    ) {
      //判断认为坐标是经纬度坐标
      cartesian3 = Cartesian3.fromDegrees(...positionArray);
    } else {
      //按ECEF坐标处理
      cartesian3 = new Cartesian3(...positionArray);
    }
  } catch (error) {
    console.log({ position });
    console.log(`position string:${position} invalid`, error);
  }
  return cartesian3;
}

//获取标签文本
export function getLabelTextByKey({ asset, key }) {
  if (key === "name") {
    return asset.name || asset.digitalAsset.name;
  }
  if (key === "type") {
    return assetLabelMap[asset.digitalAsset[key]];
  }

  if (key === "producedAt") {
    return asset.digitalAsset.producedAt.slice(0, 10);
  }
  return "no label";
}

// const data =
//   "116.29024418580127, 40.00755395352919,35.138652009753415,116.29024276774118, 40.00772773267273,35.27733245793823,116.29039979263709,40.00769763248339,35.08708648961059,116.2904509639134, 40.00756059358872,35.0180581258327";
export function getHierarchy(pointsData) {
  let hierarchy;

  try {
    const hierarchyArray = pointsData.split(",").map((num) => Number(num));
    hierarchy = Cartesian3.fromDegreesArrayHeights(hierarchyArray);
  } catch (error) {
    console.log(`error when resolve hierarchy from ${pointsData}`, error);
  }
  return hierarchy;
}

// 创建旋转四元数
export function createOrientation({
  position,
  heading = 0,
  pitch = 0,
  roll = 0,
}) {
  const h = Math.toRadians(heading);
  const p = Math.toRadians(pitch);
  const r = Math.toRadians(roll);
  const hpr = new HeadingPitchRoll(h, p, r);

  // const enu = Transforms.eastNorthUpToFixedFrame(position);

  const quaternion = Transforms.headingPitchRollQuaternion(position, hpr);

  return quaternion;
}
