import React from "react";

import Button from "@mui/material/Button";

import Divider from "@mui/material/Divider";

//icon
import CloseIcon from "@mui/icons-material/Close";

import { removeEvent } from "../../gql";

import { FixedPanel } from "./FixedPanel";

// 适用全局工具的Panel 自带关闭按钮
export function ToolSettingPanel({ children, toolName, ...props }) {
  return (
    <FixedPanel {...props}>
      {children}

      <Divider variant="fullWidth" />
      <Button
        color="error"
        variant="contained"
        onClick={() => {
          removeEvent(toolName);
        }}
        startIcon={<CloseIcon></CloseIcon>}
        fullWidth
        sx={{
          mt: 1,
        }}
      >
        关闭
      </Button>
    </FixedPanel>
  );
}
