import React from "react";
import { useReactiveVar } from "@apollo/client";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

//icons
import MemoryIcon from "@mui/icons-material/Memory";

import {
  cesium3DTilesetRenderConfigVar,
  setMaximumMemoryUsage,
} from "../../../../gql";

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value} arrow>
      {children}
    </Tooltip>
  );
}

export function Cesium3DTileMaxMemoryUsageSlider() {
  const { maximumMemoryUsage } = useReactiveVar(cesium3DTilesetRenderConfigVar);

  const handleSliderChange = (event, newValue) => {
    setMaximumMemoryUsage(newValue);
  };

  return (
    <>
      <Typography variant="subtitle2" color="primary" textAlign="left">
        最大内存缓存设置
      </Typography>
      <Stack
        spacing={1}
        direction="row"
        sx={{
          alignItems: "center",
          mb: 1,
        }}
      >
        <Tooltip title="用于瓦片缓存的最大内存限制" placement="top">
          <MemoryIcon sx={{ mr: 1 }}></MemoryIcon>
        </Tooltip>

        <Slider
          value={maximumMemoryUsage}
          onChange={handleSliderChange}
          aria-labelledby="maximumMemoryUsage-slider"
          min={128}
          step={128}
          max={2048}
          valueLabelDisplay="auto"
          components={{
            ValueLabel: ValueLabelComponent,
          }}
          valueLabelFormat={(value) => `${value} MB`}
        />
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            setMaximumMemoryUsage(
              Number(process.env.REACT_APP_3DTILE_MAX_MEMORY_USAGE)
            );
          }}
        >
          重置
        </Button>
      </Stack>
    </>
  );
}
