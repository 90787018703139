import React from "react";
import { useCesium } from "resium";

import { calcCameraViewToCartesian3 } from "./utils";
import { useSceneContext } from "../../hooks";

import { SetCameraView } from "./SetCameraView";

export function SetCameraViewFromScene({
  setSceneDefaultViewAsHome,
  goToSceneDefaultView,
}) {
  const {
    scene: { defaultCameraView },
  } = useSceneContext();
  // const { camera } = useCesium();

  //转化后端存储的相机参数，为Cartesian3对象
  // 避免view因为ref改变导致错误的re render行为
  const view = React.useMemo(
    () => calcCameraViewToCartesian3(defaultCameraView),
    [defaultCameraView]
  );
  return (
    <SetCameraView
      view={view}
      setAsHome={setSceneDefaultViewAsHome}
      goToView={goToSceneDefaultView}
    ></SetCameraView>
  );
}

export const MemoizedSetCameraViewFromScene = React.memo(
  SetCameraViewFromScene
);
