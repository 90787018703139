import React from "react";

import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";

//icons
import WallpaperIcon from "@mui/icons-material/Wallpaper";

//local
import { LocalPanel } from "../../panel";
import { ColorPanelContent } from "./panelElement";
{
  /* 临时功能,颜色仅在默认灰色与蓝天之间切换 */
}

export function BackgroundColorMenuItem(props) {
  //控制选色面板开关
  let [colorPanelOpen, setColorPanelOpen] = React.useState(false);

  return (
    <>
      <MenuItem
        onClick={() => {
          setColorPanelOpen(true);
          // if (backgroundColor === Color.GREY) {
          //   setBackgroundColor(Color.SKYBLUE);
          // } else {
          //   setBackgroundColor(Color.GREY);
          // }
        }}
      >
        <ListItemIcon>
          <WallpaperIcon />
        </ListItemIcon>
        背景切换
      </MenuItem>

      {/* 背景色选择面板 */}
      {colorPanelOpen && (
        <LocalPanel
          panelTitle="选择背景色"
          closeHandler={() => {
            setColorPanelOpen(false);
          }}
          draggableProps={{
            //初始transform参数
            positionOffset: {
              x: "-50%",
              y: "-50%",
            },
            // disabled: true,
          }}
          sx={{
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <ColorPanelContent
            setColorPanelOpen={setColorPanelOpen}
          ></ColorPanelContent>
        </LocalPanel>
      )}
    </>
  );
}
