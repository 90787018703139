import React from "react";
import { useReactiveVar } from "@apollo/client";

import Box from "@mui/material/Box";

//icons
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import { zoomVar, setZoomHeading, setzoomPitch } from "../../../../gql";
import { ZoomSettingSlider } from "./ZoomSettingSlider";
// import { Debug } from "../../../Debug";

const headingPresetMarks = [
  {
    label: "北",
    value: 0,
  },
  {
    label: "东",
    value: 90,
  },
  {
    label: "南",
    value: 180,
  },
  {
    label: "西",
    value: 270,
  },
  {
    label: "北",
    value: 360,
  },
];

const pitchPresetMarks = [
  {
    label: "俯",
    value: -90,
  },
  {
    label: "俯45",
    value: -45,
  },
  {
    label: "平",
    value: 0,
  },
  {
    label: "仰45",
    value: 45,
  },
  {
    label: "仰",
    value: 90,
  },
];

export function ZoomDirectionControl(props) {
  const { zoomPitch, zoomHeading } = useReactiveVar(zoomVar);

  return (
    <Box
      sx={{
        maxWidth: "90%",
        mx: 1,
      }}
    >
      <ZoomSettingSlider
        value={zoomHeading}
        setValue={setZoomHeading}
        max={360}
        min={0}
        presetMarks={headingPresetMarks}
        unit="°"
        defaultValue={0}
      ></ZoomSettingSlider>
      <ZoomSettingSlider
        value={zoomPitch}
        setValue={setzoomPitch}
        max={90}
        min={-90}
        presetMarks={pitchPresetMarks}
        unit="°"
        defaultValue={-45}
      ></ZoomSettingSlider>
    </Box>
  );
}
