import React from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
//icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BuildIcon from "@mui/icons-material/Build";

import { ToolMenu } from "./ToolMenu";
export function ToolButton(props) {
  //默认展开工具栏
  const [open, setOpen] = React.useState(true);

  const handleToggle = React.useCallback(() => {
    setOpen((state) => !state);
  }, [setOpen]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          minWidth: 120,
          bgcolor: "background.alphaToolPanel",
          borderRadius: 1,
          cursor: "move",
        }}
      >
        {/* 此Box用于拖动 */}
        <Box
          sx={{
            height: 8,
            borderBottom: 1,
            borderColor: "border.grey",
          }}
          id="draggable-toolmenu"
        ></Box>

        <Button
          fullWidth
          variant="string"
          color="primary"
          startIcon={<BuildIcon />}
          endIcon={<KeyboardArrowDownIcon />}
          onClick={handleToggle}
        >
          工具
        </Button>
      </Box>

      <Paper
        sx={{
          // position: "absolute",
          mt: 1,
          display: open ? "block" : "none",
          bgcolor: (theme) => theme.palette.background.alphaToolPanel,
        }}
      >
        <ToolMenu></ToolMenu>
      </Paper>
    </Box>
  );
}
