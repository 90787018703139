import React from "react";
import { useReactiveVar } from "@apollo/client";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

//icons
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";

import { viewerSettingVar, setViewerSetting } from "../../../../gql";

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value} arrow>
      {children}
    </Tooltip>
  );
}

export function PrecisionSlider() {
  const viewerSetting = useReactiveVar(viewerSettingVar);

  const handleSliderChange = (event, newValue) => {
    setViewerSetting({
      maximumScreenSpaceError: newValue,
    });
  };

  return (
    <>
      <Typography variant="subtitle2" color="primary" textAlign="left">
        渲染精度调整
      </Typography>
      <Stack
        spacing={1}
        direction="row"
        sx={{
          alignItems: "center",
          mb: 1,
        }}
      >
        <Tooltip title="数值越大渲染误差越大" placement="top">
          <DisplaySettingsIcon sx={{ mr: 1 }}></DisplaySettingsIcon>
        </Tooltip>

        <Slider
          value={viewerSetting.maximumScreenSpaceError}
          onChange={handleSliderChange}
          aria-labelledby="precision-slider"
          max={16}
          min={1}
          valueLabelDisplay="auto"
          components={{
            ValueLabel: ValueLabelComponent,
          }}
        />
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            setViewerSetting({
              maximumScreenSpaceError: Number(
                process.env.REACT_APP_SCREEN_SPACE_ERROR
              ),
            });
          }}
        >
          重置
        </Button>
      </Stack>
    </>
  );
}
