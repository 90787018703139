import React from "react";

import { DataGrid } from "@mui/x-data-grid";

import Box from "@mui/material/Box";

import { useCommentContext } from "../../../hooks";
import { columns } from "./columnDefination";
import { CustomToolbar } from "./CustomToolbar";

function getAutoRowHeight() {
  return "auto";
}
function getDefaultRowHeight() {
  return null;
}

export function CommentTable(props) {
  const [pageSize, setPageSize] = React.useState(20);
  const [autoRowHeight, setAutoRowHeight] = React.useState(false);
  const [expandHeight, setExpandHeight] = React.useState(false);

  const { comments } = useCommentContext();

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: expandHeight ? "90vh" : "38vh", //此高度需要和fabPosition的位置设置对应
        transition: (theme) => theme.transitions.create("height"),
        resize: "vertical",
      }}
    >
      <DataGrid
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        pagination
        // autoPageSize //自动计算分页尽量避免滚动条 不实用，暂时不选去此方案
        disableSelectionOnClick //禁止点击选择行
        sx={{
          zIndex: (theme) => theme.zIndex.infoPanel + 1,
          "& .MuiDataGrid-columnHeaders": {
            bgcolor: "background.alphaBlackLight",
            "& .MuiDataGrid-columnSeparator": {
              color: "primary.light",
            },
          },
          "& .MuiDataGrid-cell--withRenderer": {
            flexWrap: "wrap",
          },
          "& .comment-table-tag-cell": {
            gap: 0.5, //标签cell增加gap属性
          },
        }}
        componentsProps={{
          panel: {
            sx: {
              pb: 1,
              inset: "auto auto 0px 0px !important", //避免panel溢出
              transform: "unset !important", //避免panel溢出
            },
          },
        }}
        rows={comments}
        columns={columns}
        components={{
          Toolbar: () => (
            <CustomToolbar
              autoRowHeight={autoRowHeight}
              setAutoRowHeight={setAutoRowHeight}
              expandHeight={expandHeight}
              setExpandHeight={setExpandHeight}
            ></CustomToolbar>
          ),
        }}
        // getRowHeight={() => "auto"} //做一个控制按钮
        getRowHeight={autoRowHeight ? getAutoRowHeight : getDefaultRowHeight}
        density="standard"
      />

      {/* <JsonView json={comments}></JsonView> */}
    </Box>
  );
}
