import React from "react";
import { useReactiveVar } from "@apollo/client";
import { ScreenSpaceEvent, useCesium, Clock } from "resium";
import { ScreenSpaceEventType, Cartesian3, Transforms } from "cesium";

import { cameraModeVar, changeFpvMoveRate, setFpvMode } from "../../../gql";

// 前进后退上下 flag gamepad 键盘 通用此flag
const flags = {
  looking: false,
  moveForward: false,
  moveBackward: false,
  moveUp: false,
  moveDown: false,
  moveLeft: false,
  moveRight: false,
};

//gamepad 支持
const gamePadFlags = {
  lookingUp: false,
  lookingDwon: false,
  lookingLeft: false,
  lookingRight: false,
};
const gamePadLookingFactor = 0.25;
//gamepad 移动button映射
const gamePadMoveButtonArray = [
  { button: "up0", flagName: "moveForward" },
  { button: "down0", flagName: "moveBackward" },
  { button: "left0", flagName: "moveLeft" },
  { button: "right0", flagName: "moveRight" },
  { button: "button4", flagName: "moveDown" },
  { button: "button5", flagName: "moveUp" },
];
// gamepad 朝向button映射
const gamePadLookingButtonArray = [
  { button: "up1", flagName: "lookingUp" },
  { button: "down1", flagName: "lookingDwon" },
  { button: "left1", flagName: "lookingLeft" },
  { button: "right1", flagName: "lookingRight" },
];
// gamepad 按钮事件绑定
const gamePadButtonFunctionArray = [
  {
    button: "button6",
    event: () => {
      changeFpvMoveRate(-1);
    },
  },
  {
    button: "button7",
    event: () => {
      changeFpvMoveRate(1);
    },
  },
  {
    button: "button2",
    event: () => {
      setFpvMode("free");
    },
  },
  {
    button: "button1",
    event: () => {
      setFpvMode("ground");
    },
  },
];

// gamepad connect后执行
const gamePadInit = (gamepad) => {
  console.log("手柄连接成功");
  // 前进后退上下
  gamePadMoveButtonArray.forEach(({ button, flagName }) => {
    gamepad
      .before(button, () => {
        flags[flagName] = true;
      })
      .after(button, () => {
        flags[flagName] = false;
      });
  });

  // 移动视角朝向
  gamePadLookingButtonArray.forEach(({ button, flagName }) => {
    gamepad
      .before(button, () => {
        gamePadFlags[flagName] = true;
      })
      .after(button, () => {
        gamePadFlags[flagName] = false;
      });
  });

  //调整速率 切换模式 各类按钮事件绑定
  gamePadButtonFunctionArray.forEach(({ button, event }) => {
    gamepad.after(button, event);
  });
};

// 键盘操作的辅助参数
let startMousePosition;
let mousePosition;
const flagKeyMap = {
  w: "moveForward",
  s: "moveBackward",
  q: "moveUp",
  e: "moveDown",
  d: "moveRight",
  a: "moveLeft",
};

// 按键设置flag状态
function keyDownEvent(e) {
  const flagName = flagKeyMap[e.key];
  if (typeof flagName !== "undefined") {
    flags[flagName] = true;
  }
}
// 按键结束设置flag状态
function keyUpEvent(e) {
  const flagName = flagKeyMap[e.key];
  if (typeof flagName !== "undefined") {
    flags[flagName] = false;
  }
}

export function FpvEvent() {
  const {
    moveRate: moveRateRaw,
    fpvMode,
    lookFactor,
  } = useReactiveVar(cameraModeVar);
  const moveRate = 2 ** (moveRateRaw - 1) / 256;
  const { viewer } = useCesium();
  const scene = viewer.scene;
  const canvas = viewer.canvas;
  const camera = viewer.camera;

  const transformGD = Transforms.eastNorthUpToFixedFrame(camera.position);
  const up = React.useMemo(
    () => new Cartesian3(transformGD[8], transformGD[9], transformGD[10]),
    [transformGD]
  );
  const onTickCallBack = React.useCallback(() => {
    //鼠标操作方向
    if (flags.looking) {
      const width = canvas.clientWidth;
      const height = canvas.clientHeight;

      const x = (mousePosition.x - startMousePosition.x) / width;
      const y = -(mousePosition.y - startMousePosition.y) / height;

      // camera.lookRight(x * lookFactor);
      camera.look(up, x * lookFactor);
      camera.lookUp(y * lookFactor);
    }

    //gamepad 转头
    if (gamePadFlags.lookingUp) {
      camera.lookUp(gamePadLookingFactor * lookFactor);
    }
    if (gamePadFlags.lookingDwon) {
      camera.lookUp(-gamePadLookingFactor * lookFactor);
    }
    if (gamePadFlags.lookingRight) {
      camera.look(up, gamePadLookingFactor * lookFactor);
    }
    if (gamePadFlags.lookingLeft) {
      camera.look(up, -gamePadLookingFactor * lookFactor);
    }
    // 键盘移动 按照局部坐标的地平面进行移动
    if (fpvMode === "ground") {
      // 地面约束控制

      if (flags.moveForward) {
        const tempScale = Cartesian3.dot(up, camera.direction);
        const tempVect = Cartesian3.multiplyByScalar(
          up,
          tempScale,
          new Cartesian3()
        );
        let forwardDir = Cartesian3.subtract(
          camera.direction,
          tempVect,
          new Cartesian3()
        );
        //保证匀速
        Cartesian3.normalize(forwardDir, forwardDir);
        camera.move(forwardDir, moveRate);
      }
      if (flags.moveBackward) {
        const tempScale = Cartesian3.dot(up, camera.direction);
        const tempVect = Cartesian3.multiplyByScalar(
          up,
          tempScale,
          new Cartesian3()
        );
        let forwardDir = Cartesian3.subtract(
          camera.direction,
          tempVect,
          new Cartesian3()
        );

        let backwardDir = Cartesian3.negate(forwardDir, new Cartesian3());
        Cartesian3.normalize(backwardDir, backwardDir);

        camera.move(backwardDir, moveRate);
      }
      if (flags.moveUp) {
        camera.move(up, moveRate);

        Cartesian3.normalize(up, up);
      }
      if (flags.moveDown) {
        let downDir = Cartesian3.negate(up, new Cartesian3());
        camera.move(downDir, moveRate);
      }
      if (flags.moveLeft) {
        const tempScale = Cartesian3.dot(up, camera.direction);
        const tempVect = Cartesian3.multiplyByScalar(
          up,
          tempScale,
          new Cartesian3()
        );

        let forwardDir = Cartesian3.subtract(
          camera.direction,
          tempVect,
          new Cartesian3()
        );
        let leftDir = Cartesian3.cross(up, forwardDir, new Cartesian3());
        Cartesian3.normalize(leftDir, leftDir);

        camera.move(leftDir, moveRate);
      }
      if (flags.moveRight) {
        const tempScale = Cartesian3.dot(up, camera.direction);
        const tempVect = Cartesian3.multiplyByScalar(
          up,
          tempScale,
          new Cartesian3()
        );

        let forwardDir = Cartesian3.subtract(
          camera.direction,
          tempVect,
          new Cartesian3()
        );
        let rightDir = Cartesian3.cross(forwardDir, up, new Cartesian3());
        Cartesian3.normalize(rightDir, rightDir);

        camera.move(rightDir, moveRate);
      }
    }

    if (fpvMode === "free") {
      // 自由视角控制
      if (flags.moveForward) {
        camera.moveForward(moveRate);
      }
      if (flags.moveBackward) {
        camera.moveBackward(moveRate);
      }
      if (flags.moveUp) {
        camera.moveUp(moveRate);
      }
      if (flags.moveDown) {
        camera.moveDown(moveRate);
      }
      if (flags.moveLeft) {
        camera.moveLeft(moveRate);
      }
      if (flags.moveRight) {
        camera.moveRight(moveRate);
      }
    }
  }, [
    camera,
    canvas.clientHeight,
    canvas.clientWidth,
    fpvMode,
    lookFactor,
    moveRate,
    up,
  ]);

  // 关闭原有相机控制 添加按键事件
  React.useEffect(() => {
    scene.screenSpaceCameraController.enableRotate = false;
    scene.screenSpaceCameraController.enableTranslate = false;
    scene.screenSpaceCameraController.enableZoom = false;
    scene.screenSpaceCameraController.enableTilt = false;
    scene.screenSpaceCameraController.enableLook = false;

    document.addEventListener("keydown", keyDownEvent, false);
    document.addEventListener("keyup", keyUpEvent, false);

    //gamepad 控制事件绑定
    window.gameControl.on("connect", gamePadInit);

    return () => {
      // 恢复原有相机控制 取消按键事件
      scene.screenSpaceCameraController.enableRotate = true;
      scene.screenSpaceCameraController.enableTranslate = true;
      scene.screenSpaceCameraController.enableZoom = true;
      scene.screenSpaceCameraController.enableTilt = true;
      scene.screenSpaceCameraController.enableLook = true;
      viewer.clock.onTick.removeEventListener(onTickCallBack);
      document.removeEventListener("keydown", keyDownEvent, false);
      document.removeEventListener("keyup", keyUpEvent, false);
    };
  }, [scene, viewer.clock.onTick, onTickCallBack]);
  return (
    <>
      {/* 鼠标点击后拖动事件 */}
      <ScreenSpaceEvent
        action={(movement) => {
          flags.looking = true;
          mousePosition = startMousePosition = Cartesian3.clone(
            movement.position
          );
        }}
        type={ScreenSpaceEventType.LEFT_DOWN}
      ></ScreenSpaceEvent>
      <ScreenSpaceEvent
        action={(movement) => {
          mousePosition = movement.endPosition;
        }}
        type={ScreenSpaceEventType.MOUSE_MOVE}
      ></ScreenSpaceEvent>

      <ScreenSpaceEvent
        action={(movement) => {
          flags.looking = false;
        }}
        type={ScreenSpaceEventType.LEFT_UP}
      ></ScreenSpaceEvent>

      <Clock onTick={onTickCallBack}></Clock>

      <ScreenSpaceEvent
        action={(delta) => {
          const value = delta / 100 > 0 ? 1 : -1;
          changeFpvMoveRate(value);
        }}
        type={ScreenSpaceEventType.WHEEL}
      ></ScreenSpaceEvent>
    </>
  );
}
