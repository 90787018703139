import React from "react";
import Draggable from "react-draggable";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Portal from "@mui/base/Portal";

export function FixedPanel({
  children,
  panelTitle,
  sx,
  draggableProps = {},
  ...props
}) {
  return (
    <Portal>
      <Draggable handle="#draggable-fix-panel" {...draggableProps}>
        <Box
          sx={{
            position: "fixed",
            zIndex: "toolPanel",
            top: 0,
            left: 0,
            width: {
              xs: "100%",
              phone: 350,
            },
            textAlign: "center",
            ...sx,
          }}
          {...props}
        >
          <Paper
            sx={{
              p: 1.5,
              m: 2,
              backgroundColor: "background.alphaPaper",
            }}
            elevation={8}
          >
            <Box
              id="draggable-fix-panel"
              sx={{
                cursor: "move",
              }}
            >
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                }}
              >
                {panelTitle}
              </Typography>
            </Box>

            {children}
          </Paper>
        </Box>
      </Draggable>
    </Portal>
  );
}
