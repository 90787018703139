import React from "react";
import { useReactiveVar } from "@apollo/client";

import {
  drawingStatusVar,
  dispatchdrawingStatusVar,
} from "../../drawingToolVars";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

export function DrawingTypeButton({ icon, tooltipProps, buttonType }) {
  const { drawingType } = useReactiveVar(drawingStatusVar);

  const setDrawingType = React.useCallback((type) => {
    return () => {
      dispatchdrawingStatusVar({
        type: "SET_ADD_TOGGLE_DRAWING_TYPE",
        payload: type,
      });
    };
  }, []);

  return (
    <Tooltip arrow {...tooltipProps}>
      <IconButton
        size="small"
        onClick={setDrawingType(buttonType)}
        color={drawingType === buttonType ? "primary" : "default"}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
}
