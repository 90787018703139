//工具状态控制全局变量
import { makeVar } from "@apollo/client";
import { dispatchDrawingToolVar } from "./drawingToolVar";
const defaultValue = {
  active: false,
  drawingType: null,
};

export const drawingStatusVar = makeVar(defaultValue);

export function dispatchdrawingStatusVar(action) {
  switch (action.type) {
    //变化工具开启或关闭状态
    case "TOGGLE_DRAWING_TOOL_ACTIVE":
      drawingStatusVar({
        ...drawingStatusVar(),
        active: !drawingStatusVar().active,
      });
      return;
    case "SET_DRAWING_TOOL_ACTIVE":
      drawingStatusVar({
        ...drawingStatusVar(),
        active: true,
      });
      return;

    //开启特定几何类型绘制，仅切换工具类型，保留绘制数据，可能导致绘制数据与当前工具类型不一致，需调用者自行载入正确的数据类型
    case "SET_DRAWING_TYPE":
      drawingStatusVar({
        ...drawingStatusVar(),
        drawingType: action.payload,
      });
      return;

    //开启特定几何类型绘制，如已经开启则关闭，并清除当前绘制数据
    case "SET_ADD_TOGGLE_DRAWING_TYPE":
      drawingStatusVar({
        ...drawingStatusVar(),
        //如果当前已经使用某工具,则取消使用,否则更新工具类型
        drawingType:
          action.payload === drawingStatusVar().drawingType
            ? null
            : action.payload,
      });

      //清除当前绘制数据
      dispatchDrawingToolVar({
        type: "RESET_EDIT_DATA",
      });
      return;

    //关闭所有绘制工具选择,并清除当前绘制数据
    case "RESET_DRAWING_TYPE":
      drawingStatusVar({
        ...drawingStatusVar(),
        drawingType: null,
      });
      //清除当前绘制数据
      dispatchDrawingToolVar({
        type: "RESET_EDIT_DATA",
      });
      return;

    //工具恢复默认关闭，未选择绘画类型的状态，并清除当前绘制数据
    case "SET_TO_DEFAULT":
      drawingStatusVar({
        active: false,
        drawingType: null,
      });
      //清除当前绘制数据
      dispatchDrawingToolVar({
        type: "RESET_EDIT_DATA",
      });
      return;

    default:
      throw new Error("action type incorrect");
  }
}
