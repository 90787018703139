import React from "react";
import SwipeableViews from "react-swipeable-views";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { useSourceObjectRenderMapContext } from "../../../hooks";

function MetaButton({
  metaId,
  metaName,
  metaRenderCloseAllMap,
  sourceObjects,
  metasRenderMap,
  setMetasRenderMap,
  toggleMetaRender,
}) {
  const visible = !!metasRenderMap[metaId];

  //计算对象数量
  const count = React.useMemo(
    () =>
      sourceObjects.filter((sourceObject) => sourceObject.meta.id === metaId)
        .length,
    [sourceObjects, metaId]
  );

  return (
    <Badge
      badgeContent={count}
      color="primary"
      overlap="circular"
      sx={{
        "& .MuiBadge-badge": {
          backgroundColor: visible ? "primary.main" : "grey.600",
        },
      }}
    >
      <Button
        startIcon={
          visible ? (
            <VisibilityIcon color="primary" />
          ) : (
            <VisibilityOffIcon color="disabled" />
          )
        }
        onClick={() => toggleMetaRender(metaId)}
        onDoubleClick={() =>
          setMetasRenderMap({
            ...metaRenderCloseAllMap,
            [metaId]: true,
          })
        }
        sx={{
          minWidth: 90,
          color: visible ? "primary.main" : "grey.600",
        }}
      >
        {metaName}
      </Button>
    </Badge>
  );
}
export function MetaHeader({ sourceObjects, metasMap, ...props }) {
  const { metasRenderMap, setMetasRenderMap, toggleMetaRender } =
    useSourceObjectRenderMapContext();

  const metaRenderCloseAllMap = React.useMemo(() => {
    const map = {};

    Object.keys(metasMap).forEach((metaId) => {
      map[metaId] = false;
    });
    return map;
  }, [metasMap]);

  //表单filter根据metasRenderMap进行同步, 但是这个功能暂时不需要,
  // React.useEffect(() => {
  //   const metasNameRenderedArray = Object.keys(metasRenderMap)
  //     .filter((metaId) => metasRenderMap[metaId] === true)
  //     .map((metaId) => metasMap[metaId].name);

  //   console.log(metasNameRenderedArray);

  //   setFilterModel({
  //     items: [
  //       {
  //         columnField: "meta",
  //         operatorValue: "isAnyOf",
  //         value: metasNameRenderedArray,
  //       },
  //     ],
  //   });
  // }, [metasRenderMap, metasMap, setFilterModel]);

  return (
    <SwipeableViews>
      <Box
        sx={{
          display: "flex",
          pt: 1.5,
        }}
      >
        {Object.values(metasMap).map((meta) => {
          return (
            <MetaButton
              metaName={meta.name}
              metaId={meta.id}
              key={meta.id}
              metaRenderCloseAllMap={metaRenderCloseAllMap}
              sourceObjects={sourceObjects}
              metasRenderMap={metasRenderMap}
              setMetasRenderMap={setMetasRenderMap}
              toggleMetaRender={toggleMetaRender}
            />
          );
        })}
      </Box>
    </SwipeableViews>
  );
}
// export function MetaHeader({ sourceObjects, metasMap, ...props }) {
//   const sourceObjectRenderMap = useSourceObjectRenderMapContext();

//   const [value, setValue] = React.useState(Object.values(metasMap)[0].id);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <Tabs
//       value={value}
//       onChange={handleChange}
//       variant="scrollable"
//       scrollButtons="auto"
//       aria-label="scrollable auto tabs example"
//     >
//       {Object.values(metasMap).map((meta) => {
//         return <Tab label={meta.name} value={meta.id} key={meta.id} />;
//       })}
//     </Tabs>
//   );
// }
