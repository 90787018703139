import { Cartesian3 } from "cesium";

export function calcCameraViewToCartesian3(cameraView) {
  const { position, direction, up } = cameraView;

  return {
    position: new Cartesian3(position.x, position.y, position.z),
    direction: new Cartesian3(direction.x, direction.y, direction.z),
    up: new Cartesian3(up.x, up.y, up.z),
  };
}
