import React from "react";

import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";

//icons
import AspectRatioIcon from "@mui/icons-material/AspectRatio";

//local
import { useSetHomeView, useCopyCameraViewJson } from "../../../hooks";

export function SetAsHomeViewMenuItem(props) {
  //按钮点击后复制视角矩形json参数到剪贴板
  useCopyCameraViewJson("#copy-view-rectangle");

  const setHomeView = useSetHomeView();

  return (
    <MenuItem id="copy-view-rectangle" onClick={setHomeView}>
      <ListItemIcon>
        <AspectRatioIcon />
      </ListItemIcon>
      设为初始
    </MenuItem>
  );
}
