import { gql, useQuery } from "@apollo/client";

//fragments
const POINT_CLOUD_FIELDS = gql`
  fragment PointCloudFields on PointCloud {
    id
    name
    url
  }
`;

//queries
const GET_POINT_CLOUD_BY_ID = gql`
  ${POINT_CLOUD_FIELDS}
  query getPointCloud($where: PointCloudWhereUniqueInput!) {
    pointCloud(where: $where) {
      ...PointCloudFields
    }
  }
`;

//queries
const GET_POINT_CLOUDS = gql`
  ${POINT_CLOUD_FIELDS}
  query getPointClouds($where: PointCloudWhereInput! = {}) {
    pointClouds(where: $where) {
      ...PointCloudFields
    }
  }
`;

//mutations

//hooks query
export function usePointCloud(options) {
  return useQuery(GET_POINT_CLOUD_BY_ID, options);
}

export function usePointClouds(options) {
  return useQuery(GET_POINT_CLOUDS, options);
}
