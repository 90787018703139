import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import { variants, THEMES } from "./variants";
import { typography } from "./typography";
import { breakpoints } from "./breakpoints";
import { components } from "./components";
import { zIndex } from "./zIndex";
import { zhCN as coreZhCN } from "@mui/material/locale";
import { zhCN as gridZhCN } from "@mui/x-data-grid";

export { THEMES };

export const createTheme = (name) => {
  let themeConfig = variants.find((variant) => variant.name === name);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    themeConfig = variants[0];
  }

  const theme = createMuiTheme(
    {
      // spacing: 4,
      breakpoints: breakpoints,
      components: components,
      typography: typography,
      zIndex: zIndex,
      // shadows: shadows,
      palette: themeConfig.palette,
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
    },
    coreZhCN,
    gridZhCN
  );

  return responsiveFontSizes(theme);
};
