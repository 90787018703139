import React from "react";
import { useSnackbar } from "notistack";

import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useDeleteSourceObject } from "../../../../gql";

//删除源对象用的右键菜单项，会先弹出确认dialog，避免误删
export function DeleteSourceObjectMenuItem({
  sourceObject,
  handleContextMenuClose,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteSourceObject] = useDeleteSourceObject({
    refetchQueries: ["SourceObjects"],
  });

  //点击删除按钮，用于弹出确认删除对话框
  //Alert 相关状态
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //删除源对象
  const handleSourceObjectDelete = React.useCallback(async () => {
    try {
      const res = await deleteSourceObject({
        variables: {
          where: {
            id: sourceObject.id,
          },
        },
      });

      if (res.errors) {
        enqueueSnackbar("删除源对象失败", {
          variant: "error",
          key: "delete-sourceObject-error",
        });
      } else {
        enqueueSnackbar("删除源对象成功", {
          variant: "success",
          key: "delete-sourceObject-sucess",
        });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("删除源对象失败", {
        variant: "error",
        key: "delete-sourceObject-error",
      });
    }

    handleContextMenuClose();
  }, [
    sourceObject.id,
    handleContextMenuClose,
    deleteSourceObject,
    enqueueSnackbar,
  ]);

  return (
    <>
      <MenuItem onClick={handleClickOpen}>删除对象</MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-source-object-delete-dialog-title"
        aria-describedby="alert-source-object-delete-dialog-description"
      >
        <DialogTitle id="alert-source-object-delete-dialog-title">
          删除对象？
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-source-object-delete-dialog-description">
            是否确定删除此对象,删除后不可恢复,请谨慎操作。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>取消</Button>
          <Button onClick={handleSourceObjectDelete} autoFocus>
            删除
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
