import React from "react";
import { useReactiveVar } from "@apollo/client";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { drawingToolDataVar } from "../drawingToolVars";
import { ClearDataButton } from "./ClearDataButton";

import { LayerList } from "./LayerList";
import { calculateGeometryProps } from "../utils";

export function DataLayer() {
  const { data } = useReactiveVar(drawingToolDataVar);

  return (
    data.length > 0 && (
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            userSelect: "none",
            mb: 1,
          }}
        >
          <Typography
            variant="caption"
            align="left"
            onClick={() => {
              console.log("----------当前绘制内容几何信息列表-----------");
              console.log(JSON.stringify(data, null, 2));
            }}
            onContextMenu={(e) => {
              e.preventDefault();

              console.log(
                "----------当前绘制内容几何信息的属性设置表-----------"
              );
              console.log(
                JSON.stringify(calculateGeometryProps(data), null, 2)
              );
            }}
          >
            已绘制对象数量: {data.length}
          </Typography>
          <ClearDataButton></ClearDataButton>
        </Box>
        <LayerList data={data}></LayerList>
      </>
    )
  );
}
