import React from "react";
import { useParams, Navigate } from "react-router-dom";

import Box from "@mui/material/Box";

import { BaiduMapFromId } from "../../map";

export function MapPage() {
  const { id } = useParams();

  return (
    <BaiduMapFromId mapId={id} width="100%" height="100vh"></BaiduMapFromId>
  );
}
