import React from "react";

import MenuItem from "@mui/material/MenuItem";

import {
  dispatchdrawingStatusVar,
  dispatchDrawingToolVar,
} from "../../../../components";

//删除源对象用的右键菜单项，会先弹出确认dialog，避免误删
export function EditSourceObjectMenuItem({
  sourceObject,
  handleContextMenuClose,
  setOpenEditSourceObjectPanel,
}) {
  console.log(sourceObject.geometry);
  //编辑源对象
  const handleSourceObjectEdit = React.useCallback(async () => {
    handleContextMenuClose();
    setOpenEditSourceObjectPanel(true);
    dispatchdrawingStatusVar({
      type: "TOGGLE_DRAWING_TOOL_ACTIVE",
    });
    dispatchDrawingToolVar({
      type: "LOAD_GEOMETRY_INTO_SAVED_DATA",
      payload: sourceObject.geometry,
    });
  }, [
    sourceObject.geometry,
    handleContextMenuClose,
    setOpenEditSourceObjectPanel,
  ]);

  return (
    <>
      <MenuItem onClick={handleSourceObjectEdit}>编辑对象</MenuItem>
    </>
  );
}
