import { makeVar } from "@apollo/client";

const defaultValue = {
  showHotspot: true,
};

//控制数字场景里热点显示的全局变量
export const dsHotspotVar = makeVar(defaultValue);

//控制开关热点
export function setShowDsHotspot(show) {
  if (dsHotspotVar().showHotspot !== show) {
    dsHotspotVar({
      ...dsHotspotVar(),
      showHotspot: show,
    });
  }
}
