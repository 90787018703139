import { Navigate } from "react-router-dom";
import {
  DefaultPage,
  IndexPage,
  BimContentTest,
  WysiwygTest,
  DsHotspotTest,
  DsCollectionTest,
  FrontAppConfigPage,
  SourceObjectTestPage,
  RjsfFormTest,
} from "./testonly";

import {
  Page404,
  SignInPage,
  TiledModelViewer,
  PointCloudViewer,
  TiledImageViewerRedirect,
  TiledImageViewerIframe,
  PanoramaViewerRedirect,
  PanoramaViewerIframe,
  VtourViewerIframe,
  VtourViewerRedirect,
  DigitalScenePage,
  DsCollectionPage,
  DrawingPage,
  //config pages
  ConfigIndexPage,
  VersionPage,
  CommentTagTreeConfigPage,
  JsonSchemaUpdatePage,
  JsonSchemaCreatePage,
  JsonSchemaListPage,
  MapPage,
  MapConvertorPage,
} from "./pages";
import { Helmet } from "react-helmet";

//开发环境额外支持的路由
let devOnlyRoutes = [];
if (process.env.NODE_ENV === "development") {
  devOnlyRoutes = [
    {
      path: "/bim",
      children: [
        {
          path: ":id",
          element: <BimContentTest />,
        },
      ],
    },
    {
      path: "/wysiwyg",
      children: [
        {
          path: ":id",
          element: <WysiwygTest />,
        },
      ],
    },
    {
      path: "/sourceobject",
      element: <SourceObjectTestPage />,
    },

    {
      path: "/dscollection",
      children: [
        {
          path: ":id",
          element: <DsCollectionTest />,
        },
      ],
    },
    {
      path: "/dshotspotstest",
      element: <DsHotspotTest />,
    },
    {
      path: "/frontconfig",
      element: <FrontAppConfigPage />,
    },

    {
      path: "/rjsfTest",
      element: <RjsfFormTest />,
    },

    {
      index: true,
      element: <IndexPage />,
    },
  ];
}

export const routes = [
  {
    path: "/ds",
    children: [
      {
        path: ":id",
        element: <DigitalScenePage />,
      },
    ],
  },
  {
    path: "/ds-collection",
    children: [
      {
        path: ":id",
        element: <DsCollectionPage />,
      },
    ],
  },
  {
    path: "/drawing",
    children: [
      {
        path: ":id",
        element: <DrawingPage />,
      },
    ],
  },
  {
    path: "/viewer",
    children: [
      {
        path: "model",
        children: [
          {
            index: true,
            element: <Navigate to="/404" replace />,
          },
          {
            path: ":id",
            element: <TiledModelViewer />,
          },
        ],
      },
      {
        path: "pointcloud",
        children: [
          {
            index: true,
            element: <Navigate to="/404" replace />,
          },
          {
            path: ":id",
            element: <PointCloudViewer />,
          },
        ],
      },
      {
        path: "tiledimage",
        children: [
          {
            index: true,
            element: <Navigate to="/404" replace />,
          },
          {
            path: ":id",
            element: <TiledImageViewerIframe target="_blank" />,
          },
        ],
      },
      {
        path: "vtour",
        children: [
          {
            index: true,
            element: <Navigate to="/404" replace />,
          },
          {
            path: ":id",
            element: <VtourViewerIframe />,
          },
        ],
      },
      {
        path: "panorama",
        children: [
          {
            index: true,
            element: <Navigate to="/404" replace />,
          },

          {
            path: ":id",
            element: <PanoramaViewerIframe />,
          },
        ],
      },
    ],
  },

  {
    path: "/auth",
    children: [
      {
        index: true,
        element: <Navigate to="/404" replace />,
      },

      {
        path: "sign-in",
        element: (
          <>
            <Helmet>
              <title>登录</title>
            </Helmet>
            <SignInPage />
          </>
        ),
      },
    ],
  },

  //各类配置页面
  {
    path: "/config",
    children: [
      {
        index: true,
        element: (
          <>
            <Helmet>
              <title>网站配置</title>
            </Helmet>
            <ConfigIndexPage />
          </>
        ),
      },
      {
        path: "comment-tag-tree",
        element: (
          <>
            <Helmet>
              <title>评论标签树配置</title>
            </Helmet>
            <CommentTagTreeConfigPage />
          </>
        ),
      },
      {
        path: "schema",

        children: [
          {
            index: true,
            element: <JsonSchemaListPage />,
          },
          {
            path: "create",
            element: <JsonSchemaCreatePage />,
          },
          {
            path: ":id",
            element: <JsonSchemaUpdatePage />,
          },
        ],
      },
    ],
  },

  //版本信息页面
  {
    path: "/version",
    element: <VersionPage />,
  },

  {
    path: "/map",
    children: [
      {
        index: true,
        element: <MapPage />,
      },
      {
        path: "convertor",
        element: <MapConvertorPage />,
      },
      {
        path: ":id",
        element: <MapPage />,
      },
    ],
  },

  //test only
  ...devOnlyRoutes,
  //test only finish

  {
    path: "/404",
    element: (
      <>
        <Helmet>
          <title>404</title>
        </Helmet>
        <Page404 />
      </>
    ),
  },
  {
    path: "*",
    element: <Navigate to="/404" replace />,
  },
];
