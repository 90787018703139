import { gql, useQuery } from "@apollo/client";

//fragments
const PANORAMA_FIELDS = gql`
  fragment PanoramaFields on Panorama {
    id
    name
    viewerUrl
  }
`;

//queries
const GET_PANORAMA_BY_ID = gql`
  ${PANORAMA_FIELDS}
  query Panorama($where: PanoramaWhereUniqueInput!) {
    panorama(where: $where) {
      ...PanoramaFields
    }
  }
`;

//queries
const GET_PANORAMAS = gql`
  ${PANORAMA_FIELDS}
  query Panoramas($where: PanoramaWhereInput! = {}) {
    panoramas(where: $where) {
      ...PanoramaFields
    }
  }
`;

//mutations

//hooks query
export function usePanorama(options) {
  return useQuery(GET_PANORAMA_BY_ID, options);
}

export function usePanoramas(options) {
  return useQuery(GET_PANORAMAS, options);
}

//hooks mutation
