import React from "react";
import Box from "@mui/material/Box";

//local
import {
  HomeIconButton,
  VRIconButton,
  FullscreenIconButton,
  CameraModeIconButton,
} from "../../../components";

export function BasicButtons({
  homeButton,
  cameraModeButton,
  vrButton,
  fullScreenButton,
}) {
  return (
    <>
      {/* 初始视角按钮 */}
      {homeButton && <HomeIconButton></HomeIconButton>}

      {/* 相机操作模式切换 小屏幕不启用*/}
      {cameraModeButton && (
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "block",
            },
          }}
        >
          <CameraModeIconButton></CameraModeIconButton>
        </Box>
      )}

      {/* VR模式按钮 */}
      {vrButton && <VRIconButton></VRIconButton>}

      {/* 全屏按钮 */}
      {fullScreenButton && <FullscreenIconButton></FullscreenIconButton>}
    </>
  );
}
