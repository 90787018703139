import {
  Cartesian3,
  Cartographic,
  Transforms,
  HeadingPitchRoll,
  Math,
} from "cesium";

//将cartesian3 转化为 弧度经纬度高程坐标的array格式
export function cartesian3ToRadianArray(cartesian3) {
  const cartographic = Cartographic.fromCartesian(cartesian3);
  return [cartographic.longitude, cartographic.latitude, cartographic.height];
}

// 弧度经纬度高程坐标的array转化为Cartesian3
export function radianArrayToCartesian3(radianArray) {
  return Cartesian3.fromRadians(...radianArray);
}

//positions 数组转化未Cartesian3数组
export function radianArraysToCartesian3Array(radianArrays) {
  return radianArrays.map((radianArray) =>
    Cartesian3.fromRadians(...radianArray)
  );
}

// 创建旋转四元数
export function createOrientation({
  position,
  heading = 0,
  pitch = 0,
  roll = 0,
}) {
  const h = Math.toRadians(heading);
  const p = Math.toRadians(pitch);
  const r = Math.toRadians(roll);
  const hpr = new HeadingPitchRoll(h, p, r);

  // const enu = Transforms.eastNorthUpToFixedFrame(position);

  const quaternion = Transforms.headingPitchRollQuaternion(position, hpr);

  return quaternion;
}
