export const zIndex = {
  tooltip: 1600,
  gallery: 1490, //画廊大图查看
  modal: 1489, //评论列表各类弹出框
  drawingBar: 1480, //绘图板 绘图板设置时会用到modal选择框,故低于modal
  contextMenu: 1400,
  wysiwygDialog: 1380, //dshotspot 数字场景热点弹窗的zIndex
  commentDialog: 1380, //comment弹窗的zIndex
  sourceObjectContent: 1370, //源对象内容渲染卡片
  mapItemContent: 1370, //地图对象内容渲染卡片
  previewIframe: 1360, //预览Iframe
  bottomMenuBar: 1355, //底部菜单
  toolPanel: 1350, //测量工具等工具
  createSourceObjectPanel: 1346, //创建源对象的面板
  createCommentPanel: 1345, //创建评论的面板
  commentTablePanel: 1342, //评论列表面板
  speedDial: 1340, //Fab按钮
  toolBarButton: 1341, //工具面板
  infoPanel: 1340, //dsPanel
  menuBar: 1330,
  compass: 900,
  logo: 800,
};
