import { makeVar } from "@apollo/client";

const defaultPosition = {
  position: {
    bottom: {
      xs: 96,
      md: 32,
    },
    right: 16,
  },
};

const defaultValue = {
  ...defaultPosition,
  hidden: false,
};

//控制Fab位置
export const fabVar = makeVar(defaultValue);

export function setFabToDefault() {
  fabVar(defaultValue);
}

//控制fab位置
export function setFabPosition(position) {
  if (fabVar().position !== position) {
    fabVar({
      ...fabVar(),
      position,
    });
  }
}

//控制fab显示
export function setFabHidden(hidden) {
  if (fabVar().hidden !== hidden) {
    fabVar({
      ...fabVar(),
      hidden,
    });
  }
}
