import React from "react";
import { useReactiveVar } from "@apollo/client";

import { ScreenSpaceEventHandler } from "resium";

import { screenEventsVar, cameraModeVar, pickPositionVar } from "../../gql";

//events
import {
  PositionToolEvent,
  DistanceToolEvent,
  FpvEvent,
  PickPositionEvent,
} from "./toolEvent";
import { MemoizedEntityHoverEvent } from "./EntityHoverEvent";

export function ScreenEvents({ children, enableHoverEvent = false }) {
  const screenEvents = useReactiveVar(screenEventsVar);
  const { cameraMode } = useReactiveVar(cameraModeVar);
  const { pickPositionEvent } = useReactiveVar(pickPositionVar);

  return (
    <ScreenSpaceEventHandler>
      {screenEvents.positionTool && <PositionToolEvent></PositionToolEvent>}
      {screenEvents.distanceTool && <DistanceToolEvent></DistanceToolEvent>}
      {cameraMode === "fpv" ? (
        <FpvEvent></FpvEvent>
      ) : (
        enableHoverEvent && (
          <MemoizedEntityHoverEvent></MemoizedEntityHoverEvent> //fpv模式下hover事件会冲突
        )
      )}
      {!screenEvents.positionTool &&
        !screenEvents.distanceTool &&
        pickPositionEvent && <PickPositionEvent></PickPositionEvent>}
      {children}
    </ScreenSpaceEventHandler>
  );
}
