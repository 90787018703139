export function userIsAdmin(user) {
  return user && user.role === "admin";
}

export function userIsEditor(user) {
  return user && user.role === "editor";
}

export function userIsProjectUser(user) {
  return user && user.role === "projectuser";
}

export function userIsLoggedIn(user) {
  return !!user;
}

export function userIsAdminOrEditor(user) {
  return userIsAdmin(user) || userIsEditor(user);
}

export function userIsProjectUserOrHigher(user) {
  return userIsAdmin(user) || userIsEditor(user) || userIsProjectUser(user);
}

export function userIdEquals(user, id) {
  return user && user.id === id;
}
