import React from "react";

import { ScreenSpaceEvent, useCesium } from "resium";
import { ScreenSpaceEventType, defined } from "cesium";

import { setHoverEntityId } from "../../gql";

export function EntityHoverEvent() {
  const { viewer } = useCesium();

  return (
    <ScreenSpaceEvent
      action={(movement) => {
        const pickedObject = viewer.scene.pick(movement.endPosition);
        try {
          if (defined(pickedObject) && pickedObject.id) {
            // 不判断直接设置id
            setHoverEntityId(pickedObject.id._id);
          } else {
            setHoverEntityId(null);
          }
        } catch (e) {
          console.log(e);
        }
      }}
      type={ScreenSpaceEventType.MOUSE_MOVE}
    ></ScreenSpaceEvent>
  );
}

export const MemoizedEntityHoverEvent = React.memo(EntityHoverEvent);
