import React from "react";
import { useReactiveVar } from "@apollo/client";

import {
  DrawingLayerAccordion,
  DrawingToolContainer,
  DrawingButtonAccordion,
} from "./drawingUiComponents";

import { drawingStatusVar } from "./drawingToolVars";
import { DrawingScreenEvents } from "./drawingScreenEvents";
import {
  DrawingDataRender,
  EditingDataRender,
  DrawingDataLabelRender,
} from "./renderer";

import { DrawingSettingSavePanel } from "./drawingSettingForm";
import { DataLayer } from "./drawingLayer";
import { FlickerHacking } from "./FlickerHacking";
//dev only
import { DrawingToolDebug } from "./DrawingToolDebug";

import { HtmlOverlayContainer } from "../containers";
import { radianArrayToCartesian3 } from "./utils";

export function DrawingTool() {
  const { active } = useReactiveVar(drawingStatusVar);

  return (
    <>
      {active && (
        <>
          {/* 绘图操作面板UI */}
          <DrawingToolContainer>
            {/* 各类控制按钮UI */}
            <DrawingButtonAccordion></DrawingButtonAccordion>

            {/* 图层面板UI */}
            <DrawingLayerAccordion>
              <DataLayer></DataLayer>
            </DrawingLayerAccordion>
          </DrawingToolContainer>

          {/* 3D界面事件 */}
          <DrawingScreenEvents></DrawingScreenEvents>

          {/* 存储后的绘制几何渲染器 */}
          <DrawingDataRender></DrawingDataRender>

          {/* 几何的标签渲染器 */}
          {/* <DrawingDataLabelRender></DrawingDataLabelRender> */}

          {/* 编辑状态的几何渲染器 */}
          <EditingDataRender></EditingDataRender>

          {/* 根据当前绘图工具种类自由弹出的几何属性设置表单 */}
          <DrawingSettingSavePanel></DrawingSettingSavePanel>

          {/* 防几何抖动Hack */}
          <FlickerHacking></FlickerHacking>
        </>
      )}

      {/* dev only */}
      {/* <DrawingToolDebug></DrawingToolDebug> */}

      {/* <HtmlOverlayContainer
        position={radianArrayToCartesian3([
          2.0333734854645478, 0.7060957445783375, 903.6488061863824,
        ])}
        sx={{
          bgcolor: "#ffffff",
        }}
      >
        666dsf
      </HtmlOverlayContainer> */}
    </>
  );
}
