import React from "react";

import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// import { DataLayer } from "../drawingLayer";

export function DrawingLayerAccordion({ children }) {
  const [expanded, setExpanded] = React.useState(true);

  return (
    <Accordion
      disableGutters
      square
      expanded={expanded}
      onChange={() => {
        setExpanded((state) => !state);
      }}
      elevation={0}
      sx={{
        bgcolor: "transparent",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="caption" display="block" color="primary.main">
          已绘制内容
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* <DataLayer></DataLayer> */}
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
