import React from "react";
import { useReactiveVar } from "@apollo/client";
import { DigitalSceneViewer } from "../ds";
import { userVar } from "../../gql";
import { userIsProjectUserOrHigher } from "../../accessControl";

import { DrawingContext } from "../../hooks";
import { SpeedDialButton, BottomMenu, DrawingTool } from "../../components";
import { DrawingCommentsModule } from "./DrawingCommentsModule";
import { SourceObjectModule } from "./SourceObjectModule";

//临时使用此设置，图纸中默认不渲染部分类型的数字资产，避免图纸内容太乱，后续应该从数据库中读取
const initRenderTypeMap = {
  tiledModel: true,
  pointCloud: true,
  tiledImage: false,
  panorama: false,
  vtour: false,
};

export function DrawingViewer({ drawing, digitalSceneData, children }) {
  const user = useReactiveVar(userVar);

  return (
    <DrawingContext.Provider value={drawing}>
      <DigitalSceneViewer
        goToSceneDefaultView={true} //前往该场景默认视角
        setSceneDefaultViewAsHome={true} //设置场景的默认视角设为Home
        gotoCameraSearch={true} //前往searchparameter设置的相机视角
        setCameraSearchAsHome={true} //将searchparameter设置的相机视角设为Home视角
        scene={digitalSceneData.scene}
        assets={digitalSceneData.assets}
        initRenderMap={digitalSceneData.initRenderMap}
        initRenderTypeMap={initRenderTypeMap} //临时使用此设置，后续应该从数据库中读取,或设置代理几何的是否初始渲染，而不是类型
        // viewerProps={{
        //   timeline: true,
        // }}
      >
        {/* drawing.showComments===true时DrawingCommentsModule会请求Comments信息并提供3d渲染+Context给起子组件,否则直接返回children等于无此组件 */}
        <DrawingCommentsModule drawing={drawing}>
          <SourceObjectModule drawing={drawing}>
            <BottomMenu
              commentPanel={drawing.showComments} //drawing.showComments控制按钮显示
              sourceObjectPanel={drawing.showSourceObjects} //drawing.showSourceObjects控制按钮显示
              dsPanel
              toolMenu
              homeButton
              cameraModeButton
              vrButton={false}
              fullScreenButton
              accountButton
            ></BottomMenu>
          </SourceObjectModule>
        </DrawingCommentsModule>

        {/* 只有项目用户可以使用各种编辑功能 */}
        {userIsProjectUserOrHigher(user) && (
          <>
            <SpeedDialButton
              showCommentButton={drawing.showComments} //drawing.showComments控制按钮显示
            ></SpeedDialButton>
            <DrawingTool></DrawingTool>
          </>
        )}

        {children}
      </DigitalSceneViewer>
    </DrawingContext.Provider>
  );
}
