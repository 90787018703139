import React from "react";
import Box from "@mui/material/Box";

import { GridActionsCellItem } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
//behavior icons
import VisibilityIcon from "@mui/icons-material/Visibility";

import { useSourceObjectRenderMapContext } from "../../../../hooks";

export function ToggleSourceObjectAction({ params }) {
  const { id } = params;
  const { sourceObjectsRenderMap, toggleSourceObjectRenderStatus } =
    useSourceObjectRenderMapContext();

  return (
    <GridActionsCellItem
      icon={
        <Tooltip
          title={sourceObjectsRenderMap[id] ? "关闭" : "开启"}
          placement="top"
        >
          <VisibilityIcon
            color={sourceObjectsRenderMap[id] ? "primary" : "disabled"}
          />
        </Tooltip>
      }
      label="开关对象"
      onClick={() => {
        toggleSourceObjectRenderStatus(id);
      }}
    />
  );
}
