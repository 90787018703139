import React from "react";
import { useReactiveVar } from "@apollo/client";
import { Navigate, useParams } from "react-router-dom";

import { userIsAdmin } from "../../../accessControl";
import { userVar, useJsonSchema } from "../../../gql";
import { JsonSchemaCreatePage } from "./JsonSchemaCreatePage";

export function JsonSchemaUpdatePage(props) {
  const user = useReactiveVar(userVar);
  const { id } = useParams();

  const { data } = useJsonSchema({
    variables: {
      where: { id },
    },
  });
  return userIsAdmin(user) ? (
    data &&
      (data.jsonSchema ? (
        <JsonSchemaCreatePage
          id={data.jsonSchema.id}
          initFields={data.jsonSchema.fields}
          initName={data.jsonSchema.name}
        ></JsonSchemaCreatePage>
      ) : (
        <Navigate to="/404" replace />
      ))
  ) : (
    <Navigate to="/404" replace />
  );
}
