import { gql, useQuery, useMutation } from "@apollo/client";

//fragments
const MAP_ITEM_FIELDS = gql`
  fragment MapItemFields on MapItem {
    id
    name
    bd09
    data
    createdAt
    updatedAt
  }
`;

//queries
const GET_MAP_ITEM_BY_ID = gql`
  ${MAP_ITEM_FIELDS}
  query getMapItem($where: MapItemWhereUniqueInput!) {
    mapItem(where: $where) {
      ...MapItemFields
    }
  }
`;

//queries
const GET_MAP_ITEMS = gql`
  ${MAP_ITEM_FIELDS}
  query getMapItems($where: MapItemWhereInput! = {}) {
    mapItems(where: $where) {
      ...MapItemFields
    }
  }
`;

//mutations
const UPDATE_MAP_ITEM = gql`
  ${MAP_ITEM_FIELDS}
  mutation updateMapItem(
    $data: MapItemUpdateInput!
    $where: MapItemWhereUniqueInput!
  ) {
    updateMapItem(data: $data, where: $where) {
      ...MapItemFields
    }
  }
`;

//hooks query
export function useMapItem(options) {
  return useQuery(GET_MAP_ITEM_BY_ID, options);
}

export function useMapItems(options) {
  return useQuery(GET_MAP_ITEMS, options);
}

export function useUpdateMapItem(options) {
  return useMutation(UPDATE_MAP_ITEM, options);
}
