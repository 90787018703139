import React from "react";
import Portal from "@mui/base/Portal";

//cesium
import { Camera } from "resium";
import { Math, PerspectiveFrustum } from "cesium";

import { useCameraFromSearch } from "../../hooks";
import { Compass, SetCameraView } from "../../components";
import { setCompassHeading } from "../../gql";

let initCamera = true;

export function CustomCamera({ gotoCameraSearch, setCameraSearchAsHome }) {
  // let { viewer } = useCesium();
  let camera;
  //读取searchParmas

  const cameraPropsFromSearch = useCameraFromSearch();

  const onChange = (e) => {
    // console.log(Math.toDegrees(camera.heading));
    // console.log(Math.toDegrees(camera.frustum.fov));
    // console.log(camera.frustum);
    // console.log({
    //   pitch: Math.toDegrees(camera.pitch).toFixed(0),
    //   heading: Math.toDegrees(camera.heading).toFixed(0),
    //   roll: Math.toDegrees(camera.roll).toFixed(0),
    // });
    setCompassHeading(Math.toDegrees(camera.heading).toFixed(0));
  };
  //首次加载后修改initViewer参数
  React.useEffect(() => {
    initCamera = false;
  }, []);
  return (
    <>
      <Camera
        ref={(e) => {
          camera = e && e.cesiumElement;
        }}
        onChange={onChange}
        // frustum={
        //   new PerspectiveFrustum({
        //     fov: Math.toRadians(60),
        //     aspectRatio: viewer.canvas.clientWidth / viewer.canvas.clientHeight,
        //     near: 1.0,
        //     far: 1000.0,
        //   })
        // }
      ></Camera>

      {/* 各种视角设定，setCameraSearchAsHome决定是否将当前视角设为home，gotoCameraSearch则决定是否调整到view视角 initCamera使得仅在首次加载时进行视角设置*/}
      {cameraPropsFromSearch && initCamera && (
        <SetCameraView
          view={cameraPropsFromSearch}
          setAsHome={setCameraSearchAsHome}
          goToView={gotoCameraSearch}
        ></SetCameraView>
      )}

      {/* 如果有初始默认camera参数,设置初始视角,否则则根据设置了setAsHome属性的模型来设置初始视角 */}
      {/* {setCurrentAsHome && cameraProps && <SetCurrentHome></SetCurrentHome>} */}
      <Portal>
        <Compass width={300}></Compass>
      </Portal>
    </>
  );
}
