import React from "react";

import Box from "@mui/material/Box";
import Clipboard from "clipboard";
import { useSnackbar } from "notistack";

import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

//icon
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";

import { removeEvent } from "../../gql";

import { FixedPanel } from "./FixedPanel";

export function ToolResultPanel({
  data,
  dataKeys,
  dataUnit,
  copyText,
  toolName,
  ...props
}) {
  const copyBtnId = `${toolName}-copy-button`;
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    const btn = new Clipboard(`#${copyBtnId}`);
    return () => {
      btn.destroy();
    };
  }, [copyBtnId]);
  return (
    <FixedPanel {...props}>
      <Box
        sx={{
          p: 1,
          "& >:not(:last-child)": {
            mb: 1,
          },
        }}
      >
        {dataKeys.map(({ name, title }) => (
          <Typography variant="body2" component="p" key={name}>
            <b>{title}:</b> {data[name]}
            {dataUnit}
          </Typography>
        ))}
      </Box>

      <Divider variant="fullWidth" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          mt: 1,
        }}
      >
        <Button
          id={copyBtnId}
          color="primary"
          variant="contained"
          data-clipboard-text={copyText}
          startIcon={<ContentCopyIcon></ContentCopyIcon>}
          onClick={() => {
            enqueueSnackbar("测量结果已复制至剪贴板", {
              variant: "success",
              key: `${copyBtnId}-success`,
            });
          }}
        >
          复制
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={() => {
            removeEvent(toolName);
          }}
          startIcon={<CloseIcon></CloseIcon>}
        >
          关闭
        </Button>
      </Box>
    </FixedPanel>
  );
}
