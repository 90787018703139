import React from "react";
import {
  useScreenshotDownload,
  useVideoRecorderDownload,
} from "../../../../hooks";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Input from "@mui/material/Input";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
//icon
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";

import Form from "@rjsf/mui";

import { useSceneContext } from "../../../../hooks";
import { CustomForm } from ".././../../../schemaForms";
import { ErrorBoundary } from "../../../error";

const uiSchemaScreenShotForm = {
  targetResolutionScale: {
    "ui:title": "asdf",
    "ui:help": "按比例缩放当前屏幕后截图",
    "ui:widget": "range",
  },
  jpegQuality: {
    "ui:help": "截图jpg的压缩率设置",
    "ui:widget": "range",
  },
};

const schemaScreenShotFormProperties = {
  targetResolutionScale: {
    type: "number",
    title: "截图分辨率缩放",
    default: 1,
    minimum: 0.1,
    maximum: 5,
    multipleOf: 0.1,
  },
  jpegQuality: {
    type: "number",
    title: "压缩率",
    default: 0.75,
    minimum: 0.01,
    maximum: 1,
    multipleOf: 0.01,
  },
  fileName: {
    type: "string",
    title: "截图名称",
    default: "屏幕截图",
  },
};

function ScreenShotForm(props) {
  const data = useSceneContext();

  const schema = data
    ? {
        type: "object",
        properties: {
          ...schemaScreenShotFormProperties,
          fileName: {
            type: "string",
            title: "截图名称",
            default: data.scene.name,
          },
        },
      }
    : {
        type: "object",
        properties: schemaScreenShotFormProperties,
      };
  console.log(schema);
  const downloadCanvasScreenshot = useScreenshotDownload();
  return (
    <Box
      sx={{
        textAlign: "left",
        "& .MuiFormHelperText-root": {
          ml: 0,
        },
      }}
    >
      <Typography variant="h5" align="left">
        场景截图工具
      </Typography>
      <CustomForm
        schema={schema}
        uiSchema={uiSchemaScreenShotForm}
        onSubmit={(e) => {
          // console.log(e.formData);
          downloadCanvasScreenshot({
            ...e.formData,
            timeout: 500,
          });
        }}
        showErrorList={false}
        omitExtraData
        noHtml5Validat
      >
        <Button type="submit">截图并下载</Button>
      </CustomForm>
    </Box>
  );
}

function VideoRecorder(props) {
  const getRecorder = useVideoRecorderDownload();
  const recorder = React.useMemo(getRecorder, [getRecorder]);
  const [isRecording, setIsRecording] = React.useState(false);

  return (
    <Box>
      <Typography variant="h5" align="left">
        视频录制工具
      </Typography>
      <Button
        sx={{
          mr: 1,
        }}
        disabled={isRecording}
        startIcon={<PlayArrowIcon />}
        onClick={() => {
          if (recorder) {
            recorder.start();
            setIsRecording(true);
          }
        }}
      >
        开始录制
      </Button>
      <Button
        disabled={!isRecording}
        startIcon={<StopIcon />}
        onClick={() => {
          if (recorder) {
            recorder.stop();
            setIsRecording(false);
          }
        }}
      >
        停止并下载
      </Button>
      {/* {useTimer && <Timer></Timer>} */}
    </Box>
  );
}

export function ScreenshotPanel(props) {
  return (
    <Box>
      <ErrorBoundary message="浏览器不兼容截图功能,建议使用桌面端Chrome浏览器">
        <ScreenShotForm></ScreenShotForm>
      </ErrorBoundary>
      <ErrorBoundary message="浏览器不兼容视频录制,建议使用桌面端Chrome浏览器">
        <VideoRecorder></VideoRecorder>
      </ErrorBoundary>
    </Box>
  );
}
