import { fieldTypesDef } from "./config";

//根据元数据模式表单填写的内容，计算出填写元数据的表单schema和uiSchema
export function createSchemaFromFields(fields) {
  const schemaRequired = [];
  const schemaProperties = {};
  const uiSchema = {};
  const uiOrder = [];

  if (fields.length === 0) {
    return {
      schema: {},
      uiSchema: {},
    };
  }

  for (const [index, field] of fields.entries()) {
    console.log({ index, field });
    const { key, required, fieldType, uiOptions, schema, ...fieldSchemaProps } =
      field;
    if (required) {
      schemaRequired.push(key);
    }

    //生成schema,根据是否含有用户表单定义的schema内容分两种情况
    if (schema) {
      schemaProperties[key] = {
        ...fieldTypesDef[fieldType].attr.schema,
        ...fieldSchemaProps,
        ...schema,
      };
    } else {
      schemaProperties[key] = {
        ...fieldTypesDef[fieldType].attr.schema,
        ...fieldSchemaProps,
      };
    }

    //生成uiSchema,根据是否含有用户表单定义的uiOptions内容分两种情况
    uiOrder.push(key); //记录键顺序，避免表单渲染时顺序错乱
    if (uiOptions) {
      uiSchema[key] = {
        "ui:options": {
          ...fieldTypesDef[fieldType].attr.uiOptions,
          ...uiOptions,
        },
      };
    } else {
      uiSchema[key] = {
        "ui:options": {
          ...fieldTypesDef[fieldType].attr.uiOptions,
        },
      };
    }
  }

  // console.log(
  //   JSON.stringify(
  //     {
  //       schema: {
  //         type: "object",
  //         required: schemaRequired,
  //         properties: schemaProperties,
  //       },
  //       uiSchema,
  //     },
  //     null,
  //     2
  //   )
  // );

  return {
    schema: {
      type: "object",
      required: schemaRequired,
      properties: schemaProperties,
    },
    uiSchema: {
      "ui:order": uiOrder,
      ...uiSchema,
    },
  };
}
