import React from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

//forwardRef 用于Transition动画组件
export const SidePanel = React.forwardRef(({ sxProps, ...props }, ref) => (
  <Paper
    ref={ref}
    sx={{
      position: "fixed",
      top: (theme) => theme.spacing(2),
      width: {
        xs: "100%",
        sm: 350,
        lg: 400,
      },
      maxHeight: "80vh",
      overflowY: "auto",
      ...sxProps,
    }}
    {...props}
  >
    {props.children}
  </Paper>
));
