import React from "react";
import GlobalStyles from "@mui/material/GlobalStyles";

import Box from "@mui/material/Box";

import {
  Map,
  MapApiLoaderHOC,
  CityListControl,
  MapTypeControl,
  NavigationControl,
  ScaleControl,
  ZoomControl,
  DrawingManager,
} from "react-bmapgl";

export function _BaiduMap({ children, data, ...props }) {
  const ref = React.useRef(null);
  const {
    center,
    styleId,
    scaleControl,
    navigationControl,
    zoomControl,
    mapTypeControl,
    drawingManager,
    // zoom,
    // heading,
    // tilt,
    // enableDoubleClickZoom,
    // enableDragging,
    // enableRotate,
    // enableScrollWheelZoom,
    // enableTilt,
    // minZoom,
    // maxZoom,
    ...mapProps
  } = data;
  const centerPositionArray = React.useMemo(() => {
    return center.split(",").map((item) => parseFloat(item));
  }, [center]);

  return (
    <Box
      sx={{
        "& .BMap_cpyCtrl": {
          display: "none !important",
        },
        "& .anchorBL>img": {
          display: "none !important",
        },
      }}
      {...props}
    >
      <Map
        ref={ref}
        style={{ height: "100%", width: "100%" }}
        center={new window.BMapGL.Point(...centerPositionArray)}
        mapStyleV2={{
          styleId: styleId,
        }}
        {...mapProps}
      >
        {/* <CityListControl
              anchor={window.BMAP_ANCHOR_BOTTOM_RIGHT}
            ></CityListControl> */}
        {mapTypeControl && (
          <MapTypeControl
            anchor={window.BMAP_ANCHOR_TOP_RIGHT}
          ></MapTypeControl>
        )}
        {navigationControl && (
          <NavigationControl
            anchor={window.BMAP_ANCHOR_BOTTOM_RIGHT}
          ></NavigationControl>
        )}
        {scaleControl && (
          <ScaleControl
            anchor={window.BMAP_ANCHOR_BOTTOM_LEFT}
            offset={new window.BMapGL.Size(10, 10)}
          ></ScaleControl>
        )}
        {zoomControl && (
          <ZoomControl anchor={window.BMAP_ANCHOR_BOTTOM_RIGHT}></ZoomControl>
        )}

        {drawingManager && (
          <DrawingManager
            enableLimit
            enableCalculate
            onOverlaycomplete={(e, info) => {
              console.log(e, info);
            }}
          />
        )}

        {children}
      </Map>
      {/* 渲染完成MAP后隐藏进度条 */}
      <GlobalStyles
        styles={{
          ".pace": {
            display: "none",
          },
        }}
      ></GlobalStyles>
    </Box>
  );
}

export const BaiduMap = MapApiLoaderHOC({ ak: process.env.REACT_APP_BMAP_AK })(
  _BaiduMap
);
