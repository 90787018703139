import React from "react";
import { useReactiveVar } from "@apollo/client";

import {
  BillboardRender,
  PolylineRender,
  PolygonRender,
  PointRender,
  LabelRender,
  GlbModelRender,
} from "./geometryRenders";

// import { ErrorBoundary } from "../../../components";
// import {
//   drawingToolEditVar,
//   geometrySettingVar,
//   checkEditDataReady,
// } from "../drawingToolVars";

export function RenderMap(props) {
  // console.log("render renderMap");
  // console.log({ props });
  // useReactiveVar(drawingToolEditVar);
  // useReactiveVar(geometrySettingVar);
  switch (props.data.type) {
    case "billboard":
      return <BillboardRender {...props}></BillboardRender>;
    case "polyline":
      return <PolylineRender {...props}></PolylineRender>;
    case "polygon":
      return <PolygonRender {...props}></PolygonRender>;
    case "point":
      return <PointRender {...props}></PointRender>;
    case "label":
      return <LabelRender {...props}></LabelRender>;
    case "model":
      return <GlbModelRender {...props}></GlbModelRender>;
    default:
      return null;
  }
}

// export function RenderMap(props) {
//   return (
//     <ErrorBoundary logOnly>
//       <RenderMapRaw {...props}></RenderMapRaw>
//     </ErrorBoundary>
//   );
// }
