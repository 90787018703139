import React from "react";
import { useReactiveVar } from "@apollo/client";
import { Navigate, Link } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

import { userIsAdmin } from "../../../accessControl";
import { userVar, useJsonSchemas } from "../../../gql";
import { JsonSchemaPageHeader } from "./components";

function handleEditClick(id) {
  window.open(`${process.env.REACT_APP_ADMIN_URI}/json-schemas/${id}`);
}

export function JsonSchemaListPage(props) {
  const user = useReactiveVar(userVar);
  const { data, refetch } = useJsonSchemas();
  React.useEffect(() => {
    refetch();
  });
  return userIsAdmin(user) ? (
    <>
      <JsonSchemaPageHeader title="数据模式列表"></JsonSchemaPageHeader>

      <Box
        sx={{
          px: 2,
          width: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        {data &&
          data.jsonSchemas.map(({ id, name, schema }) => (
            <Paper
              key={id}
              sx={{
                minWidth: 200,
                p: 1,
                bgcolor: "background.alphaPrimary",
              }}
            >
              <Typography
                variant="h5"
                align="center"
                sx={{
                  fontWeight: "bold",
                }}
                gutterBottom
              >
                {name}
              </Typography>

              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button size="small" component={Link} to={id}>
                  {schema === null ? "配置" : "修改"}
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    handleEditClick(id);
                  }}
                >
                  后台编辑
                </Button>
              </Box>
            </Paper>
          ))}
      </Box>
    </>
  ) : (
    <Navigate to="/404" replace />
  );
}
