import React from "react";
import { useReactiveVar } from "@apollo/client";

import { ScreenSpaceEvent, useCesium, ScreenSpaceEventHandler } from "resium";
import { ScreenSpaceEventType, defined } from "cesium";

import { DrawingPositionsEvent } from "./DrawingPositionsEvent";
import { DrawingPositionEvent } from "./DrawingPositionEvent";
import { drawingStatusVar } from "../drawingToolVars";

export function DrawingScreenEvents() {
  const { drawingType } = useReactiveVar(drawingStatusVar);

  return (
    <ScreenSpaceEventHandler>
      {drawingType === "billboard" && (
        <DrawingPositionEvent drawingType="billboard"></DrawingPositionEvent>
      )}
      {drawingType === "point" && (
        <DrawingPositionEvent drawingType="point"></DrawingPositionEvent>
      )}
      {drawingType === "polyline" && (
        <DrawingPositionsEvent drawingType="polyline"></DrawingPositionsEvent>
      )}
      {drawingType === "polygon" && (
        <DrawingPositionsEvent drawingType="polygon"></DrawingPositionsEvent>
      )}
      {drawingType === "label" && (
        <DrawingPositionEvent drawingType="label"></DrawingPositionEvent>
      )}{" "}
      {drawingType === "model" && (
        <DrawingPositionEvent drawingType="model"></DrawingPositionEvent>
      )}
    </ScreenSpaceEventHandler>
  );
}
