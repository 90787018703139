import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

export function SettingLabel({ label, tooltipProps, icon, children }) {
  return (
    <Box
      sx={{
        "&:not(:last-child)": {
          mb: 2,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          userSelect: "none",
          mb: 0.5,
        }}
      >
        {icon &&
          (tooltipProps ? <Tooltip {...tooltipProps}>{icon}</Tooltip> : icon)}

        <Typography
          variant="caption"
          sx={{
            ml: 1,
            fontWeight: "bold",
            color: "grey.600",
          }}
        >
          {label}
        </Typography>
      </Box>
      {children}
    </Box>
  );
}
