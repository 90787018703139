import React from "react";
import { useCesium } from "resium";
import { VRButtonViewModel } from "cesium";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import PanoramaPhotosphereIcon from "@mui/icons-material/PanoramaPhotosphere";

export function VRIconButton(props) {
  const { scene } = useCesium();
  const vr = new VRButtonViewModel(scene);
  const handleClick = () => {
    if (vr.isVREnabled) {
      vr.command();
    }
  };
  return (
    <Tooltip arrow title="VR模式" placement="top">
      <IconButton size="small" onClick={handleClick} color="white">
        <PanoramaPhotosphereIcon></PanoramaPhotosphereIcon>
      </IconButton>
    </Tooltip>
  );
}
