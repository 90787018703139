import React from "react";
import { DocumentRenderer } from "@keystone-6/document-renderer";

import { componentBlockRenderers } from "./componentBlockRenderers";
import { renderers } from "./renderers";
export function Document({ document }) {
  return (
    <>
      <DocumentRenderer
        document={document}
        componentBlocks={componentBlockRenderers}
        renderers={renderers}
      />

      {/* <pre>{JSON.stringify(document, null, 2)}</pre> */}
    </>
  );
}
