import React from "react";
import { genDefaultIcons } from "./defaultIcons";
import { MapItem } from "./MapItem";

export function MapItems({ data }) {
  const iconSet = React.useMemo(() => {
    return genDefaultIcons(window.BMapGL);
  }, []);

  // React.useEffect(() => {
  //   const convertor = new window.BMapGL.Convertor();
  //   const pointArr = data.mapItems.map((item) => {
  //     const positionArray = item.bd09
  //       .split(",")
  //       .map((item) => parseFloat(item));
  //     return new window.BMapGL.Point(...positionArray);
  //   });
  //   console.log(pointArr);
  //   convertor.translate(pointArr, 1, 5, (data) => {
  //     console.log(data);
  //   });
  // }, [data.mapItems]);

  return data.mapItems.map((item) => (
    <MapItem
      item={item}
      key={item.id}
      schema={data.jsonSchema}
      iconSet={iconSet}
    ></MapItem>
  ));
}
