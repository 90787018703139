import React from "react";

import { RenderMap } from "../../drawingTool";

export function GeometryRender({ sourceObject, geometryProps, ...props }) {
  //用meta几何覆写设置与data本身的几何属性merge
  const geometryData = React.useMemo(() => {
    const data = [];
    for (const geometryItem of sourceObject.geometry) {
      data.push({
        ...geometryItem,
        ...geometryProps[geometryItem.type],
      });
    }

    // console.log(data);
    return data;
  }, [sourceObject, geometryProps]);

  // console.log({ geometryData });

  return geometryData.map((graphicData, index) => {
    return (
      <RenderMap
        data={graphicData}
        key={graphicData.id}
        {...props}

        // onRightClick={() => {
        //   console.log("----------几何对象信息-----------");
        //   console.log(JSON.stringify(graphicData, null, 2));
        // }}
        // onDoubleClick={() => {
        //   //未处于编辑状态时,双击图层几何,将其载入编辑
        //   if (drawingStatusVar().drawingType === null) {
        //     dispatchDrawingToolVar({
        //       type: "EDIT_GEOMETRY_BY_ID",
        //       payload: graphicData.id,
        //     });
        //   }
        // }}
      ></RenderMap>
    );
  });
}
