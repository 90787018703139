import React from "react";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

export function JsonView({ fields, schema, uiSchema }) {
  return (
    <>
      <Typography
        variant="h5"
        color="primary"
        sx={{
          fontWeight: "bold",
        }}
        gutterBottom
      >
        JSON数据展示
      </Typography>
      <Paper
        sx={{
          p: 2,
          bgcolor: "grey.200",
        }}
      >
        <Typography
          variant="caption"
          sx={{
            fontWeight: "bold",
          }}
          gutterBottom
        >
          配置字段数据
        </Typography>
        <pre>{JSON.stringify(fields, null, 2)}</pre>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "bold",
          }}
          gutterBottom
        >
          JSON模式定义
        </Typography>
        <pre>{JSON.stringify(schema, null, 2)}</pre>

        <Typography
          variant="caption"
          sx={{
            fontWeight: "bold",
          }}
          gutterBottom
        >
          表单UI定义
        </Typography>
        <pre>{JSON.stringify(uiSchema, null, 2)}</pre>

        <Typography
          variant="caption"
          sx={{
            fontWeight: "bold",
          }}
          gutterBottom
        >
          表单属性设置
        </Typography>
        <pre>{JSON.stringify({}, null, 2)}</pre>
      </Paper>
    </>
  );
}
