import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
//local
import {
  ToggleChildrenButton,
  BottomPanel,
  SourceObjectTable,
} from "../../../components";
export function SourceObjectPanel(props) {
  return (
    <ToggleChildrenButton
      initOpen={false}
      icon={<ListAltOutlinedIcon></ListAltOutlinedIcon>}
      tooltipProps={{
        title: "对象列表",
        placement: "top",
      }}
      slideDirection="up"
      fabPosition={{
        bottom: "48vh",
        right: 16,
      }}
    >
      <BottomPanel
        sxProps={{
          // pt: "12px", //给absolute Box
          zIndex: "commentTablePanel",
        }}
      >
        {/* <Box
          sx={{
            width: "100%",
            height: 12,
            borderBottom: 1,
            borderColor: "border.grey",
            cursor: "move",
            position: "absolute",
            top: 0,
            width: "100%",
          }}
          id="draggable-commentpanel"
        ></Box> */}
        {/* <CommentTable></CommentTable>
         */}
        <SourceObjectTable></SourceObjectTable>
      </BottomPanel>
    </ToggleChildrenButton>
  );
}
