import React from "react";
import { useReactiveVar } from "@apollo/client";

//cesium
import { Scene } from "resium";
import {
  DirectionalLight,
  SunLight,
  Cartesian3,
  Color,
  PostProcessStageLibrary,
} from "cesium";

import { viewerSettingVar, colorVar } from "../../gql";
// import { Debug } from "./Debug";

// const light = new DirectionalLight({
//   direction: new Cartesian3(1.0, 1.0, -1.0),
//   intensity: 0.001,
//   // color: Color.RED,
// });

// const light = new SunLight();
export function CustomScene({ children, ...props }) {
  const { showFps } = useReactiveVar(viewerSettingVar);
  const { backgroundColor } = useReactiveVar(colorVar);
  const ref = React.useRef(null);
  // React.useEffect(() => {
  //   if (ref.current && ref.current.cesiumElement) {
  //     if (
  //       !PostProcessStageLibrary.isAmbientOcclusionSupported(
  //         ref.current.cesiumElement
  //       )
  //     ) {
  //       window.alert(
  //         "This browser does not support the ambient occlusion post process."
  //       );
  //     }

  //     // ref.current.cesiumElement.postProcessStages.fxaa.enabled = true;
  //     const ambientOcclusion =
  //       ref.current.cesiumElement.postProcessStages.ambientOcclusion;
  //     ambientOcclusion.enabled = true;
  //     // ambientOcclusion.uniforms.ambientOcclusionOnly = true;
  //     ambientOcclusion.uniforms.intensity = 1;
  //     // ambientOcclusion.uniforms.bias = 0.1;
  //     // ambientOcclusion.uniforms.lengthCap = 0.03;

  //     // ambientOcclusion.uniforms.stepSize = 1;

  //     // ambientOcclusion.uniforms.frustumLength = 100000;
  //     // ambientOcclusion.uniforms.delta = 0.5;
  //     // ambientOcclusion.uniforms.sigma = 0.5;
  //   }
  // }, []);

  return (
    <Scene
      backgroundColor={backgroundColor}
      debugShowFramesPerSecond={showFps}
      // light={light}
      {...props}
      ref={ref}
      // contextOptions={{
      //   webgl: {
      //     alpha: false,
      //     depth: true,
      //     stencil: false,
      //     antialias: true,
      //     powerPreference: "high-performance",
      //     premultipliedAlpha: true,
      //     preserveDrawingBuffer: true,
      //     failIfMajorPerformanceCaveat: false,
      //   },
      //   allowTextureFilterAnisotropic: true,
      // }}
    >
      {children}
    </Scene>
  );
}
