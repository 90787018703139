import React from "react";

import { DataGrid } from "@mui/x-data-grid";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import {
  useSourceObjectContext,
  useSupportedMetasMapContext,
  useSourceObjectRenderMapContext,
} from "../../../hooks";

import { createColumns, columnsInitialState } from "./columnDefination";
import { CustomToolbar } from "./CustomToolbar";
import { MetaHeader } from "./MetaHeader";

function getAutoRowHeight() {
  return "auto";
}
function getDefaultRowHeight() {
  return null;
}

export function SourceObjectTable(props) {
  const [pageSize, setPageSize] = React.useState(20);
  const [expandHeight, setExpandHeight] = React.useState(false);
  const [autoRowHeight, setAutoRowHeight] = React.useState(false);
  // const [filterModel, setFilterModel] = React.useState({
  //   items: [],
  // });
  const { sourceObjects } = useSourceObjectContext();
  const { metasRenderMap } = useSourceObjectRenderMapContext();

  const { metasMap } = useSupportedMetasMapContext();

  const columns = React.useMemo(() => createColumns({ metasMap }), [metasMap]);

  const rows = React.useMemo(() => {
    return sourceObjects.filter(({ meta }) => {
      return metasRenderMap[meta.id];
    });
  }, [sourceObjects, metasRenderMap]);
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <MetaHeader
        sourceObjects={sourceObjects}
        metasMap={metasMap}
      ></MetaHeader>

      <Divider variant="middle" />

      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: expandHeight ? "90vh" : "42vh", //此高度需要和fabPosition的位置设置对应
          transition: (theme) => theme.transitions.create("height"),
          resize: "vertical",
        }}
      >
        <DataGrid
          // 可控filterModel,目前还不需要此功能
          // filterModel={filterModel}
          // onFilterModelChange={(newFilterModel) => {
          //   console.log(newFilterModel);
          //   setFilterModel(newFilterModel);
          // }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          pagination
          disableSelectionOnClick //禁止点击选择行
          sx={{
            zIndex: (theme) => theme.zIndex.infoPanel + 1,
            "& .MuiDataGrid-columnHeaders": {
              bgcolor: "background.alphaBlackLight",
              "& .MuiDataGrid-columnSeparator": {
                color: "primary.light",
              },
            },
            "& .MuiDataGrid-cell--withRenderer": {
              flexWrap: "wrap",
            },
            "& .comment-table-tag-cell": {
              gap: 0.5, //标签cell增加gap属性
            },
          }}
          componentsProps={{
            panel: {
              sx: {
                pb: 1,
                inset: "auto auto 0px 0px !important", //避免panel溢出
                transform: "unset !important", //避免panel溢出
              },
            },
          }}
          rows={rows}
          columns={columns}
          initialState={{
            columns: columnsInitialState,
            sorting: {
              sortModel: [{ field: "meta", sort: "desc" }], //默认按照meta降序排列
            },
          }}
          components={{
            Toolbar: () => (
              <CustomToolbar
                autoRowHeight={autoRowHeight}
                setAutoRowHeight={setAutoRowHeight}
                expandHeight={expandHeight}
                setExpandHeight={setExpandHeight}
              ></CustomToolbar>
            ),
          }}
          getRowHeight={autoRowHeight ? getAutoRowHeight : getDefaultRowHeight}
          density="standard"
        ></DataGrid>
      </Box>
    </Box>
  );
}
