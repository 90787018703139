import { makeVar } from "@apollo/client";
import { Cesium3DTileset, ShadowMode } from "cesium";

const defaultCesium3DTilesetUpdate = Cesium3DTileset.prototype.update;

const newCesium3DTilesetUpdate = function (frameState) {
  this.backFaceCulling = cesium3DTilesetRenderConfigVar().backFaceCulling;

  this.maximumMemoryUsage = cesium3DTilesetRenderConfigVar().maximumMemoryUsage;
  // this.shadows = ShadowMode.CAST_ONLY;

  // this.debugColorizeTiles = true;
  // this.debugWireframe = true;
  defaultCesium3DTilesetUpdate.call(this, frameState);
};

Cesium3DTileset.prototype.update = newCesium3DTilesetUpdate;

const defaultValue = {
  backFaceCulling: true, //双面显示管理
  maximumMemoryUsage: Number(process.env.REACT_APP_3DTILE_MAX_MEMORY_USAGE),
};

//控制3DTileset的渲染配置
export const cesium3DTilesetRenderConfigVar = makeVar(defaultValue);

//控制切换双面显示状态
export function setBackFaceCulling(backFaceCulling) {
  if (cesium3DTilesetRenderConfigVar().backFaceCulling !== backFaceCulling) {
    cesium3DTilesetRenderConfigVar({
      ...cesium3DTilesetRenderConfigVar(),
      backFaceCulling,
    });
  }
}

export function setMaximumMemoryUsage(maximumMemoryUsage) {
  if (
    cesium3DTilesetRenderConfigVar().maximumMemoryUsage !== maximumMemoryUsage
  ) {
    cesium3DTilesetRenderConfigVar({
      ...cesium3DTilesetRenderConfigVar(),
      maximumMemoryUsage,
    });
  }
}
