import React from "react";
import Box from "@mui/material/Box";

import WebAssetOutlinedIcon from "@mui/icons-material/WebAssetOutlined";

//local
import {
  ToggleChildrenButton,
  SidePanel,
  SettingsAccordion,
  ZoomSetting,
  DepthTestSetting,
  AssetsTreeSetting,
  DsHotspotSetting,
  DsHotspotsTree,
} from "../../../components";

export function DsPanel(props) {
  return (
    <ToggleChildrenButton
      initOpen={false}
      icon={<WebAssetOutlinedIcon></WebAssetOutlinedIcon>}
      tooltipProps={{
        title: "场景面板",
        placement: "top",
      }}
      slideDirection="right"
      draggableProps={{
        handle: "#draggable-dspanel",
      }}
    >
      <SidePanel
        aria-labelledby="draggable-dspanel"
        sxProps={{
          bgcolor: "background.alphaToolPanel",
          zIndex: "infoPanel",
          px: 1,
        }}
      >
        {/* 此Box用于拖动 */}
        <Box
          sx={{
            height: 12,
            borderBottom: 1,
            borderColor: "border.grey",
            cursor: "move",
          }}
          id="draggable-dspanel"
        ></Box>
        {/* 基础设置 */}
        <SettingsAccordion label="设置">
          <ZoomSetting></ZoomSetting>
          <DepthTestSetting></DepthTestSetting>
        </SettingsAccordion>

        {/* 数字资产 */}
        <AssetsTreeSetting></AssetsTreeSetting>

        {/* 场景热点 */}
        <SettingsAccordion label="场景热点">
          <DsHotspotSetting></DsHotspotSetting>
          <DsHotspotsTree></DsHotspotsTree>
        </SettingsAccordion>
      </SidePanel>
    </ToggleChildrenButton>
  );
}
