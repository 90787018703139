import React from "react";
import { useReactiveVar } from "@apollo/client";

import { Cartesian2, Color, LabelStyle, NearFarScalar } from "cesium";

import { WysiwygDialog, PositionBillBoard, PositionLabel } from "../..";

import { hoverEntityIdVar, depthTestVar } from "../../../gql";

import { createPosition } from "../../../utils";
import { useSceneContext } from "../../../hooks";

//临时使用固定设置，后续更改
// const scaler = new NearFarScalar(500, 0.8, 5000, 0.08);
// const hoverScaler = new NearFarScalar(500, 0.9, 5000, 0.09);

const billboardScale = 0.8;
const billboardScaleHover = 0.9;
//渲染配置
const dsHotspotBillboardSetting = {
  color: "CYAN",
  alpha: 0.8,
};
const dsHotspotBillboardHoverSetting = {
  color: "CYAN",
  alpha: 1,
};
const labelSettings = {
  offsetY: -36,
  LabelGraphicsProps: {
    style: LabelStyle.FILL,
    fillColor: Color.BLACK,
    showBackground: true,
    backgroundColor: Color.WHITE,
    backgroundPadding: new Cartesian2(8, 8),
  },
};

export function DsHotspotRenderer({ dsHotspot }) {
  //深度检测设置
  const { enableDepthTest } = useReactiveVar(depthTestVar);
  const disableDepthTestDistance = enableDepthTest
    ? undefined
    : Number.POSITIVE_INFINITY;

  const {
    scene: { near, far },
  } = useSceneContext();

  const scaleByDistance = React.useMemo(
    () =>
      new NearFarScalar(
        near,
        billboardScale,
        far,
        (billboardScale * near) / far
      ),
    [near, far]
  );
  const scaleByDistanceHover = React.useMemo(
    () =>
      new NearFarScalar(
        near,
        billboardScaleHover,
        far,
        (billboardScaleHover * near) / far
      ),
    [near, far]
  );

  const labelSettingWithNearFarScaler = {
    ...labelSettings,
    LabelGraphicsProps: {
      ...labelSettings.LabelGraphicsProps,
      scaleByDistance: scaleByDistance,
      pixelOffsetScaleByDistance: scaleByDistance,
      disableDepthTestDistance,
    },
  };

  const { id, name, iconType, targetUrl, editContent } = dsHotspot;
  let hoverEntityId = useReactiveVar(hoverEntityIdVar);

  let billboardSettings =
    hoverEntityId === id
      ? {
          ...dsHotspotBillboardHoverSetting,
          BillboardGraphicsProps: {
            scaleByDistance: scaleByDistanceHover,
            disableDepthTestDistance,
          },
        }
      : {
          ...dsHotspotBillboardSetting,
          BillboardGraphicsProps: {
            scaleByDistance: scaleByDistance,
            disableDepthTestDistance,
          },
        };

  let position = createPosition(dsHotspot.position);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    if (targetUrl) {
      window.open(targetUrl, "_blank");
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => setOpen(false);
  return (
    <>
      <PositionBillBoard
        onClick={handleOpen}
        position={position}
        id={id}
        show
        iconVarient={iconType}
        {...billboardSettings}
      ></PositionBillBoard>
      <PositionLabel
        onClick={handleOpen}
        position={position}
        show
        text={name}
        {...labelSettingWithNearFarScaler}
      ></PositionLabel>

      {editContent && (
        <WysiwygDialog
          title={editContent.title || name}
          open={open}
          handleClose={handleClose}
          id={editContent.id}
          editUri={`${process.env.REACT_APP_ADMIN_URI}/ds-hotspots/${id}`}
        ></WysiwygDialog>
      )}
    </>
  );
}
