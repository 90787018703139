import TextField from "@mui/material/TextField";

export function StringField({
  labelText,
  values,
  required = false,
  fieldName,
  helperText,
  handleChange,
  handleBlur,
  touched,
  errors,
  type = "text",
  fullWidth = true,
  ...props
}) {
  return (
    <TextField
      id={fieldName}
      name={fieldName}
      type={type}
      label={labelText}
      value={values[fieldName]}
      onChange={handleChange}
      onBlur={handleBlur}
      error={touched[fieldName] && Boolean(errors[fieldName])}
      helperText={(touched[fieldName] && errors[fieldName]) || helperText}
      variant="outlined"
      required={required}
      fullWidth={fullWidth}
      {...props}
    />
  );
}
