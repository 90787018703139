import React from "react";
import { useCesium } from "resium";
import { useSnackbar } from "notistack";

import { dispatchDrawingToolVar } from "../drawingToolVars";

// 组件unmount后，同时清除editData数据
export function useClearEditDataAfterUnmount() {
  React.useEffect(() => {
    return () => {
      dispatchDrawingToolVar({
        type: "RESET_EDIT_DATA",
      });
    };
  });
}

export function useSaveEditData() {
  const { enqueueSnackbar } = useSnackbar();
  return () => {
    const result = dispatchDrawingToolVar({
      type: "ADD_GEOMETRY",
    });
    // console.log({ result });
    if (!result.success) {
      enqueueSnackbar(result.message, {
        variant: "error",
        key: "save-geometry-error",
      });
    }
  };
}

export function useRequestRender() {
  const { scene } = useCesium();
  React.useEffect(() => {
    scene.requestRender();
  });
}
