import React from "react";
import { useReactiveVar } from "@apollo/client";

import { BillboardProxy } from "./BillboardProxy";
import { PolygonProxy } from "./PolygonProxy";
import { ModelProxy } from "./ModelProxy";

import { PreviewIframe } from "../PreviewIframe";
import { createPosition } from "../../../utils";
import { PreviewHtmlOverlayIframe } from "../PreviewHtmlOverlayIframe";
import { overlayIframeVar } from "../../../gql";

export function ProxyGraphicRender(props) {
  const { useOverlayIframe } = useReactiveVar(overlayIframeVar);

  const { asset } = props;

  let position;
  // 此处必须判断,避免非renderType在inherit等其他情况下,也渲染billboardProxy
  if (asset.renderType === "billboard") {
    position = createPosition(asset.position);
  }
  //处理特殊情况 当全景使用inherit渲染模式时，也使用Billboard进行渲染
  if (
    asset.renderType === "inherit" &&
    asset.digitalAsset.panorama &&
    asset.digitalAsset.panorama.position
  ) {
    position = createPosition(asset.digitalAsset.panorama.position);
  }

  // 控制Iframe开关
  const [open, setOpen] = React.useState(false);

  // 事件点击处理函数;
  const handleClick = React.useCallback(() => {
    if (useOverlayIframe && position) {
      setOpen(true);
    } else {
      if (asset.eventType === "blank") {
        window.open(asset.digitalAsset.viewerUrl, "_blank");
      }
      if (asset.eventType === "self") {
        window.open(asset.digitalAsset.viewerUrl, "_self");
      }
      if (asset.eventType === "iframe") {
        setOpen(true);
      }
    }
  }, [setOpen, asset, useOverlayIframe]);

  return (
    <>
      {asset.renderType === "billboard" && (
        <BillboardProxy onClick={handleClick} {...props}></BillboardProxy>
      )}
      {asset.renderType === "polygon" && (
        <PolygonProxy onClick={handleClick} {...props}></PolygonProxy>
      )}
      {asset.renderType === "model" && (
        <ModelProxy onClick={handleClick} {...props}></ModelProxy>
      )}

      {asset.renderType === "inherit" &&
        asset.digitalAsset.type === "panorama" && (
          <BillboardProxy onClick={handleClick} {...props}></BillboardProxy>
        )}

      {/* 渲染iframe弹窗 */}
      {/* {asset.eventType === "iframe" && (
        <PreviewIframe
          open={open}
          src={props.asset.digitalAsset.viewerUrl}
          closeIframe={() => {
            setOpen(false);
          }}
        ></PreviewIframe>
      )} */}

      {/* {open && position && (
        <PreviewHtmlOverlayIframe
          position={position}
          src={props.asset.digitalAsset.viewerUrl}
          closeIframe={() => {
            setOpen(false);
          }}
        ></PreviewHtmlOverlayIframe>
      )} */}

      {/* 资产为点代理且3D开启时，渲染内置overlayIframe，否则按后台设置渲染 */}
      {useOverlayIframe && position
        ? open && (
            <PreviewHtmlOverlayIframe
              position={position}
              src={props.asset.digitalAsset.viewerUrl}
              closeIframe={() => {
                setOpen(false);
              }}
            ></PreviewHtmlOverlayIframe>
          )
        : asset.eventType === "iframe" && (
            <PreviewIframe
              open={open}
              src={props.asset.digitalAsset.viewerUrl}
              closeIframe={() => {
                setOpen(false);
              }}
            ></PreviewIframe>
          )}
    </>
  );
}
