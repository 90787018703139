import React from "react";

//icon
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import PlaceIcon from "@mui/icons-material/Place";
import TitleIcon from "@mui/icons-material/Title";
import PolylineIcon from "@mui/icons-material/Polyline";
import CropDinIcon from "@mui/icons-material/CropDin";
import Crop169Icon from "@mui/icons-material/Crop169";
import ViewInArIcon from "@mui/icons-material/ViewInAr";

//props传参给icon
export function drawingButtonIconMap(props) {
  return {
    point: <RadioButtonCheckedIcon {...props}></RadioButtonCheckedIcon>,
    billboard: <PlaceIcon {...props}></PlaceIcon>,
    polyline: <PolylineIcon {...props}></PolylineIcon>,
    polygon: <CropDinIcon {...props}></CropDinIcon>,
    label: <TitleIcon {...props}></TitleIcon>,
    model: <ViewInArIcon {...props}></ViewInArIcon>,
  };
}

export const drawingButtonTypeMap = [
  {
    buttonType: "point",
    icon: <RadioButtonCheckedIcon></RadioButtonCheckedIcon>,
    tooltipProps: {
      title: "点",
      placement: "bottom",
    },
  },
  {
    buttonType: "billboard",
    icon: <PlaceIcon></PlaceIcon>,
    tooltipProps: {
      title: "标签",
      placement: "bottom",
    },
  },
  {
    buttonType: "label",
    icon: <TitleIcon></TitleIcon>,
    tooltipProps: {
      title: "文字标签",
      placement: "bottom",
    },
  },
  {
    buttonType: "polyline",
    icon: <PolylineIcon></PolylineIcon>,
    tooltipProps: {
      title: "多段线",
      placement: "bottom",
    },
  },
  {
    buttonType: "polygon",
    icon: <CropDinIcon></CropDinIcon>,
    tooltipProps: {
      title: "多边形",
      placement: "bottom",
    },
  },
  {
    buttonType: "model",
    icon: <ViewInArIcon></ViewInArIcon>,
    tooltipProps: {
      title: "模型",
      placement: "bottom",
    },
  },
];

export const drawingTypeTranslationMap = {
  point: "点",
  billboard: "标签",
  polyline: "多段线",
  polygon: "多边形",
  label: "文字标签",
  model: "模型",
};

export const drawingTypeHelperTextMap = {
  point: "左键单击选点,属性设置表中配置点属性,点击保存存储",
  billboard: "左键单击选点,属性设置表中配置标签属性,点击保存存储",
  polyline:
    "左键单击选点,右键单击回退,CTRL+左键单击替换点,通过属性设置表调整几何显示属性,点击保存存储绘制内容",
  polygon:
    "左键单击选点,右键单击回退,CTRL+左键单击替换点,通过属性设置表调整几何显示属性,点击保存存储绘制内容",
  label: "左键单击选择标签位置,属性设置表中配置文字内容与标签属性,点击保存存储",
  model:
    "左键单击选择模型原点放置位置,属性设置表中配置模型的各种显示参数,点击保存存储",
};

export const commonHelperText =
  "非编辑状态下,双击3D场景内几何,或点击已绘制内容撤回编辑按钮,可取消保存并重新编辑";

export const shortKeyMap = {
  p: "point",
  b: "billboard",
  l: "polyline",
  r: "polygon",
  t: "label",
  m: "model",
};

const shortKeys = Object.keys(shortKeyMap);

const keyMap = {};
shortKeys.forEach((key) => (keyMap[shortKeyMap[key]] = key));

export const drawingTypeShortKeyMap = keyMap;

export const drawingType = Object.keys(drawingTypeTranslationMap);
