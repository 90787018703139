import React from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

//icons
import ListItemIcon from "@mui/material/ListItemIcon";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";

//local
import { useLogout } from "../../gql";

const userRoleMap = {
  admin: "管理员",
  editor: "内容编辑员",
  projectuser: "项目用户",
  basicuser: "普通用户",
  banuser: "禁用用户",
};

export function AccountMenu({ user, ...props }) {
  const [logoutMutation] = useLogout();
  return (
    <Menu
      PaperProps={{
        elevation: 1,
        sx: {
          overflow: "visible",
          bgcolor: "background.alphaToolPanel",
          mt: -1,
        },
      }}
      {...props}
    >
      <MenuItem>{`身份: ${userRoleMap[user.role]}`}</MenuItem>
      <MenuItem
        divider
        dense
        disableRipple
        sx={{
          cursor: "auto",
          "&:hover": {
            bgcolor: "unset",
          },
        }}
      >
        <ListItemIcon>
          <PersonIcon fontSize="small" />
        </ListItemIcon>

        {user.name}
      </MenuItem>

      <MenuItem onClick={logoutMutation}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        退出登录
      </MenuItem>
    </Menu>
  );
}
