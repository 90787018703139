//控制所有绘图对象的非几何空间属性的设置
import { makeVar } from "@apollo/client";

const defaultValue = {
  point: {
    pixelSize: 10,
    color: "#ff0000",
    alpha: 0.8,
    outlineWidth: 3,
    outlineColor: "#000000",
  },
  billboard: {
    iconType: "place",
    color: "#ff0000",
    alpha: 0.8,
  },
  polyline: {
    width: 3,
    closed: false,
    color: "#ff0000",
    alpha: 0.8,
    clampToGround: false,
    dashed: false,
  },
  polygon: {
    color: "#ff0000",
    alpha: 0.5,
    clampToGround: false,
    ouline: false,
    // outlineWidth: 3,
    // outlineColor: "#2f65cb",
  },
  label: {
    text: "标签文字",
    fontSize: 14,
    color: "#000000",
    alpha: 1,
    showBackground: false,
    // backgroundColor: "#ffffff",
    // backgroundColorAlpha: 0.5,
    // backgroundPaddingX: 5,
    // backgroundPaddingY: 10,
  },
  model: {
    color: "#ffffff",
    alpha: 0.8,
    scale: 1,
    orientation: {
      heading: 0,
      pitch: 0,
      roll: 0,
    },
    colorBlendMode: "MIX", //HIGHLIGHT REPLACE MIX
    runAnimations: false,
    colorBlendAmount: 0,
    // silhouetteSize: 0,
    // silhouetteColor: "#ff0000",
  },
};

export const geometrySettingVar = makeVar(defaultValue);

export function dispatchGeometrySettingVar(action) {
  switch (action.type) {
    //变化工具开启或关闭状态
    case "SET_GEOMETRY_SETTING":
      geometrySettingVar({
        ...geometrySettingVar(),
        [action.payload.geometryType]: action.payload.data,
      });
      return;

    //恢复单一类型默认设置
    case "SET_SINGLE_GEOMETRY_SETTING_DEFAULT":
      geometrySettingVar({
        ...geometrySettingVar(),
        [action.payload]: {
          ...geometrySettingVar()[action.payload], //need testing 保留默认设置里没有的参数,例如模型的uri,需要进一步测试避免没有bug
          ...defaultValue[action.payload],
        },
      });
      return;

    //全部恢复默认设置
    case "SET_ALL_SETTING_TO_DEFAULT":
      geometrySettingVar(defaultValue);
      return;

    default:
      throw new Error("action type incorrect");
  }
}
