//源对象内容渲染
import React from "react";
import { useReactiveVar } from "@apollo/client";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import CloseIcon from "@mui/icons-material/Close";
import { userVar } from "../../../gql";
import { userIsAdminOrEditor } from "../../../accessControl";

import {
  DeleteSourceObjectMenuItem,
  EditSourceObjectMenuItem,
} from "./components";

export function SourceObjectContentHeader({
  sourceObject,
  meta,
  handleClose,
  setOpenEditSourceObjectPanel,
  ...props
}) {
  const user = useReactiveVar(userVar);

  const [contextMenu, setContextMenu] = React.useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleContextMenuClose = React.useCallback(() => {
    setContextMenu(null);
  }, []);

  const handleSourceObjectAdminOpen = React.useCallback(() => {
    window.open(
      `${process.env.REACT_APP_ADMIN_URI}/source-objects/${sourceObject.id}`
    );
    handleContextMenuClose();
  }, [sourceObject.id, handleContextMenuClose]);

  return (
    <Box
      id="draggable-source-object-content"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderBottom: 1,
      }}
    >
      <Box
        ml={2}
        py={2}
        onContextMenu={handleContextMenu}
        sx={{ cursor: "context-menu" }}
        {...props}
      >
        {/* 对象名称*/}
        <Typography
          variant="h4"
          gutterBottom
          color="primary"
          className="source-object-title"
        >
          {sourceObject.name}
        </Typography>

        {/* 对象类型+ tooltip提示 */}
        <Tooltip
          title={`此对象类型为“${meta.name}”${
            meta.description && " : " + meta.description
          }`}
          placement="right"
          arrow
        >
          <Typography
            variant="caption"
            sx={{
              fontWeight: "bold",
            }}
            className="source-object-title"
          >
            {meta.name}
          </Typography>
        </Tooltip>

        {/* 右键菜单 */}
        {userIsAdminOrEditor(user) && (
          <Menu
            open={contextMenu !== null}
            onClose={handleContextMenuClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
          >
            <MenuItem onClick={handleSourceObjectAdminOpen}>后台查看</MenuItem>
            <EditSourceObjectMenuItem
              sourceObject={sourceObject}
              handleContextMenuClose={handleContextMenuClose}
              setOpenEditSourceObjectPanel={setOpenEditSourceObjectPanel}
            ></EditSourceObjectMenuItem>
            <DeleteSourceObjectMenuItem
              sourceObject={sourceObject}
              handleContextMenuClose={handleContextMenuClose}
            ></DeleteSourceObjectMenuItem>
          </Menu>
        )}
      </Box>
      {/* 关闭面板按钮 */}
      <IconButton
        disableRipple
        onClick={handleClose}
        color="secondary"
        className="source-object-title"
        size="small"
        sx={{
          mr: 1.5,
        }}
      >
        <CloseIcon
          sx={{
            fontSize: 24,
          }}
        ></CloseIcon>
      </IconButton>
    </Box>
  );
}
