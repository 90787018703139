import React from "react";
import { Navigate } from "react-router-dom";

import { useReactiveVar } from "@apollo/client";

import GlobalStyles from "@mui/material/GlobalStyles";
import { MapApiLoaderHOC } from "react-bmapgl";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { userVar } from "../../gql";
import { userIsAdminOrEditor } from "../../accessControl";

function convertToCoordinatesArray(pointsStr) {
  const coordinates = pointsStr
    .trim()
    .split("\n")
    .map((coord) => {
      const [longitude, latitude] = coord.split(",");
      return [Number(longitude), Number(latitude)];
    });
  return coordinates;
}

function groupArrayByN(arr, n) {
  const result = [];

  for (let i = 0; i < arr.length; i += n) {
    const group = arr.slice(i, i + n);
    result.push(group);
  }

  return result;
}
function ungroupArrayByN(arr, n) {
  const result = [];

  for (let group of arr) {
    for (let element of group) {
      result.push(element);
    }
  }

  return result;
}

export function _MapConvertorPage(props) {
  const user = useReactiveVar(userVar);

  const convert = async () => {
    const convertor = new window.BMapGL.Convertor();

    const inputPointArrayGrouped = groupArrayByN(
      convertToCoordinatesArray(point).map(
        (p) => new window.BMapGL.Point(...p)
      ),
      10
    );
    const outputArray = [];
    const asyncConvert = (pointArr) => {
      return new Promise((resolve) => {
        convertor.translate(pointArr, 1, 5, (data) => {
          resolve(data.points.map((p) => `${p.lng},${p.lat}`));
        });
      });
    };
    for (let pointArr of inputPointArrayGrouped) {
      const output = await asyncConvert(pointArr);
      outputArray.push(output);
    }
    setOutputPoints(ungroupArrayByN(outputArray, 10));
  };

  const [point, setPoint] = React.useState(
    `116.404449, 39.914889
116.404448, 39.914888
116.404448, 39.914888
116.404448, 39.914888
116.404448, 39.914888
116.404448, 39.914888`
  );
  const [ouputPoints, setOutputPoints] = React.useState([]);
  return userIsAdminOrEditor(user) ? (
    <Box
      sx={{
        mt: "20vh",
        width: 1,
        p: 2,

        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
      }}
    >
      <TextField
        multiline
        rows={10}
        sx={{
          // width: 1,
          // maxWidth: 400,
          flex: "1 0 400px",
        }}
        label="WGS84坐标点"
        value={point}
        onChange={(event) => {
          setPoint(event.target.value);
        }}
      />

      <Button
        variant="contained"
        onClick={convert}
        sx={{
          // width: 1,
          // maxWidth: 300,
          flex: "0 1 300px",
        }}
      >
        转换
      </Button>

      <TextField
        multiline
        rows={10}
        sx={{
          // width: 1,
          // maxWidth: 400,
          flex: "1 0 400px",
        }}
        label="BD09坐标点"
        value={ouputPoints.join("\n")}
      />

      {/* 渲染完成MAP后隐藏进度条 */}
      <GlobalStyles
        styles={{
          ".pace": {
            display: "none",
          },
        }}
      ></GlobalStyles>
    </Box>
  ) : (
    <Navigate to="/404" replace></Navigate>
  );
}

export const MapConvertorPage = MapApiLoaderHOC({
  ak: process.env.REACT_APP_BMAP_AK,
})(_MapConvertorPage);
