import React from "react";

import Button from "@mui/material/Button";

import Divider from "@mui/material/Divider";

//icon
import CloseIcon from "@mui/icons-material/Close";

import { FixedPanel } from "./FixedPanel";

// 适用local state 控制的Panel
export function LocalPanel({ children, closeHandler, ...props }) {
  return (
    <FixedPanel {...props}>
      {children}

      <Divider variant="fullWidth" />
      <Button
        color="error"
        variant="contained"
        onClick={closeHandler}
        startIcon={<CloseIcon></CloseIcon>}
        fullWidth
        sx={{
          mt: 1,
        }}
      >
        关闭
      </Button>
    </FixedPanel>
  );
}
