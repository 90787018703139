import React from "react";
import { useReactiveVar } from "@apollo/client";

import {
  drawingToolDataVar,
  dispatchDrawingToolVar,
  drawingStatusVar,
} from "../drawingToolVars";

import { RenderMap } from "./RenderMap";

export function DrawingDataRender({ label, ...props }) {
  const { data } = useReactiveVar(drawingToolDataVar);

  return data.map((graphicData, index) => {
    return (
      <RenderMap
        data={graphicData}
        key={graphicData.id}
        onRightClick={() => {
          console.log("----------几何对象信息-----------");
          console.log(JSON.stringify(graphicData, null, 2));
        }}
        onDoubleClick={() => {
          //未处于编辑状态时,双击图层几何,将其载入编辑
          if (drawingStatusVar().drawingType === null) {
            dispatchDrawingToolVar({
              type: "EDIT_GEOMETRY_BY_ID",
              payload: graphicData.id,
            });
          }
        }}
      ></RenderMap>
    );
  });
}
