import React from "react";
import { useReactiveVar } from "@apollo/client";
import { Color } from "cesium";

import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { setBackgroundColor, colorVar } from "../../../../gql";

//可选颜色配置
const colorConfigs = [
  {
    color: Color.GREY,
    colorName: "灰色",
  },
  {
    color: Color.LIGHTGREY,
    colorName: "浅灰",
  },

  {
    color: Color.SKYBLUE,
    colorName: "天蓝",
  },
  {
    color: Color.STEELBLUE,
    colorName: "深蓝",
  },
];

function ColorSelection({ color, colorName }) {
  const { backgroundColor } = useReactiveVar(colorVar);

  return (
    <Tooltip title={colorName} placement="bottom" arrow>
      <Box
        sx={{
          bgcolor: color.toCssHexString(),
          cursor: "pointer",
          width: 24,
          height: 24,
          borderRadius: 1,
          border: backgroundColor === color && `2px solid red`,
        }}
        onClick={() => {
          setBackgroundColor(color);
        }}
      ></Box>
    </Tooltip>
  );
}

function ColorInput() {
  const { backgroundColor } = useReactiveVar(colorVar);
  // console.log(backgroundColor.toCssHexString());
  return (
    <TextField
      fullWidth
      label="自选颜色"
      variant="outlined"
      type="color"
      value={backgroundColor.toCssHexString()}
      onChange={(e) => {
        setBackgroundColor(Color.fromCssColorString(e.target.value));
      }}
    />
  );
}

export function ColorPanelContent({ setColorPanelOpen }) {
  return (
    <>
      <Box
        sx={{
          "&>*": {
            my: 2,
          },
        }}
      >
        <Typography
          variant="caption"
          display="block"
          align="left"
          color="primary"
        >
          常用颜色
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            "&>*:not(:last-child)": {
              mr: 2,
            },
          }}
        >
          {colorConfigs.map((colorConfig, index) => (
            <ColorSelection {...colorConfig} key={index}></ColorSelection>
          ))}
        </Box>
        <Typography
          variant="caption"
          display="block"
          align="left"
          color="primary"
        >
          自定义颜色
        </Typography>
        <ColorInput></ColorInput>
      </Box>
    </>
  );
}
