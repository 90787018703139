import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet } from "react-helmet";
import "gamecontroller.js";
import App from "./App";

import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";

import { createTheme, THEMES } from "./theme";

import { client } from "./gql";

//set access token
import { Ion, Primitive, Cesium3DTileset } from "cesium";
// let oldPrimitiveUpdate = Primitive.prototype.update;
// Primitive.prototype.update = function (frameState) {
//   this.appearance._renderState.depthTest.enabled = false;

//   oldPrimitiveUpdate.call(this, frameState);
// };

Ion.defaultAccessToken = process.env.REACT_APP_ION_ACCESS_TOKEN;

// 全局设置Cesium3DTileset
// let defaultCesium3DTilesetUpdate = Cesium3DTileset.prototype.update;
// Cesium3DTileset.prototype.update = function (frameState) {
//   //双面显示
//   this.backFaceCulling = false;
//   this.maximumMemoryUsage = Number(
//     process.env.REACT_APP_3DTILE_MAX_MEMORY_USAGE
//   );
//   // this.debugColorizeTiles = true;
//   defaultCesium3DTilesetUpdate.call(this, frameState);
// };

const theme = createTheme(THEMES.DEFAULT);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <CssBaseline />
        <SnackbarProvider
          maxSnack={3}
          preventDuplicate
          autoHideDuration={1500}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Helmet titleTemplate="源场景 - %s" defaultTitle="源场景"></Helmet>
          <App />
        </SnackbarProvider>
      </ApolloProvider>
    </BrowserRouter>
  </ThemeProvider>
);
