import { makeVar } from "@apollo/client";

const defaultValue = {
  pickPositionEvent: false, //点位测量事件
  pickedPosition: null,
};

export const pickPositionVar = makeVar(defaultValue);

export function setPickedPosition(pickedPosition) {
  if (pickPositionVar().pickedPosition !== pickedPosition) {
    pickPositionVar({
      ...pickPositionVar(),
      pickedPosition,
    });
  }
}

export function setPickPositionEvent(pickPositionEvent) {
  if (pickPositionVar().pickPositionEvent !== pickPositionEvent) {
    pickPositionVar({
      ...pickPositionVar(),
      pickPositionEvent,
    });
  }
}

export function resetPickPosition() {
  pickPositionVar(defaultValue);
}
