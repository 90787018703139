import React from "react";
import { useReactiveVar } from "@apollo/client";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import VideogameAssetOutlinedIcon from "@mui/icons-material/VideogameAssetOutlined";
import PanToolOutlinedIcon from "@mui/icons-material/PanToolOutlined";
import { cameraModeVar, setCameraMode } from "../../../../gql";

import { FpvPanel } from "./FpvPanel";

const iconMap = {
  normal: <VideogameAssetOutlinedIcon></VideogameAssetOutlinedIcon>,
  fpv: <PanToolOutlinedIcon></PanToolOutlinedIcon>,
};

const tooltipTitleMap = {
  normal: "切换为第一人称视角",
  fpv: "切换为鼠标操作",
};

export function CameraModeIconButton() {
  const { cameraMode, panel } = useReactiveVar(cameraModeVar);

  const toggleCameraMode = () => {
    if (cameraMode === "normal") {
      setCameraMode("fpv");
    } else {
      setCameraMode("normal");
    }
  };

  return (
    <>
      <Tooltip arrow title={tooltipTitleMap[cameraMode]} placement="top">
        <IconButton size="small" onClick={toggleCameraMode} color="white">
          {iconMap[cameraMode]}
        </IconButton>
      </Tooltip>

      {panel && <FpvPanel></FpvPanel>}
    </>
  );
}
