import { gql, useQuery } from "@apollo/client";

//fragments
const BIM_CONTENT_FIELDS = gql`
  fragment BimContentFields on BimContent {
    id
    name
    content {
      document(hydrateRelationships: true)
    }
  }
`;

//queries
const GET_BIM_CONTENT_BY_ID = gql`
  ${BIM_CONTENT_FIELDS}
  query getBimContent($where: BimContentWhereUniqueInput!) {
    bimContent(where: $where) {
      ...BimContentFields
    }
  }
`;

//queries
const GET_BIM_CONTENTS = gql`
  ${BIM_CONTENT_FIELDS}
  query getBimContents($where: BimContentWhereInput! = {}) {
    bimContents(where: $where) {
      ...BimContentFields
    }
  }
`;

//mutations

//hooks query
export function useBimContent(options) {
  return useQuery(GET_BIM_CONTENT_BY_ID, options);
}

export function useBimContents(options) {
  return useQuery(GET_BIM_CONTENTS, options);
}

//hooks mutation
