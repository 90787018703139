import React from "react";
import { useReactiveVar } from "@apollo/client";

import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";

//icons
import ExploreIcon from "@mui/icons-material/Explore";
import ExploreOffIcon from "@mui/icons-material/ExploreOff";

//local
import { compassVar, toggleCompass } from "../../../gql";

export function CompassMenuItem(props) {
  const compass = useReactiveVar(compassVar);

  return (
    <MenuItem onClick={toggleCompass}>
      <ListItemIcon>
        {compass.open ? <ExploreOffIcon /> : <ExploreIcon />}
      </ListItemIcon>
      {compass.open ? "关闭" : "开启"}罗盘
    </MenuItem>
  );
}
