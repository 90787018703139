import React from "react";
import { Navigate } from "react-router-dom";

import { useBMap } from "../gql";

import { BaiduMap } from "./BaiduMap";
import { MapItems } from "./MapItems";

export function BaiduMapFromId({ mapId, ...props }) {
  const { data } = useBMap({
    variables: {
      where: { id: mapId },
    },
  });
  return (
    data &&
    (data.bMap ? (
      <BaiduMap data={data.bMap} {...props}>
        <MapItems data={data.bMap}></MapItems>
      </BaiduMap>
    ) : (
      <Navigate to="/404" replace></Navigate>
    ))
  );
}
