import { Navigate, useSearchParams } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { LoginForm } from "../../components";
import logo from "../../assets/logos/logo.png";

import { userVar } from "../../gql";

export function SignInPage(props) {
  const [searchParams] = useSearchParams();

  const user = useReactiveVar(userVar);
  return user ? (
    <p>已登录</p>
  ) : (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: 4,
          px: {
            xs: 3,
            sm: 4,
            md: 5,
          },
          mx: 1,
        }}
      >
        <Box
          sx={{
            width: {
              xs: 120,
              sm: 150,
              md: 180,
            },
            mb: 2,
          }}
          component="img"
          src={logo}
          alt="源场景"
        ></Box>
        <Typography component="h5" variant="h5" align="center" gutterBottom>
          欢迎您！
        </Typography>
        <Typography
          component="p"
          variant="body1"
          align="center"
          sx={{
            mb: 2,
          }}
        >
          请登录您的账户以继续访问
        </Typography>
        <LoginForm redirectUrl={searchParams.get("redirect")}></LoginForm>
      </Paper>
    </Box>
  );
}
