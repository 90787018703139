import React from "react";
import { useReactiveVar } from "@apollo/client";

//cesium
import { Cesium3DTilesetGraphics, Entity, Cesium3DTileset } from "resium";
import { viewerSettingVar } from "../../gql";

// // const zoomOffsetTest = new HeadingPitchRange(0, Math.toRadians(-45), 9.3);
// export function TiledModelEntity({ url, setAsHome, zoom = false, ...props }) {
//   // const ref = React.useRef(null);
//   const viewerSetting = useReactiveVar(viewerSettingVar);
//   // const setHomeView = useSetHomeView();
//   const zoomTo = useZoomTo();
//   // const cameraProps = useCameraFromSearch();
//   // const { viewer } = useCesium();

//   // zoom属性第一次渲染该模型时zoomTo此模型
//   const ref = React.useRef(null);
//   const [initRender, setInitRender] = React.useState(true);
//   React.useEffect(() => {
//     if (ref.current && ref.current.cesiumElement && zoom && initRender) {
//       // console.log(ref.current.cesiumElement);
//       zoomTo(ref.current.cesiumElement);
//       setInitRender(false);
//     }
//   }, [zoom, zoomTo, initRender]);

//   return (
//     <Entity ref={ref} {...props}>
//       <Cesium3DTilesetGraphics
//         backFaceCulling={false}
//         uri={url}
//         maximumScreenSpaceError={viewerSetting.maximumScreenSpaceError}
//       />
//     </Entity>
//   );
// }

export function TiledModelEntity({ url, id, show }) {
  const viewerSetting = useReactiveVar(viewerSettingVar);

  return (
    <Entity id={id}>
      <Cesium3DTilesetGraphics
        uri={url}
        show={show}
        maximumScreenSpaceError={viewerSetting.maximumScreenSpaceError}
      />
    </Entity>
    // <Cesium3DTileset
    //   backFaceCulling={false}
    //   url={url}
    //   maximumScreenSpaceError={viewerSetting.maximumScreenSpaceError}
    //   show={show}
    // />
  );
}
export const MemoizedTiledModelEntity = React.memo(TiledModelEntity);
