import React from "react";
import { useReactiveVar } from "@apollo/client";

//cesium
import { Cesium3DTileset } from "resium";

import { useZoomTo, useCameraFromSearch, useSetHomeView } from "../../hooks";
import { viewerSettingVar } from "../../gql";

export function TiledModel({ url, setAsHome, zoom = false, ...props }) {
  // const ref = React.useRef(null);
  const viewerSetting = useReactiveVar(viewerSettingVar);
  const setHomeView = useSetHomeView();
  const zoomTo = useZoomTo();
  const cameraProps = useCameraFromSearch();
  return (
    <Cesium3DTileset
      backFaceCulling={false}
      url={url}
      maximumScreenSpaceError={viewerSetting.maximumScreenSpaceError}
      onReady={(model) => {
        if (setAsHome) {
          // 仅在无相机初始searchParam存在时才生效将此模型设为HomeView，否则以相机初始视角参数为主
          if (!cameraProps) {
            zoomTo(model).then(() => {
              //未设置参数时，根据加载的model设置Home视角
              setHomeView();
            });
          }
        } else {
          if (zoom) {
            zoomTo(model);
          }
        }
      }}
      {...props}
    />
  );
}
