import React from "react";

import { ScreenSpaceEvent, useCesium } from "resium";
import { ScreenSpaceEventType, defined } from "cesium";

import { getDistanceByMatrix4, getMiddlePoint } from "../../../utils";

import { ToolResultPanel } from "../../panel";
import { DistanceLine, PositionLabel, PositionBillBoard } from "../../geometry";

export function DistanceToolEvent(props) {
  const { viewer } = useCesium();
  const [points, setPoints] = React.useState([]);

  //修改控制测量点Array的state函数
  const addPoint = (p) => {
    if (points.length === 2) {
      setPoints([p]);
    } else {
      setPoints((state) => [...state, p]);
    }
  };

  //点数达到2个时，计算距离
  const distance = points.length === 2 ? getDistanceByMatrix4(...points) : null;

  return (
    <>
      {/* 事件定义 左键点击后修改state*/}
      <ScreenSpaceEvent
        action={(event) => {
          try {
            const cartesian = viewer.scene.pickPosition(event.position);
            if (defined(cartesian)) {
              addPoint(cartesian);
            }
          } catch (e) {
            console.log(e);
          }
        }}
        type={ScreenSpaceEventType.LEFT_CLICK}
      ></ScreenSpaceEvent>

      {/* 渲染测量的起点和终点 */}
      {points.map((position, index) => (
        <PositionBillBoard
          position={position}
          key={index}
          iconVarient="place"
          color="RED"
        ></PositionBillBoard>
      ))}

      {distance && (
        <>
          {/* 渲染polyline,遮挡部分使用虚线 */}
          <DistanceLine positions={points}></DistanceLine>

          {/* 在线段重点渲染一个label描述距离测量结果 */}
          <PositionLabel
            position={getMiddlePoint(...points)}
            text={`总距离: ${distance.distance}米, 水平距离: ${distance.horizontalDistance}米, 高差: ${distance.verticalDistance}米`}
            offsetY={-10}
          ></PositionLabel>

          {/* 显示结果面板 */}
          <ToolResultPanel
            panelTitle="测量结果"
            toolName="distanceTool"
            data={distance}
            dataKeys={[
              {
                title: "总距离",
                name: "distance",
              },
              {
                title: "水平距离",
                name: "horizontalDistance",
              },
              {
                title: "高差",
                name: "verticalDistance",
              },
              {
                title: "北向距离",
                name: "northDistance",
              },
              {
                title: "东向距离",
                name: "eastDistance",
              },
            ]}
            dataUnit="米"
            copyText={`总距离: ${distance.distance}米, 水平距离: ${distance.horizontalDistance}米, 高差: ${distance.verticalDistance}米, 北向距离: ${distance.northDistance}米, 东向距离: ${distance.eastDistance}米`}
          ></ToolResultPanel>
        </>
      )}
    </>
  );
}
