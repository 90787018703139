import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  GridToolbarFilterButton,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
  useGridApiContext,
  gridFilteredSortedRowIdsSelector,
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

//icon
import CloseIcon from "@mui/icons-material/Close";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  useToggleChildrenContext,
  useCommentRenderMapContext,
} from "../../../hooks";

// 关闭评论面板按钮
function CloseButton() {
  const toggleOpen = useToggleChildrenContext();
  return (
    <Tooltip title="关闭" placement="bottom">
      <CloseIcon
        onClick={toggleOpen}
        color="primary"
        sx={{
          fontSize: 20,
          cursor: "pointer",
        }}
      ></CloseIcon>
    </Tooltip>
  );
}

// 展开每行到最大高度的按钮
function ExpandViewButton({ expandHeight, setExpandHeight }) {
  return expandHeight ? (
    <Tooltip title="缩小" placement="bottom">
      <KeyboardArrowDownIcon
        color="primary"
        onClick={() => {
          setExpandHeight(false);
        }}
        sx={{
          ml: "auto",
          mr: 1,
          fontSize: 20,
          cursor: "pointer",
        }}
      ></KeyboardArrowDownIcon>
    </Tooltip>
  ) : (
    <Tooltip title="扩大" placement="bottom">
      <KeyboardArrowUpIcon
        color="primary"
        onClick={() => {
          setExpandHeight(true);
        }}
        sx={{
          ml: "auto",
          mr: 1,
          fontSize: 20,
          cursor: "pointer",
        }}
      />
    </Tooltip>
  );
}

// 行展开按钮
function RowExpandButton({ autoRowHeight, setAutoRowHeight }) {
  return autoRowHeight ? (
    <Button
      variant="text"
      startIcon={<UnfoldLessIcon />}
      onClick={() => {
        setAutoRowHeight(false);
      }}
    >
      行收起
    </Button>
  ) : (
    <Button
      variant="text"
      startIcon={<UnfoldMoreIcon />}
      onClick={() => {
        setAutoRowHeight(true);
      }}
    >
      行展开
    </Button>
  );
}

// 开关全局评论渲染按钮
function ToggleCommentRender() {
  const { showAllComments, setShowAllComments } = useCommentRenderMapContext();
  const handleChange = React.useCallback((event) => {
    setShowAllComments(event.target.checked);
  }, []);

  return (
    <FormControlLabel
      control={<Switch checked={showAllComments} onChange={handleChange} />}
      label="渲染评论"
    />
  );
}

export function CustomToolbar({
  autoRowHeight,
  setAutoRowHeight,
  expandHeight,
  setExpandHeight,
  ...props
}) {
  //pro feature test
  // const apiRef = useGridApiContext();
  // apiRef.current.subscribeEvent(
  //   "filterModelChange",
  //   (params, event, details) => {
  //     console.log({
  //       params,
  //       event,
  //       details, // GridCallbackDetails
  //     });

  //     console.log("state", gridFilteredSortedRowIdsSelector(apiRef));
  //   }
  // );

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const csvFileName = document.title + "-评论列表";
  return (
    <GridToolbarContainer>
      <ToggleCommentRender />

      {/* md以下减少部分按钮，简化UI  */}
      {mdUp && <GridToolbarQuickFilter />}

      {smUp && (
        <>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport
            csvOptions={{
              fileName: csvFileName,
              utf8WithBom: true,
            }}
          />
          <RowExpandButton
            autoRowHeight={autoRowHeight}
            setAutoRowHeight={setAutoRowHeight}
          ></RowExpandButton>
        </>
      )}

      <ExpandViewButton
        expandHeight={expandHeight}
        setExpandHeight={setExpandHeight}
      ></ExpandViewButton>
      <CloseButton></CloseButton>
    </GridToolbarContainer>
  );
}
