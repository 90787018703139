import { makeVar } from "@apollo/client";
import { debounce } from "lodash";
const defaultValue = null;

export const hoverEntityIdVar = makeVar(defaultValue);

function setHoverEntityIdRaw(id) {
  if (hoverEntityIdVar() !== id) {
    // console.log(id);
    hoverEntityIdVar(id);
  }
}

export const setHoverEntityId = debounce(setHoverEntityIdRaw, 100, {
  leading: true,
});
