import React from "react";
import Box from "@mui/material/Box";

import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";

//local
import { ToggleChildrenButton, ToolMenuBar } from "../../../components";

export function ToolMenuButton() {
  return (
    <ToggleChildrenButton
      initOpen={false}
      icon={<BuildOutlinedIcon></BuildOutlinedIcon>}
      tooltipProps={{
        title: "工具栏",
        placement: "top",
      }}
      slideDirection="down"
      draggableProps={{
        handle: "#draggable-toolmenu",
      }}
    >
      <Box
        aria-labelledby="draggable-toolmenu"
        sx={{
          position: "fixed",
          top: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          zIndex: "toolBarButton",
        }}
      >
        <ToolMenuBar></ToolMenuBar>
      </Box>
    </ToggleChildrenButton>
  );
}
