import React from "react";
import { useReactiveVar } from "@apollo/client";
import { Link, useLocation } from "react-router-dom";

import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
//icons
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LoginIcon from "@mui/icons-material/Login";

//local
import { userVar } from "../../gql";
import { AccountMenu } from "./AccountMenu";
import { useMenu } from "./../../hooks";

export function AccountIconButton(props) {
  const user = useReactiveVar(userVar);
  const location = useLocation();
  let { anchorEl, handleClick, handleClose } = useMenu();

  return (
    <>
      {user ? (
        <>
          {/* <Avatar sx={{ width: 32, height: 32 }}>{user.name[0]}</Avatar> */}

          <Tooltip arrow title="我的" placement="top">
            <IconButton onClick={handleClick} color="white">
              <AccountCircleIcon></AccountCircleIcon>
            </IconButton>
          </Tooltip>

          <AccountMenu
            user={user}
            id="account-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={handleClose}
            anchorOrigin={{
              horizontal: "center",
              vertical: "top",
            }}
            transformOrigin={{
              horizontal: "center",
              vertical: "bottom",
            }}
            sx={{
              zIndex: (theme) => theme.zIndex.contextMenu,
            }}
          ></AccountMenu>
        </>
      ) : (
        <Tooltip arrow title="登录" placement="top">
          <IconButton
            onClick={handleClick}
            color="white"
            component={Link}
            to={`/auth/sign-in?redirect=${location.pathname}`}
          >
            <LoginIcon></LoginIcon>
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}
