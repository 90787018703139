import React from "react";

import {
  CustomViewer,
  CustomScene,
  CustomCamera,
  ScreenEvents,
  SetCameraViewFromScene,
  SceneRenderer,
  DsHotspots,
  BottomMenu,
} from "../../components";
import { useCameraFromSearch, SceneContext } from "../../hooks";
import {
  setSceneOrigin,
  clearSceneOrigin,
  setEnableDepthTest,
} from "../../gql";
import { createPosition } from "../../utils";

import * as config from "./config";

export function DigitalSceneViewer({
  children,
  initRenderMap,
  initRenderTypeMap,
  scene,
  assets,
  // 控制viewer渲染时的行为，是否自动飞行至默认home视角，是否将默认视角设为home视角
  goToSceneDefaultView,
  setSceneDefaultViewAsHome,
  // 控制视角search参数行为
  gotoCameraSearch,
  setCameraSearchAsHome,
  viewerProps,
  sceneProps,
  // //控制底部菜单栏的内容
  // bottomMenuProps,
}) {
  const cameraProps = useCameraFromSearch();
  //数字资产开关显示state
  const [renderMap, setRenderMap] = React.useState(initRenderMap);

  //数字场景下首次加载关闭深度检测，避免代理几何遮挡
  React.useEffect(() => {
    setEnableDepthTest(false);
  }, []);

  //当initRenderMap变化时，必须同步state，主要在多数字场景切换时发挥作用
  React.useEffect(() => {
    setRenderMap(initRenderMap);
  }, [initRenderMap]);

  //开关一个数字资产是否render的函数
  const toggleAssetRenderStatus = (id) => {
    setRenderMap((state) => {
      return {
        ...state,
        [id]: !state[id],
      };
    });
  };

  //开关一类数字资产的state,如果没有传入initRenderTypeMap，则使用config中的initRenderTypeMap
  const [renderTypeMap, setRenderTypeMap] = React.useState(
    initRenderTypeMap || config.initRenderTypeMap
  );

  //开关一类数字资产是否render的函数
  const toggleTypeRenderStatus = (type) => {
    setRenderTypeMap((state) => {
      return {
        ...state,
        [type]: !state[type],
      };
    });
  };

  //设置场景原点，卸载后清除场景原点
  React.useEffect(() => {
    try {
      const origin = createPosition(scene.origin);
      setSceneOrigin(origin);
    } catch (error) {
      console.log("set scene origin error", error);
    }
    return clearSceneOrigin;
  }, [scene.origin]);

  return (
    <SceneContext.Provider
      value={{
        scene,
        assets,
        config,
        renderMap,
        renderTypeMap,
        toggleTypeRenderStatus,
        toggleAssetRenderStatus,
      }}
    >
      <CustomViewer shouldAnimate={true} {...viewerProps}>
        <CustomScene {...sceneProps}></CustomScene>
        <CustomCamera
          // camera会自动控制读取searchParam参数
          gotoCameraSearch={gotoCameraSearch}
          setCameraSearchAsHome={setCameraSearchAsHome}
        ></CustomCamera>

        {/* 初始视角控 后端设置了默认视角，且无用户自定义视角参数时，渲染该组件，实现初始视角并设置为home */}
        {scene.defaultCameraView && !cameraProps && (
          <SetCameraViewFromScene
            goToSceneDefaultView={goToSceneDefaultView}
            setSceneDefaultViewAsHome={setSceneDefaultViewAsHome}
          ></SetCameraViewFromScene>
        )}

        {/* 渲染数字资产 */}
        <SceneRenderer></SceneRenderer>

        {/* Cesium全局事件 */}
        <ScreenEvents enableHoverEvent></ScreenEvents>

        {/* 渲染数字场景热点 */}
        <DsHotspots></DsHotspots>

        {/* <BottomMenu {...bottomMenuProps}></BottomMenu> */}
        {children}
      </CustomViewer>
    </SceneContext.Provider>
  );
}
