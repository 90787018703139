import React from "react";

import { ScreenSpaceEvent, useCesium } from "resium";
import {
  ScreenSpaceEventType,
  defined,
  Cartesian3,
  Cartographic,
} from "cesium";

import { dispatchDrawingToolVar } from "../drawingToolVars";

import {
  cartesian3ToRadianArray,
  useClearEditDataAfterUnmount,
} from "../utils";

// 适用于设置单一position的事件
export function DrawingPositionEvent({ drawingType, ...props }) {
  const { viewer } = useCesium();

  // 切换绘图工具时，unmount此事件后，同时清除editData数据
  // useClearEditDataAfterUnmount();

  return (
    <ScreenSpaceEvent
      action={(event) => {
        try {
          const cartesian = viewer.scene.pickPosition(event.position);

          if (defined(cartesian)) {
            const action = {
              type: "SET_EDIT_GEOMETRY",
              payload: {
                type: drawingType,
                position: cartesian3ToRadianArray(cartesian),
              },
            };
            dispatchDrawingToolVar(action);
          }
        } catch (e) {
          console.log(e);
        }
      }}
      type={ScreenSpaceEventType.LEFT_CLICK}
    ></ScreenSpaceEvent>
  );
}
