import { makeVar } from "@apollo/client";
import { Primitive } from "cesium";

const defaultPrimitiveUpdate = Primitive.prototype.update;

const disableDepthTestPrimitiveUpdate = function (frameState) {
  this.appearance._renderState.depthTest.enabled = false;

  defaultPrimitiveUpdate.call(this, frameState);
};

const defaultValue = {
  enableDepthTest: true, //深度检测管理
};
// Primitive.prototype.update = disableDepthTestPrimitiveUpdate;

//控制场景代理的 Geometry 深度检测状态
export const depthTestVar = makeVar(defaultValue);

//控制切换深度检测状态
export function setEnableDepthTest(enableDepthTest) {
  if (depthTestVar().enableDepthTest !== enableDepthTest) {
    if (!enableDepthTest) {
      Primitive.prototype.update = disableDepthTestPrimitiveUpdate;
    } else {
      Primitive.prototype.update = defaultPrimitiveUpdate;
    }
    depthTestVar({
      enableDepthTest,
    });
  }
}
