import React from "react";

import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

export function UsernameCellRender({ params }) {
  const username = params.value;
  return (
    <>
      <Avatar
        sx={{
          width: 32,
          height: 32,
          fontSize: 20,
          mr: 1.5,
          bgcolor: "primary.main",
          textTransform: "capitalize",
        }}
      >
        {username.slice(0, 1)}
      </Avatar>
      <Typography variant="h5" color="initial" component="p">
        {username}
      </Typography>
    </>
  );
}
