import React from "react";
import { useReactiveVar } from "@apollo/client";

import { useDropzone } from "react-dropzone";

import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";

import {
  useUpdateComment,
  pickPositionVar,
  useDeleteUserUploadImages,
} from "../../../gql";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import {
  StringField,
  FileDropField,
  PositionPickField,
  TagSelectField,
  SelectFileToDeleteField,
} from "../..";

import { MutateCommentHeader } from "./MutateCommentHeader";
import { WaitingProgress } from "./WaitingProgress";

//config
const maxFileZize = 30; //最大照片尺寸(MB)
const maxFilesCount = 10; //最大照片张数

const validationSchema = yup.object({
  text: yup
    .string()
    .max(2048, "评论不超过2048字符")
    .required("评论内容不能为空"),
});

export function UpdateCommentForm({ comment, handleClose }) {
  const { pickedPosition } = useReactiveVar(pickPositionVar);

  //dropzone
  const [files, setFiles] = React.useState([]);
  const [showErrorText, setShowErrorText] = React.useState(false);
  const onDrop = React.useCallback((acceptedFiles) => {
    setFiles((files) => {
      const filesWithPreview = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      return [...files, ...filesWithPreview].slice(0, maxFilesCount);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    onDrop,
    onDropRejected: () => {
      setShowErrorText(true);
    },
    maxSize: maxFileZize * 1024 * 1024,
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
    },
  });

  //选取标准状态;tagId[]
  const [tags, setTags] = React.useState(comment.tags.map(({ id }) => id));

  const { enqueueSnackbar } = useSnackbar();

  //mutation
  const [updateCommentMutation, { loading }] = useUpdateComment({
    refetchQueries: ["getComments"],
  });

  // 删除照片Mutation
  const [imageIdsToDelete, setImageIdsToDelete] = React.useState([]);
  const [deleteUserUploadImages, { loading: deleteLoading }] =
    useDeleteUserUploadImages({});

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    isSubmitting,
    handleReset,
  } = useFormik({
    initialValues: {
      text: comment.text,
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!pickedPosition) {
        enqueueSnackbar("必须选取评论坐标", {
          variant: "error",
          key: "create-comments-error-noposition",
        });
        return;
      }
      try {
        //删除图片
        if (imageIdsToDelete.length > 0) {
          const deleteImagesRes = await deleteUserUploadImages({
            variables: { where: imageIdsToDelete.map((id) => ({ id })) },
          });
        }

        const res = await updateCommentMutation({
          variables: {
            where: { id: comment.id },
            data: {
              ...values,
              position: pickedPosition,
              images: {
                create: files.map((file) => ({
                  image: {
                    upload: file,
                  },
                })),
              },
              tags: {
                set: tags.map((id) => ({
                  id,
                })),
              },
            },
          },
        });
        if (res.errors) {
          enqueueSnackbar("编辑评论失败errors", {
            variant: "error",
            key: "update-comments-error",
          });
        } else {
          enqueueSnackbar("成功编辑评论", {
            variant: "success",
            key: "update-comments-sucess",
          });
          handleClose();
        }
      } catch (error) {
        enqueueSnackbar("编辑评论失败", {
          variant: "error",
          key: "update-comments-error",
        });
      }
    },
  });

  const formHandlers = {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
  };

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        pb: 2,
      }}
    >
      <form onSubmit={handleSubmit} noValidate>
        {/* 表单标题 */}
        <MutateCommentHeader actionText="编辑评论:"></MutateCommentHeader>

        <Box
          sx={{
            width: 1,
            mx: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "stretch",
            "&>*:not(:last-child)": {
              mb: 2,
            },
          }}
        >
          {/* 现有图片删除选择框 */}
          <SelectFileToDeleteField
            images={comment.images}
            imageIdsToDelete={imageIdsToDelete}
            setImageIdsToDelete={setImageIdsToDelete}
          ></SelectFileToDeleteField>
          {/* 新增图片上传框 */}
          <FileDropField
            setFiles={setFiles}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            files={files}
            initText="新增评论配图：拖拽或点击上传（可选）"
            helperText={`最多新增上传10张配图,且图片大小不超过${maxFileZize}MB`}
          ></FileDropField>
          {(files.length > 9 || showErrorText) && (
            <Typography
              variant="subtitle"
              display="block"
              align="center"
              color="error"
            >
              最多新增上传10张配图,且图片大小不超过{maxFileZize}MB
            </Typography>
          )}

          <PositionPickField
            initPosition={comment.position}
          ></PositionPickField>

          <TagSelectField tags={tags} setTags={setTags}></TagSelectField>

          <StringField
            {...formHandlers}
            fieldName="text"
            labelText="评论内容"
            helperText=""
            required
            multiline
            rows={3}
            sx={{
              "& .MuiInputBase-multiline": {
                maxHeight: 500,
                fontSize: 16,
                lineHeight: 1.8,
                "& textarea": {
                  maxHeight: 480,
                  resize: "vertical", //底层输入框可以竖向扩展
                },
              },
            }}
          ></StringField>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              onClick={handleClose}
              disabled={loading || deleteLoading}
            >
              取消编辑
            </Button>
            <Button
              color="info"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              编辑评论
            </Button>
          </Box>
        </Box>
      </form>
      {(loading || deleteLoading) && (
        <WaitingProgress
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
          }}
        ></WaitingProgress>
      )}
    </Box>
  );
}
