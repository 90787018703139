import React from "react";
import Typography from "@mui/material/Typography";

import { ToggleSourceObjectAction } from "./ToggleSourceObjectAction";
import { FlyToAction } from "./FlyToAction";
import { OpenContentDialogAction } from "./OpenContentDialogAction";
//util functions
function getCreatedAt({ value }) {
  return new Date(value);
}

function getFormattedDate(date) {
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return year + "-" + month + "-" + day;
}

//通用设置
const commonColumnConfig = {
  hideable: true,
  minWidth: 140,
  flex: 1,
};

// column def
export function createColumns({ metasMap }) {
  const metaValueOptions = Object.values(metasMap).map((meta) => {
    return meta.name;
  });

  return [
    {
      ...commonColumnConfig,
      flex: 0,
      field: "flyAction",
      type: "actions",
      headerName: "常用操作",

      getActions: (params) => [
        <ToggleSourceObjectAction params={params}></ToggleSourceObjectAction>,
        <FlyToAction params={params}></FlyToAction>,
      ],
    },
    {
      ...commonColumnConfig,
      field: "id",
      headerName: "ID",
    },
    {
      ...commonColumnConfig,
      field: "name",
      headerName: "名称",
    },

    {
      ...commonColumnConfig,
      field: "meta",
      headerName: "类型",
      valueGetter: (params) => {
        return metasMap[params.value.id].name;
      },

      renderCell: (params) => {
        return (
          <Typography variant="caption" color="primary">
            {params.value}
          </Typography>
        );
      },
      type: "singleSelect",

      valueOptions: metaValueOptions,
    },
    {
      ...commonColumnConfig,
      field: "createdAt",
      headerName: "创建时间",
      type: "dateTime",

      valueGetter: getCreatedAt,
      valueFormatter: (params) => {
        return getFormattedDate(params.value);
      },
    },
    {
      ...commonColumnConfig,
      field: "openContentAction",
      flex: 0,
      type: "actions",
      headerName: "查看内容",
      getActions: (params) => [
        <OpenContentDialogAction params={params}></OpenContentDialogAction>,
      ],
    },
  ];
}
//初始化列设置
export const columnsInitialState = {
  columnVisibilityModel: {
    // Hide columns status and traderName, the other columns will remain visible
    id: false,
    // createdAt: false,
  },
};
