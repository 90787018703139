import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Portal from "@mui/base/Portal";
import Slide from "@mui/material/Slide";
import Draggable from "react-draggable";

import { ToggleChildrenContext } from "../../../hooks";
import { setFabPosition, setFabToDefault } from "../../../gql";

export function ToggleChildrenButton({
  initOpen,
  icon,
  children,
  slideDirection,
  tooltipProps,
  draggableProps,
  fabPosition, //传入此prop以在面板开启后调整fab位置
}) {
  const [open, setOpen] = React.useState(initOpen);

  const toggleOpen = React.useCallback(() => {
    setOpen((state) => !state);
  }, [setOpen]);

  React.useEffect(() => {
    if (fabPosition) {
      // 开启时
      if (open) {
        setFabPosition(fabPosition);
      } else {
        setFabToDefault();
      }
    }
  }, [open, fabPosition]);

  return (
    <ToggleChildrenContext.Provider value={toggleOpen}>
      <Tooltip arrow {...tooltipProps}>
        <IconButton
          size="small"
          onClick={toggleOpen}
          color={open ? "white" : "default"}
        >
          {icon}
        </IconButton>
      </Tooltip>

      <Portal>
        {draggableProps ? (
          <Draggable {...draggableProps}>
            <Slide direction={slideDirection} in={open}>
              {children}
            </Slide>
          </Draggable>
        ) : (
          <Slide direction={slideDirection} in={open}>
            {children}
          </Slide>
        )}

        {/* <Draggable {...draggableProps}>
          <Slide direction={slideDirection} in={open}>
            {children}
          </Slide>
        </Draggable> */}
      </Portal>
    </ToggleChildrenContext.Provider>
  );
}
