import { makeVar } from "@apollo/client";

export const minMoveRate = 1;
export const maxMoveRate = 12;

const defaultValue = {
  cameraMode: "normal", // normal | fpv
  moveRate: 7, //64
  panel: false,
  fpvMode: "free", // free | ground
  lookFactor: 0.05, //视角旋转速度,一般不需要调
};

export const cameraModeVar = makeVar(defaultValue);

export function setCameraMode(cameraMode) {
  if (cameraModeVar().cameraMode !== cameraMode) {
    cameraModeVar({
      ...cameraModeVar(),
      cameraMode,
      panel: cameraMode === "fpv" ? true : false,
    });
  }
}
export function setFpvMode(fpvMode) {
  if (cameraModeVar().fpvMode !== fpvMode) {
    cameraModeVar({
      ...cameraModeVar(),
      fpvMode,
    });
  }
}

export function setFpvMoveRate(moveRate) {
  cameraModeVar({
    ...cameraModeVar(),
    moveRate,
  });
}

export function changeFpvMoveRate(value) {
  if (
    cameraModeVar().moveRate + value > maxMoveRate ||
    cameraModeVar().moveRate + value < minMoveRate
  ) {
    return;
  } else {
    cameraModeVar({
      ...cameraModeVar(),
      moveRate: cameraModeVar().moveRate + value,
    });
  }
}

// export const changeFpvMoveRate = debounce(changeFpvMoveRateRaw, 1000, {
//   leading: true,
// });

export function closeFpvPanel() {
  cameraModeVar({
    ...cameraModeVar(),
    panel: false,
  });
}
