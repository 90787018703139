//gen-by-chatgpt 在这个函数中，我们首先创建一个 Date 对象，将传入的参数 dateTimeStr 转换成日期对象。然后，我们分别使用 Date 对象提供的方法获取年、月、日、小时和分钟等信息，并对它们进行格式化，最后返回格式化后的时间字符串。
export function formatDateTime(dateTimeStr) {
  const date = new Date(dateTimeStr);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day} ${hour}:${minute}`;
}

//gen-by-chatgpt
export function downloadFile(url, filename) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const a = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => console.error(error));
}
