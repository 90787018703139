import React from "react";
import { useReactiveVar } from "@apollo/client";
import { useSnackbar } from "notistack";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { useDrawingContext } from "../../../hooks";

import { useCreateSourceObject } from "../../../gql";
import {
  drawingToolDataVar,
  dispatchDrawingToolVar,
} from "../../../components/drawingTool";
import { SourceObjectEditHeader } from "./SourceObjectEditHeader";

import { CustomForm } from "../../../schemaForms";

export function SourceObjectCreateForm(props) {
  const [formData, setFormData] = React.useState({});

  const { data: geometry } = useReactiveVar(drawingToolDataVar);

  const { enqueueSnackbar } = useSnackbar();
  const drawing = useDrawingContext();

  // const schema = drawing.supportedMetas[0].jsonSchema.schema;

  React.useEffect(() => {
    const nameInput = document.querySelector(".source-object-name-input input");
    nameInput.addEventListener("keypress", (e) => {
      e.stopPropagation(); //避免和绘图快捷键冲突
    });
  }, []);

  const metaChoices = drawing.supportedMetas.map(
    (meta) => {
      return {
        type: "string",
        title: meta.name,
        enum: [meta.id],
      };
    },
    [drawing.supportedMetas]
  );

  const { schema, uiSchema } = React.useMemo(() => {
    return {
      schema: {
        type: "object",
        required: ["meta", "name"],
        properties: {
          meta: {
            title: "源对象类型",
            type: "string",
            anyOf: metaChoices,
          },
          name: {
            type: "string",
            title: "对象名称",
          },
        },
      },
      uiSchema: {
        name: {
          "ui:classNames": "source-object-name-input",
        },
      },
    };
  }, [metaChoices]);

  const [createSourceObjectMutation, { loading }] = useCreateSourceObject({
    refetchQueries: ["SourceObjects"],
  });

  const handleSubmit = React.useCallback(
    async (e) => {
      if (geometry === null || geometry.length === 0) {
        enqueueSnackbar("未绘制源对象的几何", {
          variant: "error",
          key: "create-source-object-error",
        });
        return;
      }

      try {
        const res = await createSourceObjectMutation({
          variables: {
            data: {
              name: e.formData.name,
              meta: {
                connect: {
                  id: e.formData.meta,
                },
              },
              drawing: {
                connect: {
                  id: drawing.id,
                },
              },
              geometry,
            },
          },
        });
        if (res.errors) {
          enqueueSnackbar("创建源对象失败", {
            variant: "error",
            key: "create-source-object-error",
          });
        } else {
          enqueueSnackbar("成功创建源对象", {
            variant: "success",
            key: "create-source-object-sucess",
          });

          setFormData({});
          dispatchDrawingToolVar({
            type: "SET_TO_DEFAULT",
          });
        }
      } catch (error) {
        enqueueSnackbar("创建源对象失败", {
          variant: "error",
          key: "create-source-object-error",
        });
      }
    },
    [createSourceObjectMutation, enqueueSnackbar, drawing.id, geometry]
  );

  return (
    <>
      <SourceObjectEditHeader></SourceObjectEditHeader>
      <CustomForm
        formData={formData}
        onChange={(e) => setFormData(e.formData)}
        showErrorList={false}
        schema={schema}
        uiSchema={uiSchema}
        onSubmit={handleSubmit}
      >
        <Button
          size="small"
          type="submit"
          variant="contained"
          disabled={loading}
          sx={{
            my: 2,
          }}
        >
          创建源对象
        </Button>
      </CustomForm>
    </>
  );
}
