import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

//icon
import QueueIcon from "@mui/icons-material/Queue";

export function SourceObjectEditHeader({ editMode = false }) {
  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        textTransform: "capitalize",
        mb: 1,
      }}
    >
      <QueueIcon
        color="primary"
        sx={{
          mr: 1,
        }}
      ></QueueIcon>

      <Typography
        variant="h5"
        color="initial"
        component="p"
        sx={{
          fontWeight: 700,
        }}
      >
        {editMode ? "编辑源对象" : "创建源对象"}
      </Typography>
    </Box>
  );
}
