import { makeVar } from "@apollo/client";

const defaultValue = {
  useOverlayIframe: false,
  overlayContainerProps: { width: 480, height: 270 }, //默认overlay尺寸
};

export const overlayIframeVar = makeVar(defaultValue);

export function setOverlayIframeVar(useOverlayIframe) {
  if (useOverlayIframe !== overlayIframeVar().useOverlayIframe) {
    overlayIframeVar({
      ...overlayIframeVar(),
      useOverlayIframe,
    });
  }
}
