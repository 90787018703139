import React from "react";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

import { Preview } from "./Preview";
import { useCreateUserUploadImages, useCreateUserUploadFiles } from "../../gql";

export function DropZoneUploadField(props) {
  // const [filesToDelete, setFilesToDelete] = React.useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const {
    schema,
    uiSchema,
    idSchema,
    formData,
    errorSchema,
    onChange,
    onBlur,
    onFocus,
    disabled,
    readonly,
    required,
    name,
    rawErrors,
  } = props;
  // console.log({
  //   schema,
  //   uiSchema,
  //   idSchema,
  //   formData,
  //   errorSchema,
  //   onChange,
  //   onBlur,
  //   onFocus,
  //   disabled,
  //   readonly,
  //   required,
  //   name,
  // });
  const options = uiSchema["ui:options"];
  // const [files, setFiles] = React.useState([]);
  // console.log(files);

  const [createUserUploadImagesMutation, { loading: uploadImageLoading }] =
    useCreateUserUploadImages({});

  const [createUserUploadFilesMutation, { loading: uploadFileLoading }] =
    useCreateUserUploadFiles({});

  const onDrop = async (acceptedFiles) => {
    console.log({ acceptedFiles });

    if (acceptedFiles.length === 0) {
      return;
    }
    //拖入后直接上传
    try {
      let res;
      if (options.fileType === "image") {
        res = await createUserUploadImagesMutation({
          variables: {
            data: acceptedFiles.map((file) => ({
              image: {
                upload: file,
              },
              altText: file.name,
              identifier: options.identifier || "rjsf-DropZoneUploadField",
            })),
          },
        });
      }

      if (options.fileType === "file") {
        res = await createUserUploadFilesMutation({
          variables: {
            data: acceptedFiles.map((file) => ({
              file: {
                upload: file,
              },
              altText: file.name,
              identifier: options.identifier || "rjsf-DropZoneUploadField",
            })),
          },
        });
      }

      if (res.errors) {
        enqueueSnackbar("文件上传失败", {
          variant: "error",
          key: "upload-file-error",
        });
      } else {
        enqueueSnackbar("文件上传成功", {
          variant: "success",
          key: "upload-file-error",
        });

        //上传成功后更新表单字段
        let data = [];
        if (options.fileType === "image") {
          data = res.data.createUserUploadImages.map((image) => ({
            id: image.id,
            name: image.altText,
            url: image.image.url,
          }));
        }

        if (options.fileType === "file") {
          data = res.data.createUserUploadFiles.map((file) => ({
            id: file.id,
            name: file.altText,
            url: file.file.url,
          }));
        }
        if (options.multiple) {
          onChange([...formData, ...data]);
        } else {
          onChange(data);
        }
      }
    } catch (error) {
      enqueueSnackbar("文件上传失败", {
        variant: "error",
        key: "upload-file-error",
      });
    }
  };

  //计算接受的文件类型，图片类型默认只接受jpg jpeg png
  const accept = React.useMemo(() => {
    return options.fileType === "image"
      ? {
          "image/jpeg": [".jpeg", ".jpg"],
          "image/png": [".png"],
        }
      : options.accept.length > 0
      ? {
          "application/octet-stream": options.accept,
        }
      : undefined;
  }, [options]);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: options.multiple,
    onDrop,
    onDropRejected: (rejectedFiles) => {
      console.log({ rejectedFiles });
      const msg = rejectedFiles.map(({ file, error }) => file.name).join(", ");
      enqueueSnackbar(msg + " 文件类型或大小不符合上传要求", {
        variant: "error",
        key: "choose-file-error",
      });
    },
    maxSize: options.maxFileZize
      ? options.maxFileZize * 1024 * 1024
      : undefined,
    accept,
  });

  return (
    <>
      <Box
        sx={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          minHeight: 120,
          borderColor: "primary.main",
          bgcolor: "background.alphaPrimary",
          borderWidth: 2,
          borderStyle: "dashed",
          p: 1,
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Typography variant="button" display="block" align="center">
          {schema.title}
        </Typography>
        <Typography
          variant="caption"
          align="center"
          sx={{
            color: "grey.600",
            mb: 2,
          }}
        >
          {schema.description}{" "}
        </Typography>

        <Preview
          formData={formData}
          onChange={onChange}
          fileType={options.fileType}
        ></Preview>

        <Typography variant="caption" align="center" color="primary">
          {uploadImageLoading || uploadFileLoading
            ? "上传中..."
            : "文件选中或拖入后会自动上传" +
              (options.maxFileZize
                ? ` (单文件大小不超过${options.maxFileZize}MB)`
                : "")}
        </Typography>
        <Typography variant="caption" align="center" color="primary">
          {options.fileType !== "image" &&
            options.accept.length > 0 &&
            ` (支持${options.accept.join(", ")})`}
        </Typography>
        {(uploadImageLoading || uploadFileLoading) && (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
            }}
          >
            <LinearProgress />
          </Box>
        )}
      </Box>
    </>
  );
}
