import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import DeleteIcon from "@mui/icons-material/Delete";

function ImagePreview({ id, url, name, deleteFile }) {
  return (
    <Box
      sx={{
        position: "relative",
        width: 100,
        height: 100,
        m: 1,
      }}
    >
      <Box
        sx={{
          borderRadius: 1,
          width: 1,
          height: 1,
          // 使图片img标签具有background-size的效果
          objectFit: "cover",
        }}
        component="img"
        src={url}
        onLoad={() => {
          // 元素的onload 事件触发后将销毁URL对象, 释放内存。
          // URL.revokeObjectURL(url);
        }}
      ></Box>

      {/* 删除按钮 */}
      <IconButton
        size="small"
        color="error"
        aria-label="delete"
        sx={{
          position: "absolute",
          right: 1,
          top: 1,
        }}
        onClick={(e) => {
          e.stopPropagation();
          deleteFile({ id });
        }}
      >
        <DeleteIcon />
      </IconButton>

      {/* 图片元信息显示 */}
      <Tooltip title={name}>
        <Typography
          variant="subtitle"
          display="block"
          align="center"
          sx={{
            width: 1,
            bgcolor: "background.alphaPaper",
            px: 0.5,
            position: "absolute",
            bottom: 1,
            left: "50%",
            transform: "translateX(-50%)",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {name}
        </Typography>
      </Tooltip>
    </Box>
  );
}

export function ImagesPreview({ formData, onChange }) {
  const deleteFile = ({ id }) => {
    onChange(formData.filter((file) => file.id !== id));
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: 1,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flexStart",
        pb: 5,
      }}
    >
      {formData &&
        formData.map(({ id, url, name }) => (
          <ImagePreview
            key={id}
            url={url}
            name={name}
            deleteFile={deleteFile}
            id={id}
          ></ImagePreview>
        ))}
    </Box>
  );
}
