import React from "react";
import Clipboard from "clipboard";
import { useSnackbar } from "notistack";

import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

import { QRCodeSVG } from "qrcode.react";
import logo from "../../../../assets/logos/logo-qr.png";

export function QrPanelContent({ url }) {
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    const btn = new Clipboard("#copy-viewurl-link", {
      text: function (trigger) {
        return url;
      },
    });

    return () => {
      btn.destroy();
    };
  }, [url]);

  return (
    <Tooltip title="点击复制链接到剪贴板" placement="bottom" arrow>
      <Box
        sx={{
          cursor: "pointer",
          my: 4,
        }}
      >
        <QRCodeSVG
          id="copy-viewurl-link"
          onClick={() => {
            enqueueSnackbar("当前视角链接已复制至剪贴板", {
              variant: "success",
              key: "copy-viewurl-success",
            });
          }}
          value={url}
          size={200}
          imageSettings={{
            src: logo,
            width: 48,
            height: 48,
          }}
        />
      </Box>
    </Tooltip>
  );
}
