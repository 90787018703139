import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const PanoramaRender = ({ data }) => {
  return (
    <>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          component="iframe"
          sx={{
            border: "none",
            mx: 4,
            width: 960,
            height: 600,
            maxHeight: "100vh",
            maxWidth: "100%",
          }}
          allowFullScreen
          src={data.data.viewerUrl}
        ></Box>
      </Box>
      <Typography variant="subtitle1" color="initial" align="center">
        {data.label}
      </Typography>
    </>
  );
};
