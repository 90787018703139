import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const UploadImageRender = ({ data }) => {
  return (
    <>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      <Box
        sx={{
          // width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          sx={{
            maxWidth: "80%",
          }}
          src={data.data.image.url}
        ></Box>
      </Box>
      <Typography variant="subtitle1" color="initial" align="center">
        {/* {data.data.name || data.data.altText || data.label} */}
        {/* 图片显示其altText作为图片标题 */}
        {data.data.altText}
      </Typography>
    </>
  );
};
