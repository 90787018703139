import React from "react";
import { useReactiveVar } from "@apollo/client";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { zoomVar, setZoomHeading, setzoomPitch } from "../../../../gql";

const presetConfig = [
  { label: "前视图", headingOffset: 0, pitch: 0 },
  { label: "后视图", headingOffset: 180, pitch: 0 },
  { label: "左视图", headingOffset: 270, pitch: 0 },
  { label: "右视图", headingOffset: 90, pitch: 0 },
  { label: "俯视图", headingOffset: 0, pitch: -90, pitchOnly: true },
  { label: "仰视图", headingOffset: 0, pitch: 90, pitchOnly: true },
];
function calcHeading(frontViewHeading, headingOffset) {
  return (frontViewHeading + headingOffset) % 360;
}

export function PresetViews({ frontViewHeading }) {
  const { zoomPitch, zoomHeading } = useReactiveVar(zoomVar);

  const presetConfigMap = React.useMemo(
    () =>
      presetConfig.map(({ label, headingOffset, pitch, pitchOnly }) => ({
        label,
        heading: calcHeading(frontViewHeading, headingOffset),
        pitch,
        pitchOnly,
      })),
    [frontViewHeading]
  );
  // console.log(presetConfigMap);
  return (
    <Box
      sx={{
        maxWidth: "100%",
        mt: 1,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        // mx: 1,
      }}
    >
      {presetConfigMap.map(({ label, heading, pitch, pitchOnly }) => (
        <Button
          sx={{
            m: "1px",
            minWidth: "30%",
          }}
          key={label}
          variant={
            (pitchOnly || heading === zoomHeading) && pitch === zoomPitch
              ? "contained"
              : "text"
          }
          size="small"
          onClick={() => {
            if (!pitchOnly) {
              setZoomHeading(heading);
            }
            setzoomPitch(pitch);
          }}
        >
          {label}
        </Button>
      ))}
    </Box>
  );
}
