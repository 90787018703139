import React from "react";

import { RenderMap } from "./renderer";
const jsonData = `[
{
    "id": "hacker-polygon",
    "type": "polygon",
    "positions": [
      [
        2.0333503301503595,
        0.7060898705150561,
        893.7771529400293
      ],
      [
        2.033350552477255,
        0.7060900974436317,
        894.1998067258428
      ],
      [
        2.0333506857851344,
        0.7060899669288931,
        892.7911111780631
      ]
    ],
    "color": "#ff0000",
    "alpha": 0.5,
    "clampToGround": false,
    "ouline": false
  },
  {
    "id": "hacker-polyline",
    "type": "polyline",
    "positions": [
      [
        2.033350233307372,
        0.7060901037197242,
        895.5107787524493
      ],
      [
        2.0333504992577,
        0.7060902954137235,
        895.5638809585746
      ],
      [
        2.033350786476128,
        0.7060902482725848,
        894.1886133279346
      ]
    ],
    "width": 3,
    "color": "#ff0000",
    "alpha": 0.8,
    "clampToGround": false,
    "dashed": false
  },
  {
    "id": "hacker-billboard",
    "type": "billboard",
    "position": [
      2.033350724451556,
      0.706090306339491,
      899.3371293358199
    ],
    "iconType": "place",
    "color": "#ff0000",
    "alpha": 0.8}]`;

const data = JSON.parse(jsonData);

//首次绘制的几何类型会闪烁，这里强行渲染每种类型各一个对象，并隐藏起来，来避免首次绘制某个几何时闪烁影响用户体验
export function FlickerHacking(props) {
  return data.map((graphicData) => {
    return (
      <RenderMap
        data={graphicData}
        key={graphicData.id}
        show={false}
      ></RenderMap>
    );
  });
}
