import React from "react";
import { useReactiveVar } from "@apollo/client";
import { Link, Navigate } from "react-router-dom";

import { userIsAdmin } from "../../accessControl";
import { userVar } from "../../gql";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export function ConfigIndexPage(props) {
  const user = useReactiveVar(userVar);

  return userIsAdmin(user) ? (
    <Box
      sx={{
        width: 1,
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Button variant="outlined" component={Link} to="comment-tag-tree">
        标签树配置
      </Button>
      <Button variant="outlined" component={Link} to="schema">
        数据模式配置管理
      </Button>
    </Box>
  ) : (
    <Navigate to="/404" replace />
  );
}
