import React from "react";
import { useReactiveVar } from "@apollo/client";

import {
  Cartesian2,
  Color,
  LabelStyle,
  NearFarScalar,
  Cartesian3,
} from "cesium";

import { useGlobalFlyToAssetById } from "../../../hooks";

import { WysiwygDialog, PositionBillBoard, PositionLabel } from "../..";

import { hoverEntityIdVar, depthTestVar } from "../../../gql";
import {
  useDrawingContext,
  useCommentRenderMapContext,
  useSceneContext,
} from "../../../hooks";
import { createPosition } from "../../../utils";
import { CommentDialog } from "./CommentDialog";

const billboardScale = 0.8;
const billboardScaleHover = 0.9;
//渲染配置
// const BillboardGraphicsProps = {
//   height: 48,
//   width: 48,
// };

const commentBillboardSetting = {
  color: "BLUE",
  alpha: 0.8,
};
const commentBillboardHoverSetting = {
  color: "BLUE",
  alpha: 1,
};
const labelSettings = {
  offsetY: -36,
  LabelGraphicsProps: {
    // style: LabelStyle.FILL,
    fillColor: Color.BLACK,
    showBackground: false,
    font: `18px "Microsoft Yahei"`,
    // backgroundColor: Color.BLACK,
    // backgroundPadding: new Cartesian2(8, 8),
  },
};

export function CommentRenderer({ comment, flyTo }) {
  //如果传入flyTo参数则在渲染完成此评论标签后，相机导航飞行至标签处
  const flyToAssetById = useGlobalFlyToAssetById();
  React.useEffect(() => {
    if (flyTo) {
      console.log("fly to " + comment.id);
      flyToAssetById(comment.id);
    }
  }, [flyTo]);

  //深度检测设置
  const { enableDepthTest } = useReactiveVar(depthTestVar);
  const disableDepthTestDistance = enableDepthTest
    ? undefined
    : Number.POSITIVE_INFINITY;

  const { id, iconType, user } = comment;
  let hoverEntityId = useReactiveVar(hoverEntityIdVar);
  const { commentsRenderMap, showAllComments } = useCommentRenderMapContext();
  const {
    scene: { near, far },
  } = useSceneContext();
  const scaleByDistance = React.useMemo(
    () =>
      new NearFarScalar(
        near,
        billboardScale,
        far,
        (billboardScale * near) / far
      ),
    [near, far]
  );
  const scaleByDistanceHover = React.useMemo(
    () =>
      new NearFarScalar(
        near,
        billboardScaleHover,
        far,
        (billboardScaleHover * near) / far
      ),
    [near, far]
  );

  let billboardSettings =
    hoverEntityId === id
      ? {
          ...commentBillboardHoverSetting,
          BillboardGraphicsProps: {
            // ...BillboardGraphicsProps,
            // image: user.avatar.url,
            scaleByDistance: scaleByDistanceHover,
            disableDepthTestDistance,
          },
        }
      : {
          ...commentBillboardSetting,
          BillboardGraphicsProps: {
            // ...BillboardGraphicsProps,
            // image: user.avatar.url,
            scaleByDistance: scaleByDistance,
            disableDepthTestDistance,
          },
        };

  const labelSettingWithNearFarScaler = {
    ...labelSettings,
    LabelGraphicsProps: {
      ...labelSettings.LabelGraphicsProps,
      scaleByDistance: scaleByDistance,
      pixelOffsetScaleByDistance: scaleByDistance,
      disableDepthTestDistance,
    },
  };
  let position = createPosition(comment.position);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  return (
    <>
      <PositionBillBoard
        onClick={handleOpen}
        position={position}
        id={id}
        show={showAllComments && commentsRenderMap[id]}
        // iconVarient={iconType}
        // iconVarient="personPin"
        iconVarient="comment"
        {...billboardSettings}
      ></PositionBillBoard>
      <PositionLabel
        onClick={handleOpen}
        position={position}
        show={showAllComments && commentsRenderMap[id]}
        text={user.name}
        {...labelSettingWithNearFarScaler}
      ></PositionLabel>

      <CommentDialog
        open={open}
        handleClose={handleClose}
        id={id}
      ></CommentDialog>
    </>
  );
}
