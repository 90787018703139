import React from "react";
import { useReactiveVar } from "@apollo/client";

import { PolygonEntity, PositionLabel } from "../../../components";

import { labelVar, hoverEntityIdVar, depthTestVar } from "../../../gql";
import { useSceneContext } from "../../../hooks";

import { getHierarchy } from "../../../utils";

export function PolygonProxy({ show, asset, ...props }) {
  //深度检测全局状态追踪，当深度检测发生变化时polygon需要重新渲染
  useReactiveVar(depthTestVar);

  const {
    config: { polygonSetting, polygonHoverSetting },
  } = useSceneContext();

  let hoverEntityId = useReactiveVar(hoverEntityIdVar);

  //多边形渲染效果设置
  let polygonRenderSetting = polygonSetting;
  // if (hoverEntityId === asset.id) {
  //   polygonRenderSetting = polygonHoverSetting;
  // }

  //支持多个多边形渲染
  if (hoverEntityId && hoverEntityId.indexOf(asset.id) !== -1) {
    polygonRenderSetting = polygonHoverSetting;
  }

  // const { show: showLabel, key } = useReactiveVar(labelVar);
  // const hierarchy = getHierarchy(asset.polygon);

  //支持多个多边形渲染
  const hierarchyArray = asset.polygon.split("|").map(getHierarchy);
  return (
    hierarchyArray.length > 0 && (
      <>
        <PolygonEntity
          hierarchyArray={hierarchyArray}
          id={asset.id}
          show={show}
          polygonClamp={asset.polygonClamp}
          {...polygonRenderSetting}
          {...props}
          // {...billboardSettings}
        ></PolygonEntity>
        {/* <PositionLabel
            position={position}
            show={show && showLabel}
            text={getLabelTextByKey({ asset, key })}
            {...labelSettings}
          ></PositionLabel> */}
      </>
    )
  );
}
