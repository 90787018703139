import { gql, useQuery } from "@apollo/client";

import { DIGITAL_SCENE_FIELDS } from "./digitalScene";
import { SOURCE_OBJECT_META_FIELDS } from "./sourceObjectMeta";

//fragments
const SOURCE_DRAWING_FIELDS = gql`
  ${DIGITAL_SCENE_FIELDS}
  ${SOURCE_OBJECT_META_FIELDS}
  fragment SourceDrawingFields on SourceDrawing {
    id
    name
    description
    order
    createdAt
    updatedAt
    showComments
    showSourceObjects
    digitalScene {
      ...DigitalSceneFields
    }
    supportedMetas {
      ...SourceObjectMetaFields
    }
  }
`;

//queries
const GET_SOURCE_DRAWING_BY_ID = gql`
  ${SOURCE_DRAWING_FIELDS}
  query SourceDrawing($where: SourceDrawingWhereUniqueInput!) {
    sourceDrawing(where: $where) {
      ...SourceDrawingFields
    }
  }
`;

//queries
const GET_SOURCE_DRAWINGS = gql`
  ${SOURCE_DRAWING_FIELDS}
  query SourceDrawings($where: SourceDrawingWhereInput! = {}) {
    sourceDrawings(where: $where) {
      ...SourceDrawingFields
    }
  }
`;

//mutations

//hooks query
export function useSourceDrawing(options) {
  return useQuery(GET_SOURCE_DRAWING_BY_ID, options);
}

export function useSourceDrawings(options) {
  return useQuery(GET_SOURCE_DRAWINGS, options);
}

//hooks mutation
