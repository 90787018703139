import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

// import "swiper/css/navigation";
// import "swiper/css/scrollbar";
import Image from "rc-image";
import "rc-image/assets/index.css";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import Portal from "@mui/base/Portal";
import Typography from "@mui/material/Typography";

//icon
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import CloseIcon from "@mui/icons-material/Close";

export function CommentImageGallery({ images, title }) {
  const imageCount = images.length;
  const theme = useTheme();
  const backdropRef = React.useRef(null);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const handlePreviewVisibleChange = React.useCallback((visible) => {
    setBackdropOpen(visible);
  }, []);
  return (
    <>
      <Image.PreviewGroup
        preview={{
          onVisibleChange: handlePreviewVisibleChange,
          getContainer: backdropRef.current,
        }}
        icons={{
          left: <ChevronLeftIcon color="primary" />,
          right: <ChevronRightIcon color="primary" />,
          rotateLeft: <RotateLeftIcon />,
          rotateRight: <RotateRightIcon />,
          zoomIn: <ZoomInIcon />,
          zoomOut: <ZoomOutIcon />,
          close: <CloseIcon />,
        }}
      >
        <Swiper
          // 设置不同屏幕宽度下显示的图片数量
          breakpoints={{
            [theme.breakpoints.values.phone]: {
              slidesPerView: 1,
            },
            [theme.breakpoints.values.sm]: {
              slidesPerView: imageCount > 1 ? 2 : 1,
            },
            [theme.breakpoints.values.md]: {
              slidesPerView: imageCount > 2 ? 3 : imageCount,
            },
          }}
          spaceBetween={1}
          modules={[Pagination]}
          pagination={{ clickable: true }}
        >
          {images.map(({ id, altText, image: { url } }) => (
            <SwiperSlide key={id}>
              <Box
                sx={{
                  minWidth: 200,
                  maxWidth: "100%",

                  // 微信浏览器不支持aspect-ratio，故改为外层padding-bottom，内层绝对定位方式 hack出长宽比的效果
                  height: 0,
                  paddingBottom: "100%",
                  position: "relative",
                  "& > .rc-image": {
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  },

                  transform: "scale(0.98)",
                  ":hover": {
                    transform: "scale(1)",
                  },
                  cursor: "pointer",
                  transition: "transform 0.2s",

                  //注释掉为aspectRatio方案
                  // aspectRatio: "1 / 1",
                  // borderRadius: 2,
                  // 使图片img标签具有background-size的效果
                  // objectFit: "cover",
                }}
                // component={Image}
                // src={url}
              >
                <Box
                  sx={{
                    borderRadius: 2,
                    // 使图片img标签具有background-size的效果
                    objectFit: "cover",
                  }}
                  component={Image}
                  src={
                    backdropOpen
                      ? url
                      : url + "?x-oss-process=style/scale_512_512" //oss图片处理，缩放到512_512
                  }
                ></Box>
              </Box>
              {/* {altText && (
                <Typography variant="body1" color="initial" align="center">
                  {altText}
                </Typography>
              )} */}
            </SwiperSlide>
          ))}
        </Swiper>
      </Image.PreviewGroup>
      <Portal>
        <Backdrop
          ref={backdropRef}
          open={backdropOpen}
          sx={{
            zIndex: "gallery", //higher than swiper object
          }}
        >
          <Typography
            variant="body2"
            align="center"
            sx={{
              maxHeight: 100,
              padding: 2,
              color: "white.main",
              bgcolor: "background.AlphaBlackForWhiteText",
              position: "absolute",
              left: 0,
              bottom: 0,
              width: "100%",
              zIndex: "gallery", //higher than swiper object
            }}
          >
            {title}
          </Typography>
        </Backdrop>
      </Portal>
    </>
  );
}

// {
//   /* <Box
//                 sx={{
//                   // 微信浏览器不支持aspect-ratio，故改为外层padding-bottom，内层绝对定位方式 hack出长宽比的效果
//                   height: 0,
//                   paddingBottom: imageCount === 1 ? "100%" : "200px",
//                   position: "relative",
//                   "& > .rc-image": {
//                     position: "absolute",
//                     top: 0,
//                     right: 0,
//                     bottom: 0,
//                     left: 0,
//                   },
//                   transform: "scale(0.98)",
//                   ":hover": {
//                     transform: "scale(1)",
//                   },
//                   cursor: "pointer",
//                   transition: "transform 0.2s",
//                   width: imageCount === 1 ? "100%" : "200px",
//                 }}
//               >
//                 <Box
//                   sx={{
//                     borderRadius: 2,
//                     // 使图片img标签具有background-size的效果
//                     objectFit: "cover",
//                   }}
//                   component={Image}
//                   src={url}
//                 ></Box>
//                 {altText && (
//                   <Typography
//                     variant="body1"
//                     color="initial"
//                     align="center"
//                     sx={{}}
//                   >
//                     {altText}
//                   </Typography>
//                 )}
//               </Box> */
// }
