import React from "react";

import { drawingStatusVar, dispatchdrawingStatusVar } from "../drawingToolVars";
import { drawingButtonTypeMap, shortKeyMap } from "../config";

// 按键设置
function keyPressEvent(e) {
  const drawingType = shortKeyMap[e.key];
  if (drawingType) {
    dispatchdrawingStatusVar({
      type: "SET_ADD_TOGGLE_DRAWING_TYPE",
      payload: drawingType,
    });
  }
}

export function ShortCutEvent(props) {
  React.useEffect(() => {
    //绑定快捷键事件
    document.addEventListener("keypress", keyPressEvent, false);
    return () => {
      //取消快捷按键事件
      document.removeEventListener("keypress", keyPressEvent, false);
    };
  });
  return null;
}
