import {
  Cartesian3,
  Cartographic,
  Math as CMath,
  Transforms,
  Matrix4,
} from "cesium";

//Cartesian3 转化为 经纬度弧度
export function ECEF2LatLongRadians(position) {
  return Cartographic.fromCartesian(position);
}

////Cartesian3 转化为 经纬度角度
export function ECEF2LatLongDegrees(position) {
  const latLongRadians = Cartographic.fromCartesian(position);
  const longitude = CMath.toDegrees(latLongRadians.longitude);
  const latitude = CMath.toDegrees(latLongRadians.latitude);
  const height = latLongRadians.height;

  return {
    longitude,
    latitude,
    height,
  };
}

//计算两点距离，高差为p2-p1
export function getDistance(p1, p2, fixed = 3) {
  const distance =
    ((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2 + (p1.z - p2.z) ** 2) ** 0.5;
  const verticalDistance =
    ECEF2LatLongRadians(p2).height - ECEF2LatLongRadians(p1).height;
  const horizontalDistance = (distance ** 2 - verticalDistance ** 2) ** 0.5;
  return {
    distance: distance.toFixed(fixed),
    verticalDistance: verticalDistance.toFixed(fixed),
    horizontalDistance: horizontalDistance.toFixed(fixed),
  };
}

// 计算亮点距离,p2 - p1
export function getDistanceByMatrix4(p1, p2, fixed = 3) {
  // const transformMatrix4 = Matrix4.inverse(
  //   Transforms.eastNorthUpToFixedFrame(p1),
  //   new Matrix4()
  // );

  const transformMatrix4 = Matrix4.inverseTransformation(
    Transforms.eastNorthUpToFixedFrame(p1),
    new Matrix4()
  );

  const v = Matrix4.multiplyByPoint(transformMatrix4, p2, new Cartesian3());

  return {
    eastDistance: v.x.toFixed(fixed),
    northDistance: v.y.toFixed(fixed),
    verticalDistance: v.z.toFixed(fixed),
    distance: ((v.x ** 2 + v.y ** 2 + v.z ** 2) ** 0.5).toFixed(fixed),
    horizontalDistance: ((v.x ** 2 + v.y ** 2) ** 0.5).toFixed(fixed),
  };
}

export function getMiddlePoint(p1, p2) {
  return new Cartesian3(
    (p1.x + p2.x) / 2,
    (p1.y + p2.y) / 2,
    (p1.z + p2.z) / 2
  );
}

export function cartesian2String(p) {
  return `${p.x},${p.y},${p.z}`;
}
