import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { Navigate } from "react-router-dom";

import { useReactiveVar } from "@apollo/client";

import Box from "@mui/material/Box";

import { userVar } from "../../gql";
import { userIsAdmin } from "../../accessControl";
import Typography from "@mui/material/Typography";

export function VersionPage(props) {
  const user = useReactiveVar(userVar);
  const [backendReadme, setBackendReadme] = React.useState("");

  const [readme, setReadme] = useState("");

  //3d端记录
  useEffect(() => {
    fetch("/version.md")
      .then((response) => response.text())
      .then((text) => setReadme(text))
      .catch((error) => console.error(error));
  }, []);

  //后端升级记录
  useEffect(() => {
    fetch(`${process.env.REACT_APP_ADMIN_URI}/_version`)
      .then((response) => response.text())
      .then((data) => setBackendReadme(data))
      .catch((error) => console.log(error));
  }, []);

  return userIsAdmin(user) ? (
    <Box
      sx={{
        p: 4,
        width: 1,
        //ai generated jss code
        "& table": {
          border: "1px solid black",
          borderCollapse: "collapse",
        },
        "& th": {
          border: "1px solid black",
          padding: "8px",
          textAlign: "left",
          backgroundColor: "#ddd",
        },
        "& td": {
          border: "1px solid black",
          padding: "8px",
          textAlign: "left",
        },
      }}
    >
      <Typography
        variant="h6"
        color="primary"
        sx={{
          mb: 2,
          fontWeight: "bold",
        }}
      >
        3D程序版本记录
      </Typography>
      <ReactMarkdown children={readme} remarkPlugins={[remarkGfm]} />
      <hr />
      <Typography
        variant="h6"
        color="primary"
        sx={{
          mb: 2,
          fontWeight: "bold",
        }}
      >
        后端程序版本记录
      </Typography>
      <ReactMarkdown children={backendReadme} remarkPlugins={[remarkGfm]} />
    </Box>
  ) : (
    <Navigate to="/404" replace />
  );
}
