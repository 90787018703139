import React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
//icon
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
//local
import { AccountIconButton } from "../../../components";

import { BottomMenuBar } from "./BottomMenuBar";
import { DsPanel } from "./DsPanel";
import { BasicButtons } from "./BasicButtons";
import { ToolMenuButton } from "./ToolMenuButton";
import { TimeLineButton } from "./TimeLineButton";
import { CommentPanel } from "./CommentPanel";
import { SourceObjectPanel } from "./SourceObjectPanel";

function Seperator() {
  return (
    <Box
      sx={{
        mr: {
          xs: 0.5,
          xl: 1,
        },
      }}
    ></Box>
  );
}

export function BottomMenu({
  defaultMenuOpen = true,
  toggleTimeLine,
  showTimeline,
  commentPanel = false,
  sourceObjectPanel = false,
  dsPanel = false,
  toolMenu = false,
  homeButton = true,
  cameraModeButton = true,
  vrButton = true,
  fullScreenButton = true,
  accountButton = false,
}) {
  const [open, setOpen] = React.useState(defaultMenuOpen);
  return (
    <>
      {/* 时间轴 */}
      <BottomMenuBar open={open}>
        {toggleTimeLine && (
          <TimeLineButton
            toggleTimeLine={toggleTimeLine}
            showTimeline={showTimeline}
          ></TimeLineButton>
        )}

        {/* 评论面板 */}
        {commentPanel && <CommentPanel></CommentPanel>}

        {/* 源对象面板 */}
        {sourceObjectPanel && <SourceObjectPanel></SourceObjectPanel>}

        {(sourceObjectPanel || commentPanel) && <Seperator></Seperator>}

        {/* 数字资产管理面板  */}
        {dsPanel && <DsPanel></DsPanel>}

        {/* 工具栏 */}
        {toolMenu && <ToolMenuButton></ToolMenuButton>}
        <Seperator></Seperator>

        <BasicButtons
          homeButton={homeButton}
          cameraModeButton={cameraModeButton}
          vrButton={vrButton}
          fullScreenButton={fullScreenButton}
        ></BasicButtons>
        <Seperator></Seperator>

        {/* 用户按钮 */}
        {accountButton && <AccountIconButton></AccountIconButton>}

        <Seperator></Seperator>

        {/* 隐藏面板按钮 */}
        <Tooltip arrow title="收起菜单栏" placement="top">
          <IconButton
            size="small"
            onClick={() => {
              setOpen(false);
            }}
            color="white"
          >
            <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
          </IconButton>
        </Tooltip>
      </BottomMenuBar>

      {/* 关闭menu时渲染开启menu的按钮 */}
      {!open && (
        <Tooltip arrow title="打开菜单栏" placement="top">
          <IconButton
            size="small"
            disableRipple
            onClick={() => {
              setOpen(true);
            }}
            color="primary"
            sx={{
              position: "fixed",
              bottom: 0,
              left: "50%",
              transform: "translate(-50%,30%) ",
              zIndex: (theme) => theme.zIndex.bottomMenuBar,
            }}
          >
            <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}
