import React from "react";
import { useReactiveVar } from "@apollo/client";

import { useCesium } from "resium";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import { dispatchDrawingToolVar, drawingStatusVar } from "../drawingToolVars";
import { drawingTypeTranslationMap, drawingButtonIconMap } from "../config";
import { useGlobalFlyToAssetById } from "../../../hooks";

export function LayerList({ data }) {
  return (
    <List dense disablePadding>
      {data.map((itemData, index) => (
        <LayerListItem
          key={itemData.id}
          data={itemData}
          index={index}
        ></LayerListItem>
      ))}
    </List>
  );
}

function LayerListItem({ data, index, ...props }) {
  const { drawingType } = useReactiveVar(drawingStatusVar);

  const flyToAssetById = useGlobalFlyToAssetById();

  const handleDelete = React.useCallback(() => {
    dispatchDrawingToolVar({
      type: "DELETE_GEOMETRY_BY_ID",
      payload: data.id,
    });
  }, [data.id]);

  const handleEdit = React.useCallback(() => {
    dispatchDrawingToolVar({
      type: "EDIT_GEOMETRY_BY_ID",
      payload: data.id,
    });
  }, [data.id]);
  return (
    <>
      <ListItem
        onDoubleClick={() => {
          flyToAssetById(data.id);
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          console.log(
            `----------本条${
              drawingTypeTranslationMap[data.type]
            }信息-----------`
          );

          console.log(JSON.stringify(data, null, 2));
        }}
        secondaryAction={
          <IconButton
            edge="end"
            aria-label="delete"
            size="small"
            color="error"
            onClick={handleDelete}
          >
            <DeleteIcon fontSize="small"></DeleteIcon>
          </IconButton>
        }
        disablePadding
        dense
        disableGutters
        {...props}
      >
        <ListItemButton dense disableGutters>
          <Tooltip title={drawingType ? "" : "撤回编辑"} placement="bottom">
            <ListItemIcon
              size="small"
              sx={{
                minWidth: "unset",
                mr: 1,
              }}
              onClick={drawingType ? undefined : handleEdit}
            >
              {
                drawingButtonIconMap({
                  fontSize: "small",
                })[data.type]
              }
            </ListItemIcon>
          </Tooltip>

          <ListItemText
            primary={`${index + 1}:  ${drawingTypeTranslationMap[data.type]}`}
            primaryTypographyProps={{
              variant: "body1",
            }}
            secondary={data.text}
            secondaryTypographyProps={{
              variant: "caption",
              fontSize: 12,
            }}
          />
        </ListItemButton>
      </ListItem>
    </>
  );
}
