import React from "react";
import { useCesium } from "resium";
import { useReactiveVar } from "@apollo/client";
import { useSnackbar } from "notistack";

import { GridActionsCellItem } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import {
  useGlobalFlyToAssetById,
  useSourceObjectRenderMapContext,
  useGoToView,
} from "../../../../hooks";
import { userVar, useUpdateSourceObject } from "../../../../gql";
import { userIsAdminOrEditor } from "../../../../accessControl";

export function FlyToAction({ params }) {
  const { camera } = useCesium();
  const user = useReactiveVar(userVar);

  //飞行到设置的相机视角函数的hook
  const goToView = useGoToView();
  const flyToAssetById = useGlobalFlyToAssetById();

  const { sourceObjectsRenderMap, showAllSourceObjects } =
    useSourceObjectRenderMapContext();

  const { enqueueSnackbar } = useSnackbar();
  const [updateSourceObjectMutation, { loading }] = useUpdateSourceObject({
    refetchQueries: ["SourceObjects"],
  });

  return (
    <GridActionsCellItem
      icon={
        <Tooltip title="导航" placement="top">
          <PlayArrowIcon
            color={
              showAllSourceObjects && sourceObjectsRenderMap[params.id]
                ? "action"
                : "disabled"
            }
          />
        </Tooltip>
      }
      disabled={!(showAllSourceObjects && sourceObjectsRenderMap[params.id])}
      label="Fly"
      onClick={() => {
        try {
          if (params.row.zoomView) {
            goToView(params.row.zoomView);
          } else {
            flyToAssetById(params.row.geometry[0].id);
          }
        } catch (error) {
          console.log(error);
        }
      }}
      onContextMenu={async (e) => {
        if (userIsAdminOrEditor(user)) {
          e.preventDefault();
          try {
            const res = await updateSourceObjectMutation({
              variables: {
                where: {
                  id: params.id,
                },
                data: {
                  zoomView: {
                    position: camera.position.clone(),
                    direction: camera.direction.clone(),
                    up: camera.up.clone(),
                  },
                },
              },
            });
            if (res.errors) {
              enqueueSnackbar("聚焦视角设置失败", {
                variant: "error",
                key: "update-source-object-error",
              });
            } else {
              enqueueSnackbar("聚焦视角设置成功", {
                variant: "success",
                key: "create-source-object-sucess",
                autoHideDuration: 300,
              });
            }
          } catch (error) {
            enqueueSnackbar("聚焦视角设置失败", {
              variant: "error",
              key: "update-source-object-error",
            });
          }
        }
      }}
    />
  );
}
