import { makeVar } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";

import {
  geometrySettingVar,
  dispatchGeometrySettingVar,
} from "./geometrySettingVar";
import { dispatchdrawingStatusVar, drawingStatusVar } from "./drawingStatusVar";

const defaultDataValue = {
  data: [],
};
const defaultEditValue = {
  editData: {},
};

//绘画工具绘制数据全局变量
export const drawingToolDataVar = makeVar(defaultDataValue);

//当前编辑内容状态全局变量
export const drawingToolEditVar = makeVar(defaultEditValue);

//检测提交的几何数据的完整性,以及属性配置是否满足渲染要求
export function checkEditDataReady() {
  const geometrySetting = geometrySettingVar();
  const editData = drawingToolEditVar().editData;
  if (!editData.type) {
    return {
      success: false,
      message: "未绘制几何信息,无法保存",
    };
  }
  switch (editData.type) {
    case "billboard":
      if (editData.position) {
        return {
          success: true,
        };
      } else {
        return {
          success: false,
          message: "未选取标签坐标,无法保存",
        };
      }

    case "polyline":
      if (editData.positions && editData.positions.length > 1) {
        return {
          success: true,
        };
      } else {
        return {
          success: false,
          message: "多段线几何信息不完整,无法保存",
        };
      }
    case "polygon":
      if (editData.positions && editData.positions.length > 2) {
        return {
          success: true,
        };
      } else {
        return {
          success: false,
          message: "多边形几何信息不完整,无法保存",
        };
      }
    case "point":
      if (editData.position) {
        return {
          success: true,
        };
      } else {
        return {
          success: false,
          message: "未选取点坐标,无法保存",
        };
      }
    case "label":
      if (editData.position) {
        return {
          success: true,
        };
      } else {
        return {
          success: false,
          message: "未选取文字坐标,无法保存",
        };
      }
    case "model":
      if (editData.position && geometrySetting.model.uri) {
        return {
          success: true,
        };
      } else {
        return {
          success: false,
          message: "未选取模型原点坐标,无法保存",
        };
      }
    default:
      return false;
  }
}

export function dispatchDrawingToolVar(action) {
  switch (action.type) {
    //清楚所有数据，恢复默认数据状态
    case "SET_TO_DEFAULT":
      drawingToolDataVar(defaultDataValue);
      drawingToolEditVar(defaultEditValue);
      return;

    //重置编辑状态数据
    case "RESET_EDIT_DATA":
      drawingToolEditVar(defaultEditValue);
      return;

    //重置已存储数据
    case "RESET_SAVED_DATA":
      drawingToolDataVar(defaultDataValue);
      return;

    //载入几何对象进入存储的图层数据
    case "LOAD_GEOMETRY_INTO_SAVED_DATA":
      drawingToolDataVar({ data: action.payload });
      return;

    //存储数据--新增一个几何
    case "ADD_GEOMETRY":
      const geometryReadyResult = checkEditDataReady();
      const editData = drawingToolEditVar().editData;
      if (geometryReadyResult.success) {
        //存储数据
        drawingToolDataVar({
          data: [
            ...drawingToolDataVar().data,
            {
              id: uuidv4(),
              ...editData,
              ...geometrySettingVar()[editData.type],
            },
          ],
        });
        //清除编辑中数据内容
        // drawingToolEditVar(defaultEditValue);
        //关闭绘制工具
        dispatchdrawingStatusVar({
          type: "RESET_DRAWING_TYPE",
        });
      }
      return geometryReadyResult;

    // //存储数据--新增一个几何
    // case "UPDATE_GEOMETRY":
    //   if (geometryReadyResult.success) {
    //     const drawingData = drawingToolDataVar().data.filter(({id})=>id!===action.payload.id)
    //     drawingToolDataVar({
    //       data:
    //       data: [
    //         ...drawingToolDataVar().data,
    //         {
    //           id: uuidv4(),
    //           ...editData,
    //           ...geometrySettingVar()[editData.type],
    //         },
    //       ],
    //     });
    //     drawingToolEditVar(defaultEditValue);
    //   }
    //   return geometryReadyResult;

    //存储数据--新增一个几何
    case "EDIT_GEOMETRY_BY_ID":
      const dataToEdit = drawingToolDataVar().data.filter(
        ({ id }) => id === action.payload
      );
      const dataToHold = drawingToolDataVar().data.filter(
        ({ id }) => id !== action.payload
      );
      //如果拟修改的图层几何id存在
      if (dataToEdit.length > 0) {
        const { id, type, position, positions, ...geometrySetting } =
          dataToEdit[0];

        //当前图层数据撤回删除
        drawingToolDataVar({
          data: dataToHold,
        });

        //载入数据至编辑区
        //1 设置正确的数据编辑类型
        dispatchdrawingStatusVar({
          type: "SET_DRAWING_TYPE",
          payload: type,
        });
        //2 载入几何属性设置
        geometrySettingVar({
          ...geometrySettingVar(),
          [type]: geometrySetting,
        });
        //3 载入几何信息
        drawingToolEditVar({
          editData: {
            type,
            position,
            positions,
          },
        });
      }
      return;

    //删除特定id的几何
    case "DELETE_GEOMETRY_BY_ID":
      drawingToolDataVar({
        data: drawingToolDataVar().data.filter(
          (geometry) => geometry.id !== action.payload
        ),
      });
      return;

    //修改当前编辑状态数据
    case "SET_EDIT_GEOMETRY":
      drawingToolEditVar({
        ...drawingToolEditVar(),
        editData: action.payload,
      });
      return;

    default:
      throw new Error("action type incorrect");
  }
}
