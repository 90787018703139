import { cloneDeep } from "lodash";

const allAssetsType = [
  "tiledModel",
  "pointCloud",
  "tiledImage",
  "panorama",
  "vtour",
];

export function createDigitalSceneData(digitalScene) {
  let scene;
  let assets = {};
  let initRenderMap = {};
  if (digitalScene) {
    scene = cloneDeep(digitalScene);
    //过滤未绑定实际资产的link
    scene.digitalAssetsLinks = scene.digitalAssetsLinks.filter(
      (link) => link.digitalAsset !== null
    );
    for (const assetType of allAssetsType) {
      assets[assetType] = scene.digitalAssetsLinks.filter(
        (link) => link.digitalAsset.type === assetType
      );
    }
    for (const link of scene.digitalAssetsLinks) {
      initRenderMap[link.id] = link.initRender;
    }
  }
  return { scene, assets, initRenderMap };
}

export function createDrawingData(sourceDrawing) {
  let drawing;
  let initCommentsRenderMap = {};
  if (sourceDrawing) {
    drawing = cloneDeep(sourceDrawing);
    for (const comment of drawing.comments) {
      initCommentsRenderMap[comment.id] = true;
    }
  }
  return { drawing, initCommentsRenderMap };
}
