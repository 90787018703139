import React from "react";

import { Marker, Label } from "react-bmapgl";

import { MapItemData } from "./MapItemData";
export function MapItem({ item, schema, iconSet, ...props }) {
  const [infoWindowOpen, setInfoWindowOpen] = React.useState(false);
  const itemPosition = React.useMemo(() => {
    const positionArray = item.bd09.split(",").map((item) => parseFloat(item));
    return new window.BMapGL.Point(...positionArray);
  }, [item]);

  const handleOpen = React.useCallback(() => {
    if (item.targetUrl) {
      window.open(item.targetUrl, "_blank"); //直接跳转后台设置的链接
    } else {
      setInfoWindowOpen(true); //展示信息窗口
    }
  }, [item.targetUrl]);
  const handleClose = React.useCallback(() => {
    setInfoWindowOpen(false);
  }, []);

  return (
    <>
      <Marker
        position={itemPosition}
        onClick={handleOpen}
        // onMouseover={handleOpen}
        // onMouseout={handleClose}
        // enableDragging
        icon={iconSet["simple_red"]} //自制图标集合,默认图标有访问不到的风险
      />
      <Label
        position={itemPosition}
        text={item.name}
        offset={new window.BMapGL.Size(15, -25)}
        style={{
          color: "black",
        }}
        onClick={handleOpen}
      />

      {infoWindowOpen && schema && item.data && (
        <MapItemData
          item={item}
          schema={schema}
          handleClose={handleClose}
        ></MapItemData>
      )}
    </>
  );
}
