import React from "react";
import { useReactiveVar } from "@apollo/client";
import { Navigate } from "react-router-dom";

import { userIsAdmin } from "../../../accessControl";
import { userVar, useCommentTagTreeConfig, useCommentTags } from "../../../gql";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { CommentTagTreeConfigPageEditForm } from "./CommentTagTreeConfigPageEditForm";

export function CommentTagTreeConfigPage(props) {
  const user = useReactiveVar(userVar);
  const { data: tagConfigData, loading } = useCommentTagTreeConfig({});
  const { data: tagData } = useCommentTags({
    variables: {
      orderBy: {
        order: "asc",
      },
    },
  });

  return userIsAdmin(user) ? (
    tagConfigData && tagConfigData.commentTagTree ? (
      tagData &&
      tagData.commentTags && (
        <>
          <CommentTagTreeConfigPageEditForm
            commentTagTree={tagConfigData.commentTagTree}
            tags={tagData.commentTags}
          ></CommentTagTreeConfigPageEditForm>
        </>
      )
    ) : loading ? (
      <Typography
        variant="button"
        color="initial"
        gutterBottom
        display="block"
        align="center"
        sx={{
          width: 1,
          mt: "40vh",
        }}
      >
        加载中...
      </Typography>
    ) : (
      <Typography
        variant="button"
        color="initial"
        gutterBottom
        display="block"
        align="center"
        sx={{
          width: 1,
          mt: "40vh",
        }}
      >
        还未创建配置,请前往后台面板
        <Button
          variant="text"
          onClick={() =>
            window.open(
              `${process.env.REACT_APP_ADMIN_URI}/comment-tag-tree/create`
            )
          }
        >
          创建配置
        </Button>
        后再来此处可视化编辑
      </Typography>
    )
  ) : (
    <Navigate to="/404" replace />
  );
}
