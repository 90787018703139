import React from "react";

import { styled } from "@mui/material/styles";

export const FullScreenIframe = styled("iframe")({
  width: "100vw",
  height: "100vh",
  display: "block",
  border: "unset",
});
