import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import DeleteIcon from "@mui/icons-material/Delete";

import { getFileExtIcon } from "../../assets/types/config";

// function getFileExtIcon(filename) {
//   let ext;
//   const fileSplit = filename.split(".");
//   if (fileSplit.length > 1) {
//     ext = fileSplit.pop();
//   } else {
//     ext = "default";
//   }
//   return fileExtensionToIcon[ext] || fileExtensionToIcon.default;
// }

function FilePreview({ id, url, name, deleteFile }) {
  return (
    <Box
      sx={{
        minWidth: 0,
        width: 1,
        m: 1,
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {/* 文件Icon */}
      <Box
        sx={{
          width: 16,
          height: 16,
          mr: 1,
          flexShrink: 0,
          flexGrow: 0,
        }}
        component="img"
        src={getFileExtIcon(name)}
      ></Box>

      {/* 文件名称 */}
      <Tooltip title={name} placement="right">
        <Typography
          variant="subtitle2"
          color="primary"
          sx={{
            display: "block",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            flexGrow: 1,
          }}
        >
          {name}
        </Typography>
      </Tooltip>

      {/* 删除按钮 */}
      <IconButton
        size="small"
        color="error"
        aria-label="delete"
        sx={{
          flexShrink: 0,
          flexGrow: 0,
        }}
        onClick={(e) => {
          e.stopPropagation();
          deleteFile({ id });
        }}
      >
        <DeleteIcon fontSize="24px" />
      </IconButton>
    </Box>
  );
}

export function FilesPreview({ formData, onChange }) {
  const deleteFile = ({ id }) => {
    onChange(formData.filter((file) => file.id !== id));
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: 1,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flexStart",
        pb: 5,
      }}
    >
      {formData &&
        formData.map(({ id, url, name }) => (
          <FilePreview
            key={id}
            url={url}
            name={name}
            deleteFile={deleteFile}
            id={id}
          ></FilePreview>
        ))}
    </Box>
  );
}
