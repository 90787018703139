import React from "react";
import { useReactiveVar } from "@apollo/client";

import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import EditLocationIcon from "@mui/icons-material/EditLocation";

import {
  setPickPositionEvent,
  pickPositionVar,
  setPickedPosition,
  resetPickPosition,
} from "../../gql";

export function PositionPickField({ initPosition }) {
  const { pickedPosition, pickPositionEvent } = useReactiveVar(pickPositionVar);

  React.useEffect(() => {
    //设置初始坐标如果是update的情况
    if (initPosition) {
      setPickedPosition(initPosition);
    }
    //返回重置函数，确保任何时候关闭表单，红色位置标记消失
    return resetPickPosition;
  }, []);
  return (
    <Box
      sx={{
        my: 1,
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          p: 1,
          // bgcolor: "background.alphaPrimary",
        }}
      >
        <Typography
          variant="h6"
          align="left"
          sx={{
            fontWeight: 700,
          }}
          gutterBottom
        >
          选取评论坐标*
        </Typography>
        {pickedPosition ? (
          <Typography variant="body1" align="center">
            {pickedPosition.split(",").join("\n")}
          </Typography>
        ) : (
          <Typography variant="body1" align="center" color="error">
            必须设置评论坐标，点击右侧按钮，然后在场景中点选
          </Typography>
        )}
      </Box>

      <Tooltip
        title={pickPositionEvent ? "点击停止选取" : "点击激活选取"}
        placement="top"
      >
        <IconButton
          size="large"
          color={pickPositionEvent ? "primary" : "error"}
          aria-label="set location"
          onClick={() => {
            setPickPositionEvent(!pickPositionEvent);
          }}
          sx={{
            ml: "auto",
          }}
        >
          <EditLocationIcon fontSize="20px" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
