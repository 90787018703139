import React from "react";
import Draggable from "react-draggable";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";

//icon
import CloseIcon from "@mui/icons-material/Close";

export function SourceObjectEditPanel({ handleClose, children, handleId }) {
  return (
    <Draggable handle={`#${handleId}`} cancel={'[class*="MuiIconButton-root"]'}>
      <Paper
        elevation={8}
        sx={{
          position: "fixed",
          top: {
            xs: 0,
            md: 16,
          },
          left: {
            xs: 0,
            md: 16,
          },
          maxHeight: {
            xs: "50vh",
            md: "80vh",
          },
          overflowY: "auto",
          zIndex: "createSourceObjectPanel",
          bgcolor: "background.alphaPaper",
          px: 2,

          minWidth: {
            xs: "100%",
            md: 400,
            lg: 450,
            xl: 500,
          },
          maxWidth: {
            xs: "100%",
            md: 550,
            lg: 600,
            xl: 650,
          },
          minHeight: 200,
        }}
      >
        {/* 此Box用于拖动 */}
        <Box
          sx={{
            position: "relative",
            height: 40,
            cursor: "move",
            pt: 2,
          }}
          id={handleId}
        >
          <IconButton
            aria-label="close"
            color="primary"
            onClick={handleClose}
            size="small"
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {/* 创建表单的内容 */}
        {children}
      </Paper>
    </Draggable>
  );
}
