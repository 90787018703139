import { gql, useQuery, useMutation } from "@apollo/client";

//queries
const GET_COMMENT_TAG_TREE = gql`
  query CommentTagTree {
    commentTagTree {
      id
      description
      editUrl
      name
      treeDef
      inUse
    }
  }
`;

//mutations
const UPDATE_COMMENT_TAG_TREE = gql`
  mutation UpdateCommentTagTree($data: CommentTagTreeUpdateInput!) {
    updateCommentTagTree(data: $data) {
      treeDef
      name
      id
    }
  }
`;

//hooks query
export function useCommentTagTreeConfig() {
  return useQuery(GET_COMMENT_TAG_TREE);
}

//hooks mutation
export function useUpdateCommentTagTreeConfig(options) {
  return useMutation(UPDATE_COMMENT_TAG_TREE, options);
}
