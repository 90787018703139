import React from "react";
import { TiledModelEntity } from "../../../components";

import { ProxyGraphicRender } from "../proxyGraphicRender";

//点云渲染逻辑与tiledModel一致,故此处使用type提取url，但复用TiledModelRender组件
export function TiledModelRender({ type = "tiledModel", ...props }) {
  const { asset, show } = props;

  // console.log(
  //   `render tiledModel ${asset.name || asset.digitalAsset.name} ${asset.id}`
  // );

  //   定义默认渲染规则
  if (asset.renderType === "inherit") {
    return (
      <TiledModelEntity
        url={asset.digitalAsset[type].url}
        id={asset.id}
        show={show}
      />
    );
  }

  return <ProxyGraphicRender {...props}></ProxyGraphicRender>;
}

export const MemoizedTiledModelRender = React.memo(TiledModelRender);
