import React from "react";
import { useReactiveVar } from "@apollo/client";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import InfoIcon from "@mui/icons-material/Info";
import { drawingStatusVar } from "../../drawingToolVars";

import {
  drawingTypeHelperTextMap,
  drawingTypeTranslationMap,
  drawingTypeShortKeyMap,
  commonHelperText,
} from "../../config";

export function DrawingButtonHelper(props) {
  const { drawingType } = useReactiveVar(drawingStatusVar);

  return drawingType ? (
    <Box
      sx={{
        fontSize: 12,
        my: 1,
        userSelect: "none",
      }}
    >
      <Typography
        fontSize="inherit"
        variant="caption"
        color="primary"
        sx={{
          display: "inline-block",
        }}
      >
        <InfoIcon
          fontSize="inherit"
          sx={{
            verticalAlign: "text-top",
            mr: 0.5,
          }}
        ></InfoIcon>
        {`${drawingTypeTranslationMap[drawingType]}绘制说明`}
      </Typography>
      <Typography
        fontSize="inherit"
        variant="caption"
        color="primary"
        sx={{
          display: "block",
        }}
      >
        快捷键 {drawingTypeShortKeyMap[drawingType].toUpperCase()}
      </Typography>
      <Typography
        fontSize="inherit"
        variant="caption"
        sx={{
          color: "grey.600",
          display: "block",
        }}
      >
        {drawingTypeHelperTextMap[drawingType]}
      </Typography>
    </Box>
  ) : (
    <Typography
      fontSize={12}
      variant="caption"
      sx={{
        color: "grey.600",
        userSelect: "none",
        mt: 2,
        display: "block",
      }}
    >
      {commonHelperText}
    </Typography>
  );
}
