import { gql, useQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { userVar } from "../vars";
import { useNavigate } from "react-router-dom";

//fragments
const BASIC_USERS_FIELDS = gql`
  fragment BasicUserField on User {
    id
    name
    email
    profile
    role
    org
  }
`;

//queries
const CURRENT_USER = gql`
  ${BASIC_USERS_FIELDS}
  query currentUser {
    authenticatedItem {
      ...BasicUserField
    }
  }
`;

//mutations
export const LOGIN = gql`
  ${BASIC_USERS_FIELDS}
  mutation login($name: String!, $password: String!) {
    authenticateUserWithPassword(name: $name, password: $password) {
      ... on UserAuthenticationWithPasswordSuccess {
        sessionToken
        item {
          ...BasicUserField
        }
      }
      ... on UserAuthenticationWithPasswordFailure {
        message
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    endSession
  }
`;

//hooks query
export function useCurrentUser(options) {
  return useQuery(CURRENT_USER, options);
}

//hooks mutation
export function useLogin({ redirectUrl, ...options } = {}) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  return useMutation(LOGIN, {
    onCompleted: ({ authenticateUserWithPassword }) => {
      if (authenticateUserWithPassword.sessionToken) {
        enqueueSnackbar("登录成功", {
          variant: "success",
          key: "login-success",
        });
        userVar(authenticateUserWithPassword.item);

        if (redirectUrl) {
          navigate(redirectUrl);
        }
      } else {
        enqueueSnackbar("登陆失败，邮箱或密码不正确", {
          variant: "error",
          key: "login-fail",
        });
      }
    },
    onError: () => {
      enqueueSnackbar("登陆失败，邮箱或密码不正确", {
        variant: "error",
        key: "login-error",
      });
    },
    ...options,
  });
}

export function useLogout({ redirectUrl, ...options } = {}) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return useMutation(LOGOUT, {
    onCompleted: (data) => {
      enqueueSnackbar("您已成功退出登录", {
        variant: "success",
        key: "logout-success",
      });
      userVar(null);
      if (redirectUrl) {
        navigate(redirectUrl);
      }
    },
    onError: () => {
      enqueueSnackbar("退出登录失败", {
        variant: "error",
        key: "logout-fail",
      });
    },
    ...options,
  });
}
