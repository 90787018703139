import React, { useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
import { useTiledImage } from "../../../gql";

import { FullScreenIframe } from "../../../components";

export function TiledImageViewerIframe(props) {
  const { id } = useParams();
  const { data } = useTiledImage({
    variables: {
      where: { id },
    },
  });

  return (
    data &&
    (data.tiledImage ? (
      <FullScreenIframe
        src={data.tiledImage.viewerUrl}
        title={data.tiledImage.name}
        allowFullScreen
      ></FullScreenIframe>
    ) : (
      <Navigate to="/404" replace></Navigate>
    ))
  );
}
