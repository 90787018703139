import React from "react";
import { useReactiveVar } from "@apollo/client";
import { Entity, BillboardGraphics } from "resium";
import { Color, VerticalOrigin, Cartesian2 } from "cesium";

import { depthTestVar } from "../../../../gql";

import { iconVarientMap } from "../../icons";

import {
  radianArrayToCartesian3,
  createCesiumColor,
  useRequestRender,
} from "../../utils";
import { LabelRender } from "./LabelRender";
function BillboardEntity({ entityProps, graphicProps }) {
  //在性能优化的情况下，请求主动渲染
  useRequestRender();
  return (
    <Entity {...entityProps}>
      <BillboardGraphics
        verticalOrigin={VerticalOrigin.BOTTOM}
        // 始终显示不被遮挡
        // disableDepthTestDistance={Number.POSITIVE_INFINITY}
        {...graphicProps}
      />
    </Entity>
  );
}

//当billboard带有text属性时，需要同时渲染label，的显示属性效果
const labelProps = {
  fontSize: 16,
  color: "#000000",
  alpha: 1,
  showBackground: true,
  backgroundColor: "#ffffff",
  backgroundColorAlpha: 0.9,
  backgroundPaddingX: 6,
  backgroundPaddingY: 4,
  pixelOffset: new Cartesian2(0, 12),
};

export function BillboardRender({ data, ...props }) {
  //深度检测设置,耦合了全局设置，破坏独立性
  const { enableDepthTest } = useReactiveVar(depthTestVar);
  const disableDepthTestDistance = enableDepthTest
    ? undefined
    : Number.POSITIVE_INFINITY;

  const { type, id, position, iconType, color, alpha, text, ...extraProps } =
    data;

  const billboardEntityProps = {
    entityProps: { id, position: radianArrayToCartesian3(position), ...props },
    graphicProps: {
      image: iconVarientMap[iconType],
      color: createCesiumColor({ color, alpha }),
      disableDepthTestDistance,
      ...extraProps,
    },
  };

  return (
    <>
      <BillboardEntity {...billboardEntityProps}></BillboardEntity>
      {text && (
        <LabelRender
          data={{
            id: id + "-label",
            position,
            text,
            ...labelProps,
          }}
          {...props}
        ></LabelRender>
      )}
    </>
  );
}
