import React from "react";

import { ScreenSpaceEvent, useCesium } from "resium";
import { ScreenSpaceEventType, defined } from "cesium";

import { ECEF2LatLongDegrees } from "../../../utils";
import { ToolResultPanel } from "../../panel";
import { PositionLabel, PositionBillBoard } from "../../geometry";

export function PositionToolEvent(props) {
  const { viewer } = useCesium();
  const [position, setPosition] = React.useState(null);
  const coord = position ? ECEF2LatLongDegrees(position) : null;
  return (
    <>
      {/* 事件定义 左键点击后修改state*/}
      <ScreenSpaceEvent
        action={(event) => {
          try {
            const cartesian = viewer.scene.pickPosition(event.position);

            if (defined(cartesian)) {
              //生产环境保留此输出，方便内部用户获取ECEF坐标
              console.log(`拾取坐标结果(ECEF)坐标系坐标`, cartesian);
              console.log(`${cartesian.x},${cartesian.y},${cartesian.z}`);
              setPosition(cartesian);
            }
          } catch (e) {
            console.log(e);
          }
        }}
        type={ScreenSpaceEventType.LEFT_CLICK}
      ></ScreenSpaceEvent>
      {coord && (
        <>
          {/* 渲染测量点 */}
          <PositionBillBoard
            position={position}
            iconVarient="place"
            color="RED"
          ></PositionBillBoard>

          {/* 测量点上方渲染标签 */}
          <PositionLabel
            position={position}
            text={coord.longitude + "\n" + coord.latitude + "\n" + coord.height}
            offsetY={-40}
          ></PositionLabel>

          {/* 显示结果面板 */}
          <ToolResultPanel
            panelTitle="坐标拾取结果"
            toolName="positionTool"
            data={coord}
            dataKeys={[
              {
                title: "经度",
                name: "longitude",
              },
              {
                title: "纬度",
                name: "latitude",
              },
              {
                title: "高程",
                name: "height",
              },
            ]}
            dataUnit=""
            copyText={`${coord.longitude}, ${coord.latitude},${coord.height}`}
          ></ToolResultPanel>
        </>
      )}
    </>
  );
}
