import React from "react";
import { useReactiveVar } from "@apollo/client";
import { NearFarScalar } from "cesium";

import { PositionBillBoard, PositionLabel } from "../../../components";

import { labelVar, hoverEntityIdVar, depthTestVar } from "../../../gql";
import { useSceneContext } from "../../../hooks";
import { createPosition, getLabelTextByKey } from "../../../utils";

export function BillboardProxy({ show, asset, ...props }) {
  //深度检测设置
  const { enableDepthTest } = useReactiveVar(depthTestVar);
  const disableDepthTestDistance = enableDepthTest
    ? undefined
    : Number.POSITIVE_INFINITY;

  const {
    config: {
      labelSettings,
      billboardSettingMap,
      billboardHoverSettingMap,
      billboardScale,
      billboardScaleHover,
    },
    scene: { near, far },
  } = useSceneContext();

  const scaleByDistance = React.useMemo(
    () =>
      new NearFarScalar(
        near,
        billboardScale,
        far,
        (billboardScale * near) / far
      ),
    [near, far, billboardScale]
  );
  const scaleByDistanceHover = React.useMemo(
    () =>
      new NearFarScalar(
        near,
        billboardScaleHover,
        far,
        (billboardScaleHover * near) / far
      ),
    [near, far, billboardScaleHover]
  );
  const scaleByDistanceLabel = React.useMemo(
    () => new NearFarScalar(near, 1, far, (1 * near) / far),
    [near, far, billboardScaleHover]
  );

  let hoverEntityId = useReactiveVar(hoverEntityIdVar);

  let billboardSettings =
    hoverEntityId === asset.id
      ? {
          ...billboardHoverSettingMap[asset.digitalAsset.type],
          BillboardGraphicsProps: {
            ...billboardHoverSettingMap[asset.digitalAsset.type][
              "BillboardGraphicsProps"
            ],
            scaleByDistance: scaleByDistanceHover,
            disableDepthTestDistance,
          },
        }
      : {
          ...billboardSettingMap[asset.digitalAsset.type],
          BillboardGraphicsProps: {
            ...billboardSettingMap[asset.digitalAsset.type][
              "BillboardGraphicsProps"
            ],
            scaleByDistance: scaleByDistance,
            disableDepthTestDistance,
          },
        };

  const labelSettingWithNearFarScaler = {
    ...labelSettings,
    LabelGraphicsProps: {
      ...labelSettings.LabelGraphicsProps,
      scaleByDistance: scaleByDistanceLabel,
      pixelOffsetScaleByDistance: scaleByDistanceLabel,
      disableDepthTestDistance,
    },
  };
  const { show: showLabel, key } = useReactiveVar(labelVar);

  let position;

  // 此处必须判断,避免非renderType在inherit等其他情况下,也渲染billboardProxy
  if (asset.renderType === "billboard") {
    position = createPosition(asset.position);
  }

  //处理特殊情况 当全景使用inherit渲染模式时，也使用Billboard进行渲染
  if (
    asset.renderType === "inherit" &&
    asset.digitalAsset.panorama &&
    asset.digitalAsset.panorama.position
  ) {
    position = createPosition(asset.digitalAsset.panorama.position);
  }

  return (
    position && (
      <>
        <PositionBillBoard
          position={position}
          id={asset.id}
          show={show}
          {...billboardSettings}
          {...props}
        ></PositionBillBoard>
        <PositionLabel
          position={position}
          show={show && showLabel}
          text={getLabelTextByKey({ asset, key })}
          {...labelSettingWithNearFarScaler}
        ></PositionLabel>
      </>
    )
  );
}
