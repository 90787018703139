import React from "react";
import { useReactiveVar } from "@apollo/client";

import Stack from "@mui/material/Stack";

import Input from "@mui/material/Input";
//icons
import { zoomVar, setZoomDistance } from "../../../../gql";
import { ZoomSettingSlider } from "./ZoomSettingSlider";

export function ZoomDistanceSlider({ defaultZoomDistance }) {
  const { zoomDistance } = useReactiveVar(zoomVar);

  React.useEffect(() => {
    if (zoomDistance !== defaultZoomDistance) {
      setZoomDistance(defaultZoomDistance);
    }
  }, [defaultZoomDistance]);

  const handleInputChange = (event) => {
    setZoomDistance(
      event.target.value === "" ? "" : Number(event.target.value)
    );
  };
  const handleBlur = () => {
    if (zoomDistance < 1) {
      setZoomDistance(1);
    } else if (zoomDistance > 512) {
      setZoomDistance(512);
    }
  };
  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <ZoomSettingSlider
        value={zoomDistance}
        setValue={setZoomDistance}
        max={512}
        min={1}
        unit=" m"
        defaultValue={defaultZoomDistance}
      ></ZoomSettingSlider>

      <Input
        value={zoomDistance}
        size="small"
        onChange={handleInputChange}
        onBlur={handleBlur}
        inputProps={{
          step: 1,
          min: 1,
          max: 512,
          type: "number",
        }}
        sx={{
          minWidth: 42,
        }}
      />
    </Stack>
  );
}
