import { gql, useQuery } from "@apollo/client";

//fragments
const VTOUR_FIELDS = gql`
  fragment VtourFields on Vtour {
    id
    name
    viewerUrl
  }
`;

//queries
const GET_VTOUR_BY_ID = gql`
  ${VTOUR_FIELDS}
  query Vtour($where: VtourWhereUniqueInput!) {
    vtour(where: $where) {
      ...VtourFields
    }
  }
`;

const GET_VTOURS = gql`
  ${VTOUR_FIELDS}
  query Vtours($where: VtourWhereInput! = {}) {
    vtours(where: $where) {
      ...VtourFields
    }
  }
`;

//mutations

//hooks query
export function useVtour(options) {
  return useQuery(GET_VTOUR_BY_ID, options);
}

export function useVtours(options) {
  return useQuery(GET_VTOURS, options);
}

//hooks mutation
