import React from "react";
import { useReactiveVar } from "@apollo/client";

import TreeView from "@mui/lab/TreeView";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

//icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LabelIcon from "@mui/icons-material/Label";
import EditIcon from "@mui/icons-material/Edit";

//local
import { SettingsAccordion, SettingLabel } from "../..";
import { useSceneContext } from "../../../hooks";
import { AssetLabelSetting } from "./AssetLabelSetting";
import { AssetOverlayIframeSetting } from "./AssetOverlayIframeSetting";
import { TreeItems } from "./TreeItems";
import { AssetSearchField } from "./AssetSearchField";
import { userIsAdminOrEditor } from "../../../accessControl";
import { userVar } from "../../../gql";

function handleEditClick(id) {
  window.open(`${process.env.REACT_APP_ADMIN_URI}/digital-scenes/${id}`);
}

export function AssetsTreeSetting() {
  const {
    scene: { id },
    assets,
    config,
    renderMap,
    toggleAssetRenderStatus,
    renderTypeMap,
    toggleTypeRenderStatus,
  } = useSceneContext();

  const user = useReactiveVar(userVar);

  //searchFilter
  const [search, setSearch] = React.useState("");

  return (
    <>
      <SettingsAccordion
        label="数字资产"
        defaultExpanded
        // summary=
      >
        <AssetSearchField
          search={search}
          setSearch={setSearch}
          sx={{
            mb: 2,
          }}
        ></AssetSearchField>
        <TreeView
          sx={{ mb: 2 }}
          aria-label="digital assets tree view"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {config.allAssetsType.map(
            (assetType) =>
              assets[assetType].length > 0 && (
                <TreeItems
                  search={search}
                  key={assetType}
                  assetType={assetType}
                  config={config}
                  assets={assets[assetType]}
                  renderMap={renderMap}
                  toggleAssetRenderStatus={toggleAssetRenderStatus}
                  renderTypeMap={renderTypeMap}
                  toggleTypeRenderStatus={toggleTypeRenderStatus}
                ></TreeItems>
              )
          )}
        </TreeView>

        <SettingLabel
          label="资产设置"
          icon={<LabelIcon fontSize="small"></LabelIcon>}
          tooltipProps={{
            title: "资产的显示设置及交互设置",
            placement: "right",
          }}
        >
          <AssetLabelSetting></AssetLabelSetting>
          <AssetOverlayIframeSetting></AssetOverlayIframeSetting>
        </SettingLabel>
        {userIsAdminOrEditor(user) && (
          <Tooltip title="编辑数字场景" placement="left" arrow>
            <IconButton
              sx={{ position: "absolute", bottom: 8, right: 8 }}
              onClick={() => {
                handleEditClick(id);
              }}
              color="primary"
              size="small"
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
      </SettingsAccordion>
    </>
  );
}
