import React from "react";
import { useReactiveVar } from "@apollo/client";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import { SettingLabel } from "../../setting";

import { depthTestVar, setEnableDepthTest } from "../../../../gql";

export function DepthTestSetting() {
  const { enableDepthTest } = useReactiveVar(depthTestVar);

  const handleDepthTestChange = (event) => {
    setEnableDepthTest(event.target.checked);
  };
  return (
    <SettingLabel
      label="深度检测"
      icon={<AutoAwesomeMotionIcon fontSize="small"></AutoAwesomeMotionIcon>}
      tooltipProps={{
        title: "设置渲染点与面的深度检测开启与关闭",
        placement: "right",
      }}
    >
      <FormControlLabel
        control={
          <Switch checked={enableDepthTest} onChange={handleDepthTestChange} />
        }
        label="开启深度检测"
      />
    </SettingLabel>
  );
}
