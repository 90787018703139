import React from "react";

import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="bottom" title={value} arrow>
      {children}
    </Tooltip>
  );
}

export function ZoomSettingSlider({
  setValue,
  value,
  max,
  min,
  presetMarks,
  unit,
  defaultValue,
}) {
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDoubleClick = () => {
    if (defaultValue !== undefined) {
      setValue(defaultValue);
    }
  };
  return (
    <Slider
      value={value}
      onChange={handleSliderChange}
      max={max}
      min={min}
      valueLabelDisplay="auto"
      components={{
        ValueLabel: ValueLabelComponent,
      }}
      marks={presetMarks}
      onDoubleClick={handleDoubleClick}
      valueLabelFormat={(x) => `${x}${unit ? unit : ""}`}
    />
  );
}
