import React from "react";
import { useReactiveVar } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Draggable from "react-draggable";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import { useWysiwyg, userVar } from "../../gql";
import { Document } from "..";
import { userIsAdminOrEditor } from "../../accessControl";

//用于控制Dialog拖动
function DraggablePaper(props) {
  return (
    <Draggable handle="#draggable-wysiwyg-title">
      <Paper {...props} />
    </Draggable>
  );
}

export function WysiwygDocument({ id }) {
  const { data } = useWysiwyg({
    variables: {
      where: { id },
    },
  });
  return (
    data &&
    data.wysiwyg && (
      <Document document={data.wysiwyg.content.document}></Document>
    )
  );
}

// 使用时注意，避免直接渲染此请求，因为会导致大量数据库查询,套一层判断，仅在open=true时渲染此组件
export function WysiwygDialog({ id, open, handleClose, title, editUri }) {
  const user = useReactiveVar(userVar);

  const theme = useTheme();
  //小于md时全屏展示
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={mdDown}
      PaperComponent={DraggablePaper}
      aria-labelledby="draggable-wysiwyg-title"
      maxWidth={xlUp ? "lg" : "md"}
      scroll="paper"
      sx={{
        zIndex: (theme) => theme.zIndex.wysiwygDialog,
      }}
    >
      {title && (
        <DialogTitle
          id="draggable-wysiwyg-title"
          sx={{
            cursor: "move",
          }}
        >
          <Typography variant="h4" color="initial" component="span">
            {title}
          </Typography>
        </DialogTitle>
      )}

      <DialogContent dividers>
        {<WysiwygDocument id={id}></WysiwygDocument>}
      </DialogContent>

      <DialogActions>
        {userIsAdminOrEditor(user) && (
          <Button
            onClick={() => {
              if (editUri) {
                window.open(editUri);
              }
            }}
          >
            编辑
          </Button>
        )}
        <Button onClick={handleClose}>关闭</Button>
      </DialogActions>
    </Dialog>
  );
}
