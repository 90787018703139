import React from "react";
import { useSearchParams } from "react-router-dom";

import { useCommentContext } from "../../../hooks";
import { CommentRenderer } from "./CommentRenderer";

let initDrawingComments = true;

export function DrawingComments(props) {
  const { comments } = useCommentContext();
  //获取comment search param 用于自动飞行至此评论
  const [search] = useSearchParams();
  const searchCommentId = search.get("comment");

  //首次加载后修改initDrawingComments参数,确保flyTo始终未false
  React.useEffect(() => {
    initDrawingComments = false;
  }, []);

  return (
    <>
      {comments.map((comment) => (
        <CommentRenderer
          comment={comment}
          key={comment.id}
          flyTo={initDrawingComments && searchCommentId === comment.id}
        ></CommentRenderer>
      ))}
    </>
  );
}
