import { gql, useQuery } from "@apollo/client";

//fragments
const COMMENT_TAG_FIELDS = gql`
  fragment CommentTagFields on CommentTag {
    id
    name
    order
    description
  }
`;

//queries
const GET_COMMENT_TAG_BY_ID = gql`
  ${COMMENT_TAG_FIELDS}
  query TiledImage($where: TiledImageWhereUniqueInput!) {
    commentTag(where: $where) {
      ...CommentTagFields
    }
  }
`;

//queries
const GET_COMMENT_TAGS = gql`
  ${COMMENT_TAG_FIELDS}
  query TiledImages(
    $where: CommentTagWhereInput! = {}
    $orderBy: [CommentTagOrderByInput!]!
  ) {
    commentTags(where: $where, orderBy: $orderBy) {
      ...CommentTagFields
    }
  }
`;

//mutations

//hooks query
export function useCommentTag(options) {
  return useQuery(GET_COMMENT_TAG_BY_ID, options);
}

export function useCommentTags(options) {
  return useQuery(GET_COMMENT_TAGS, options);
}

//hooks mutation
