import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

//icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export function SettingsAccordion({
  label,
  children,
  initExpanded = false,
  ...props
}) {
  return (
    <Accordion
      square
      disableGutters
      elevation={0}
      sx={{
        width: "100%",
        bgcolor: "transparent",
      }}
      {...props}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          px: 1,
          // "&.Mui-expanded": {
          //   // bgcolor: "grey.100",
          //   bgcolor: "inherit",
          //   // bgcolor: "background.alphaBlack",
          // },
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
          }}
        >
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          mt: 1,
          p: 1,
          position: "relative",
          // "&:not(:last-child)": {
          //   pb: 2,
          // },
        }}
      >
        {children}
        {/* <Divider></Divider> */}
      </AccordionDetails>
    </Accordion>
  );
}
