import React from "react";
import { useReactiveVar } from "@apollo/client";

import { useDsHotspots, dsHotspotVar } from "../../../gql";
import { useSceneContext } from "../../../hooks";
import { DsHotspotRenderer } from "./DsHotspotRenderer";

export function DsHotspots() {
  const {
    scene: { id },
  } = useSceneContext();

  const { showHotspot } = useReactiveVar(dsHotspotVar);

  const { data } = useDsHotspots({
    variables: {
      where: {
        digitalScene: {
          id: {
            equals: id,
          },
        },
      },
    },
  });

  return (
    showHotspot && (
      <>
        {data &&
          data.dsHotspots &&
          data.dsHotspots.map((dsHotspot) => (
            <DsHotspotRenderer
              dsHotspot={dsHotspot}
              key={dsHotspot.id}
            ></DsHotspotRenderer>
          ))}
      </>
    )
  );
}
