import React from "react";
import { Entity, LabelGraphics } from "resium";
import { Cartesian2, Color, VerticalOrigin, LabelStyle } from "cesium";

//在线段重点渲染一个label描述各类工具的计算结果
export function PositionLabel({
  text,
  offsetY,
  LabelGraphicsProps,
  font,
  ...props
}) {
  return (
    <Entity {...props}>
      <LabelGraphics
        text={text}
        pixelOffset={new Cartesian2(0, offsetY)}
        font={`14px "Microsoft Yahei"`}
        verticalOrigin={VerticalOrigin.BOTTOM}
        // 始终显示不被遮挡
        disableDepthTestDistance={Number.POSITIVE_INFINITY}
        // default styles 白底黑字
        style={LabelStyle.FILL}
        fillColor={Color.BLACK}
        showBackground
        backgroundColor={Color.WHITE}
        backgroundPadding={new Cartesian2(10, 10)}
        {...LabelGraphicsProps}
      ></LabelGraphics>
    </Entity>
  );
}
