import React from "react";
import { useReactiveVar } from "@apollo/client";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSceneContext } from "../../../hooks";

import {
  drawingStatusVar,
  geometrySettingVar,
  dispatchdrawingStatusVar,
  dispatchDrawingToolVar,
  dispatchGeometrySettingVar,
} from "../drawingToolVars";
import { DrawingSettingContainer } from "./DrawingSettingContainer";
import { GeometrySettingForm } from "./GeometrySettingForm";

import { drawingTypeTranslationMap } from "../config";
import {
  billboardSettingSchema,
  billboardSettingUiSchema,
  polylineSettingSchema,
  polylineSettingUiSchema,
  polygonSettingSchema,
  polygonSettingUiSchema,
  polygonSettingFormProps,
  pointSettingSchema,
  pointSettingUiSchema,
  labelSettingSchema,
  labelSettingUiSchema,
  labelSettingFormProps,
  modelSettingSchema,
  modelSettingUiSchema,
} from "./schema";

export function DrawingSettingSavePanel(props) {
  const sceneData = useSceneContext();
  const { drawingType } = useReactiveVar(drawingStatusVar);
  const title = `${drawingTypeTranslationMap[drawingType]}属性设置`;

  const handleClose = React.useCallback(() => {
    dispatchdrawingStatusVar({
      type: "RESET_DRAWING_TYPE",
    });
  }, []);

  const setDefaultOrigin = React.useCallback(() => {
    //只有当工具处于数字场景context时才执行此操作
    if (sceneData) {
      dispatchGeometrySettingVar({
        type: "SET_GEOMETRY_SETTING",
        payload: {
          geometryType: "model",
          data: {
            ...geometrySettingVar().model,
            orientation: { heading: 90, pitch: 0, roll: 0 },
          },
        },
      });
      const origin = sceneData.scene.origin.split(",");

      const originRadianArray = origin
        .map((num) => {
          return (parseFloat(num) * Math.PI) / 180;
        })
        .slice(0, 2);
      originRadianArray.push(parseFloat(origin[2]));
      console.log(originRadianArray);

      dispatchDrawingToolVar({
        type: "SET_EDIT_GEOMETRY",
        payload: {
          type: "model",
          position: originRadianArray,
        },
      });
    }
  }, [sceneData]);

  return (
    drawingType && (
      <DrawingSettingContainer
        title={title}
        resetGeometryType={drawingType}
        handleClose={handleClose}
      >
        <Box sx={{ px: 3, pb: 1 }}>
          {drawingType === "billboard" && (
            <GeometrySettingForm
              geometryType="billboard"
              schema={billboardSettingSchema}
              uiSchema={billboardSettingUiSchema}
            ></GeometrySettingForm>
          )}
          {drawingType === "point" && (
            <GeometrySettingForm
              geometryType="point"
              schema={pointSettingSchema}
              uiSchema={pointSettingUiSchema}
            ></GeometrySettingForm>
          )}
          {drawingType === "polyline" && (
            <GeometrySettingForm
              geometryType="polyline"
              schema={polylineSettingSchema}
              uiSchema={polylineSettingUiSchema}
            ></GeometrySettingForm>
          )}
          {drawingType === "polygon" && (
            <GeometrySettingForm
              {...polygonSettingFormProps}
              geometryType="polygon"
              schema={polygonSettingSchema}
              uiSchema={polygonSettingUiSchema}
            ></GeometrySettingForm>
          )}
          {drawingType === "label" && (
            <GeometrySettingForm
              {...labelSettingFormProps}
              geometryType="label"
              schema={labelSettingSchema}
              uiSchema={labelSettingUiSchema}
            ></GeometrySettingForm>
          )}
          {drawingType === "model" && (
            <GeometrySettingForm
              geometryType="model"
              schema={modelSettingSchema}
              uiSchema={modelSettingUiSchema}
            >
              {sceneData && (
                <Button onClick={setDefaultOrigin}>设置默认原点与旋转</Button>
              )}
            </GeometrySettingForm>
          )}
        </Box>
      </DrawingSettingContainer>
    )
  );
}
