import React from "react";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

//icon
import CloseIcon from "@mui/icons-material/Close";

import { FixedPanel } from "../../../panel";
import { closeFpvPanel } from "../../../../gql";

import { FpvSlider } from "./FpvSlider";
import { FpvModeRadio } from "./FpvModeRadio";
export function FpvPanel({ children, ...props }) {
  return (
    <FixedPanel panelTitle="第一人称设置" {...props}>
      <Typography variant="body1" gutterBottom>
        W:前 S:后 A:左 D:右 Q:上 E:下
      </Typography>
      <Typography variant="body1" gutterBottom>
        鼠标点击拖动:转向 滚轮:调整速度
      </Typography>
      <Divider variant="fullWidth" />

      <FpvModeRadio></FpvModeRadio>
      <FpvSlider></FpvSlider>

      <Divider variant="fullWidth" />
      <Button
        color="error"
        variant="contained"
        onClick={closeFpvPanel}
        startIcon={<CloseIcon></CloseIcon>}
        fullWidth
        sx={{
          mt: 1,
        }}
      >
        关闭
      </Button>
    </FixedPanel>
  );
}
