import React from "react";

import { CustomForm } from "../../../../schemaForms";
import { createFieldsSchema, createFieldsUiSchema } from "../../../../meta";

export const CreateFieldsForm = React.forwardRef((props, ref) => (
  <CustomForm
    showErrorList={false}
    ref={ref}
    liveOmit
    omitExtraData
    noHtml5Validate
    schema={createFieldsSchema}
    uiSchema={createFieldsUiSchema}
    {...props}
  ></CustomForm>
));
