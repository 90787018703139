import React from "react";
import { useReactiveVar } from "@apollo/client";

import { Cartesian3 } from "cesium";

import { ModelEntity } from "../../../components";

import { sceneOriginVar, labelVar, hoverEntityIdVar } from "../../../gql";
import { useSceneContext } from "../../../hooks";

import { createPosition, createOrientation } from "../../../utils";

export function ModelProxy({ show, asset, ...props }) {
  const {
    config: { modelSetting, modelHoverSetting },
  } = useSceneContext();

  let hoverEntityId = useReactiveVar(hoverEntityIdVar);

  //模型渲染效果设置
  let modelRenderSetting = modelSetting;
  if (hoverEntityId === asset.id) {
    modelRenderSetting = modelHoverSetting;
  }

  const { sceneOrigin } = useReactiveVar(sceneOriginVar);

  let uri;
  let origin;
  if (asset.model) {
    uri = asset.model.url;
  }
  if (asset.modelOrigin) {
    origin = createPosition(asset.modelOrigin);
  } else {
    origin = Cartesian3.clone(sceneOrigin);
  }

  return (
    uri &&
    origin && (
      <ModelEntity
        position={origin}
        id={asset.id}
        show={show}
        uri={uri}
        orientation={createOrientation({
          position: origin,
          heading: asset.modelHeading,
          pitch: asset.modelPitch,
          roll: asset.modelRoll,
        })}
        modelGraphicsProps={{
          runAnimations: asset.modelAnimate,
          scale: asset.modelScale,
        }}
        {...modelRenderSetting}
        {...props}
      ></ModelEntity>
    )
  );
}
