import { makeVar } from "@apollo/client";

// const legalKeys = ["open", "heading"];

const defaultValue = {
  open: true,
  heading: null,
};

export const compassVar = makeVar(defaultValue);

export function setCompassHeading(heading) {
  compassVar({
    ...compassVar(),
    heading,
  });
}

export function toggleCompass() {
  compassVar({
    ...compassVar(),
    open: !compassVar().open,
  });
}
