//源对象内容渲染
import React from "react";
import SwipeableViews from "react-swipeable-views";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ListAltIcon from "@mui/icons-material/ListAlt";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import { SourceObjectWysiwyg, SourceObjectData } from "./components";

//SwipeableViews，如果传入null作为children，会报错，这样hack一下
function SwipeableViewsAuto({ children, ...props }) {
  const nonNullChildren = React.useMemo(() => {
    return children.filter((item) => item !== null);
  }, [children]);
  return <SwipeableViews {...props}>{nonNullChildren}</SwipeableViews>;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box role="tabpanel" hidden={value !== index} {...other}>
      {value === index && children}
    </Box>
  );
}

export function SourceObjectContentTabs({ sourceObject, meta }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable"
        sx={{
          px: 2,
          "& .MuiTab-root": {
            minHeight: 56,
            " :not(:last-child)": {
              mr: {
                xs: 1,
                md: 2,
              },
            },
          },
        }}
      >
        {meta.jsonSchema && (
          <Tab
            icon={<ListAltIcon />}
            iconPosition="start"
            label="基础属性"
            sx={{
              py: 0.5,
              px: 1,
            }}
          /> //不设value,根据index自动
        )}
        {sourceObject.editContent && (
          <Tab icon={<WysiwygIcon />} iconPosition="start" label="自定义文档" /> //不设value,根据index自动
        )}
      </Tabs>

      {/* mobile touch can swipe */}
      <SwipeableViewsAuto index={value} onChangeIndex={handleChangeIndex}>
        {meta.jsonSchema && (
          <TabPanel value={value} index={0}>
            <SourceObjectData
              sourceObject={sourceObject}
              meta={meta}
              sx={{
                py: 1,
                px: 3,
                maxHeight: "60vh",
              }}
            ></SourceObjectData>
          </TabPanel>
        )}
        {sourceObject.editContent && (
          <TabPanel
            value={meta.jsonSchema ? 1 : 0}
            index={meta.jsonSchema ? 1 : 0}
          >
            <SourceObjectWysiwyg
              sourceObject={sourceObject}
              meta={meta}
              sx={{
                py: 1,
                px: 3,
                maxHeight: "60vh",
              }}
            ></SourceObjectWysiwyg>
          </TabPanel>
        )}
      </SwipeableViewsAuto>
    </Box>
  );
}
