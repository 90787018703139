import React from "react";
import { useSnackbar } from "notistack";

import Form from "@rjsf/mui";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { useUpdateCommentTagTreeConfig } from "../../../gql";
import { validator } from "../../../schemaForms";
import { TagTreeView } from "./TagTreeView";

const uiSchema = {
  //后端程序自动添加此字段
  //   "ui:rootFieldId": "OptionTestForm",
  "ui:submitButtonOptions": {
    submitText: "上传配置",
  },
  items: {
    color: {
      "ui:widget": "color",
      "ui:classNames": "custom-color-field",
    },
  },
};

export function CommentTagTreeConfigPageEditForm({ commentTagTree, tags }) {
  //mutation
  const [updateCommentTagTreeConfig] = useUpdateCommentTagTreeConfig();
  const { enqueueSnackbar } = useSnackbar();

  const { name, description, treeDef } = commentTagTree;
  const [formData, setFormData] = React.useState(treeDef || []);

  const tagChoices = tags.map(({ id, name }) => ({
    const: id,
    title: name,
  }));
  const definitions = {
    definitions: {
      root: {
        type: "object",
        title: "根节点",
        properties: {
          name: {
            type: "string",
            title: "根节点名称",
            default: "节点名称",
          },
          color: {
            type: "string",
            title: "颜色",
            default: "#376fd0",
            description: "根节点颜色设置",
          },
          children: {
            type: "array",
            title: "子节点",
            minItems: 1,
            items: {
              $ref: "#/definitions/node",
            },
          },
        },
        required: ["name", "color"],
      },
      node: {
        type: "object",
        title: "节点",
        properties: {
          isLastNode: {
            title: "是否是最终节点",
            type: "boolean",
            default: true,
          },

          // required: ["isLastNode"], //升级beta19以后，此配置会导致schema验证失败
        },
        dependencies: {
          isLastNode: {
            oneOf: [
              {
                required: ["isLastNode", "name", "children"],

                properties: {
                  isLastNode: {
                    enum: [false],
                  },
                  name: {
                    type: "string",
                    title: "节点名称",
                    default: "节点",
                  },

                  children: {
                    type: "array",
                    minItems: 1,
                    items: {
                      $ref: "#/definitions/node",
                    },
                  },
                },
              },
              {
                required: ["isLastNode", "tagId"],
                properties: {
                  isLastNode: {
                    enum: [true],
                  },
                  tagId: {
                    title: "标签",
                    type: "string",
                    anyOf: tagChoices,
                  },
                },
              },
            ],
          },
        },
      },
      tag: {
        type: "object",
        title: "标签实例",
        properties: {
          id: {
            type: "string",
            title: "标签id",
          },
          name: {
            type: "string",
            title: "标签name",
          },
        },
        required: ["id", "name"],
      },
    },
  };
  const schema = React.useMemo(
    () => ({
      ...definitions,
      title: name,
      description: description,
      type: "array",

      items: {
        $ref: "#/definitions/root",
      },
    }),
    [name, description]
  );

  const handleSubmit = React.useCallback(async (e) => {
    console.log("onSubmit", e.formData);
    try {
      const res = await updateCommentTagTreeConfig({
        variables: {
          data: {
            treeDef: e.formData,
          },
        },
      });
      if (res.errors) {
        enqueueSnackbar("编辑标签树失败", {
          variant: "error",
          key: "update-comments-tag-tree-error",
        });
      } else {
        enqueueSnackbar("成功编辑标签树", {
          variant: "success",
          key: "update-comments-tag-tree-sucess",
        });
      }
    } catch (error) {
      enqueueSnackbar("编辑标签树失败", {
        variant: "error",
        key: "update-comments-tag-tree-error",
      });
    }
  }, []);
  return (
    <Box
      sx={{
        mt: 8,
        maxWidth: "90vw",
        mx: "auto",
        "& .custom-color-field": {
          maxWidth: 400,
        },
      }}
    >
      <Typography
        variant="button"
        color="initial"
        gutterBottom
        display="block"
        sx={{
          width: 1,
        }}
      >
        {commentTagTree.inUse ? (
          <>
            配置正在启用中,仅支持查看,无法编辑,如需编辑请前往后台
            <Button
              variant="text"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_ADMIN_URI}/comment-tag-tree/1`
                )
              }
            >
              修改配置
            </Button>
            后再来此处可视化编辑
          </>
        ) : (
          <>
            配置未启用,如需启用请前往后台
            <Button
              variant="text"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_ADMIN_URI}/comment-tag-tree/1`
                )
              }
            >
              修改配置
            </Button>
          </>
        )}
      </Typography>

      <Box>
        <TagTreeView tree={formData} tags={tags}></TagTreeView>
      </Box>
      <Form
        readonly={commentTagTree.inUse}
        noHtml5Validate
        liveValidate
        liveOmit
        schema={schema}
        validator={validator}
        uiSchema={uiSchema}
        formData={formData}
        onChange={(e) => {
          console.log("onChange", e.formData);
          setFormData(e.formData);
        }}
        onError={(e) => {
          console.log("onError", e.formData);
          console.log("onError", e);
        }}
        onSubmit={handleSubmit}
        // templates={{ ErrorListTemplate }}
        showErrorList={false}
        // liveOmit
        omitExtraData
      ></Form>
    </Box>
  );
}
