import React from "react";
import Paper from "@mui/material/Paper";

import { Navigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { cloneDeep } from "lodash";

import { useDsCollection } from "../../gql";
import { Logo, JsonView } from "../../components";
import { DsCollectionViewer } from "./DsCollectionViewer";

import { createDigitalSceneData } from "../../utils";

//config
const typeAvailable = "timeline";

export function DsCollectionPage() {
  const { id } = useParams();

  const { data } = useDsCollection({
    variables: {
      where: { id },
      orderBy: [
        {
          timePoint: "asc", //按照时间轴顺序返回的数字场景
        },
      ],
    },
  });

  const dsCollectionData = [];

  //data返回后,初始化各类参数
  if (data && data.dsCollection && data.dsCollection.type === typeAvailable) {
    data.dsCollection.DigitalSceneLinks.forEach(
      ({ digitalScene, ...sceneLink }) => {
        const digitalSceneData = createDigitalSceneData(digitalScene);

        //初始化生成dsCollectionData
        dsCollectionData.push({
          sceneLink,
          ...digitalSceneData,
        });
      }
    );
  }
  return (
    data &&
    (data.dsCollection &&
    data.dsCollection.type === typeAvailable &&
    dsCollectionData.length > 0 ? (
      <>
        <Helmet>
          <title>{data.dsCollection.name}</title>
        </Helmet>
        {/* <Paper
          sx={{
            maxWidth: 800,
            m: "auto",
            p: 2,
          }}
        >
          <JsonView json={dsCollectionData}></JsonView>
        </Paper> */}
        <DsCollectionViewer
          dsCollectionData={dsCollectionData}
        ></DsCollectionViewer>
        <Logo></Logo>
      </>
    ) : (
      <Navigate to="/404" replace></Navigate>
    ))
  );
}
