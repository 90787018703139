import React from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import ArticleIcon from "@mui/icons-material/Article";
import { CommentDialog } from "../../drawingComment/CommentDialog";

export function OpenContentDialogAction({ params }) {
  const { id } = params;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <GridActionsCellItem
        icon={<ArticleIcon color="primary" />}
        label="查看内容"
        onClick={handleOpen}
        showInMenu
        sx={{
          color: "primary.main",
        }}
      />
      <CommentDialog
        open={open}
        handleClose={handleClose}
        id={id}
      ></CommentDialog>
    </>
  );
}
