//用于创建元数据模式的表单
import { fieldTypesDef, fieldTypes } from "./config";

const fieldTypeChoices = fieldTypes.map((key) => ({
  type: "string",
  title: fieldTypesDef[key].title,
  enum: [key],
}));

const fieldTypeDependency = {
  oneOf: fieldTypes.map(
    (key) =>
      fieldTypesDef[key].dependency || {
        required: ["fieldType"],
        properties: {
          fieldType: {
            enum: [key],
          },
        },
      }
  ),
};

//用于生成schema uiSchema的表单配置的schema
export const createFieldsSchema = {
  type: "array",
  items: {
    type: "object",
    required: ["fieldType", "key", "title", "required"],
    properties: {
      required: {
        type: "boolean",
        title: "是否为必填字段",
        default: true,
      },
      key: {
        type: "string",
        title: "字段键名称(英文)",
        description:
          "后台存储时使用的键,设置后请勿更改,否则已经填写的数据将会失效",
      },
      title: {
        type: "string",
        title: "字段名称(中文)",
      },
      description: {
        type: "string",
        title: "字段填写描述",
        description: "会在用户填写表单时作为字段填写描述辅助",
      },
      fieldType: {
        type: "string",
        title: "字段类型",
        anyOf: fieldTypeChoices,
        default: "textInput",
      },
      // test: {
      //   type: "string",
      //   // format: "data-url",
      //   title: "字段填写描述",
      //   description: "会在用户填写表单时作为字段填写描述辅助",
      // },
    },
    dependencies: {
      fieldType: fieldTypeDependency,
    },
  },
};

export const createFieldsUiSchema = {
  items: {
    // required: {
    //   "ui:widget": "radio", // or "select"
    // },
    schema: {
      items: {
        type: {
          "ui:options": {
            widget: "hidden",
          },
        },
      },
    },
  },
};
