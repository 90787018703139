import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

//icons
import TimelineIcon from "@mui/icons-material/Timeline";

export function TimeLineButton({ toggleTimeLine, showTimeline }) {
  return (
    <Tooltip arrow title="时间轴">
      <IconButton
        size="small"
        onClick={toggleTimeLine}
        color={showTimeline ? "white" : "default"}
      >
        <TimelineIcon></TimelineIcon>
      </IconButton>
    </Tooltip>
  );
}
