//源对象 结构化源信息数据 内容渲染
import React from "react";
import { useReactiveVar } from "@apollo/client";
import Box from "@mui/material/Box";

import { userVar } from "../../../../gql";
import { userIsAdminOrEditor } from "../../../../accessControl";

import { MetaRenderer } from "../../../../meta";
import { SourceObjectDataToolBar } from "./SourceObjectDataToolBar";
import { SourceObjectDataUpdateForm } from "./SourceObjectDataUpdateForm";
export function SourceObjectData({ sourceObject, meta, ...props }) {
  const user = useReactiveVar(userVar);

  //卸载组件后切回来，默认是非编辑状态，如需要修改，需要将edit state提升至SourceObjectContentTabs
  const [edit, setEdit] = React.useState(false);

  return (
    meta.jsonSchema &&
    meta.jsonSchema.fields &&
    meta.jsonSchema.fields.length > 0 && (
      <Box {...props}>
        {userIsAdminOrEditor(user) && (
          <SourceObjectDataToolBar
            sourceObject={sourceObject}
            meta={meta}
            edit={edit}
            setEdit={setEdit}
            mb={1}
          ></SourceObjectDataToolBar>
        )}

        {edit ? (
          <SourceObjectDataUpdateForm
            sourceObject={sourceObject}
            meta={meta}
          ></SourceObjectDataUpdateForm>
        ) : sourceObject.data ? (
          <MetaRenderer
            data={sourceObject.data}
            fields={meta.jsonSchema.fields}
            fieldRootBoxProps={{
              mb: 1,
              maxWidth: "100%",
            }}
          ></MetaRenderer>
        ) : (
          "暂无数据,请点击编辑属性,修改数据"
        )}
      </Box>
    )
  );
}
