import { gql, useQuery, useMutation } from "@apollo/client";

//fragments
const JSONSCHEMA_FIELDS = gql`
  fragment JsonSchemaFields on JsonSchema {
    id
    name
    fields
    schema
    uiSchema
    formProps
  }
`;

//queries
const GET_JSONSCHEMA_BY_ID = gql`
  ${JSONSCHEMA_FIELDS}
  query getJsonSchema($where: JsonSchemaWhereUniqueInput!) {
    jsonSchema(where: $where) {
      ...JsonSchemaFields
    }
  }
`;

//queries
const GET_JSONSCHEMAS = gql`
  ${JSONSCHEMA_FIELDS}
  query getJsonSchemas($where: JsonSchemaWhereInput! = {}) {
    jsonSchemas(where: $where) {
      ...JsonSchemaFields
    }
  }
`;

//mutations
//mutations
const CREATE_JSONSCHEMA = gql`
  ${JSONSCHEMA_FIELDS}
  mutation CreateJsonSchema($data: JsonSchemaCreateInput!) {
    createJsonSchema(data: $data) {
      ...JsonSchemaFields
    }
  }
`;

const UPDATE_JSONSCHEMA = gql`
  ${JSONSCHEMA_FIELDS}
  mutation UpdateJsonSchema(
    $where: JsonSchemaWhereUniqueInput!
    $data: JsonSchemaUpdateInput!
  ) {
    updateJsonSchema(where: $where, data: $data) {
      ...JsonSchemaFields
    }
  }
`;

const DELETE_JSONSCHEMA = gql`
  mutation DeleteJsonSchema($where: JsonSchemaWhereUniqueInput!) {
    deleteJsonSchema(where: $where) {
      id
    }
  }
`;

//hooks query
export function useJsonSchema(options) {
  return useQuery(GET_JSONSCHEMA_BY_ID, options);
}

export function useJsonSchemas(options) {
  return useQuery(GET_JSONSCHEMAS, options);
}

//hooks mutation
export function useCreateJsonSchema(options) {
  return useMutation(CREATE_JSONSCHEMA, options);
}

export function useUpdateJsonSchema(options) {
  return useMutation(UPDATE_JSONSCHEMA, options);
}

export function useDeleteJsonSchema(options) {
  return useMutation(DELETE_JSONSCHEMA, options);
}
