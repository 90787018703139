import React from "react";
import { useParams, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useDigitalScene, resetEvent } from "../../gql";

// import { Debug, JsonView, AssetsTree } from "../../components";
import { DigitalSceneViewer } from "./DigitalSceneViewer";

import { Logo, BottomMenu } from "../../components";
import { createDigitalSceneData } from "../../utils";

export function DigitalScenePage(props) {
  const { id } = useParams();
  const { data } = useDigitalScene({
    variables: {
      where: { id },
    },
  });

  //unmount时重置screenEventsVar
  React.useEffect(() => {
    return resetEvent;
  }, []);

  let digitalSceneData = {};

  if (data && data.digitalScene) {
    digitalSceneData = createDigitalSceneData(data.digitalScene);
  }
  return (
    data &&
    (data.digitalScene ? (
      <>
        <Helmet>
          <title>{digitalSceneData.scene.name}</title>
        </Helmet>
        <DigitalSceneViewer
          {...digitalSceneData}
          goToSceneDefaultView={true}
          setSceneDefaultViewAsHome={true}
          gotoCameraSearch={true}
          setCameraSearchAsHome={true}
        >
          <BottomMenu
            dsPanel
            toolMenu
            homeButton
            cameraModeButton
            vrButton={false}
            fullScreenButton
            accountButton
          ></BottomMenu>
        </DigitalSceneViewer>
        <Logo></Logo>
      </>
    ) : (
      <Navigate to="/404" replace></Navigate>
    ))
  );
}
