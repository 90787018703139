import { makeVar } from "@apollo/client";
import { Color } from "cesium";

const defaultValue = {
  backgroundColor: Color.GREY,
};

//控制各类全局颜色
export const colorVar = makeVar(defaultValue);

//控制天空盒颜色
export function setBackgroundColor(backgroundColor) {
  if (colorVar().backgroundColor !== backgroundColor) {
    colorVar({
      ...colorVar(),
      backgroundColor,
    });
  }
}
