import { gql, useQuery } from "@apollo/client";

//fragments
export const SOURCE_OBJECT_META_FIELDS = gql`
  fragment SourceObjectMetaFields on SourceObjectMeta {
    id
    name
    description
    geometryProps
    jsonSchema {
      id
      name
      fields
      schema
      uiSchema
      formProps
    }
  }
`;

//queries
const GET_SOURCE_OBJECT_META_BY_ID = gql`
  ${SOURCE_OBJECT_META_FIELDS}
  query SourceObjecMeta($where: SourceObjecMetaWhereUniqueInput!) {
    sourceObjecMeta(where: $where) {
      ...SourceObjecMetaFields
    }
  }
`;

const GET_SOURCE_OBJECT_METAS = gql`
  ${SOURCE_OBJECT_META_FIELDS}
  query SourceObjectMetas($where: SourceObjectMetaWhereInput! = {}) {
    sourceObjectMetas(where: $where) {
      ...SourceObjectMetaFields
    }
  }
`;

//mutations

//hooks query
export function useSourceObjectMeta(options) {
  return useQuery(GET_SOURCE_OBJECT_META_BY_ID, options);
}

export function useSourceObjectMetas(options) {
  return useQuery(GET_SOURCE_OBJECT_METAS, options);
}

//hooks mutation
