import { makeVar } from "@apollo/client";

const defaultValue = {
  show: false,
  key: "name",
};

export const labelVar = makeVar(defaultValue);

export function setLabelShow(show) {
  labelVar({
    ...labelVar(),
    show,
  });
}

export function setLabelKey(key) {
  labelVar({
    ...labelVar(),
    key,
  });
}
