import React from "react";
import Image from "rc-image";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import Portal from "@mui/base/Portal";

import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";

//image gallery icon

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
// import CloseIcon from "@mui/icons-material/Close";

import { getFileExtIcon } from "../../../assets/types/config";
import { formatDateTime, downloadFile, openUrlInNewTab } from "./utils";

function KVPairRender({ keyName, value, children }) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexWrap: "nowrap",
        alignItems: {
          xs: "flex-start",
          xm: "center",
        },
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        mb: 1.5,
      }}
    >
      <Tooltip title={keyName} placement="top" arrow>
        <Typography
          display="inline-block"
          variant="caption"
          sx={{
            color: {
              xs: "grey.900",
              sm: "grey.600",
            },
            fontWeight: "bold",
            textAlign: {
              xs: "left",
              xm: "center",
            },
            mr: {
              xs: 0,
              sm: 1,
            },
            mb: {
              xs: 0.5,
              sm: 0,
            },
            maxWidth: {
              xs: 1,
              sm: "40%",
            },
            flexShrink: 0,
            flexGrow: 0,
            flexBasis: {
              xs: 1,
              sm: 140,
              md: 160,
              lg: 180,
              xl: 200,
            },

            alignSelf: {
              xs: "flex-start",
              sm: "center",
            },
          }}
        >
          {keyName}
        </Typography>
      </Tooltip>

      {value && (
        <Typography
          display="inline-block"
          variant="body1"
          sx={{
            textAlign: {
              xs: "left",
              xm: "center",
            },
          }}
        >
          {value}
        </Typography>
      )}

      {children}
    </Box>
  );
}

// TextInputRender
function TextInputRender({ field, value, ...props }) {
  return (
    <Box {...props}>
      <KVPairRender keyName={field.title} value={value}></KVPairRender>
    </Box>
  );
}

// TextareaRender
function TextareaRender({ field, value, ...props }) {
  return (
    <Box {...props}>
      <KVPairRender keyName={field.title} value={value}></KVPairRender>
    </Box>
  );
}

// PasswordRender
function PasswordRender({ field, value, ...props }) {
  return (
    <Box {...props}>
      <KVPairRender
        keyName={field.title}
        value={value ? "已设置" : "未设置"}
      ></KVPairRender>
    </Box>
  );
}

// ColorRender
function ColorRender({ field, value, ...props }) {
  return (
    <Box {...props}>
      <KVPairRender keyName={field.title}>
        {value && (
          <Typography
            display="inline-block"
            variant="body1"
            sx={{
              color: value,
              bgcolor: value,
            }}
          >
            {value}
          </Typography>
        )}
      </KVPairRender>
    </Box>
  );
}

// EmailRender
function EmailRender({ field, value, ...props }) {
  return (
    <Box {...props}>
      <KVPairRender keyName={field.title} value={value}></KVPairRender>
    </Box>
  );
}

// UriRender
function UriRender({ field, value, ...props }) {
  return (
    <Box {...props}>
      <KVPairRender keyName={field.title}>
        <Tooltip title={value}>
          <Typography
            display="inline-block"
            variant="body1"
            color="primary"
            sx={{
              fontWeight: "bold",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              window.open(value);
            }}
          >
            点击打开链接<OpenInNewIcon fontSize="inherit"></OpenInNewIcon>
          </Typography>
        </Tooltip>
      </KVPairRender>
    </Box>
  );
}

// DateRender
function DateRender({ field, value, ...props }) {
  return (
    <Box {...props}>
      <KVPairRender keyName={field.title} value={value}></KVPairRender>
    </Box>
  );
}

// DateTimeRender
function DateTimeRender({ field, value, ...props }) {
  return (
    <Box {...props}>
      <KVPairRender
        keyName={field.title}
        value={formatDateTime(value)}
      ></KVPairRender>
    </Box>
  );
}

// UpdownRender RangeRender 完全一样
function UpdownRender({ field, value, ...props }) {
  return (
    <Box {...props}>
      <KVPairRender keyName={field.title}>
        <Typography display="inline-block" variant="body1">
          {value} {field.uiOptions.unit}
        </Typography>
      </KVPairRender>
    </Box>
  );
}

// UpdownRender RangeRender 完全一样
function RangeRender({ field, value, ...props }) {
  return (
    <Box {...props}>
      <KVPairRender keyName={field.title}>
        <Typography display="inline-block" variant="body1">
          {value} {field.uiOptions.unit}
        </Typography>
      </KVPairRender>
    </Box>
  );
}

// CheckboxRender
function CheckboxRender({ field, value, ...props }) {
  return (
    <Box {...props}>
      <KVPairRender keyName={field.title}>
        <Typography display="inline-block" variant="body1">
          {value ? "是" : "否"}

          {/* 使用对错符号-此方案暂不使用 */}
          {/* {value ? (
            <DoneIcon
              color="success"
              sx={{
                fontSize: 24,
              }}
            ></DoneIcon>
          ) : (
            <CloseIcon
              color="error"
              sx={{
                fontSize: 24,
              }}
            ></CloseIcon>
          )} */}
        </Typography>
      </KVPairRender>
    </Box>
  );
}

// SelectRender
function SelectRender({ field, value, ...props }) {
  return (
    <Box {...props}>
      <KVPairRender keyName={field.title}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "&>*:not(:last-child)": {
              mr: 2,
            },
          }}
        >
          {field.schema.enum.map((choice) => (
            <Typography
              display="inline-block"
              variant="body1"
              key={choice}
              sx={{
                color: choice === value ? "primary.main" : "grey.500",
                whiteSpace: "nowrap",
                fontWeight: choice === value ? "bold" : "unset",
              }}
            >
              {choice}
            </Typography>
          ))}
        </Box>
      </KVPairRender>
    </Box>
  );
}

function MultiSelectRender({ field, value, ...props }) {
  return (
    <Box {...props}>
      <KVPairRender keyName={field.title}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "&>*:not(:last-child)": {
              mr: 2,
            },
          }}
        >
          {field.schema.items.enum.map((choice) => (
            <Typography
              display="inline-block"
              variant="body1"
              key={choice}
              sx={{
                whiteSpace: "nowrap",
                color:
                  value.indexOf(choice) !== -1 ? "primary.main" : "grey.500",
                fontWeight: value.indexOf(choice) !== -1 ? "bold" : "unset",
              }}
            >
              {choice}
            </Typography>
          ))}
        </Box>
      </KVPairRender>
    </Box>
  );
}

function FileUploadRender({ field, value, ...props }) {
  // console.log(JSON.stringify(value, null, 2));
  return (
    <Box {...props}>
      <KVPairRender keyName={field.title}>
        <Box
          sx={{
            minWidth: 0, //否则会被长文件名的文件元素撑开宽度
            width: {
              xs: 1,
              sm: "auto",
            },
            flex: "auto",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          {value.map(({ id, name, url }) => (
            <Paper
              key={id}
              elevation={0}
              sx={{
                bgcolor: "background.alphaPrimary",
                "&:hover": {
                  bgcolor: "background.alphaPaper",
                },
                p: 0.5,
                my: 1,
                mx: {
                  xs: 0,
                  sm: 1,
                },
                width: {
                  xs: 1,
                  sm: 180,
                  md: 250,
                  lg: 300,
                },
                flex: "none",
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* 文件Icon */}
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  mr: 1,
                  flex: "none",
                }}
                component="img"
                src={getFileExtIcon(name)}
              ></Box>

              {/* 文件名称 */}
              <Tooltip title={name} placement="top">
                <Typography
                  variant="body1"
                  align="left"
                  sx={{
                    display: "block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",

                    flex: "1 1 auto",
                    minWidth: 0,
                  }}
                  // onClick={() => {
                  //   //跨域后会自动下载，此问题还需hack
                  //   window.open(url, "_blank");
                  // }}
                >
                  {name}
                </Typography>
              </Tooltip>

              {/* 下载按钮 */}
              <IconButton
                size="small"
                color="error"
                aria-label="download"
                sx={{
                  flex: "none",
                }}
                onClick={(e) => {
                  downloadFile(url, name);
                }}
              >
                <DownloadIcon fontSize="24px" />
              </IconButton>
            </Paper>
          ))}
        </Box>
      </KVPairRender>
    </Box>
  );
}

function ImageUploadRender({ field, value, ...props }) {
  const theme = useTheme();
  const upLg = useMediaQuery(theme.breakpoints.up("lg"));

  const backdropRef = React.useRef(null);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const handlePreviewVisibleChange = React.useCallback((visible) => {
    setBackdropOpen(visible);
  }, []);
  return (
    <>
      <Box {...props}>
        <KVPairRender keyName={field.title}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
            }}
          >
            <Image.PreviewGroup
              preview={{
                onVisibleChange: handlePreviewVisibleChange,
                getContainer: backdropRef.current,
              }}
              icons={{
                left: <ChevronLeftIcon color="primary" />,
                right: <ChevronRightIcon color="primary" />,
                rotateLeft: <RotateLeftIcon />,
                rotateRight: <RotateRightIcon />,
                zoomIn: <ZoomInIcon />,
                zoomOut: <ZoomOutIcon />,
                close: <CloseIcon />,
              }}
            >
              {value.map(({ id, name, url }) => (
                <Box
                  key={id}
                  component={Image}
                  src={
                    backdropOpen
                      ? url
                      : url +
                        `?x-oss-process=style/${
                          upLg ? "scale_256_256" : "scale_128_128"
                        }` //oss图片处理，缩放到256px
                  }
                  sx={{
                    width: {
                      xs: 100,
                      sm: 128,
                      lg: 135,
                      xl: 150,
                    },
                    height: {
                      xs: 100,
                      sm: 128,
                      lg: 135,
                      xl: 150,
                    },
                    borderRadius: 4,
                    m: 0.5,
                    objectFit: "cover",
                    cursor: "pointer",
                    transition: "transform 0.2s",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                ></Box>
              ))}
            </Image.PreviewGroup>
          </Box>
        </KVPairRender>
      </Box>

      <Portal>
        <Backdrop
          ref={backdropRef}
          open={backdropOpen}
          sx={{
            zIndex: "gallery", //higher than swiper object
          }}
        ></Backdrop>
      </Portal>
    </>
  );
}

export const fieldRenderMap = {
  textInput: TextInputRender,
  textarea: TextareaRender,
  password: PasswordRender,
  color: ColorRender,
  email: EmailRender,
  uri: UriRender,
  date: DateRender,
  dateTime: DateTimeRender,
  updown: UpdownRender,
  range: RangeRender,
  checkbox: CheckboxRender,
  select: SelectRender,
  multiSelect: MultiSelectRender,
  fileUpload: FileUploadRender,
  imageUpload: ImageUploadRender,
};
