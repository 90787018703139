import React from "react";

import Typography from "@mui/material/Typography";

import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import NavigationIcon from "@mui/icons-material/Navigation";

import { ZoomDistanceSlider } from "./ZoomDistanceSlider";
import { ZoomRadio } from "./ZoomRadio";
import { ZoomDirectionControl } from "./ZoomDirectionControl";
import { PresetViews } from "./PresetViews";
import { SettingLabel } from "../../setting";
import { useSceneContext } from "../../../../hooks";

export function ZoomSetting() {
  const {
    scene: {
      defaultZoomDistance,
      usePresetView,
      frontViewHeading,
      // horizontalViewPitch,
    },
  } = useSceneContext();

  return (
    <>
      <SettingLabel
        label="聚焦距离"
        icon={<ZoomInMapIcon fontSize="small"></ZoomInMapIcon>}
        tooltipProps={{
          title: "设置聚焦距离",
          placement: "right",
        }}
      >
        <ZoomDistanceSlider
          defaultZoomDistance={defaultZoomDistance}
        ></ZoomDistanceSlider>
      </SettingLabel>
      <SettingLabel
        label="聚焦方式"
        icon={<CenterFocusStrongIcon fontSize="small"></CenterFocusStrongIcon>}
        tooltipProps={{
          title: "设置聚焦时使用飞行模式或直接聚焦",
          placement: "right",
        }}
      >
        <ZoomRadio></ZoomRadio>
      </SettingLabel>

      <SettingLabel
        label="聚焦角度"
        icon={<NavigationIcon fontSize="small"></NavigationIcon>}
        tooltipProps={{
          title: "设置聚焦时相机方向",
          placement: "right",
        }}
      >
        <ZoomDirectionControl></ZoomDirectionControl>
        {usePresetView && (
          <PresetViews
            frontViewHeading={frontViewHeading}
            // horizontalViewPitch={-30}
          ></PresetViews>
        )}
      </SettingLabel>
    </>
  );
}
