import React from "react";

import { ProxyGraphicRender } from "../proxyGraphicRender";

export function TiledImageRender(props) {
  const { asset, show } = props;
  // console.log(
  //   `render tiledImage ${asset.name || asset.digitalAsset.name} ${asset.id}`
  // );
  //   const { show: showLabel, key } = useReactiveVar(labelVar);
  //获得资产点击事件处理函数

  //定义默认渲染规则
  if (asset.renderType === "inherit") {
    // console.log(`no inheirt render method for TiledImage:${asset.id}`);
    return null;
  }
  return <ProxyGraphicRender {...props}></ProxyGraphicRender>;
}

// export const MemoizedTiledImageRender = React.memo(TiledImageRender);
export const MemoizedTiledImageRender = TiledImageRender;
