import { gql, useQuery, useMutation } from "@apollo/client";

//fragments
const USER_UPLOAD_IMAGE_FIELDS = gql`
  fragment UserUploadImageFields on UserUploadImage {
    id
    image {
      url
    }
    altText
  }
`;

//queries
const GET_USER_UPLOAD_IMAGE_BY_ID = gql`
  ${USER_UPLOAD_IMAGE_FIELDS}
  query UserUploadImage($where: UserUploadImageWhereUniqueInput!) {
    userUploadImage(where: $where) {
      ...UserUploadImageFields
    }
  }
`;

const GET_USER_UPLOAD_IMAGES = gql`
  ${USER_UPLOAD_IMAGE_FIELDS}
  query UserUploadImages($where: UserUploadImageWhereInput! = {}) {
    userUploadImages(where: $where) {
      ...UserUploadImageFields
    }
  }
`;

//mutations
const CREATE_USER_UPLOAD_IMAGES = gql`
  ${USER_UPLOAD_IMAGE_FIELDS}
  mutation CreateUserUploadImages($data: [UserUploadImageCreateInput!]!) {
    createUserUploadImages(data: $data) {
      ...UserUploadImageFields
    }
  }
`;

const DELETE_USER_UPLOAD_IMAGES = gql`
  mutation DeleteComment($where: [UserUploadImageWhereUniqueInput!]!) {
    deleteUserUploadImages(where: $where) {
      id
    }
  }
`;

export function useCreateUserUploadImages(options) {
  return useMutation(CREATE_USER_UPLOAD_IMAGES, options);
}

export function useDeleteUserUploadImages(options) {
  return useMutation(DELETE_USER_UPLOAD_IMAGES, options);
}
