import React from "react";
import { useSearchParams } from "react-router-dom";

import Portal from "@mui/material/Portal";

import { BottomMenu } from "../../components";
import { DigitalSceneViewer } from "../ds";
import { DsTimeLine } from "./DsTimeLine";
let initViewer = true;

export function DsCollectionViewer({ dsCollectionData }) {
  let sceneCount = dsCollectionData.length;

  // 初始化首先显示的场景
  let initScene = 0;
  const [search] = useSearchParams();
  const initSceneSearch = Number(search.get("initscene"));
  if (initSceneSearch && !isNaN(initSceneSearch)) {
    if (initSceneSearch >= 0 && initSceneSearch < sceneCount) {
      initScene = initSceneSearch;
    }
  }

  const [showTimeline, setShowTimeLine] = React.useState(true);
  const toggleTimeLine = () => {
    setShowTimeLine((state) => !state);
  };
  const bottomMenuProps = React.useMemo(
    () => ({
      dsPanel: true,
      toolMenu: true,
      homeButton: true,
      cameraModeButton: true,
      vrButton: true,
      fullScreenButton: true,
      accountButton: true,
    }),
    []
  );
  //首次加载后修改initViewer参数
  React.useEffect(() => {
    initViewer = false;
  }, []);
  const [currentScene, setCurrentScene] = React.useState(initScene);

  return (
    <>
      <DigitalSceneViewer
        goToSceneDefaultView={initViewer} //首次加载场景，前往该场景默认视角
        setSceneDefaultViewAsHome={true} //切换场景时总设置新场景的默认视角设为Home
        gotoCameraSearch={initViewer} //首次加载场景，前往searchparameter设置的相机视角
        setCameraSearchAsHome={initViewer} //首次加载场景，将searchparameter设置的相机视角设为Home视角
        scene={dsCollectionData[currentScene].scene}
        assets={dsCollectionData[currentScene].assets}
        initRenderMap={dsCollectionData[currentScene].initRenderMap}
        bottomMenuProps={{
          ...bottomMenuProps,
          toggleTimeLine,
          showTimeline,
        }}
      >
        <BottomMenu
          toggleTimeLine={toggleTimeLine}
          showTimeline={showTimeline}
          dsPanel
          toolMenu
          homeButton
          cameraModeButton
          vrButton={false}
          fullScreenButton
          accountButton
        ></BottomMenu>
      </DigitalSceneViewer>
      <Portal>
        {showTimeline && (
          <DsTimeLine
            dsCollectionData={dsCollectionData}
            currentScene={currentScene}
            setCurrentScene={setCurrentScene}
          ></DsTimeLine>
        )}
      </Portal>
    </>
  );
}
