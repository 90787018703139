import React from "react";
import { useReactiveVar } from "@apollo/client";

import { useSearchParams } from "react-router-dom";
import Clipboard from "clipboard";

import { useCesium } from "resium";
import { Cartesian3 } from "cesium";
import { useLocation, createSearchParams } from "react-router-dom";

import { viewerSettingVar, setViewerSetting, zoomVar } from "../gql";

//返回当前视角url的生成函数
export function useCameraViewUrl() {
  let { camera } = useCesium();
  const location = useLocation();
  const getViewUrl = () => {
    const { position, direction, up } = camera;
    const p = [position.x, position.y, position.z];
    const d = [direction.x, direction.y, direction.z];
    const u = [up.x, up.y, up.z];
    //Format of SearchString
    //?p=-2166993.5204636706&p=4385999.42598213&p=4078783.9073348227&d=0.3932917018154682&d=-0.9048127946290659&d=-0.16320368855706877
    const searchString = createSearchParams({
      p,
      d,
      u,
    }).toString();
    const viewUrl = `${process.env.REACT_APP_ROOT_URL}${location.pathname}?${searchString}`;
    return viewUrl;
  };
  return getViewUrl;
}

//根据searchParams返回camera初始化参数,传参错误则返回undefined
export function useCameraFromSearch() {
  const [search] = useSearchParams();
  const position = search.getAll("p");
  const direction = search.getAll("d");
  const up = search.getAll("u");
  let cameraProps;
  if (position.length === 3 && direction.length === 3 && up.length === 3) {
    try {
      cameraProps = {
        position: new Cartesian3(...position.map((num) => Number(num))),
        direction: new Cartesian3(...direction.map((num) => Number(num))),
        up: new Cartesian3(...up.map((num) => Number(num))),
      };
    } catch (e) {
      console.log(e);
    }
  }
  return cameraProps;
}

//返回跳转到Home的操作函数
export function useGoHome(options = { fly: false }) {
  let { camera } = useCesium();
  const { fly } = options;
  return () => {
    try {
      if (viewerSettingVar().homeCameraProps) {
        //两种行为二选一 直接切换视角 或飞行
        if (fly) {
          camera.flyTo({
            destination: viewerSettingVar().homeCameraProps.position.clone(),
            orientation: {
              direction: viewerSettingVar().homeCameraProps.direction.clone(),
              up: viewerSettingVar().homeCameraProps.up.clone(),
            },
          });
        } else {
          // console.log(viewerSettingVar().homeCameraProps);
          camera.setView({
            destination: viewerSettingVar().homeCameraProps.position.clone(),
            orientation: {
              direction: viewerSettingVar().homeCameraProps.direction.clone(),
              up: viewerSettingVar().homeCameraProps.up.clone(),
            },
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
}

//飞行到特定设置的相机视角，包含position,direction,up，并且可以根据全局设置判断飞行还是直接切换视角
export function useGoToView() {
  let { camera } = useCesium();
  const { flyTo } = useReactiveVar(zoomVar);

  return (view) => {
    try {
      if (flyTo) {
        camera.flyTo({
          destination: view.position,
          orientation: {
            direction: view.direction,
            up: view.up,
          },
        });
      } else {
        camera.setView({
          destination: view.position,
          orientation: {
            direction: view.direction,
            up: view.up,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
}

//获取当前相机视角参数JSON,并设置selector按钮点击后复制
export function useCopyCameraViewJson(selector) {
  let { camera } = useCesium();

  React.useEffect(() => {
    const btn = new Clipboard(selector, {
      text: function (trigger) {
        const position = camera.position.clone();
        const direction = camera.direction.clone();
        const up = camera.up.clone();

        //生成HomeView RecTangle的json,便于后台存储
        const CameraViewJson = JSON.stringify({
          position,
          direction,
          up,
        });
        console.log(CameraViewJson);
        return CameraViewJson;
      },
    });

    return () => {
      btn.destroy();
    };
  });
}

//返回设置当前视角为默认视角的操作函数
export function useSetHomeView() {
  let { camera } = useCesium();

  return () => {
    //必须clone 否则存储的是reference，会随camera变化
    const position = camera.position.clone();
    const direction = camera.direction.clone();
    const up = camera.up.clone();
    console.log({ position, direction, up });
    setViewerSetting({
      homeCameraProps: {
        position,
        direction,
        up,
      },
    });
  };
}

// export function useSetDefaultCameraView({ cameraView }) {
//   let { camera } = useCesium();

//   const setAsHome = true;
//   const go = true;
//   React.useEffect(() => {
//     if (cameraView) {
//       try {
//         if (go) {
//           camera.setView(cameraView);
//         }
//         if (setAsHome) {
//           setViewerSetting({
//             homeCameraProps: cameraView,
//           });
//         }
//       } catch (error) {
//         console.log("error when setting default camera view", error);
//       }
//     }
//   }, [camera, go, setAsHome]);
// }

// export function useCopyViewRectangleJson(selector) {
//   let { camera } = useCesium();

//   React.useEffect(() => {
//     const btn = new Clipboard(selector, {
//       text: function (trigger) {
//         //生成HomeView RecTangle的json,便于后台存储
//         const viewRectangleJson = JSON.stringify(camera.computeViewRectangle());
//         console.log(viewRectangleJson);
//         return viewRectangleJson;
//       },
//     });

//     return () => {
//       btn.destroy();
//     };
//   });
// }
