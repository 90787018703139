import React from "react";

import { ScreenSpaceEvent, useCesium } from "resium";
import {
  KeyboardEventModifier,
  ScreenSpaceEventType,
  defined,
  Cartesian3,
  Cartographic,
} from "cesium";

import { dispatchDrawingToolVar, drawingToolEditVar } from "../drawingToolVars";

import {
  cartesian3ToRadianArray,
  // useClearEditDataAfterUnmount,
} from "../utils";

// 适用于设置多个点组成的array的positions的事件
export function DrawingPositionsEvent({ drawingType, ...props }) {
  const { viewer } = useCesium();

  // 切换绘图工具时，unmount此事件后，同时清除editData数据
  // useClearEditDataAfterUnmount();

  return (
    <>
      <ScreenSpaceEvent
        action={(event) => {
          try {
            const cartesian = viewer.scene.pickPosition(event.position);
            const radianArray = cartesian3ToRadianArray(cartesian);
            if (defined(cartesian)) {
              const action = {
                type: "SET_EDIT_GEOMETRY",
                payload: {
                  type: drawingType,
                  positions:
                    drawingToolEditVar().editData.type === drawingType
                      ? [
                          ...drawingToolEditVar().editData.positions,
                          radianArray,
                        ]
                      : [radianArray],
                },
              };
              dispatchDrawingToolVar(action);
            }
          } catch (e) {
            console.log(e);
          }
        }}
        type={ScreenSpaceEventType.LEFT_CLICK}
      ></ScreenSpaceEvent>
      <ScreenSpaceEvent
        action={(event) => {
          if (
            drawingToolEditVar().editData.positions &&
            drawingToolEditVar().editData.positions.length > 0
          ) {
            const action = {
              type: "SET_EDIT_GEOMETRY",
              payload: {
                type: drawingType,

                positions: drawingToolEditVar().editData.positions.slice(0, -1), // 移除array中最后一个元素
              },
            };
            dispatchDrawingToolVar(action);
          }
        }}
        type={ScreenSpaceEventType.RIGHT_CLICK}
      ></ScreenSpaceEvent>
      <ScreenSpaceEvent
        action={(event) => {
          if (
            drawingToolEditVar().editData.positions &&
            drawingToolEditVar().editData.positions.length > 0
          ) {
            console.log("left");
            const cartesian = viewer.scene.pickPosition(event.position);
            const radianArray = cartesian3ToRadianArray(cartesian);

            if (defined(cartesian)) {
              const action = {
                type: "SET_EDIT_GEOMETRY",
                payload: {
                  type: drawingType,
                  positions: [
                    ...drawingToolEditVar().editData.positions.slice(0, -1),
                    radianArray,
                  ],
                },
              };
              dispatchDrawingToolVar(action);
            }
          }
        }}
        type={ScreenSpaceEventType.LEFT_CLICK}
        modifier={KeyboardEventModifier.CTRL}
      ></ScreenSpaceEvent>
    </>
  );
}
