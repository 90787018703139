import React from "react";
import {
  Cartesian2,
  Color,
  VerticalOrigin,
  LabelStyle,
  NearFarScalar,
} from "cesium";

//assets icon
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import CloudIcon from "@mui/icons-material/Cloud";
import VrpanoIcon from "@mui/icons-material/Vrpano";
import PanoramaPhotosphereSelectIcon from "@mui/icons-material/PanoramaPhotosphereSelect";
import CameraIcon from "@mui/icons-material/Camera";
import { assetLabelMap as labelMap } from "../../utils";
export const allAssetsType = [
  "tiledModel",
  "pointCloud",
  "tiledImage",
  "panorama",
  "vtour",
];

export const assetIconMap = {
  tiledModel: <ViewInArOutlinedIcon></ViewInArOutlinedIcon>,
  pointCloud: <CloudIcon></CloudIcon>,
  tiledImage: <CameraIcon></CameraIcon>,
  panorama: <PanoramaPhotosphereSelectIcon></PanoramaPhotosphereSelectIcon>,
  vtour: <VrpanoIcon></VrpanoIcon>,
  // panorama: <VrpanoIcon></VrpanoIcon>,
  // vtour: <PanoramaPhotosphereSelectIcon></PanoramaPhotosphereSelectIcon>,
};

export const assetLabelMap = labelMap;
//  {
//   tiledModel: "实景模型",
//   pointCloud: "三维点云",
//   tiledImage: "正射影像",
//   panorama: "全景图像",
//   vtour: "全景漫游",
// };

export const initRenderTypeMap = {
  tiledModel: true,
  pointCloud: true,
  tiledImage: true,
  panorama: true,
  vtour: true,
};

export const selfRenderable = ["tiledModel", "pointCloud"];

//默认代理资产标签设置
export const labelStyles = {
  style: LabelStyle.FILL,
  fillColor: Color.RED,
  showBackground: false,
  backgroundColor: Color.WHITE,
  backgroundPadding: new Cartesian2(6, 6),
  // disableDepthTestDistance: undefined, //让标签被遮挡
};

export const labelSettings = {
  offsetY: -24,
  LabelGraphicsProps: labelStyles,
};

//billboard 渲染参数
export const billboardScale = 0.6;
export const billboardScaleHover = 0.8;
const billboardSettings = {
  alpha: 0.5,
  BillboardGraphicsProps: {
    // scaleByDistance: new NearFarScalar(50, 0.6, 500, 0.06),
    // sizeInMeters: true,
    // disableDepthTestDistance: undefined, //让标签被遮挡
  },
};
const billboardHoverSettings = {
  ...billboardSettings,
  alpha: 1,
  BillboardGraphicsProps: {
    // scaleByDistance: new NearFarScalar(50, 0.8, 500, 0.08),
  },
};

export const billboardSettingMap = {
  tiledModel: {
    iconVarient: "tiledModel",
    color: "BLUE",
    ...billboardSettings,
  },

  pointCloud: {
    iconVarient: "pointCloud",
    color: "YELLOW",
    ...billboardSettings,
  },
  tiledImage: {
    iconVarient: "tiledImage",
    color: "BLUE",
    ...billboardSettings,
  },
  panorama: {
    iconVarient: "panorama",
    color: "RED",
    ...billboardSettings,
  },
  vtour: {
    iconVarient: "vtour",
    color: "DEEPSKYBLUE ",
    ...billboardSettings,
  },
};
export const billboardHoverSettingMap = {
  tiledModel: {
    iconVarient: "tiledModel",
    color: "BLUE",
    ...billboardHoverSettings,
  },

  pointCloud: {
    iconVarient: "pointCloud",
    color: "YELLOW",
    ...billboardHoverSettings,
  },
  tiledImage: {
    iconVarient: "tiledImage",
    color: "BLUE",
    ...billboardHoverSettings,
  },
  panorama: {
    iconVarient: "panorama",
    color: "RED",
    ...billboardHoverSettings,
  },
  vtour: {
    iconVarient: "vtour",
    color: "DEEPSKYBLUE ",
    ...billboardHoverSettings,
  },
};

//Polygon 渲染设置
export const polygonSetting = {
  // 填充
  fill: true,
  color: "RED",
  alpha: 0.2,
  // 边界设置
  // outline: true,
  // outlineColor: "BLUE",
};
export const polygonHoverSetting = {
  ...polygonSetting,
  alpha: 0.4,
};

//Model 渲染设置
export const modelSetting = {
  // 填充
  color: "BLUE",
  alpha: 0.2,
  // // 边界设置
  // outline: true,
  // outlineColor: "BLUE",
};
export const modelHoverSetting = {
  ...modelSetting,
  alpha: 0.4,
};
