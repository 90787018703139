import React from "react";
import { useReactiveVar } from "@apollo/client";

import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

// icon
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";

import { setZoomFly, zoomVar } from "../../../../gql";

export function ZoomRadio(props) {
  const { flyTo } = useReactiveVar(zoomVar);

  const handleChange = (event) => {
    if (event.target.value === "fly") {
      setZoomFly(true);
    } else {
      setZoomFly(false);
    }
  };

  return (
    <RadioGroup
      value={flyTo ? "fly" : "zoom"}
      onChange={handleChange}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <FormControlLabel value="zoom" control={<Radio />} label="快速聚焦" />
      <FormControlLabel value="fly" control={<Radio />} label="飞行聚焦" />
    </RadioGroup>
  );
}
