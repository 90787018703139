import React from "react";
import Form from "@rjsf/mui";
import { validator } from "./validator";

import { customWidgets } from "./customWidgets";
import { customFields } from "./customFields";
// import { ErrorBoundary } from "../components";
// export function CustomForm(props) {
//   return <Form widgets={widgets} validator={validator} {...props} />;
// }

export const CustomForm = React.forwardRef((props, ref) => {
  return (
    <Form
      ref={ref}
      widgets={customWidgets}
      fields={customFields}
      validator={validator}
      showErrorList={false}
      onError={(errors) => {
        console.log("表单验证失败", errors);
      }}
      {...props}
    />
  );
});
