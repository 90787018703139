import React from "react";
import { useCesium } from "resium";
import { useReactiveVar } from "@apollo/client";
import { Math } from "cesium";

//local
import { compassVar, setCompassHeading } from "../gql";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
function normalizeHeading(heading) {
  return heading > 359 ? 0 : heading;
}

// const titles = [
//   { title: "N", angle: 0 },
//   { title: "NE", angle: 45 },
//   { title: "E", angle: 90 },
//   { title: "SE", angle: 135 },
//   { title: "S", angle: 180 },
//   { title: "SW", angle: 225 },
//   { title: "W", angle: 270 },
//   { title: "NW", angle: 315 },
// ];
const titles = [
  { title: "北", angle: 180, color: "red" },
  // { title: "东北", angle: 225 },
  { title: "东", angle: 270 },
  // { title: "东南", angle: 315 },
  { title: "南", angle: 360, color: "blue" },
  // { title: "西南", angle: 45 },
  { title: "西", angle: 90 },
  // { title: "西北", angle: 135 },
];
export function Compass({ width }) {
  const { camera } = useCesium();
  const compass = useReactiveVar(compassVar);
  const heading = normalizeHeading(compass.heading);

  const calcTransform = (angle) => {
    const dg = angle - heading;
    let left = (width * dg) / 360;
    if (left < 0) {
      left = left + width;
    }
    return left;
  };

  return (
    compass.open &&
    compass.heading !== null && (
      <>
        <Box
          sx={{
            userSelect: "none",
            width: width,
            position: "fixed",
            top: 10,
            left: "50%",
            transform: "translateX(-50%)",
            cursor: "pointer",
            zIndex: (theme) => theme.zIndex.compass,

            "& > *": {
              position: "absolute",
              transform: "translateX(-50%)",
              // transition: (theme) => theme.transitions.create(["left"]),
            },
          }}
          onClick={() => {
            setCompassHeading(Math.toDegrees(camera.heading).toFixed(0));
            console.log("----------当前相机参数----------");
            console.log(
              JSON.stringify(
                {
                  pitch: Math.toDegrees(camera.pitch).toFixed(2),
                  heading: Math.toDegrees(camera.heading).toFixed(2),
                  roll: Math.toDegrees(camera.roll).toFixed(2),
                },
                null,
                2
              )
            );
          }}
        >
          <Tooltip
            title={`${heading}°`}
            placement="right"
            sx={{
              left: width / 2,
            }}
          >
            <ArrowDropDownIcon color="secondary"></ArrowDropDownIcon>
          </Tooltip>
          {titles.map(({ title, angle, color }) => (
            <Typography
              variant="h5"
              key={title}
              sx={{
                top: 20,
                left: calcTransform(angle),
                color: color ? color : "black",
                fontWeight: "bold",
              }}
            >
              {title}
            </Typography>
          ))}
        </Box>
      </>
    )
  );
}
