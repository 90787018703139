import React from "react";
import Chip from "@mui/material/Chip";

export function CommentTag({ tag }) {
  const { name } = tag;

  return (
    <Chip
      key={name}
      label={name}
      size="small"
      variant="filled"
      color="primary"
    />
  );
}
