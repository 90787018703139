import React from "react";

import { dispatchDrawingToolVar } from "../drawingToolVars";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import RestartAltIcon from "@mui/icons-material/RestartAlt";

export function ClearDataButton(props) {
  const resetAll = React.useCallback(() => {
    dispatchDrawingToolVar({
      type: "RESET_SAVED_DATA",
    });
  }, []);

  return (
    <Tooltip arrow title="清楚全部图层数据" placement="bottom">
      <IconButton size="small" onClick={resetAll} color="default" {...props}>
        <RestartAltIcon fontSize="small"></RestartAltIcon>
      </IconButton>
    </Tooltip>
  );
}
