import { makeVar } from "@apollo/client";
import { useCurrentUser } from "../models";

// console.log(window.localStorage.getItem("user"));
export const userVar = makeVar(null);

export function useInitUserVar() {
  return useCurrentUser({
    onCompleted: ({ authenticatedItem }) => {
      userVar(authenticatedItem);
      if (authenticatedItem) {
        window.localStorage.setItem("user", JSON.stringify(authenticatedItem));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });
}
