//所有角色
export const roles = [
  "admin",
  "editor",
  "projectuser",
  "basicuser",
  "anonymous",
  "banuser",
];

//所有支持配置的页面
export const pageList = {
  welcome: {
    title: "项目欢迎页面",
    description: "显示项目标题,副标题,封面图等信息,可作为首页使用",
    routes: "/route",
  },
  news: {
    title: "动态页面",
    description: "可以以时间轴形式显示项目各类动态事件",
    routes: "/route",
  },
  schedule: {
    title: "进度页面",
    description: "使用甘特图形式显示项目的实施进度",
    routes: "/route2",
  },
  personnel: {
    title: "人员页面",
    description: "根据项目分工显示项目参与人员信息",
    routes: "/route2",
  },
  files: {
    title: "资料页面",
    description: "展示项目中各类资料数据,例如研究文献、规范标准、档案、视频等",
    routes: "/route4",
  },
  comments: {
    title: "评论页面",
    description: "展示项目图纸中的评论信息的列表",
    routes: "/route4",
  },
  hub: {
    title: "索引页面",
    description: "显示项目中所有几何索引对象的列表信息",
  },
  drawings: {
    title: "数字图纸页面",
    description: "显示所有数字图纸的卡片以及列表页面",
    routes: "/route4",
  },
  scenes: {
    title: "数字场景页面",
    description: "显示所有数字场景的卡片以及列表页面",
    routes: "/route4",
  },
  assetsIndex: {
    title: "数字资产索引页面",
    description:
      "显示数字资产的介绍,各类资产的数量,并提供各类单项资产详情页面的入口",
    routes: "/route4",
  },
  assetsDetail: {
    title: "数字资产单项详情页面",
    description: "显示改类型数字资产的卡片以及列表页面",
    routes: "/route4",
  },
  mycomment: {
    title: "我的评论",
    description: "显示当前用户发布的评论的列表信息，方便用户管理自己发布的评论",
    routes: "/route4",
  },
};

export const pageKeys = Object.keys(pageList);
export const indexPageChoices = pageKeys.map((pageKey) => ({
  type: "string",
  title: pageList[pageKey].title,
  enum: [pageKey],
}));

//模块导航
export const moduleList = {
  welcome: {
    title: "欢迎",
    pages: ["welcome"],
  },
  news: {
    title: "动态",
    pages: ["news"],
  },
  schedule: {
    title: "进度",
    pages: ["schedule"],
  },
  personnel: {
    title: "人员",
    pages: ["personnel"],
  },
  files: {
    title: "资料",
    pages: ["files"],
  },
  comments: {
    title: "交流",
    pages: ["comments"],
  },
  hub: {
    title: "索引",
    pages: ["hub"],
  },
  drawings: {
    title: "数字图纸",
    pages: ["drawings"],
  },
  scenes: {
    title: "数字场景",
    pages: ["scenes"],
  },
  assets: {
    title: "数字资产",
    pages: ["assetsIndex", "assetsDetail"],
  },
  mycomment: {
    title: "我的评论",
    pages: ["mycomment"],
  },
};

export const moduleListKeys = Object.keys(moduleList);

// "欢迎",
// "动态",
// "进度",
// "人员",
// "资料",
// "交流",
// "索引",
// "数字图纸",
// "数字场景",
// "数字资产",
// "讨论",
// 页面启用是否应当为原子化单元
// 页面包含启用设置，权限设置，内容属性设置
// 页面的概念一定对应一个人为定义的中文页面名称（方便用户理解）+ 页面key +页面路由，具体如何对应则由前端基于配置key写死
// 模块包含多个页面，即多个页面组成一个功能模块，例如数字资产类型展示入口页面+数字资产每类单独列表页面
// 导航栏对应什么，对应模块？对应页面？都不合理，不一定是一一对应

// 模块=导航 >1个或多个页面

//导航 模块 页面
//P页面是单个路由组成的对象，页面有访问权限配置（P1），也有页面相关的UI属性配置（P2）；
//M功能模块（多个页面或一个页面构成），其功能上具有独立性，功能有是否启用的配置（M1），是导航最末端的对象
//N导航 （导航是对功能模块使用树结构组织后形成的ui组件），导航有结构配置（N1），导航与PM完全解耦，对于特定的PM，可以用任意导航去组织，故可以略去此配置先不开发，仅使用目前已经硬编码的导航
//W网站 （网站是上述所有概念的总和），网站有属性配置（W1）
