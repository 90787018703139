import { gql, useQuery } from "@apollo/client";

//fragments
const TILED_IMAGE_FIELDS = gql`
  fragment TiledImageFields on TiledImage {
    id
    name
    viewerUrl
  }
`;

//queries
const GET_TILED_IMAGE_BY_ID = gql`
  ${TILED_IMAGE_FIELDS}
  query TiledImage($where: TiledImageWhereUniqueInput!) {
    tiledImage(where: $where) {
      ...TiledImageFields
    }
  }
`;

//queries
const GET_TILED_IMAGES = gql`
  ${TILED_IMAGE_FIELDS}
  query TiledImages($where: TiledImageWhereInput! = {}) {
    tiledImages(where: $where) {
      ...TiledImageFields
    }
  }
`;

//mutations

//hooks query
export function useTiledImage(options) {
  return useQuery(GET_TILED_IMAGE_BY_ID, options);
}

export function useTiledImages(options) {
  return useQuery(GET_TILED_IMAGES, options);
}

//hooks mutation
