import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: createUploadLink({
    uri: process.env.REACT_APP_GQL_URI,
    credentials: "include",
    headers: {
      "Apollo-Require-Preflight": "true", //keystonejs后端升级core5后，不加报错
    },
  }),
  uri: process.env.REACT_APP_GQL_URI,
  credentials: "include",
  headers: {},
});
