import React from "react";

import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
//icons
import ScreenshotMonitorIcon from "@mui/icons-material/ScreenshotMonitor";

//local
import { LocalPanel } from "../../panel";
import { ScreenshotPanel } from "./panelElement";

export function ScreenshotMenuItem(props) {
  //控制截图面板开关
  let [screenshotPanelOpen, setScreenshotPanelOpen] = React.useState(false);

  return (
    <>
      <MenuItem
        onClick={() => {
          setScreenshotPanelOpen(true);
        }}
      >
        <ListItemIcon>
          <ScreenshotMonitorIcon />
        </ListItemIcon>
        截图录制
      </MenuItem>

      {/* 截图面板 */}
      {screenshotPanelOpen && (
        <LocalPanel
          panelTitle="场景截图与录制"
          closeHandler={() => {
            setScreenshotPanelOpen(false);
          }}
        >
          <ScreenshotPanel></ScreenshotPanel>
        </LocalPanel>
      )}
    </>
  );
}
