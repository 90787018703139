import React from "react";
import Slide from "@mui/material/Slide";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";

export function BottomMenuBar({ children, open }) {
  return (
    <Fade in={open}>
      <Box
        sx={{
          position: "fixed",
          bottom: 10,
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: (theme) => theme.zIndex.bottomMenuBar,

          width: {
            xs: "100%",
            md: "unset",
          },
          maxWidth: {
            xs: "100%",
            sm: "90%",
          },
          borderRadius: {
            xs: 0,
            phone: 2,
          },
          bgcolor: "background.alphaBlack",
          px: 1,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          overflowX: "auto",
        }}
      >
        {children}
      </Box>
    </Fade>
  );
}
