import { iconTypes } from "../icons";

//billboard schema
export const billboardSettingSchema = {
  type: "object",
  required: ["iconType", "color", "alpha"],
  properties: {
    iconType: {
      title: "标签类型",
      type: "string",
      enum: iconTypes,
    },
    color: {
      type: "string",
      title: "颜色",
      // description: "点颜色设置",
    },
    alpha: {
      type: "number",
      title: "透明度",
      // description: "点透明度设置",
      minimum: 0,
      maximum: 1,
      multipleOf: 0.01,
    },
    text: {
      type: "string",
      title: "标签文字内容",
      description: "在标签下方显示文字,不填则不显示",
    },
  },
};

export const billboardSettingUiSchema = {
  color: {
    "ui:widget": "color",
  },
  alpha: {
    "ui:widget": "range",
  },
};

//polyline schema
export const polylineSettingSchema = {
  type: "object",
  required: ["color", "alpha", "width", "clampToGround", "dashed"],
  properties: {
    width: {
      type: "integer",
      title: "宽度",
      minimum: 1,
      maximum: 100,
      multipleOf: 1,
    },
    closed: {
      type: "boolean",
      title: "是否闭合",
    },
    color: {
      type: "string",
      title: "颜色",
    },
    alpha: {
      type: "number",
      title: "透明度",
      minimum: 0,
      maximum: 1,
      multipleOf: 0.01,
    },
    clampToGround: {
      type: "boolean",
      title: "是否贴地",
    },
    dashed: {
      type: "boolean",
      title: "是否为虚线",
    },
  },
};

export const polylineSettingUiSchema = {
  width: {
    "ui:widget": "range",
  },
  color: {
    "ui:widget": "color",
  },
  alpha: {
    "ui:widget": "range",
  },
};

//polygon schema
export const polygonSettingSchema = {
  type: "object",
  required: ["clampToGround", "color", "alpha"],
  properties: {
    clampToGround: {
      type: "boolean",
      title: "是否贴地",
    },
    color: {
      type: "string",
      title: "填充颜色",
      default: "#ff0000", //动态字段必须添加默认值
    },
    alpha: {
      type: "number",
      title: "填充透明度",
      minimum: 0,
      maximum: 1,
      multipleOf: 0.01,
      default: 0.8, //动态字段必须添加默认值
    },

    outline: {
      type: "boolean",
      title: "是否有轮廓线",
    },
  },
  dependencies: {
    outline: {
      oneOf: [
        {
          required: ["outline"],
          properties: {
            outline: {
              enum: [false],
            },
          },
        },
        {
          required: ["outline", "outlineColor"],
          properties: {
            outline: {
              enum: [true],
            },
            // 注意:此属性在 Windows 平台上的所有主要浏览器上都将被忽略。有关详细信息，请参阅（@link https://github.com/CesiumGS/cesium/issues/40},故暂时不使用
            // outlineWidth: {
            //   type: "integer",
            //   title: "轮廓线宽度(像素)",
            //   minimum: 1,
            //   maximum: 100,
            //   default: 1, //动态字段必须添加默认值
            // },
            outlineColor: {
              type: "string",
              title: "轮廓线颜色",
              default: "#2f65cb", //动态字段必须添加默认值
            },
          },
        },
      ],
    },
  },
};

export const polygonSettingUiSchema = {
  color: {
    "ui:widget": "color",
  },
  alpha: {
    "ui:widget": "range",
  },
  outlineColor: {
    "ui:widget": "color",
  },
  // outlineWidth: {
  //   "ui:widget": "range",
  // },
};

//动态字段，开启omitExtraData与liveOmit
export const polygonSettingFormProps = {
  omitExtraData: true,
  liveOmit: true,
  customValidate: (formData, errors, uiSchema) => {
    if (formData.clampToGround && formData.outline) {
      errors.outline.addError("贴地时不可设置轮廓线");
    }
    return errors;
  },
};

//point schema
export const pointSettingSchema = {
  type: "object",
  required: ["pixelSize", "color", "alpha"],
  properties: {
    pixelSize: {
      type: "integer",
      title: "点尺寸",
      minimum: 0,
      multipleOf: 1,
      maximum: 100,
    },
    color: {
      type: "string",
      title: "颜色",
      // description: "点颜色设置",
    },
    alpha: {
      type: "number",
      title: "透明度",
      // description: "点透明度设置",
      minimum: 0,
      maximum: 1,
      multipleOf: 0.01,
    },
    outlineWidth: {
      type: "integer",
      title: "轮廓线宽度",
      minimum: 0,
      maximum: 100,
      multipleOf: 1,
    },
    outlineColor: {
      type: "string",
      title: "轮廓线颜色",
      // description: "点颜色设置",
    },
  },
};

export const pointSettingUiSchema = {
  pixelSize: {
    "ui:widget": "range",
  },
  color: {
    "ui:widget": "color",
  },
  alpha: {
    "ui:widget": "range",
  },
  outlineColor: {
    "ui:widget": "color",
  },
  outlineWidth: {
    "ui:widget": "range",
  },
};

//label schema
export const labelSettingSchema = {
  type: "object",
  required: ["text", "fontSize", "color", "alpha"],
  properties: {
    text: {
      type: "string",
      title: "文字内容",
    },
    fontSize: {
      type: "integer",
      title: "文字大小(像素)",
      minimum: 12,
      multipleOf: 1,
      maximum: 100,
    },
    color: {
      type: "string",
      title: "文字颜色",
      // description: "点颜色设置",
    },
    alpha: {
      type: "number",
      title: "文字透明度",
      // description: "点透明度设置",
      minimum: 0,
      maximum: 1,
      multipleOf: 0.01,
    },
    showBackground: {
      type: "boolean",
      title: "是否有背景",
    },
  },
  dependencies: {
    showBackground: {
      oneOf: [
        {
          required: ["showBackground"],
          properties: {
            showBackground: {
              enum: [false],
            },
          },
        },
        {
          required: [
            "showBackground",
            "backgroundColor",
            "backgroundColorAlpha",
            "backgroundPaddingX",
            "backgroundPaddingY",
          ],
          properties: {
            showBackground: {
              enum: [true],
            },

            backgroundColor: {
              type: "string",
              title: "背景颜色",
              default: "#ffffff", //动态字段必须添加默认值
            },
            backgroundColorAlpha: {
              type: "number",
              title: "背景透明度",
              minimum: 0,
              maximum: 1,
              multipleOf: 0.01,
              default: 1, //动态字段必须添加默认值
            },
            backgroundPaddingX: {
              type: "integer",
              title: "背景水平边距(像素)",
              minimum: 1,
              multipleOf: 1,
              maximum: 100,
              default: 10, //动态字段必须添加默认值
            },
            backgroundPaddingY: {
              type: "integer",
              title: "背景垂直边距(像素)",
              minimum: 1,
              multipleOf: 1,
              maximum: 100,
              default: 10, //动态字段必须添加默认值
            },
          },
        },
      ],
    },
  },
};

export const labelSettingUiSchema = {
  text: {
    "ui:widget": "textarea",
  },
  fontSize: {
    "ui:widget": "range",
  },
  color: {
    "ui:widget": "color",
  },
  alpha: {
    "ui:widget": "range",
  },
  backgroundColor: {
    "ui:widget": "color",
  },
  backgroundColorAlpha: {
    "ui:widget": "range",
  },
  backgroundPaddingX: {
    "ui:widget": "range",
  },
  backgroundPaddingY: {
    "ui:widget": "range",
  },
};

//动态字段，开启omitExtraData与liveOmit
export const labelSettingFormProps = {
  omitExtraData: true,
  liveOmit: true,
  liveValidate: true,
};

//model schema
export const modelSettingSchema = {
  type: "object",
  required: ["uri", "color", "alpha", "scale", "runAnimations", "orientation"],
  properties: {
    uri: {
      type: "string",
      format: "uri",
      title: "选择本地模型",
      description:
        "选中模型后,需要上传才能保存云端绘图内容,否则仅在浏览器内本地预览(支持.glb, .gltf)",
    },
    color: {
      type: "string",
      title: "颜色",
      // description: "点颜色设置",
    },
    alpha: {
      type: "number",
      title: "透明度",
      // description: "点透明度设置",
      minimum: 0,
      maximum: 1,
      multipleOf: 0.01,
    },
    scale: {
      type: "number",
      title: "模型缩放比例",
      minimum: 0,
    },
    runAnimations: {
      title: "是否开启动画",
      type: "boolean",
    },
    orientation: {
      type: "object",
      title: "模型旋转",
      required: ["heading", "pitch", "roll"],
      properties: {
        heading: {
          type: "number",
          title: "heading旋转",
          minimum: 0,
          maximum: 360,
        },
        pitch: {
          type: "number",
          title: "pitch旋转",
          minimum: 0,
          maximum: 360,
        },
        roll: {
          type: "number",
          title: "roll旋转",
          minimum: 0,
          maximum: 360,
        },
      },
    },
    colorBlendMode: {
      type: "string",
      title: "模型颜色混合模式",
      anyOf: [
        {
          type: "string",
          title: "高亮",
          enum: ["HIGHLIGHT"],
        },
        {
          type: "string",
          title: "替换",
          enum: ["REPLACE"],
        },
        {
          type: "string",
          title: "混合",
          enum: ["MIX"],
        },
      ],
    },
    //设置轮廓后，部分模型会导致渲染崩溃，目前无解决方案
    // silhouetteSize: {
    //   type: "integer",
    //   title: "模型轮廓宽度(像素)",
    //   minimum: 0,
    //   maximum: 100,
    //   multipleOf: 1,
    // },
    // silhouetteColor: {
    //   type: "string",
    //   title: "模型轮廓颜色",
    // },
  },
  dependencies: {
    colorBlendMode: {
      oneOf: [
        {
          required: ["colorBlendMode"],
          properties: {
            colorBlendMode: {
              enum: ["HIGHLIGHT", "REPLACE"],
            },
          },
        },
        {
          required: ["colorBlendMode", "colorBlendAmount"],
          properties: {
            colorBlendMode: {
              enum: ["MIX"],
            },
            colorBlendAmount: {
              type: "number",
              title: "颜色混合比例",
              minimum: 0,
              maximum: 1,
              multipleOf: 0.01,
              default: 0, //动态字段必须添加默认值
            },
          },
        },
      ],
    },
  },
};

export const modelSettingUiSchema = {
  uri: {
    "ui:widget": "fileToUrl",
    "ui:options": {
      accept: [".glb", ".gltf"],
      upload: true,
      identifier: "rjsf-drawing-model",
    },
  },
  color: {
    "ui:widget": "color",
  },
  alpha: {
    "ui:widget": "range",
  },
  colorBlendAmount: {
    "ui:widget": "range",
  },
  // silhouetteSize: {
  //   "ui:widget": "range",
  // },
  // silhouetteColor: {
  //   "ui:widget": "color",
  // },
};
