import React from "react";
import { ariaDescribedByIds } from "@rjsf/utils";
import { useSnackbar } from "notistack";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Slider from "@mui/material/Slider";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputBase from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { useCreateUserUploadFile } from "../../gql";

export function FileToUrlWidget(props) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    value,
    readonly,
    disabled,
    onBlur,
    onFocus,
    options,
    schema,
    onChange,
    required,
    label,
    id,
  } = props;

  //清理字段的本地bloc url缓存，但是对于缓存的网络url保留
  React.useEffect(() => {
    if (!file && value && value.startsWith("blob:")) {
      onChange("");
    }
  });

  const [createUserUploadFileMutation, { loading }] = useCreateUserUploadFile(
    {}
  );

  const [file, setFile] = React.useState(null);
  const inputRef = React.createRef(null);

  const handleChange = (e) => {
    const filesWithUrl = Object.assign(e.target.files[0], {
      url: URL.createObjectURL(e.target.files[0]),
    });
    onChange(filesWithUrl.url);
    if (file) {
      URL.revokeObjectURL(file.url);
    }
    setFile(filesWithUrl);
  };

  const handleUpload = async () => {
    try {
      const res = await createUserUploadFileMutation({
        variables: {
          data: {
            altText: file.name,
            file: {
              upload: file,
            },
            identifier: options.identifier || "rjsf-FileToUrlWidget",
          },
        },
      });
      if (res.errors) {
        enqueueSnackbar("文件上传失败", {
          variant: "error",
          key: "upload-file-error",
        });
      } else {
        enqueueSnackbar("文件上传成功", {
          variant: "success",
          key: "upload-file-error",
        });
        console.log(res.data);
        onChange(res.data.createUserUploadFile.file.url);
        setFile(null);
      }
    } catch (error) {
      enqueueSnackbar("文件上传失败", {
        variant: "error",
        key: "upload-file-error",
      });
    }
  };

  return (
    <Box
      sx={{
        width: 1,
      }}
    >
      {/* 文件存在时渲染文件元信息 */}
      {file ? (
        <Typography
          variant="subtitle2"
          sx={{
            width: 1,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            fontSize: 12,
          }}
        >
          {file.name} - {(file.size / 1024 / 1024).toFixed(2)}MB
        </Typography>
      ) : (
        <Typography
          variant="subtitle2"
          sx={{
            width: 1,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            fontSize: 12,
          }}
        >
          {value}
        </Typography>
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            if (inputRef.current) {
              inputRef.current.click();
            }
          }}
        >
          {label || schema.title}
        </Button>

        {/* 上传按钮 -- 当option.upload开启时才会上传，否则默认是使用本地bloc url*/}
        {options.upload && (
          <IconButton
            size="small"
            color="error"
            aria-label="delete"
            disabled={!file}
            onClick={handleUpload}
          >
            <CloudUploadIcon />
          </IconButton>
        )}
      </Box>
      {loading && (
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
          }}
        >
          <LinearProgress />
        </Box>
      )}
      <input
        ref={inputRef}
        type="file"
        aria-describedby={ariaDescribedByIds(id)}
        id={id}
        style={{
          display: "none",
        }}
        accept={options.accept ? options.accept.join(", ") : undefined}
        onChange={handleChange}
      />
    </Box>
  );
}
