import { gql, useQuery } from "@apollo/client";
//fragments
//orderBy: [{ name: asc }] 这里强制编码了绑定资产按name排序
export const DIGITAL_SCENE_FIELDS = gql`
  fragment DigitalSceneFields on DigitalScene {
    id
    name
    description
    order
    viewerUrl
    defaultZoomDistance
    defaultCameraView
    usePresetView
    frontViewHeading
    horizontalViewPitch
    near
    far
    origin
    coverImage {
      url
    }
    digitalAssetsLinks(orderBy: [{ name: asc }]) {
      id
      name
      initRender
      renderType
      eventType
      position
      polygon
      polygonClamp
      model {
        url
      }
      modelScale
      modelOrigin
      modelAnimate
      modelHeading
      modelPitch
      modelRoll
      digitalAsset {
        id
        name
        viewerUrl
        type
        producedAt
        tiledModel {
          url
        }
        pointCloud {
          url
        }
        panorama {
          position
        }
      }
    }
  }
`;

//queries
const GET_DIGITAL_SCENE_BY_ID = gql`
  ${DIGITAL_SCENE_FIELDS}
  query DigitalScene($where: DigitalSceneWhereUniqueInput!) {
    digitalScene(where: $where) {
      ...DigitalSceneFields
    }
  }
`;

// $orderBy: [DigitalScene_DigitalAssetOrderByInput!]! = []

// const GET_DIGITAL_SCENE_BY_ID = gql`
//   query DigitalScene($where: DigitalSceneWhereUniqueInput!) {
//     digitalScene(where: $where) {
//       id
//       name
//       description
//       order
//       viewerUrl
//       defaultZoomDistance
//       defaultCameraView
//       usePresetView
//       frontViewHeading
//       horizontalViewPitch
//       near
//       far
//       origin
//       coverImage {
//         url
//       }
//       digitalAssetsLinks(orderBy: []) {
//         id
//         name
//         initRender
//         renderType
//         eventType
//         position
//         polygon
//         polygonClamp
//         model {
//           url
//         }
//         modelScale
//         modelOrigin
//         modelAnimate
//         modelHeading
//         modelPitch
//         modelRoll
//         digitalAsset {
//           id
//           name
//           viewerUrl
//           type
//           producedAt
//           tiledModel {
//             url
//           }
//           panorama {
//             position
//           }
//         }
//       }
//     }
//   }
// `;

//queries
const GET_DIGITAL_SCENES = gql`
  ${DIGITAL_SCENE_FIELDS}
  query DigitalScenes($where: DigitalSceneWhereInput! = {}) {
    digitalScenes(where: $where) {
      ...DigitalSceneFields
    }
  }
`;

//mutations

//hooks query
export function useDigitalScene(options) {
  return useQuery(GET_DIGITAL_SCENE_BY_ID, options);
}

export function useDigitalScenes(options) {
  return useQuery(GET_DIGITAL_SCENES, options);
}

//hooks mutation
