import React from "react";

import { useReactiveVar } from "@apollo/client";
// import Draggable from "react-draggable";

import Box from "@mui/material/Box";
import Portal from "@mui/base/Portal";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";

import CreateIcon from "@mui/icons-material/Create";
import ChatIcon from "@mui/icons-material/Chat";
import BrushIcon from "@mui/icons-material/Brush";
import QueueIcon from "@mui/icons-material/Queue";
import { fabVar, userVar } from "../../../gql";
import {
  CommentEditPanel,
  CreateCommentForm,
  dispatchdrawingStatusVar,
  SourceObjectEditPanel,
  SourceObjectCreateForm,
} from "../../../components";
import { userIsAdminOrEditor } from "../../../accessControl";

function usePanel(initOpen = false) {
  const [open, setOpen] = React.useState(initOpen);
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  return [open, setOpen, handleClose];
}

export function SpeedDialButton({
  children,
  showCommentButton = true,
  showDrawingToolButton = true,
  ...props
}) {
  const user = useReactiveVar(userVar);
  const { position, hidden } = useReactiveVar(fabVar);

  //控制评论面板开关
  const [
    openCreateCommentPanel,
    setOpenCreateCommentPanel,
    handleCommentPanelClose,
  ] = usePanel(false);

  //控制源对象创建面板开关
  const [
    openCreateSourceObjectPanel,
    setOpenCreateSourceObjectPanel,
    handleSourceObjectPanelClose,
  ] = usePanel(false);

  return (
    <>
      {/* <Draggable handle="#draggable-speeddial"> */}
      {/* Fab按钮 */}
      <SpeedDial
        hidden={hidden} //use fabVar to Control hidden
        direction="up"
        ariaLabel="user actions"
        sx={{
          position: "fixed",
          transition: (theme) => theme.transitions.create(),
          ...position, //use fabVar to Control Position
        }}
        icon={<CreateIcon />}
        FabProps={{
          size: "medium",
        }}
        // open={open}
        // onClick={toggleSpeedDialOpen}
      >
        {/* Fab按钮-评论 */}
        {showCommentButton && (
          <SpeedDialAction
            onClick={() => {
              setOpenCreateCommentPanel(true);
            }}
            icon={<ChatIcon />}
            tooltipTitle="发布评论"
          ></SpeedDialAction>
        )}
        {showDrawingToolButton && (
          <SpeedDialAction
            onClick={() => {
              dispatchdrawingStatusVar({
                type: "TOGGLE_DRAWING_TOOL_ACTIVE",
              });
            }}
            icon={<BrushIcon />}
            tooltipTitle="绘图工具"
          ></SpeedDialAction>
        )}

        {userIsAdminOrEditor(user) && (
          <SpeedDialAction
            onClick={() => {
              //同时开启创建表单+绘图工具
              setOpenCreateSourceObjectPanel(true);
              dispatchdrawingStatusVar({
                type: "SET_DRAWING_TOOL_ACTIVE",
              });
            }}
            icon={<QueueIcon />}
            tooltipTitle="创建源对象"
          ></SpeedDialAction>
        )}
      </SpeedDial>
      {/* </Draggable> */}

      {/* 各类编辑面板 */}
      <Portal>
        {/* 创建评论面板 */}
        {openCreateCommentPanel && (
          <CommentEditPanel
            handleClose={handleCommentPanelClose}
            handleId="draggable-create-comment-panel"
          >
            <CreateCommentForm></CreateCommentForm>
          </CommentEditPanel>
        )}

        {openCreateSourceObjectPanel && (
          <SourceObjectEditPanel
            handleClose={handleSourceObjectPanelClose}
            handleId="draggable-create-sourceobject-panel"
          >
            <SourceObjectCreateForm></SourceObjectCreateForm>
          </SourceObjectEditPanel>
        )}
      </Portal>
    </>
  );
}
