import React from "react";
import { useReactiveVar } from "@apollo/client";
import Portal from "@mui/base/Portal";
import Draggable from "react-draggable";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";

import { userVar } from "../gql";
import { userIsAdminOrEditor } from "../accessControl";
import { MetaRenderer } from "../meta";
import { MapItemRenderToolBar } from "./MapItemRenderToolBar";
import { MapItemDataUpdateForm } from "./MapItemDataUpdateForm";
export function MapItemData({ item, schema, handleClose, ...props }) {
  const user = useReactiveVar(userVar);

  //卸载组件后切回来，默认是非编辑状态，如需要修改，需要将edit state提升至SourceObjectContentTabs
  const [edit, setEdit] = React.useState(false);

  return (
    schema.fields &&
    schema.fields.length > 0 && (
      <Portal>
        <Draggable
          handle="#draggable-map-item-content"
          cancel=".map-item-title"
          // 默认使Paper居中
          positionOffset={{
            x: "-50%",
            y: 0,
          }}
        >
          <Paper
            sx={{
              bgcolor: "background.alphaPaper",
              zIndex: "mapItemContent",
              position: "fixed",
              top: 80, //默认向下移动80px，不遮挡logo和指南针
              left: "50%",

              // maxHeight: "70vh",
              overflow: "auto",
              width: {
                xs: 1,
                sm: "80%",
                xl: "70%",
              },
              borderRadius: {
                xs: "unset",
                sm: 4,
              },
              pb: 1,
            }}
          >
            {/* map item header */}
            <Box
              id="draggable-map-item-content"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: 1,
                p: 1,
                my: 1,
                width: "100%",
              }}
            >
              {/* 对象名称*/}
              <Typography
                variant="h4"
                color="primary"
                className="map-item-title"
                sx={{ ml: 1 }}
              >
                {item.name}
              </Typography>

              <IconButton
                disableRipple
                onClick={handleClose}
                color="secondary"
                className="map-item-title"
                size="small"
              >
                <CloseIcon
                  sx={{
                    fontSize: 24,
                  }}
                ></CloseIcon>
              </IconButton>
            </Box>

            {/* map item content */}
            <Box
              sx={{
                maxHeight: "60vh",
                width: "100%",
                overflowY: "auto",
                p: 2,
              }}
            >
              {/* 数据管理工具 */}
              {userIsAdminOrEditor(user) && (
                <MapItemRenderToolBar
                  item={item}
                  schema={schema}
                  edit={edit}
                  setEdit={setEdit}
                  mb={1}
                ></MapItemRenderToolBar>
              )}
              {edit ? (
                <MapItemDataUpdateForm
                  item={item}
                  schema={schema}
                ></MapItemDataUpdateForm>
              ) : Object.keys(item.data).length !== 0 ? (
                <MetaRenderer
                  data={item.data}
                  fields={schema.fields}
                  fieldRootBoxProps={{
                    mb: 1,
                    maxWidth: "100%",
                  }}
                ></MetaRenderer>
              ) : (
                "暂无数据,请点击编辑属性,修改数据"
              )}
            </Box>
          </Paper>
        </Draggable>
      </Portal>
    )
  );
}
