import React from "react";
import Draggable from "react-draggable";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Portal from "@mui/base/Portal";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { ResetGeometrySettingButton } from "./ResetGeometrySettingButton";

//icon
import CloseIcon from "@mui/icons-material/Close";

export function DrawingSettingContainer({
  title,
  handleClose,
  resetGeometryType,
  children,
}) {
  return (
    <Portal>
      <Draggable
        handle="#draggable-drawing-setting"
        cancel={'[class*="MuiIconButton-root"]'}
      >
        <Paper
          elevation={8}
          sx={{
            width: 350,
            maxHeight: "80vh",
            position: "fixed",
            zIndex: (theme) => theme.zIndex.drawingBar + 1,
            overflowY: "auto",
            top: 16,
            left: 16,
            bgcolor: "background.alphaPaper",
          }}
        >
          <Box
            id="draggable-drawing-setting"
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "move",
              px: 2,
              py: 1,
              borderBottom: 1,
              borderColor: "grey.500",
            }}
          >
            <Typography
              variant="h6"
              display="block"
              // color="primary.main"
              align="left"
              sx={{
                fontWeight: "bold",
              }}
            >
              {title}
            </Typography>
            {resetGeometryType && (
              <ResetGeometrySettingButton
                geometryType={resetGeometryType}
              ></ResetGeometrySettingButton>
            )}
            {/* 关闭工具面板按钮 */}
            {handleClose && (
              <Tooltip
                arrow
                title="未保存直接关闭会清除当前绘制内容"
                placement="bottom"
              >
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  size="small"
                  sx={{
                    ml: "auto",
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          {children}
        </Paper>
      </Draggable>
    </Portal>
  );
}
