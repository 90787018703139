import { gql, useQuery, useMutation } from "@apollo/client";

//fragments
const COMMENT_FIELDS = gql`
  fragment CommentFields on Comment {
    id
    text
    position
    iconType
    repliesCount
    imagesCount
    createdAt
    user {
      id
      name
      avatar {
        url
      }
    }
    tags {
      id
      name
    }
  }
`;

//queries
const GET_COMMENT_BY_ID = gql`
  ${COMMENT_FIELDS}
  query getComment($where: CommentWhereUniqueInput!) {
    comment(where: $where) {
      ...CommentFields
      updatedAt
      images {
        id
        altText
        image {
          url
        }
      }
    }
  }
`;

//queries
const GET_COMMENTS = gql`
  ${COMMENT_FIELDS}
  query getComments($where: CommentWhereInput! = {}) {
    comments(where: $where) {
      ...CommentFields
    }
  }
`;

//mutations
const CREATE_COMMENT = gql`
  ${COMMENT_FIELDS}
  mutation CreateComment($data: CommentCreateInput!) {
    createComment(data: $data) {
      ...CommentFields
      updatedAt
      images {
        id
        altText
      }
    }
  }
`;

const UPDATE_COMMENT = gql`
  ${COMMENT_FIELDS}
  mutation UpdateComment(
    $where: CommentWhereUniqueInput!
    $data: CommentUpdateInput!
  ) {
    updateComment(where: $where, data: $data) {
      ...CommentFields
      updatedAt
      images {
        id
        altText
      }
    }
  }
`;

const DELETE_COMMENT = gql`
  mutation DeleteComment($where: CommentWhereUniqueInput!) {
    deleteComment(where: $where) {
      id
    }
  }
`;

//hooks query
export function useComment(options) {
  return useQuery(GET_COMMENT_BY_ID, options);
}

export function useComments(options) {
  return useQuery(GET_COMMENTS, options);
}

//hooks mutation
export function useCreateComment(options) {
  return useMutation(CREATE_COMMENT, options);
}

export function useUpdateComment(options) {
  return useMutation(UPDATE_COMMENT, options);
}

export function useDeleteComment(options) {
  return useMutation(DELETE_COMMENT, options);
}
