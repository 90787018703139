//项目欢迎页面
const welcome = {
  title: "UI配置",
  type: "object",
  required: ["titleColor", "subTitleColor", "coverImage", "coverImageOpacity"],
  properties: {
    titleColor: {
      type: "string",
      title: "标题文字颜色",
      default: "#000000",
    },
    subTitleColor: {
      type: "string",
      title: "副标题文字颜色",
      default: "#99292c",
    },
    coverImage: {
      type: "string",
      title: "封面图链接",
      default: "http://config.yuancj.com/jkcc/assets/cover.jpg",
    },
    coverImageOpacity: {
      type: "number",
      title: "封面图透明度",
      default: 0.5,
      minimum: 0,
      maximum: 1,
      multipleOf: 0.01,
    },
  },
};

const welcomeUi = {
  titleColor: {
    "ui:widget": "color",
  },
  subTitleColor: {
    "ui:widget": "color",
  },
  coverImage: {
    "ui:widget": "uri",
  },
  coverImageOpacity: {
    "ui:widget": "range",
  },
};

//数字资产索引页面
const assetsIndex = {
  title: "UI配置",
  description: "配置各类资产的介绍以及封面图",
  type: "object",
  required: ["model", "pointcloud", "orthophoto", "panorama"],
  properties: {
    model: {
      title: "实景模型",
      type: "object",
      required: ["active", "description", "coverImage"],
      properties: {
        active: {
          type: "boolean",
          title: "启用此类型资产入口",
          default: true,
        },
        description: {
          type: "string",
          title: "文字介绍",
          default:
            "利用摄影测量技术，通过无人机或地面系统采集的图像数据，制作资产对象真实、立体、时序的实景三维模型，是构建数字场景的主要资产类型",
        },
        coverImage: {
          type: "string",
          title: "封面图链接",
          default: "http://config.yuancj.com/lzx/assets/model_cover.jpg",
        },
      },
    },
    pointcloud: {
      title: "三维点云",
      type: "object",
      required: ["active", "description", "coverImage"],
      properties: {
        active: {
          type: "boolean",
          title: "启用此类型资产入口",
          default: true,
        },
        description: {
          type: "string",
          title: "文字介绍",
          default:
            "通过地面、手持式三维激光扫描仪，获取资产对象精准的三维空间点云数据，为构建数字场景提供重要参考",
        },
        coverImage: {
          type: "string",
          title: "封面图链接",
          default: "http://config.yuancj.com/lzx/assets/pointcloud_cover.jpg",
        },
      },
    },
    orthophoto: {
      title: "正射影像",
      type: "object",
      required: ["active", "description", "coverImage"],
      properties: {
        active: {
          type: "boolean",
          title: "启用此类型资产入口",
          default: true,
        },
        description: {
          type: "string",
          title: "文字介绍",
          default:
            "以实景模型或点云模型为基础，制作资产对象典型平立剖面的高分辨率影像图，有效衔接传统工作需求",
        },
        coverImage: {
          type: "string",
          title: "封面图链接",
          default: "http://config.yuancj.com/lzx/assets/orthophoto_cover.jpg",
        },
      },
    },
    panorama: {
      title: "全景照片",
      type: "object",
      required: ["active", "description", "coverImage"],
      properties: {
        active: {
          type: "boolean",
          title: "启用此类型资产入口",
          default: true,
        },
        description: {
          type: "string",
          title: "文字介绍",
          default:
            "通过空中、地面系统采集重要空间节点的全景图像，提供沉浸式的场景还原体验，是构建数字场景的重要资产类型",
        },
        coverImage: {
          type: "string",
          title: "封面图链接",
          default: "http://config.yuancj.com/lzx/assets/panorama_cover.jpg",
        },
      },
    },
  },
};

const assetsIndexUi = {
  model: {
    description: {
      "ui:widget": "textarea",
    },
    coverImage: {
      "ui:widget": "uri",
    },
  },
  pointcloud: {
    description: {
      "ui:widget": "textarea",
    },
    coverImage: {
      "ui:widget": "uri",
    },
  },
  orthophoto: {
    description: {
      "ui:widget": "textarea",
    },
    coverImage: {
      "ui:widget": "uri",
    },
  },
  panorama: {
    description: {
      "ui:widget": "textarea",
    },
    coverImage: {
      "ui:widget": "uri",
    },
  },
};

//全部配置
export const pageUiConfigSchemas = { welcome, assetsIndex };

export const pageUiConfigUiSchemas = {
  welcome: welcomeUi,
  assetsIndex: assetsIndexUi,
};
