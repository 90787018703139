import React from "react";
import GlobalStyles from "@mui/material/GlobalStyles";

import { Viewer, Globe } from "resium";

export function CustomViewer({ children, ...props }) {
  // const ref = React.useRef(null);

  // React.useEffect(() => {
  //   if (ref.current && ref.current.cesiumElement) {
  //     const shadowMap = ref.current.cesiumElement.shadowMap;
  //     console.log(shadowMap);
  //   }
  // }, []);
  return (
    <>
      {/* 渲染完成Viewer后隐藏进度条 */}
      <GlobalStyles
        styles={{
          ".pace": {
            display: "none",
          },
        }}
      ></GlobalStyles>

      <Viewer
        // ref={ref}
        full
        baseLayerPicker={false}
        animation={false}
        timeline={false}
        vrButton={false}
        homeButton={false}
        navigationHelpButton={false}
        geocoder={false}
        skyBox={false}
        skyAtmosphere={false}
        sceneModePicker={false}
        infoBox={false}
        selectionIndicator={false} //禁止entity选中
        fullscreenButton={false}
        creditContainer="credit"
        shadows={false}
        //性能优化,最低可以降低至10fps渲染
        // resolutionScale={0.3}
        requestRenderMode={true}
        maximumRenderTimeChange={0.1} //10fps
        orderIndependentTranslucency={false}
        {...props}
      >
        <Globe show={false}></Globe>
        {children}
      </Viewer>
    </>
  );
}
