import { createContext, useContext } from "react";

export const SceneContext = createContext();
export const useSceneContext = () => useContext(SceneContext);

// export const AssetRenderContext = createContext();
// export const useAssetRenderContext = () => useContext(AssetRenderContext);

export const DrawingContext = createContext();
export const useDrawingContext = () => useContext(DrawingContext);

export const CommentContext = createContext();
export const useCommentContext = () => useContext(CommentContext);

export const CommentRenderMapContext = createContext();
export const useCommentRenderMapContext = () =>
  useContext(CommentRenderMapContext);

export const ToggleChildrenContext = createContext();
export const useToggleChildrenContext = () => useContext(ToggleChildrenContext);

export const SourceObjectContext = createContext();
export const useSourceObjectContext = () => useContext(SourceObjectContext);

export const SourceObjectRenderMapContext = createContext();
export const useSourceObjectRenderMapContext = () =>
  useContext(SourceObjectRenderMapContext);

export const SupportedMetasMapContext = createContext();
export const useSupportedMetasMapContext = () =>
  useContext(SupportedMetasMapContext);
