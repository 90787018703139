import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import DeleteIcon from "@mui/icons-material/Delete";

export function FileDropField({
  getRootProps,
  getInputProps,
  files,
  setFiles,
  initText,
  helperText,
}) {
  const deleteFileByKey = React.useCallback(
    (key) => {
      setFiles((files) => files.filter((file) => file.preview !== key));
    },
    [setFiles]
  );

  return (
    <Box
      sx={{
        position: "relative",
        cursor: "pointer",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: files.length === 0 ? "center" : "flexStart",
        width: "100%",
        minHeight: 120,
        borderColor: "primary.main",
        bgcolor: "background.alphaPrimary",
        borderWidth: 2,
        borderStyle: "dashed",
        pt: 1,
        pb: files.length === 0 ? 1 : 5,
        px: 1,
        mt: 2,
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {files.length === 0 ? (
        <Typography
          variant="button"
          display="block"
          align="center"
          sx={{
            my: "auto",
          }}
        >
          {initText}
        </Typography>
      ) : (
        // <ul>{filesText}</ul>
        files.map((file) => (
          <Box
            key={file.preview}
            sx={{
              position: "relative",
              width: 100,
              height: 100,
              mr: 1,
              mb: 1,
              "& .MuiIconButton-root,.MuiTypography-root": {
                display: "none",
              },
              //hover时，显示图片删除按钮
              "&:hover": {
                "& .MuiIconButton-root,.MuiTypography-root": {
                  display: "block",
                },
              },
            }}
          >
            <Box
              sx={{
                borderRadius: 1,
                width: 1,
                height: 1,

                // 使图片img标签具有background-size的效果
                objectFit: "cover",
              }}
              component="img"
              src={file.preview}
              onLoad={() => {
                // 元素的onload 事件触发后将销毁URL对象, 释放内存。
                URL.revokeObjectURL(file.preview);
              }}
            ></Box>

            {/* 删除按钮 */}
            <IconButton
              size="small"
              color="error"
              aria-label="delete"
              sx={{
                position: "absolute",
                right: 1,
                top: 1,
              }}
              onClick={(e) => {
                e.stopPropagation();
                deleteFileByKey(file.preview);
              }}
            >
              <DeleteIcon />
            </IconButton>

            {/* 图片元信息显示 */}
            <Typography
              variant="subtitle"
              display="block"
              align="center"
              sx={{
                width: 1,
                bgcolor: "background.alphaPaper",
                px: 0.5,
                position: "absolute",
                bottom: 1,
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              {(file.size / 1024 ** 2).toFixed(2)} MB
            </Typography>
          </Box>
        ))
      )}

      {files.length > 0 && (
        <Button
          variant="text"
          color="error"
          sx={{
            position: "absolute",
            bottom: 1,
            left: "50%",
            transform: "translateX(-50%)",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setFiles([]);
          }}
        >
          清除全部
        </Button>
      )}
    </Box>
  );
}
