import React from "react";
import Box from "@mui/material/Box";

import { ErrorBoundary } from "../../components";

import { fieldRenderMap } from "./fieldRenderers";

function FieldRender({ field, value, fieldRootBoxProps }) {
  const RenderComponent = fieldRenderMap[field.fieldType];
  return (
    <RenderComponent
      field={field}
      value={value}
      {...fieldRootBoxProps}
    ></RenderComponent>
  );
}

export function MetaRenderer({ fields, data, fieldRootBoxProps, ...props }) {
  return (
    <ErrorBoundary message="当前设置的基础属性不符合显示要求,请排查属性内容">
      <Box {...props}>
        {fields.map(
          (field) =>
            //未设置字段直接不显示
            (data[field.key] || data[field.key] === false) && (
              <FieldRender
                field={field}
                value={data[field.key]}
                fieldRootBoxProps={fieldRootBoxProps}
                key={field.key}
              ></FieldRender>
            )
        )}
        {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      </Box>
    </ErrorBoundary>
  );
}
